import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState, setSelectedAndCompatibleProductOptions } from "../compoundActions";

const studioSelectedProductOptionsSlice = createSlice({
    name: "studioSelectedProductOptions",
    initialState: {} as Record<string, string>,
    reducers: {
        setStudioSelectedProductOptions(state, action: PayloadAction<Record<string, string> | undefined>) {
            return action.payload ?? state;
        }
    },
    extraReducers: builder => {
        builder.addCase(setSelectedAndCompatibleProductOptions, (state, action) => {
            return action.payload.studioSelectedProductOptions ?? state;
        });
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.studioSelectedProductOptions ?? state;
        });
    }
});

export const { setStudioSelectedProductOptions } = studioSelectedProductOptionsSlice.actions;
export const studioSelectedProductOptions = studioSelectedProductOptionsSlice.reducer;
