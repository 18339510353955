import { createAction } from "@reduxjs/toolkit";
import type { EaselSceneConfiguration } from "@shared/utils/Scene";
import type { ProductGroupConfiguration, StudioConfiguration } from "@shared/utils/StudioConfiguration";

export interface SetDefaultStatePayloadType {
    productKey?: string | null;
    productVersion?: number;
    customerSelectedProductOptions?: Record<string, string>;
    studioSelectedProductOptions?: Record<string, string>;
    quantity?: number;
    quantityPerSize?: string;
    mpvId?: string | null;
    locale?: string;
    workId?: string;
    workRevisionId?: string | null;
    workName?: string | null;
    workLastSaved?: string | null;
    productName?: string;
    template?: string | null;
    currency?: string;
    owner?: string;
    udsDocument?: any;
    documentRevisionUrl?: string;
    productDataLoadAttempted?: boolean;
    productDataLoadSuccessful?: boolean;
    scenesConfiguration?: EaselSceneConfiguration;
    isFullBleed?: boolean;
    studioConfiguration?: StudioConfiguration;
    productGroupConfiguration?: ProductGroupConfiguration;
    mcpSku?: string;
    mcpVersion?: number;
    hasDesigns: boolean;
    isDesignAssistantTabEnabled?: boolean;
}

export const setDefaultState = createAction<SetDefaultStatePayloadType>("setDefaultState");
