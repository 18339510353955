import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type StateType = {
    show: boolean;
    startTime?: number;
};

// Show Crop Image modal
const showCropImageSlice = createSlice({
    name: "showCropImage",
    initialState: { show: false } as StateType,
    reducers: {
        showCropImage(state, action: PayloadAction<StateType>) {
            return action.payload;
        }
    }
});

export const { showCropImage } = showCropImageSlice.actions;
export const cropImageState = showCropImageSlice.reducer;
