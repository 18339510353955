import React from "react";
import { Button } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { UploadsButton } from "@design-stack-vista/upload-components";
import { UploadsPanelMessages, useUploadComponents } from "@shared/features/UploadsAndAssets";

interface Props extends React.ComponentProps<typeof Button> {
    buttonMessage?: string;
    onClick: (event: React.MouseEvent) => void;
}

export const UploadButtonPresentational = ({ children, onClick, ...rest }: Props) => {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const uploadComponents = useUploadComponents();

    const sizeVarientOfButton = isSmall ? "mini" : "standard";
    return (
        <div className="image-panel-upload-button-container">
            {uploadComponents ? (
                <UploadsButton
                    onClick={onClick}
                    multiple={true}
                    width="full-width"
                    size={sizeVarientOfButton}
                    skin="primary"
                    marginBottom={isSmall ? 5 : 4}
                    aria-describedby={rest["aria-describedby"]}
                    style={{ minHeight: "40px" }}
                >
                    {/* FIXME: UploadsButton `children` props are wrong. */}
                    {/* @ts-ignore */}
                    {children || t(UploadsPanelMessages.uploadButton.id)}
                </UploadsButton>
            ) : (
                <Button
                    style={{ minHeight: "40px" }}
                    onClick={onClick}
                    width="full-width"
                    size={sizeVarientOfButton}
                    skin="primary"
                    marginBottom={isSmall ? 5 : 4}
                    {...rest}
                >
                    {children || t(UploadsPanelMessages.uploadButton.id)}
                </Button>
            )}
        </div>
    );
};
UploadButtonPresentational.displayName = "UploadButtonPresentational";
