import React from "react";
import { newRelicWrapper, debouncedReportRawErrorToSegment, ERROR_CODES } from "@shared/utils/Errors";
import { windowExists, getQueryParams, addQueryParam } from "@shared/utils/WebBrowser";
import { isDevelopmentMode } from "@shared/utils/Debug";
// TODO: https://vistaprint.atlassian.net/browse/DT-12523
// import { saveDesign } from "../clients/saveClient";
import { ErrorPage } from "@shared/features/Errors";
import StudioFiveDebugTools from "./Debug/StudioFiveDebugTools";

const PREVENT_RELOAD_CYCLE = 7000;
const AUTO_REFRESH_WAIT_TIME = 2000;

type StudioErrorBoundaryProps = {
    onComponentDidCatch?: (error: Error, errorInfo: React.ErrorInfo) => void;
};

function addErrorTimingToURL() {
    if (windowExists()) {
        window.location.href = addQueryParam(window.location.href, "pe", Date.now().toString());
    }
}

function reloadAfterTimeout(errorTime: number) {
    const difference = Date.now() - errorTime;
    // if the save happens quickly the error page flashes for only a few hundred milliseconds, and it is jarring
    if (difference < AUTO_REFRESH_WAIT_TIME) {
        setTimeout(addErrorTimingToURL, AUTO_REFRESH_WAIT_TIME - difference);
    } else {
        addErrorTimingToURL();
    }
}

const errorCode = `${ENTITY_CODE}-${ERROR_CODES.ERROR_BOUNDARY}`;

// Lint is not familiar with render prop patterns
// and thinks the error boundary doesn't have a display name
// eslint-disable-next-line react/display-name
export default class StudioErrorBoundary extends React.Component<
    StudioErrorBoundaryProps,
    { hasError: boolean; errorMessage: string }
> {
    constructor(props: StudioErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, errorMessage: "" };
    }

    static getDerivedStateFromError(error: Error) {
        // first make sure this isn't a loop (react crashes on load, perhaps)
        const errorTime = Date.now();
        if (windowExists() && !isDevelopmentMode()) {
            const previousError = getQueryParams().pe;
            // if we don't have a previous error, or the previous error was a long time ago, then try to save & reload
            if (!previousError || errorTime - previousError > PREVENT_RELOAD_CYCLE) {
                // try to reload, and try to save if designer is loaded
                // TODO: useDesigner hook here?
                // enable saving a design
                // TODO: https://vistaprint.atlassian.net/browse/DT-12523
                // if (window && window.designer) {
                //     saveStudio5Design()
                //         .then(() => {
                //             reloadAfterTimeout(errorTime);
                //         })
                //         .catch(err => {
                //             newRelicWrapper.noticeError(err, { errorBoundary: true });
                //             reloadAfterTimeout(errorTime);
                //         });
                // } else {
                //     reloadAfterTimeout(errorTime);
                // }
                reloadAfterTimeout(errorTime);
            }
        }

        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message || error };
    }

    // if I make this functions static like eslint wants, then it doesn't run
    // eslint-disable-next-line class-methods-use-this
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // errorInfo includes the componentStack
        newRelicWrapper.noticeError(error, errorInfo);
        const errorMessage = error.message || error;
        newRelicWrapper.logPageAction("studio-errorpage", {
            errorCode,
            errorMessage
        });
        debouncedReportRawErrorToSegment(errorMessage, errorCode, error.stack, true);
    }

    DebugToolbar = StudioFiveDebugTools;

    render() {
        if (this.state.hasError) {
            return (
                <ErrorPage
                    errorCodeOverride={errorCode}
                    errorMessageOverride={this.state.errorMessage}
                    DebugToolbar={this.DebugToolbar}
                />
            );
        }

        return this.props.children;
    }
}
