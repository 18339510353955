import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState, setSelectedAndCompatibleProductOptions } from "../compoundActions";

const customerSelectedProductOptionsSlice = createSlice({
    name: "customerSelectedProductOptions",
    initialState: {} as Record<string, string>,
    reducers: {
        setCustomerSelectedProductOptions(state, action: PayloadAction<Record<string, string> | undefined>) {
            return action.payload ?? state;
        }
    },
    extraReducers: builder => {
        builder.addCase(setSelectedAndCompatibleProductOptions, (state, action) => {
            return action.payload.customerSelectedProductOptions ?? state;
        });
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.customerSelectedProductOptions ?? state;
        });
    }
});

export const { setCustomerSelectedProductOptions } = customerSelectedProductOptionsSlice.actions;
export const customerSelectedProductOptions = customerSelectedProductOptionsSlice.reducer;
