// Utilities for lodash implementations that simple implementations can substitute
//  See: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore

export const get = (obj: Object, path: string[], defaultValue: any = undefined) => {
    const travel = (regexp: RegExp) =>
        String.prototype.split
            .call(path, regexp)
            .filter(Boolean)
            .reduce((res: any, key: string) => (res !== null && res !== undefined ? res[key] : res), obj);
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === obj ? defaultValue : result;
};
