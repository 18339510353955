// Originally, this entire file is a copy of app/core/behavior/Clipboard.js from cimpress designer.
// It now is not very similiar
import {
    clearStorage,
    copyToLocalStorage,
    getCopiedDimensions,
    getStoredItems,
    setCurrentVisitorId,
    shouldUseStoredItems
} from "./ClipboardStorageUtilities";
import {
    singleColorFixUp,
    offset,
    premiumFinishesFixup,
    scaledDisplaceWithActiveCanvas,
    scrubItemViewModel
} from "./ClipboardAdjustmentUtilities";
import { decoTechCategories, USER_CHANGE_EVENT } from "./constants";
import type { Designer } from "./@types/designer";

let copiedAttributes: ModelAttributes[] = [];
let copiedViewModels: ItemViewModel[] = [];

let originalDimensions: Dimensions;
let originalCanvasId = "";
let originalCanvasDecoTechCategory = "";

function clear() {
    copiedAttributes = [];
    copiedViewModels = [];
    originalCanvasId = "";
    originalCanvasDecoTechCategory = "";
    clearStorage();
}

function onCopy(designer: Designer) {
    clear();
    copiedViewModels = designer.selectionManager.toArray();

    if (copiedViewModels.length) {
        const originalCanvas = designer.documentRepository.getActiveCanvas();
        originalCanvasId = originalCanvas.id;
        originalDimensions = getCopiedDimensions(copiedViewModels);
        originalCanvasDecoTechCategory = decoTechCategories[originalCanvas.get("processType")];
    }
    if (!designer.featureManager.featureEnabled("editRestrictedItems")) {
        copiedViewModels = copiedViewModels.filter(viewModel => !viewModel.model.get("restricted"));
    }

    copiedAttributes = copiedViewModels.map(scrubItemViewModel);
    copyToLocalStorage(designer, copiedAttributes);
}

function onCut(designer: Designer) {
    onCopy(designer);
    const items = copiedViewModels.filter(viewModel => viewModel.get("deletable"));
    if (items.length) {
        designer.commandDispatcher.delete({ viewModels: items });
    }
}

function onPaste(designer: Designer, e: KeyboardEvent) {
    // in chrome, sometimes it thinks text areas still have focus and will
    // happily paste text into them when it shouldn't. this prevents that.
    e.preventDefault();

    if (shouldUseStoredItems()) {
        const { itemAttributes, dimensions, canvasId, canvasDecoTechCategory } = getStoredItems();
        copiedAttributes = itemAttributes;
        originalDimensions = dimensions;
        originalCanvasId = canvasId;
        originalCanvasDecoTechCategory = canvasDecoTechCategory;
    }

    const activeCanvas = designer.documentRepository.getActiveCanvas();
    const activeCanvasProcessType = activeCanvas.get("processType");
    // do not allow copying items between different deco techs
    if (originalCanvasDecoTechCategory !== decoTechCategories[activeCanvasProcessType]) {
        return;
    }

    if (!activeCanvas.get("locked") && !activeCanvas.get("addRestrict") && copiedAttributes.length) {
        if (activeCanvas.id === originalCanvasId) {
            offset(copiedAttributes);
        } else {
            // For canvases are different size, adjust offset using the ratio of the
            // size difference relative to the items center point
            // For multi-select paste this will cause things to move appart / get
            // closer relative to the canvas dimension(s) that are different
            scaledDisplaceWithActiveCanvas(designer, activeCanvas, originalDimensions, copiedAttributes);
        }

        premiumFinishesFixup(activeCanvas, copiedAttributes);
        singleColorFixUp(activeCanvas, copiedAttributes);

        // Filter out images that are not valid
        const validItems = copiedAttributes.filter(item => {
            if (item.module !== "UploadedImage") return true;
            return !!item.printUrl || !!item.premiumFinishMaskPrintUrl;
        });

        // If no items valid to paste don't do anything
        if (validItems?.length === 0) {
            return;
        }
        const options = {
            model: activeCanvas,
            items: validItems,
            selectNewModels: true
        };

        designer.commandDispatcher.add(options);
    }
}

function onOwnerChange(userId: string) {
    clear();
    setCurrentVisitorId(userId);
}

export default {
    enable(designer: Designer) {
        const eventMapping = [
            { key: designer.eventBus.events.copyItems, callback: onCopy.bind(this, designer) },
            { key: designer.eventBus.events.cutItems, callback: onCut.bind(this, designer) },
            { key: designer.eventBus.events.pasteItems, callback: onPaste.bind(this, designer) },
            { key: USER_CHANGE_EVENT, callback: onOwnerChange.bind(this) }
        ];

        eventMapping.forEach(({ key, callback }) => designer.eventBus.on(key, callback));
    },
    clear
};
