export function isItemQRCodePlaceholder(item: Item) {
    // eslint-disable-next-line no-underscore-dangle
    return (
        item._itemViewModel.model.get("placeholder") === true &&
        item._itemViewModel.model.get("templateMetadata")?.placeholder === true &&
        item._itemViewModel.model.get("templateMetadata")?.intent === "QR Code"
    );
}

// I think this function is doing too much. It should check the type outside the function
// Say i call this on a textfield, it's going to return false.
export function isImageUnreplacedPlaceholder(item: Item): item is ImageItem {
    // eslint-disable-next-line no-underscore-dangle
    const placeholder = item._itemViewModel.model.get("placeholder");
    // eslint-disable-next-line no-underscore-dangle
    const placeholderReplaced = item._itemViewModel.model.get("placeholderReplaced");
    const itemIsQRPlaceholder = isItemQRCodePlaceholder(item);
    return item.itemType === "IMAGE" && placeholder && !placeholderReplaced && !itemIsQRPlaceholder;
}
