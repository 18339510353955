import React, { useMemo, useState } from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "@vp/i18n-helper";
import { TempStudioMetadataProvider } from "@easel";
import { IdentityProvider } from "@design-stack-vista/identity-provider";
import { SwanProvider, ScreenClassProvider } from "@vp/swan";
import { getQueryParams } from "@shared/utils/WebBrowser";
import { isProd } from "@shared/utils/Debug";
import { isCareAgent } from "@shared/utils/Care";
import { initializeI18n } from "@shared/utils/i18n";
import { PageContextProps, PageContextProvider, HeadCollection } from "@shared/features/StudioConfiguration";
import { Store, setLocale, DexName } from "@shared/redux";
import { TrackingClient, fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import { TrackEventsProvider, TrackingClientProvider } from "@shared/features/Tracking";
import { AbTestContextProvider } from "@shared/features/ABTesting";
import { CartContextProvider } from "@shared/features/Cart";
import { CompetitiveBaseliningABProvider } from "@shared/features/CompetitiveBaselining";
import { MyProjectsPanelProvider } from "@shared/features/MyProjects";
import { SmartValidationsProvider } from "@shared/features/SmartValidations";
import { StudioFlexibilityABProvider } from "@shared/features/Flexibility";
import { FullScreenLoader } from "@shared/features/Loading";
import { GenerateProofABProvider } from "@shared/features/GlobalHeader";
import { Studio6ABProvider } from "@shared/features/Studio6ABTest";
import { UploadQRABProvider } from "@shared/features/QRUrlUploads";
import StudioSiteContent from "./StudioSiteContent";
import { Studio5HeadCollection } from "./components/Studio5HeadCollection";
import { GlobalCSSVariableOverides } from "./components/GlobalCSSVariableOverrides";

interface StudioFiveTemplateWrapperProps {
    pageContext: PageContextProps;
}
export function StudioFiveTemplateWrapper({
    children,
    pageContext
}: React.PropsWithChildren<StudioFiveTemplateWrapperProps>) {
    const { locale = "en-ie", ...context } = pageContext || {};
    const { dispatch } = Store;
    const ownerId = getQueryParams().owner;
    const [i18nInstance] = useState(() =>
        initializeI18n(locale.toLowerCase(), context.studioTranslations, context.easelTranslations)
    );
    const trackingClient = useMemo(() => new TrackingClient(DexName.Studio5), []);
    // store the locale in redux now that it's been removed from pageContext
    if (Store.getState().locale !== locale) {
        dispatch(setLocale(locale));
    }
    return (
        <I18nextProvider i18n={i18nInstance}>
            <SwanProvider swanTenant="vistaprint" swanLocale={locale}>
                <Provider store={Store}>
                    <HeadCollection locale={locale} UILibraryDomain={context.UILibraryDomain}>
                        <Studio5HeadCollection />
                        <FullScreenLoader />
                        <IdentityProvider
                            locale={locale}
                            isProd={isProd()}
                            isCareAgent={isCareAgent()}
                            ownerId={ownerId}
                        >
                            <AbTestContextProvider>
                                <PageContextProvider content={context}>
                                    <TempStudioMetadataProvider>
                                        <ScreenClassProvider>
                                            <CompetitiveBaseliningABProvider>
                                                <MyProjectsPanelProvider>
                                                    <GenerateProofABProvider>
                                                        <UploadQRABProvider>
                                                            <StudioFlexibilityABProvider>
                                                                <SmartValidationsProvider>
                                                                    <CartContextProvider>
                                                                        <StudioSiteContent>
                                                                            <TrackEventsProvider
                                                                                fireEventTracker={
                                                                                    fireDesignToolTrackingEvent
                                                                                }
                                                                            >
                                                                                <TrackingClientProvider
                                                                                    trackingClient={trackingClient}
                                                                                >
                                                                                    <GlobalCSSVariableOverides>
                                                                                        <Studio6ABProvider
                                                                                            shouldTrackImpression
                                                                                        >
                                                                                            {" "}
                                                                                            {children}
                                                                                        </Studio6ABProvider>
                                                                                    </GlobalCSSVariableOverides>
                                                                                </TrackingClientProvider>
                                                                            </TrackEventsProvider>
                                                                        </StudioSiteContent>
                                                                    </CartContextProvider>
                                                                </SmartValidationsProvider>
                                                            </StudioFlexibilityABProvider>
                                                        </UploadQRABProvider>
                                                    </GenerateProofABProvider>
                                                </MyProjectsPanelProvider>
                                            </CompetitiveBaseliningABProvider>
                                        </ScreenClassProvider>
                                    </TempStudioMetadataProvider>
                                </PageContextProvider>
                            </AbTestContextProvider>
                        </IdentityProvider>
                    </HeadCollection>
                </Provider>
            </SwanProvider>
        </I18nextProvider>
    );
}
StudioFiveTemplateWrapper.displayName = "StudioFiveTemplateWrapper";
