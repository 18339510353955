export const enableBetweenBoundariesValidation = {
    validations: {
        betweenBoundaries: {
            enabled: true,
            overlay: true,
            image: {
                enabled: true,
                tolerance: 0,
                innerBoundary: "safety",
                outerBoundary: "bleed"
            },
            shape: {
                enabled: true,
                tolerance: 0,
                innerBoundary: "safety",
                outerBoundary: "bleed"
            },
            preventSave: false
        }
    }
};
