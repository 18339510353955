import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const isPremiumFinishToggleOnSlice = createSlice({
    name: "isPremiumFinishToggleOn",
    initialState: true,
    reducers: {
        setPremiumFinishToggle: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setPremiumFinishToggle } = isPremiumFinishToggleOnSlice.actions;
export const isPremiumFinishToggleOn = isPremiumFinishToggleOnSlice.reducer;
