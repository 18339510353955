import qs from "qs";
import { getStringifiedSelectedImageTypes, itemIsIcon } from "@designer-suite";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { UploadTypes, ImageType } from "@shared/features/UploadsAndAssets";
import { SelectionTrackingData } from "@shared/features/Tracking";
import { getStringifiedSelectedItemTypes } from "./utils";
import { isImageUnreplacedPlaceholder } from "./placeholderUtilities";

export function getTrackingDataForSelection(selection: CanvasItem[]): SelectionTrackingData {
    return {
        itemType: getStringifiedSelectedItemTypes(selection),
        unreplacedImagePlaceholder:
            selection.filter(item => item.itemType === ItemTypes.IMAGE).filter(isImageUnreplacedPlaceholder).length > 0,
        imageType: getStringifiedSelectedImageTypes(selection)
    };
}

export function getTrackingDataForImageSelection(selection: CanvasItem[]) {
    const imageViewModel = (selection[0] as ImageItem)._itemViewModel;
    const isSingleColor = imageViewModel.get("isOneColor");
    const isIcon = itemIsIcon(selection[0]);
    const imageType = isIcon ? UploadTypes.ICON : UploadTypes.IMAGE;

    // TODO: Update analysis for saved images in DT-3647
    const { analysis } = imageViewModel.model.attributes;
    return {
        isSingleColor,
        imageType,
        isLogo: analysis?.isLogo === "True",
        isPhoto: analysis?.isPhoto === "True",
        isVector: analysis?.isVector === "True",
        lineartness: analysis?.lineartness
    };
}

interface ImageInformation {
    imageType: string;
    imageId?: string;
}

export function classifyImage(item: Item): ImageInformation {
    const originalUrl = item._itemViewModel.model.get("originalUrl");
    // deposit photos + noun project
    if (originalUrl?.includes("image-library-service")) {
        const pathPieces = new URL(originalUrl).pathname.split("/");
        const id = pathPieces[pathPieces.length - 1];
        const pathItem = pathPieces[pathPieces.length - 2];
        const imageType = pathItem === "image" ? ImageType.DepositPhotos : ImageType.Noun;
        return {
            imageType,
            imageId: id
        };
    }
    // template / old world image library images
    const previewUrl: string = item._itemViewModel.model.get("previewUrl");
    if (previewUrl?.includes("vpid")) {
        const vpid = qs.parse(new URL(previewUrl).search).vpid as string;
        return {
            imageType: ImageType.Library,
            imageId: vpid
        };
    }
    // Its an upload
    // Get the file name if possible, only available on initial upload
    const name = item._itemViewModel.model.get("file")?.name;
    if (name) {
        return {
            imageType: ImageType.Upload,
            imageId: name
        };
    }
    return {
        imageType: ImageType.Upload
    };
}
