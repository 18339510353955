import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ClipartGalleryPreview {
    id: string;
    src: string;
    width: number;
    height: number;
    previewUrl: string;
    printUrl: string;
    printPixelHeight: number;
    printPixelWidth: number;
}

const clipartGalleryPreviewSlice = createSlice({
    name: "clipartGalleryPreview",
    initialState: null as ClipartGalleryPreview | null,
    reducers: {
        setClipartGalleryPreview: (state, action: PayloadAction<ClipartGalleryPreview | null>) => {
            return action.payload;
        }
    }
});

export const { setClipartGalleryPreview } = clipartGalleryPreviewSlice.actions;
export const clipartGalleryPreview = clipartGalleryPreviewSlice.reducer;
