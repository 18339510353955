import { useIdentityContext } from "@design-stack-vista/identity-provider";

interface createQRCodeDataParams {
    url: string;
    color: string | undefined;
    width: number;
    height: number;
    altId: string | undefined;
    locale: string;
    identityContext: ReturnType<typeof useIdentityContext>;
}

export const createQRCodeData = async (params: createQRCodeDataParams) => {
    const {
        url,
        color,
        width,
        height,
        altId,
        identityContext: { identity, auth },
        locale
    } = params;
    let qrCode = { redirectionUrl: url, qrAltId: altId || "" };
    const user = {
        shopperId: identity.shopperId,
        anonymousUserId: identity.anonymousUserId
    };
    const data = {
        url,
        processedUrl: url,
        color,
        width,
        height,
        ...user
    };
    try {
        if (!altId) {
            const url = `${VISTA_CONNECT_QR_CODE_URL}/api/qrcode/itemref/qrdata`;
            const response = await fetch(url, {
                body: JSON.stringify(data),
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.getToken()}`
                }
            });
            qrCode = await response.json();
        }
    } catch (error) {
        // error while retreiving qr code
        qrCode.qrAltId = "1";
    }

    return {
        url,
        processedUrl: qrCode.redirectionUrl,
        altId: qrCode.qrAltId,
        color,
        locale
    };
};

export interface editQRCodeDataParams {
    url: string;
    identityContext: ReturnType<typeof useIdentityContext>;
}

export const editQRCodeData = async (params: editQRCodeDataParams) => {
    const {
        url,
        identityContext: { identity, auth }
    } = params;
    const user = {
        shopperId: identity.shopperId,
        anonymousUserId: identity.anonymousUserId
    };
    const data = {
        url,
        processedUrl: url,
        ...user
    };
    const VCEndpoint = `${VISTA_CONNECT_QR_CODE_URL}/api/qrcode/qrdata`;
    const response = await fetch(VCEndpoint, {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.getToken()}`
        }
    });
    const qrCode = await response.json();
    return { url, processedUrl: qrCode.qrRedirectionUrl, altId: qrCode.uuid };
};
