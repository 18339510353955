import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

interface SetQuantityPayload {
    quantity?: number;
    quantityPerSize?: string;
}

const quantitySlice = createSlice({
    name: "quantity",
    initialState: 0,
    reducers: {
        setQuantity: (state, action: PayloadAction<SetQuantityPayload>) => action.payload.quantity || state,
        resetQuantity: () => 1
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.quantity || state;
        });
    }
});

export const { setQuantity, resetQuantity } = quantitySlice.actions;
export const quantity = quantitySlice.reducer;
