import qs from "qs";
import { windowExists } from "../WebBrowser/general";

export const AVAILABLE_FEATURE_FLAGS = [
    "studioLive",
    "textImageSelection",
    "disable uploadComponents",
    "forceUseVortex",
    "xerox",
    "xerox-flip",
    "aiTemplates",
    "advancedTools",
    "studioLiveSix",
    "mobileTemplates"
] as const;

export type Flag = typeof AVAILABLE_FEATURE_FLAGS[number];

const VP_EASEL_FLAG_PREFIX = "vp-easel-flag-";
/**
 * This function will extract the flag from the query parameters and will return a boolean based on parameter value.
 * Will also store in local storage so you do not need to provide the query parameter on reload.
 * @param {string} flagName
 */
export function isFlagEnabled(flagName: Flag) {
    if (!windowExists()) {
        return false;
    }
    const urlParams = qs.parse(window.location.search.substr(1));

    const paramValue = urlParams[flagName];

    const localStorageFlagKey = VP_EASEL_FLAG_PREFIX + flagName;

    if (paramValue === "false" || paramValue === "0") {
        window.localStorage.removeItem(localStorageFlagKey);
    }

    if (paramValue === "true" || paramValue === "1") {
        window.localStorage.setItem(localStorageFlagKey, "true");
    }

    return window.localStorage.getItem(localStorageFlagKey) === "true";
}

export function toggleFlag(flagName: Flag) {
    const localStorageFlagKey = VP_EASEL_FLAG_PREFIX + flagName;
    if (window.localStorage.getItem(localStorageFlagKey) === "true") {
        window.localStorage.removeItem(localStorageFlagKey);
    } else {
        window.localStorage.setItem(localStorageFlagKey, "true");
    }
}
