import { MinFontSize } from "./types";

const MIN_FONT_SIZE_BY_DECO_TECH = {
    embroideryFlat: 30,
    heatTransfer: 24,
    directToGarment: 18,
    screenPrint: 18,
    padPrint: 8,
    digital: 8,
    inkJet: 8,
    sublimation: 8,
    laserEngraving: 9,
    offsetOrDigital: 8,
    offset: 8
};

export const MIN_FONT_SIZE_BY_FONT_FLAVOR = {
    print: 8,
    embroidery: 30
};

// default taken from rich-text-manager
export const DEFAULT_MAX_FONT_SIZE = 10000;

export function getMinFontSizeForDecorationTechnology(decorationTechnology: string): number {
    if (Object.keys(MIN_FONT_SIZE_BY_DECO_TECH).includes(decorationTechnology)) {
        return MIN_FONT_SIZE_BY_DECO_TECH[decorationTechnology];
    }
    if (decorationTechnology.includes("embroidery")) {
        return MIN_FONT_SIZE_BY_FONT_FLAVOR.embroidery;
    }
    return MIN_FONT_SIZE_BY_FONT_FLAVOR.print;
}

export function getMinMaxFontSizes(decorationTechnology: string, productMinFontSize?: MinFontSize) {
    const minFontSizeForProduct = productMinFontSize?.[decorationTechnology];
    const minFontSizeForCimdoc = getMinFontSizeForDecorationTechnology(decorationTechnology);
    const minFontSize =
        !!minFontSizeForProduct && minFontSizeForProduct < minFontSizeForCimdoc
            ? minFontSizeForProduct
            : minFontSizeForCimdoc;
    return {
        minFontSize,
        maxFontSize: DEFAULT_MAX_FONT_SIZE
    };
}
