import React, {
    useContext,
    createContext,
    useEffect,
    useState,
    useMemo,
    ReactNode,
    useRef,
    MutableRefObject
} from "react";
import { useAbTestContext } from "@shared/features/ABTesting";

type ContextData = {
    isSmartValidationsEnabled: boolean;
    isTestActive: boolean;
    elementToFocusOnCloseRef: MutableRefObject<HTMLButtonElement | null>;
};

const context = createContext<ContextData | undefined>(undefined);

export function useSmartValidations() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context.  This must be called within a SmartValidationsProvider");
    }
    return result;
}
enum Variations {
    Control = "variation_1",
    Enabled = "variation_2"
}

export const SmartValidationsExperimentData: ExperimentData = {
    experimentKey: "studio_smart_validations",
    experimentName: "Enable Smart Validation",
    variations: Variations
};

type Props = {
    children: ReactNode | ReactNode[];
};

export const SmartValidationsProvider = ({ children }: Props) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { experimentKey } = SmartValidationsExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [isTestActive, setIsTestActive] = useState(false);
    const elementToFocusOnCloseRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (!experimentKey || !ABTest) {
            return;
        }
        const { isExperimentUsingVariation, trackImpression, isExperimentActive } = ABTest;
        isExperimentActive(experimentKey).then((active: boolean) => setIsTestActive(active));
        const trackImpressionIfInVariant = async (variation: Variations) => {
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res) {
                trackImpression(experimentKey, variation);
                setIsEnabled(variation !== Variations.Control);
            }
        };

        trackImpressionIfInVariant(Variations.Enabled);
        trackImpressionIfInVariant(Variations.Control);
    }, [experimentKey, ABTest]);

    const contextObject = useMemo(
        () => ({
            isSmartValidationsEnabled: isEnabled,
            isTestActive,
            elementToFocusOnCloseRef
        }),
        [isEnabled, isTestActive, elementToFocusOnCloseRef]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

SmartValidationsProvider.displayName = "SmartValidationsProvider";
