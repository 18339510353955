/**
 * Note that these events are not a 1:1 mapping with the Events in Snowflake. Several of
 * these values would actually fall under the same Event and would be differentiated
 * with Event Details. They have been hoisted here as a way to simplify the translation between
 * the previous tracking client methods and the new TrackingClient class.
 */
export enum Events {
    StudioTracking = "Studio Tracking",
    ProductViewed = "Product Viewed",
    StudioPerformance = "Studio Performance",
    DesignToolUsed = "Design Tool Used",
    StudioLogin = "Studio Login",
    ProductAdded = "Product Added",
    InteractionTimed = "Interaction Timed",
    StudioProjectSaved = "Studio Project Saved",
    ExperimentClicked = "Experiment Clicked",
    StudioContinue = "Studio Continue",
    StudioUDSError = "Studio UDS Error",
    StudioErrors = "Studio Errors",
    CareAgentLoadedWork = "CARE_AGENT_LOADED_WORK",
    ProjectEdited = "Project Edited",
    StudioDiagnostic = "Studio Diagnostic",
    StudioSmartValidations = "Studio Smart Validations",
    StudioValidationShown = "Studio Validation Shown",
    StudioUpdateCart = "Studio Update Cart",
    StudioReviewPage = "Studio Review Page",
    StudioPageView = "Studio Page View",
    SearchResultClicked = "Search Result Clicked"
}
