import { createAction } from "@reduxjs/toolkit";
import { CompatibleOptionsEntry } from "../../utils/Product";

export interface PayloadType {
    studioSelectedProductOptions?: Record<string, string>;
    customerSelectedProductOptions?: Record<string, string>;
    compatibleOptions?: CompatibleOptionsEntry[];
}

export const setSelectedAndCompatibleProductOptions = createAction<PayloadType>(
    "setSelectedAndCompatibleProductOptions"
);
