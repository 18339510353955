import React, { useEffect } from "react";
import { Metric, onFCP } from "web-vitals";
import { firePerformanceTrackingEvent } from "@shared/utils/Tracking";

export function Performance() {
    function sendToAnalytics(metric: Metric) {
        const fcp = metric.value; // first contentful paint from https://web.dev/vitals/
        firePerformanceTrackingEvent({
            eventDetail: "First Contentful Paint",
            label: "FCP",
            timeSinceLoad: fcp
        });
    }
    useEffect(() => {
        onFCP(sendToAnalytics);
    }, []);

    return <></>;
}

Performance.displayName = "Performance";
