import { convertSvgToCimdoc } from "@cimpress-technology/svg-to-cimdoc";
import type { SmartShape } from "src/easel/designer-suite/@types/shapes";

const convertShape = (scaledShape: any, rotation: number = 0): SmartShape => {
    return {
        module: "Curve",
        curves: scaledShape.curves,
        viewBox: scaledShape.viewBox,
        width: scaledShape.position.width,
        height: scaledShape.position.height,
        strokeColor: scaledShape.stroke.color,
        fillColor: scaledShape.color,
        top: 0,
        left: 0,
        transforms: {
            rotatable: true,
            scalable: true
        },
        strokeWidth: "0",
        rotation
    };
};

export const getFormattedShape = async (svg: any, rotationAngle: number = 0) => {
    const scaledShapes = await convertSvgToCimdoc({ svgString: svg, widthInMM: 1000 });
    return scaledShapes.map((shape: any) => convertShape(shape, rotationAngle));
};
