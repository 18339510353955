import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const isDesignAssistantTabSlice = createSlice({
    name: "isDesignAssistantTabEnabled",
    initialState: false as boolean,
    reducers: {
        setIsDesignAssistantTabEnabled: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setIsDesignAssistantTabEnabled } = isDesignAssistantTabSlice.actions;
export const isDesignAssistantTabEnabled = isDesignAssistantTabSlice.reducer;
