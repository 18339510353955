// Localstorage Utilities for the Clipboard

import type { Designer } from "./@types/designer";
import { decoTechCategories } from "./constants";

const STORAGE_KEY_NAMESPACE = "studio"; // added to our keys so they don't conflict with other keys used by the site
const VISITOR_ID_STORAGE_KEY = "visitor_id";

let lastCopyTime: string;
let userId = "unknown";

const COPIED_ITEMS_LOCAL_STORAGE_KEY = "clipboard_copied_items";
const LAST_COPY_TIME_LOCAL_STORAGE_KEY = "clipboard_last_copy_time";
const LAST_COPY_CANVAS_DIMENSIONS_LOCAL_STORAGE_KEY = "clipboard_last_copy_canvas_dimensions";
const LAST_COPY_CANVAS_ID_LOCAL_STORAGE_KEY = "clipboard_last_copy_canvas_id";
const LAST_COPY_CANVAS_DECO_TECH_CATEGORY_LOCAL_STORAGE_KEY = "clipboard_last_copy_canvas_deco_tech_category";

// Keep track of all the keys we have stored so we can easily clear them all later
const storedKeys = {};

export function setCurrentVisitorId(id: string) {
    userId = id;
}

export function getCurrentVisitorID() {
    return userId;
}

export function setStorageItem(key: string, value: string[] | string | number | ModelAttributes[] | Dimensions) {
    try {
        if (!localStorage) {
            return;
        }
        storedKeys[key] = true;
        const nameSpacedKey = `${STORAGE_KEY_NAMESPACE}_${key}`;
        localStorage.setItem(nameSpacedKey, JSON.stringify(value));
    } catch (ex) {
        // iPad Safari Private Mode treats local storage as effectively read-only and this throws an exception.
        // For the current session everything works as expected,
        // but the data is not updated between sessions.
    }
}

export function getStorageItem(key: string | null) {
    const nameSpacedKey = `${STORAGE_KEY_NAMESPACE}_${key}`;
    return localStorage ? JSON.parse(localStorage.getItem(nameSpacedKey) as string) : null;
}

export function removeStorageItem(key: string) {
    if (!localStorage) {
        return;
    }
    delete storedKeys[key];
    const nameSpacedKey = `${STORAGE_KEY_NAMESPACE}_${key}`;
    localStorage.removeItem(nameSpacedKey);
}

export function clearStorage() {
    try {
        for (const storedKey in storedKeys) {
            // eslint-disable-next-line no-prototype-builtins
            if (storedKeys.hasOwnProperty(storedKey)) {
                removeStorageItem(storedKey);
            }
        }
    } catch (ex) {
        // iPad Safari Private Mode treats local storage as effectively read-only and this throws an exception.
        // Everything basically works anyway to it's safe to ignore it.
    }
}

export function saveCurrentVisitorIDIntoStorage() {
    const currentVisitorID = getCurrentVisitorID();
    if (currentVisitorID) {
        setStorageItem(VISITOR_ID_STORAGE_KEY, currentVisitorID);
    }
}

// Clear the storage if the visitor logged out or we have a new visitor
export function ensureLocalStorageReflectsCurrentUser() {
    const previousVisitorID = getStorageItem(VISITOR_ID_STORAGE_KEY);
    const currentVisitorID = getCurrentVisitorID();

    // Visitor is different from the last stored visitor
    // (this could also happen if visitor just logged in to their Vistaprint account)
    if (previousVisitorID !== currentVisitorID) {
        clearStorage(); // clear stuff from previous user
        saveCurrentVisitorIDIntoStorage();
    }
}

export function setItemForCurrentVisitor(
    key: string,
    value: string[] | string | number | ModelAttributes[] | Dimensions
) {
    ensureLocalStorageReflectsCurrentUser();
    setStorageItem(key, value);
}

export function getItemForCurrentVisitor(key: string) {
    ensureLocalStorageReflectsCurrentUser();
    return getStorageItem(key);
}

export function copyToLocalStorage(designer: Designer, copiedAttributes: ModelAttributes[]) {
    // Stuff things into localstorage
    const activeCanvasViewModel = designer.documentRepository.getActiveCanvasViewModel();
    const canvasProcessType = activeCanvasViewModel.model.get("processType");

    // Use Date instead of window.performance.now() because performance is not synced across multiple browser windows
    lastCopyTime = new Date().toISOString();

    // Support for copying between different products in different windows at the same time (CARE use-case)
    // Store copied items into local storage so they can be pasted in another session
    setItemForCurrentVisitor(COPIED_ITEMS_LOCAL_STORAGE_KEY, copiedAttributes);
    setItemForCurrentVisitor(LAST_COPY_TIME_LOCAL_STORAGE_KEY, lastCopyTime);
    setItemForCurrentVisitor(LAST_COPY_CANVAS_DIMENSIONS_LOCAL_STORAGE_KEY, {
        width: activeCanvasViewModel.model.get("width"),
        height: activeCanvasViewModel.model.get("height")
    });
    setItemForCurrentVisitor(LAST_COPY_CANVAS_ID_LOCAL_STORAGE_KEY, activeCanvasViewModel.id);
    setItemForCurrentVisitor(
        LAST_COPY_CANVAS_DECO_TECH_CATEGORY_LOCAL_STORAGE_KEY,
        decoTechCategories[canvasProcessType]
    );
}

export function getCopiedDimensions(copiedViewModels: ItemViewModel[]): Dimensions {
    if (copiedViewModels.length) {
        return {
            height: copiedViewModels[0].parent.model.get("height"),
            width: copiedViewModels[0].parent.model.get("width")
        };
    }
    return { height: 0, width: 0 };
}

export function shouldUseStoredItems() {
    const storedLastCopyTime = getItemForCurrentVisitor(LAST_COPY_TIME_LOCAL_STORAGE_KEY);

    if (!lastCopyTime && !storedLastCopyTime) {
        // No items to copy, short circuit
        return false;
    }
    if (lastCopyTime && !storedLastCopyTime) {
        // Regular copy, no stored copy
        // i.e. pasting from regular clipboard
        return false;
    }
    if (!lastCopyTime && storedLastCopyTime) {
        // No regular copy, stored copy
        // i.e. pasting from "Studio Clipboard" a.k.a. Local Storage
        return true;
    }
    if (lastCopyTime >= storedLastCopyTime) {
        // There are items in both the regular clipboard and "Studio Clipboard" a.k.a. Local Storage,
        // use whichever was copied later
        // i.e. pasting from regular clipboard
        return false;
    }
    // i.e. pasting from "Studio Clipboard" a.k.a. Local Storage
    // modelsToPaste = storedCopiedItems;
    return true;
}

export function getStoredItems() {
    const storedCopiedItems = getItemForCurrentVisitor(COPIED_ITEMS_LOCAL_STORAGE_KEY);
    const storedDimensions = getItemForCurrentVisitor(LAST_COPY_CANVAS_DIMENSIONS_LOCAL_STORAGE_KEY);
    const storedCanvasId = getItemForCurrentVisitor(LAST_COPY_CANVAS_ID_LOCAL_STORAGE_KEY);
    const storedCanvasDecoTechCategory = getItemForCurrentVisitor(
        LAST_COPY_CANVAS_DECO_TECH_CATEGORY_LOCAL_STORAGE_KEY
    );

    return {
        itemAttributes: storedCopiedItems,
        dimensions: storedDimensions,
        canvasId: storedCanvasId,
        canvasDecoTechCategory: storedCanvasDecoTechCategory
    };
}
