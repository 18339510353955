export type SliderType = "hue" | "saturation" | "lightness";

export interface BasicSliderProps {
    className?: string;
    /** Fires every time the slider is moved */
    onChange: (value: number) => void;
    /** Callback that is fired when the handle is released. */
    onRelease?: () => void;
}

export interface SelectableColor {
    value: string;
    cssBackground: string;
}

export interface EmbroiderySelectableColor extends SelectableColor {
    ordinal: number;
}

export enum ColorSpace {
    HSL = "hsl",
    HSV = "hsv",
    RGB = "rgb",
    CMYK = "cmyk",
    HEX = "hex",
    NoColor = "No Color",
    Pantone = "pantone",
    Thread = "thread"
}
