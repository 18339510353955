import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const designDocumentSlice = createSlice({
    name: "designDocumentHasMultiplePanels",
    initialState: false,
    reducers: {
        setDesignDocument: (state, action: PayloadAction<any>) => action.payload?.document?.panels?.length > 1 ?? false
    }
});

export const { setDesignDocument } = designDocumentSlice.actions;
export const designDocumentHasMultiplePanels = designDocumentSlice.reducer;
