import { newRelicWrapper } from "@shared/utils/Errors";

/**
 * Retries an async function if the error message is `failed to fetch`
 */
export function retry<T>(
    fn: (...args: any[]) => Promise<T>,
    {
        retryCount = 7,
        interval = 250,
        name = "",
        retriesAttempted = 0,
        retryWhenNoException = (result: T) => false
    }: {
        retryCount?: number;
        interval?: number;
        name?: string;
        retriesAttempted?: number;
        retryWhenNoException?: (result: T) => boolean;
    }
): Promise<T> {
    return new Promise((resolve, reject) => {
        let additionalHeaders;
        if (name && name !== "loadable" && name !== "import" && retriesAttempted > 0) {
            additionalHeaders = {
                "cache-control": "no-cache"
            };
        }
        fn(additionalHeaders)
            .then(result => {
                // CORS/network errors can result in an exception, or they can result in a TypeError
                // we expected an exception for those errors so throw one
                if (result instanceof TypeError) {
                    throw result;
                }
                if (retryWhenNoException(result)) {
                    if (retryCount <= 0) {
                        newRelicWrapper.logPageAction("studio-retry-failed", {
                            retryCount,
                            retriesAttempted,
                            retryName: name,
                            errorMesssage: "Retry failed, executed due to retry fn"
                        });
                        reject(result);
                        return;
                    }
                    retry(fn, {
                        retryCount: retryCount - 1,
                        interval: Math.min(interval * 2, 1500),
                        name,
                        retriesAttempted: retriesAttempted + 1,
                        retryWhenNoException
                    }).then(resolve, reject);
                } else {
                    // dont log this if it is the initial call
                    if (retriesAttempted > 0) {
                        newRelicWrapper.logPageAction("studio-retry-successful", {
                            retryCount,
                            retriesAttempted,
                            retryName: name
                        });
                    }
                    resolve(result);
                }
            })
            .catch(error => {
                if (retryCount <= 0) {
                    newRelicWrapper.logPageAction("studio-retry-failed", {
                        retryCount,
                        retriesAttempted,
                        retryName: name,
                        errorMesssage: error.message
                    });
                    reject(error);
                    return;
                }
                setTimeout(() => {
                    // Passing on "reject" is the important part
                    retry(fn, {
                        retryCount: retryCount - 1,
                        interval: Math.min(interval * 2, 1500),
                        name,
                        retriesAttempted: retriesAttempted + 1,
                        retryWhenNoException
                    }).then(resolve, reject);
                }, interval);
            });
    });
}

export function loadableRetry(fn: (...args: any[]) => Promise<any>): Promise<any> {
    return retry(fn, { name: "loadable" });
}
