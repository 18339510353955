import { createSlice } from "@reduxjs/toolkit";
import { setError, showSaveLoader } from "../compoundActions";

const errorMessageSlice = createSlice({
    name: "errorMessage",
    initialState: null as null | string,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(showSaveLoader, (state, action) => null);
        builder.addCase(setError, (state, action) => action.payload.errorMessage || state);
    }
});

export const errorMessage = errorMessageSlice.reducer;
