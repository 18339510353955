import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const workIdSlice = createSlice({
    name: "workId",
    initialState: null as string | null,
    reducers: {
        setWorkId(state, action: PayloadAction<string | null>) {
            return action.payload ?? state;
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.workId ?? state;
        });
    }
});

export const { setWorkId } = workIdSlice.actions;
export const workId = workIdSlice.reducer;
