const Context = {
    docId: null,
    productKey: "",
    productOptions: {},
    locale: ""
};

const CurrentOptions: any = {};

export { Context, CurrentOptions };
