import type { TableItemStyleData, TableItemPredefinedStyle } from "./types";

const updateAttributeData = (
    data: TableItemStyleData,
    styleName: string,
    newStyle: Partial<TableItemPredefinedStyle>
) => {
    let mergedStyle;
    if (data[styleName]) {
        mergedStyle = {
            ...data[styleName]!,
            ...newStyle
        };
    }
    return { [styleName]: mergedStyle };
};

// When setting a tables attribute data, we are either going to set it on the predefined style.
// Or set it on the body, header and alternate style. The plan is to UI to set the other styles as one.
// If the UI should change, we'll split this up.
export const updateTableAttributeData = (
    data: TableItemStyleData,
    newStyle: Partial<TableItemPredefinedStyle>,
    useFallback: boolean = true
) => {
    if (data.predefinedStyle) {
        return updateAttributeData(data, "predefinedStyle", newStyle);
    }
    if (useFallback) {
        let mergedData: TableItemStyleData;
        mergedData = updateAttributeData(data, "bodyStyle", newStyle);
        mergedData = { ...mergedData, ...updateAttributeData(data, "headerStyle", newStyle) };
        mergedData = { ...mergedData, ...updateAttributeData(data, "alternateStyle", newStyle) };
        return mergedData;
    }
    return undefined;
};

export const getTableStyle = (data: TableItemStyleData) => {
    return data?.predefinedStyle || data?.bodyStyle || data?.headerStyle || data?.alternateStyle || undefined;
};

export const getTableFontColor = (data: TableItemStyleData) => {
    return getTableStyle(data)?.fontColor;
};

export const setTableFontColor = (data: TableItemStyleData, fontColor: string) => {
    updateTableAttributeData(data, { fontColor });
};

export const getTableFontSize = (data: TableItemStyleData) => {
    return getTableStyle(data)?.fontSize;
};

export const getTableStyleId = (data: TableItemStyleData) => {
    return data?.predefinedStyle?.styleId;
};

export const getTableFontFamily = (data: TableItemStyleData) => {
    return getTableStyle(data)?.fontFamily;
};

export const getTableFontStyle = (data: TableItemStyleData) => {
    return getTableStyle(data)?.fontStyle;
};

export const getTableBackgroundColor = (data: TableItemStyleData) => {
    return getTableStyle(data)?.backgroundColor;
};

const COLUMN_LETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export function columnIndexToLetters(columnIndex: number): string {
    let number = columnIndex;
    let letters = "";

    while (number >= 0) {
        letters = COLUMN_LETTERS[number % 26] + letters;
        number = Math.floor(number / 26) - 1;
    }

    return letters;
}
