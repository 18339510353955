import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import merge from "lodash/merge";
import { setDefaultState } from "../compoundActions";
import { getQueryParams } from "../../utils/WebBrowser";
import { StudioConfiguration } from "../../utils/StudioConfiguration";

const addOverrides = (studioConfiguration: StudioConfiguration) => {
    const overrides = (getQueryParams() as { [key: string]: any }).configurationOverrides;
    if (overrides) {
        try {
            const newValues = merge({}, studioConfiguration, JSON.parse(overrides));
            return newValues;
        } catch (error) {
            // this is a developer tool.  No reason to fail.  Return redux configuration
            return studioConfiguration;
        }
    }
    return studioConfiguration;
};

const studioConfigurationSlice = createSlice({
    name: "studioConfiguration",
    initialState: {} as StudioConfiguration,
    reducers: {
        setStudioConfiguration: (state, action: PayloadAction<StudioConfiguration>) =>
            addOverrides(action.payload || state)
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => addOverrides(action.payload.studioConfiguration || state));
    }
});

export const { setStudioConfiguration } = studioConfigurationSlice.actions;
export const studioConfiguration = studioConfigurationSlice.reducer;
