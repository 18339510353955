import { configureStore } from "@reduxjs/toolkit";
import { isDebugMode, isDevelopmentMode } from "@shared/utils/Debug";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { reducers } from "./Reducers";

const exposeReduxDevTools = isDevelopmentMode() || isDebugMode();

export const Store = configureStore({
    devTools: exposeReduxDevTools,
    reducer: reducers
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
