import { createSlice } from "@reduxjs/toolkit";

const surfaceUpsellsLoadedSlice = createSlice({
    name: "surfaceUpsellsLoaded",
    initialState: false,
    reducers: {
        surfaceUpsellsLoaded() {
            return true;
        }
    }
});

export const { surfaceUpsellsLoaded } = surfaceUpsellsLoadedSlice.actions;
export const surfaceUpsellsLoadedState = surfaceUpsellsLoadedSlice.reducer;
