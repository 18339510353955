// eslint-disable-next-line import/no-extraneous-dependencies
import type { DSS } from "@vp/types-ddif";
import { encodeForRenderingApis } from "./encoding";
import { saveTransientDocument } from "./clients/udsClient";

const TRANSIENT_INSTRUCTIONS_SIZE_LIMIT = 7000;

export async function getTransientDocumentUrl(
    document: DSS.DesignDocument,
    authToken: string,
    sizeLimit: number = TRANSIENT_INSTRUCTIONS_SIZE_LIMIT
): Promise<string> {
    const base64Document = await encodeForRenderingApis(document);
    const docLength = base64Document.length;

    if (docLength < sizeLimit) {
        return `${UDS_STORAGE_V3_URL}/documents/transient?document=${encodeURIComponent(base64Document)}`;
    }

    return (await saveTransientDocument(document, authToken))._links.documentRevision.href;
}
