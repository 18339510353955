export type { StudioConfiguration, ProductGroupConfiguration, MinFontSize } from "./types";
export {
    getMinMaxFontSizes,
    getMinFontSizeForDecorationTechnology,
    MIN_FONT_SIZE_BY_FONT_FLAVOR,
    DEFAULT_MAX_FONT_SIZE
} from "./fontConfigurationUtils";

// This may not be the best home for this type definition. Feel free to move if you can find a better place.
export enum ItemTypes {
    TEXT = "TEXT",
    ITEM_REFERENCE = "REFERENCE",
    IMAGE = "IMAGE",
    SHAPE = "SHAPE",
    WORDART = "WORDART",
    TABLE = "Table",
    QR_CODE = "QRCODE",
    CALENDAR_GRID = "CALENDARGRID",
    TEAMS = "TEAMS",
    SUBPANEL = "SUBPANEL"
}
