import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductAttributesKeyAndValues } from "../../utils/Product";

const productAttributeAndValuesSlice = createSlice({
    name: "productAttributeAndValues",
    initialState: [] as ProductAttributesKeyAndValues[],
    reducers: {
        setProductAttributeAndValues(state, action: PayloadAction<ProductAttributesKeyAndValues[] | undefined>) {
            return action.payload ?? state;
        }
    }
});

export const { setProductAttributeAndValues } = productAttributeAndValuesSlice.actions;
export const productAttributeAndValues = productAttributeAndValuesSlice.reducer;
