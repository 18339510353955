import React, { useEffect } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { DesignExperienceErrorUi } from "@design-stack-vista/design-experience-error-ui";
import { BoundedContent } from "@vp/swan";
import { newRelicWrapper } from "@shared/utils/Errors";
import { isDebugMode } from "@shared/utils/Debug";
import { getTrackingInfoBase } from "@shared/utils/Tracking";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { useAppSelector } from "@shared/redux";
import { useErrorMessageData, useExtraErrorDetails, ErrorDetailsPanel } from "@shared/features/Errors";
import "./ErrorPage.scss";
import { Helmet } from "react-helmet";
import { Header, BookendsMetaTag, BOOKEND_TYPES } from "@vp/esi-bookends";

interface Props {
    errorCodeOverride?: string;
    errorMessageOverride?: string;
    DebugToolbar: any;
}

export function ErrorPage({ errorCodeOverride, errorMessageOverride, DebugToolbar }: Props) {
    const { t } = useTranslationSSR();
    const { bookendsHeader } = usePageContext();
    const locale = useAppSelector(state => state.locale);
    const stateErrorCode = useAppSelector(state => state.errorCode);
    const stateErrorMessage = useAppSelector(state => state.errorMessage);
    const productKey = useAppSelector(state => state.productKey);
    const extraErrorDetails = useExtraErrorDetails();
    const errorCode = stateErrorCode || errorCodeOverride;
    const errorMessage = stateErrorMessage || errorMessageOverride;

    const errorData = useErrorMessageData(locale, errorCode, errorMessage);

    const { trackingBase } = getTrackingInfoBase("Studio", true);
    const trackingConfiguration = {
        pageSection: trackingBase.pageSection,
        pageStage: trackingBase.pageStage,
        pageName: trackingBase.pageName
    };

    useEffect(() => {
        // don't log studio init errors for 3 problematic products getting spammed by who knows what
        // for more information, https://vistaprint.atlassian.net/browse/DT-14805
        const ignoreErrors =
            productKey === "PRD-ZJMTXCFFH" || productKey === "PRD-IGRC4158B" || productKey === "PRD-LM9Z7YMZS";

        if (errorCode && !ignoreErrors) {
            newRelicWrapper.logPageAction("studio-errorpage", {
                errorCode,
                errorMessage
            });
        }
    }, [errorMessage, errorCode, productKey]);

    return (
        <>
            <BookendsMetaTag
                renderWith={Helmet}
                locale={locale}
                tenant="vistaprint"
                headerType={BOOKEND_TYPES.FULL}
                footerType={BOOKEND_TYPES.FULL}
                hideSearch={true}
            />
            {isDebugMode() && <DebugToolbar />}
            <div className="error-page-wrapper">
                {/* TODO: Add the abTestData prop when when top level testing is unlocked in Bookends. */}
                <Header
                    {...bookendsHeader}
                    locale={locale}
                    tenant="vistaprint"
                    hideSearch={true}
                    trackingConfiguration={trackingConfiguration}
                    type={BOOKEND_TYPES.FULL}
                />
                <BoundedContent>
                    <div className="error-page-container">
                        <DesignExperienceErrorUi
                            Icon={<errorData.iconType className={errorData.iconColor} />}
                            errorHeader={t(errorData.errorHeader)}
                            errorSubHeader={t(errorData.errorSubHeader)}
                            primaryButtonMessage={t(errorData.errorButtonMessage)}
                            primaryOnClick={errorData.onClick}
                            secondaryButtonMessage={t(errorData.errorButtonMessageSecondary)}
                            secondaryOnClick={errorData.onClickSecondary}
                            errorDetailChildren={
                                <ErrorDetailsPanel
                                    error={errorCode}
                                    session={errorData.studioUniqueSession}
                                    date={new Date().toUTCString()}
                                    errorMessage={errorMessage}
                                    errorStackTrace={extraErrorDetails.errorStackTrace}
                                    moduleFunction={extraErrorDetails.errorExtraData?.moduleFunction}
                                    friendlyDescription={extraErrorDetails.errorExtraData?.friendlyDescription}
                                    productKey={extraErrorDetails.productKey}
                                    Quantity={extraErrorDetails.Quantity}
                                />
                            }
                        />
                    </div>
                </BoundedContent>
            </div>
        </>
    );
}

ErrorPage.displayName = "ErrorPage";
