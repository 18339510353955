import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const productVersionSlice = createSlice({
    name: "productVersion",
    initialState: null,
    reducers: {
        setProductVersion(state, action: PayloadAction<number>) {
            return action.payload || state;
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.productVersion ?? state;
        });
    }
});

export const { setProductVersion } = productVersionSlice.actions;
export const productVersion = productVersionSlice.reducer;
