import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showSafetyAreaSlice = createSlice({
    name: "showSafetyArea",
    initialState: true,
    reducers: {
        setShowSafetyArea: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowSafetyArea } = showSafetyAreaSlice.actions;
export const showSafetyArea = showSafetyAreaSlice.reducer;
