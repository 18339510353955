import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ErrorDetail } from "@design-stack-vista/design-experience-error-ui";
import { Link } from "@vp/swan";
import { isDebugMode, isLocalHost } from "@shared/utils/Debug";
import { isCareAgent } from "@shared/utils/Care";
import { useAppSelector } from "@shared/redux";

// @ts-ignore
const errorDocumentationURL = ERROR_DOCUMENTATION_URL;

const messages = defineMessages({
    documentationLink: {
        id: "studio.components.errorPage.documentationLink",
        defaultMessage: "Learn how to fix this error"
    }
});

interface props {
    error: string | undefined;
    session: string;
    date: string;
    errorMessage: string | undefined;
    errorStackTrace: string | null;
    moduleFunction: string;
    friendlyDescription: string;
    productKey: string;
    Quantity: number;
    Fulfillability?: object;
}

export const ErrorDetailsPanel = ({
    error,
    session,
    date,
    errorMessage,
    errorStackTrace,
    moduleFunction,
    friendlyDescription,
    productKey,
    Quantity,
    Fulfillability
}: props) => {
    const showExtraDetails = isDebugMode() || isLocalHost() || isCareAgent();
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    const { t } = useTranslationSSR();

    return (
        <>
            {!easelLoaded && showExtraDetails && (
                <>
                    <Link href={errorDocumentationURL} target="_blank" skin="cta" mb={4}>
                        {t(messages.documentationLink.id)}
                    </Link>
                </>
            )}
            <ErrorDetail name={"Error"} value={error ?? ""} />
            <ErrorDetail name={"Session"} value={session} />
            <ErrorDetail name={"Date"} value={date} />
            {showExtraDetails && <hr />}
            {showExtraDetails && <ErrorDetail name="Message" value={errorMessage ?? ""} />}
            {showExtraDetails && <hr />}
            {showExtraDetails && <ErrorDetail name="ProductKey" value={productKey} />}
            {showExtraDetails && <ErrorDetail name="Quantity" value={Quantity.toString()} />}
            {showExtraDetails && <ErrorDetail name="Fulfillability" value={JSON.stringify(Fulfillability, null, 2)} />}
            {errorStackTrace && isLocalHost() && <ErrorDetail name="Stack Trace" value={errorStackTrace} />}
            {moduleFunction && showExtraDetails && <ErrorDetail name="Module Function" value={moduleFunction} />}
            {friendlyDescription && showExtraDetails && (
                <ErrorDetail name="Friendly Description" value={friendlyDescription} />
            )}
        </>
    );
};

ErrorDetailsPanel.displayName = "ErrorDetailsPanel";
