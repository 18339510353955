import { ItemReferenceTypes } from "@shared/utils/CimDoc";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import type { Designer } from "../designer-suite/@types/designer";
import { ProcessType } from "../designer-suite/constants";

export function updateSelectedItems<T extends CanvasItem | ItemViewModel = CanvasItem>(
    designer: Designer | undefined,
    selection: T[] | undefined,
    updateFunction: (item: MutableItem & T) => void
) {
    if (designer && selection) {
        selection.forEach(selectedItem => {
            designer.api.design.updateItem(selectedItem.id, mutableItem => {
                updateFunction(mutableItem as MutableItem & T);
            });
        });
    }
}

export function getReferenceData(item: ReferenceItem) {
    return item._itemViewModel.model.get("data");
}

export function setReferenceData(updatedData: any) {
    return (item: ReferenceItem) =>
        item._itemViewModel.model.set("data", {
            ...item.data,
            ...updatedData
        });
}

function getBoldStyleForWordArt(item: WordArtItem) {
    return item.data.fontStyle?.substr(0, item.data.fontStyle.indexOf(","));
}

function getItalicStyleForWordArt(item: WordArtItem) {
    return item.data.fontStyle?.substr(item.data.fontStyle.indexOf(",") + 1);
}

export function updateFontStyle(isBold: boolean, newStyle: string) {
    return (item: WordArtItem & Mutable) => {
        item.setData({
            ...item.data,
            fontStyle: isBold
                ? `${newStyle},${getItalicStyleForWordArt(item)}`
                : `${getBoldStyleForWordArt(item)},${newStyle}`
        });
    };
}

export function isItemReference(item: CanvasItem): item is ReferenceItem {
    return item.itemType === ItemTypes.ITEM_REFERENCE;
}

export function isWordArt(item: CanvasItem): item is WordArtItem {
    return isItemReference(item) && item.type === ItemReferenceTypes.WORD_ART;
}

export function isTable(item: CanvasItem): item is TableItem {
    return isItemReference(item) && item.type === ItemReferenceTypes.TABLE;
}

export function isTeamsPlaceholder(item: CanvasItem): item is TextPlaceholderItem {
    return isItemReference(item) && item.type === ItemReferenceTypes.TEAMS_NAME;
}

export function isWordArtBold(item: WordArtItem) {
    return getBoldStyleForWordArt(item) === "Bold";
}

export function isWordArtItalic(item: WordArtItem) {
    return getItalicStyleForWordArt(item) === "Italic";
}

export function wordArtFilter(designer: Designer | undefined, selection: CanvasItem[]) {
    return selection.reduce(
        (accumulator, current) => {
            if (designer && isWordArt(current)) {
                accumulator.wordArtItems.push(current);
            } else {
                accumulator.otherItems.push(current);
            }
            return accumulator;
        },
        { wordArtItems: [] as WordArtItem[], otherItems: [] as CanvasItem[] }
    );
}

/** * TABLE UTILITIES */
/**
 * Update the data for a table
 * @param {ItemRefernce} table item to be updated
 * @param {TableData} newData data to be update
 */
export function updateTableItemData(table: Mutable & TableItem, newData: Partial<TableItemData>) {
    table.setData({
        ...table.data,
        ...newData
    });
}

export function updateQRCodeItemData(qrCode: Mutable & QRCodeItem, newData: Partial<QRCodeData>) {
    qrCode.setData({
        ...qrCode.data,
        ...newData
    });
}

/**
 *  From the selection get a list of selected tables (and non tables)
 * @param {designer} designer
 * @param {Item[]} selection List of selected objects
 */
export function tableFilter(selection: CanvasItem[]) {
    return selection.reduce(
        (accumulator, current) => {
            if (isTable(current)) {
                accumulator.tableItems.push(current);
            } else {
                accumulator.otherItems.push(current);
            }
            return accumulator;
        },
        { tableItems: [] as TableItem[], otherItems: [] as CanvasItem[] }
    );
}

/**
 * Get if only tables are selected
 * @param {ItemSelection} selection
 * @returns {boolean} - true if only tables selected, false otherwise
 */
export function isTablesOnly(selection: CanvasItem[]) {
    const { tableItems, otherItems } = tableFilter(selection);
    return !!tableItems.length && !otherItems.length;
}

export function isText(item: CanvasItem): item is TextItem {
    return item.itemType === ItemTypes.TEXT;
}

export function textFilter(designer: Designer | undefined, selection: CanvasItem[]) {
    return selection.reduce(
        (bucketedItems, currentItem) => {
            if (designer && isText(currentItem)) {
                bucketedItems.matchingItems.push(currentItem);
            } else {
                bucketedItems.otherItems.push(currentItem);
            }
            return bucketedItems;
        },
        { matchingItems: [] as TextItem[], otherItems: [] as CanvasItem[] }
    );
}

const FONTBOLD = "fontBold";
const FONTITALIC = "fontItalic";

const disableableVariantMapping = {
    [FONTBOLD]: {
        variant: "Bold"
    },
    [FONTITALIC]: {
        variant: "Italic"
    }
};

export function getIsDisabledForWordArt(
    designer: Designer | undefined,
    selection: WordArtItem[],
    internalItemAttributeKey: string
) {
    if (!designer) {
        return true;
    }

    return selection.some(item => {
        // Check whether B/I is supported for the item's fontFamily
        const { fontFamily } = item.data;
        const fontOptions = designer.clients.font.fontMapping[fontFamily.toLowerCase()];
        if (!fontOptions || !fontOptions.Variants[disableableVariantMapping[internalItemAttributeKey].variant]) {
            return true;
        }

        // If getting disabled for Bold, check whether it's
        const isOtherAttribute = internalItemAttributeKey === FONTBOLD ? isWordArtItalic(item) : isWordArtBold(item);
        return !fontOptions.Variants.BoldItalic && isOtherAttribute;
    });
}

export function getSelectedItemTypes(selection: CanvasItem[]) {
    return selection.reduce((acc, curr) => {
        let currentType: ItemTypes = curr.itemType as ItemTypes;
        if (isItemReference(curr)) {
            if (curr.type === ItemReferenceTypes.WORD_ART) {
                currentType = ItemTypes.WORDART;
            } else if (curr.type === ItemReferenceTypes.TABLE) {
                currentType = ItemTypes.TABLE;
            } else if (curr.type === ItemReferenceTypes.QR_CODE) {
                currentType = ItemTypes.QR_CODE;
            } else if (curr.type === ItemReferenceTypes.CALENDAR_GRID) {
                currentType = ItemTypes.CALENDAR_GRID;
            } else if (curr.type === ItemReferenceTypes.TEAMS_NAME) {
                currentType = ItemTypes.TEAMS;
            }
        }
        if (acc.includes(currentType)) {
            return acc;
        }
        return [...acc, currentType];
    }, [] as ItemTypes[]);
}

export function getStringifiedSelectedItemTypes(selection: CanvasItem[]) {
    return selection
        .reduce((acc, curr) => {
            let currentType: ItemTypes = curr.itemType as ItemTypes;
            if (isItemReference(curr)) {
                if (curr.type === ItemReferenceTypes.WORD_ART) {
                    currentType = ItemTypes.WORDART;
                } else if (curr.type === ItemReferenceTypes.TABLE) {
                    currentType = ItemTypes.TABLE;
                } else if (curr.type === ItemReferenceTypes.QR_CODE) {
                    currentType = ItemTypes.QR_CODE;
                } else if (curr.type === ItemReferenceTypes.CALENDAR_GRID) {
                    currentType = ItemTypes.CALENDAR_GRID;
                }
            }
            if (acc.includes(currentType)) {
                return acc;
            }
            return [...acc, currentType];
        }, [] as string[])
        .join(",");
}

export function selectedItemsAreOfTypes(selection: CanvasItem[], types: ItemTypes[]) {
    return getSelectedItemTypes(selection).every(type => types.includes(type));
}

export function getItemBoundingBox(item: CanvasItem) {
    return {
        top: item.mmPosition.top,
        left: item.mmPosition.left,
        width: item.mmDimensions.width,
        height: item.mmDimensions.height
    };
}

export function updateItemBoundingBox(item: MutableItem, newBoundingBox: ReturnType<typeof getItemBoundingBox>) {
    const originalBoundingBox = getItemBoundingBox(item);
    item._itemViewModel.model.set(newBoundingBox);
    item._itemViewModel.set("temporaryPositionDelta", {
        top: originalBoundingBox.top - newBoundingBox.top,
        left: originalBoundingBox.left - newBoundingBox.left
    });
}

// TODO DT-280: instead of checking tenant/token in the previewUrl, use template metadata
export function isLegacyUserUploadItem(item: CanvasItem) {
    return (
        item._itemViewModel.model.get("previewUrl") &&
        item._itemViewModel.model.get("previewUrl").includes("uploadToken=")
    );
}

export function isUserUploadV1Item(item: CanvasItem) {
    return (
        item._itemViewModel.model.get("previewUrl") &&
        item._itemViewModel.model.get("previewUrl").includes("tenant=designtechprod")
    );
}

export function isSherbertUpload(item: CanvasItem) {
    return (
        item._itemViewModel.model.get("previewUrl") &&
        item._itemViewModel.model.get("previewUrl").includes("api.sherbert.cimpress.io")
    );
}

export function selectionContainsOnlyUserUploads(selection: CanvasItem[]) {
    return selection.every(item => isLegacyUserUploadItem(item) || isUserUploadV1Item(item) || isSherbertUpload(item));
}

export function isEngravingView(views: any, index: number) {
    return /engraving/i.test(views[index].processType);
}

export function isEmbroideryView(views: any, index: number) {
    return /embroidery/i.test(views[index].processType);
}

export function getEngravingColor(views: any, index: number) {
    return views[index].docAdditionalData.engravingColor;
}

export function isLocked(item: CanvasItem) {
    return item && item._itemViewModel && item._itemViewModel.get("locked");
}

export function getProcessType(designer: Designer | undefined) {
    if (!designer || !designer.documentRepository || !designer.documentRepository.getActiveCanvas()) {
        return undefined;
    }
    return designer.documentRepository.getActiveCanvas().get("processType");
}

const singleColorTypes = [ProcessType.padPrint, ProcessType.laserEngraving, ProcessType.screenPrint];
export function isSingleColorCanvas(designer: Designer | undefined) {
    if (!designer) {
        return undefined;
    }
    return singleColorTypes.includes(getProcessType(designer));
}

export const isSingleColorCanvasModel = (canvasViewModel: CanvasViewModel | Canvas) =>
    singleColorTypes.includes(canvasViewModel.get("processType"));

const embroideryTypes = [ProcessType.embroideryCylinder, ProcessType.embroideryFlat];

export const isEmbroideryCanvasModel = (canvasViewModel: CanvasViewModel | Canvas) =>
    embroideryTypes.includes(canvasViewModel.get("processType"));

export function isCalendarGrid(item: CanvasItem): boolean {
    return isItemReference(item) && item.type === ItemReferenceTypes.CALENDAR_GRID;
}

export function updateTeamsItemData(placeholder: MutableReferenceItem & TextPlaceholderItem, newData: any) {
    placeholder.setData({
        ...placeholder.data,
        ...newData
    });
}

export function getTeamsSelectedFont(selection: TextPlaceholderItem[]) {
    const selectedFontFamilies = selection.reduce((accumulator, current) => {
        const { fontFamily } = current.data;
        if (!fontFamily || accumulator.includes(fontFamily)) {
            return accumulator;
        }
        return [...accumulator, fontFamily];
    }, [] as string[]);
    return selectedFontFamilies.length === 1 ? selectedFontFamilies[0] : "";
}

export function teamsPlaceholderFilter(selection: CanvasItem[]) {
    return selection.reduce(
        (accumulator, current) => {
            if (isTeamsPlaceholder(current)) {
                accumulator.teamsPlaceholderItems.push(current);
            } else {
                accumulator.otherItems.push(current);
            }
            return accumulator;
        },
        { teamsPlaceholderItems: [] as TextPlaceholderItem[], otherItems: [] as CanvasItem[] }
    );
}

export function getCurrentBackgroundModel(model: Canvas) {
    // @ts-ignore
    return model?.items?.models?.find(m => m?.get("isBackground"));
}

export function getCurrentBackgroundColor(model: Canvas): string | undefined {
    const bgModel = getCurrentBackgroundModel(model);
    return bgModel ? bgModel.get("fillColor") : undefined;
}
