import { defineMessages } from "@vp/i18n-helper";

export const UploadsPanelMessages = defineMessages({
    hideUsedPhotos: {
        id: "easel.components.panels.uploadsPanel.hideUsedPhotosLabel",
        defaultMessage: "Hide used photos",
        description: {
            note: "Checkbox to hide photos already used in the design"
        }
    },
    uploadButton: {
        id: "easel.components.panels.uploadsPanel.uploadButton",
        defaultMessage: "Upload Image",
        description: {
            note: "The button users tap/click to upload an image"
        }
    },
    uploadButtonWithExperiment: {
        id: "easel.components.panels.uploadsPanel.uploadButtonExperiment",
        defaultMessage: "Add from your computer",
        description: {
            note: "The button users tap/click to upload an image"
        }
    },
    uploadButtonTablet: {
        id: "easel.components.panels.uploadsPanel.uploadButtonTablet",
        defaultMessage: "Add from this device",
        description: {
            note: "The button users tap/click to upload an image"
        }
    },
    uploadButtonMobile: {
        id: "easel.components.panels.uploadsPanel.uploadButtonMobile",
        defaultMessage: "Upload logo or image",
        description: {
            note: "The button users tap to upload an image"
        }
    },
    uploadFromMobileButton: {
        id: "editorUI.uploads.uploadFromMobile",
        defaultMessage: "Add from your phone",
        description: {
            note: "The button users tap/click to upload image from mobile by scanning QR code"
        }
    },
    deleteButton: {
        id: "easel.components.panels.uploadsThumbnail.delete",
        defaultMessage: "Delete",
        description: {
            note: "Button on bottom of image in order to delete image"
        }
    },
    previousImagesText: {
        id: "easel.components.panels.uploadsThumbnail.previousImagesText",
        defaultMessage: "Your Recent and Previous Photos",
        description: {
            note: "Text above list of images"
        }
    },
    uploading: {
        id: "easel.components.panels.uploadsThumbnail.uploading",
        defaultMessage: "Uploading....",
        description: {
            note: "Text that appears below the progress indicator to indicate the image is being uploaded"
        }
    },
    processing: {
        id: "easel.components.panels.uploadsThumbnail.processing",
        defaultMessage: "Processing....",
        description: {
            note: "Text that appears below the progress indicator to indicate the image is being processed"
        }
    },
    currentlyUploading: {
        id: "easel.components.panels.uploadUImage.currentlyUploading",
        defaultMessage: "Currently Uploading",
        description: {
            note: "Alt text that appears when an upload doesn't have a file name"
        }
    }
});
