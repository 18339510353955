import { type DefaultTFunction } from "@vp/i18n-helper";
import traverse from "traverse";
import type { DesignerDefaultTypes } from "./types";

export interface TranslationMessages {
    [key: string]: {
        id: string;
        defaultMessage: string;
        description?: {
            note: string;
        };
    };
}

// Currently only used for designer
export function provideTranslations(original: DesignerDefaultTypes, prefix: string, t: DefaultTFunction) {
    // eslint-disable-next-line func-names, array-callback-return
    const replaced = traverse(original).map(function (node) {
        if (this.isLeaf && typeof node === "string") {
            this.update(t(`${prefix}.${this.path.join(".")}`));
        }
    });
    return replaced;
}
