export function defaultZoom(initialZoomFactor: number | undefined) {
    return {
        ui: {
            zoomStrategy: {
                initialWidth: initialZoomFactor || 1,
                initialHeight: initialZoomFactor || 1
            }
        }
    };
}
