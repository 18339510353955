// Taken from https://vistaprint.atlassian.net/wiki/spaces/DIYP/pages/3235643991/Studio+6+A+B+test+-+EXPO-1283
// const businessCards = [
//     "PRD-IYXT1T3V",
//     "PRD-RWLCHPA0",
//     "PRD-GURNTEDA",
//     "PRD-GIDUKALA",
//     "PRD-XF6RVSIU",
//     "PRD-XRO5HANH",
//     "PRD-LKKFLWV6",
//     "PRD-XFC0VAKV",
//     "PRD-WBY4DECX",
//     "PRD-WJIDFUBS",
//     "PRD-YN48WWE9",
//     "PRD-4OVSFBSP",
//     "PRD-15BJSMMI",
//     "PRD-40GK1WRI",
//     "PRD-EOEDLXNN",
//     "PRD-CRHN2BKI",
//     "PRD-0XWCRIW8",
//     "PRD-EGEYBZIC",
//     "PRD-D8I4UQTV",
//     "PRD-KKS53PHP",
//     "PRD-SCMXDLRV",
//     "PRD-JN9KKZL1",
//     "PRD-X0KWG5Y8",
//     "PRD-5SP4HOJQ",
//     "PRD-TOZ9BHS5",
//     "PRD-5J2Y8YLT",
//     "PRD-X0EUFMEQ",
//     "PRD-XG9X25WG",
//     "PRD-VPY6INL3",
//     "PRD-ZUV6GBXW5",
//     "PRD-2JEC4VWG",
//     "PRD-HS1RN52O",
//     "PRD-JKENF4EI",
//     "PRD-YS8KBFDN",
//     "PRD-FRHRB9BX",
//     "PRD-3IWZDBGN",
//     "PRD-Q47ZPCYRG",
//     "PRD-NHN1OO4E",
//     "PRD-7W0HVLJD",
//     "PRD-VMH1LT9E",
//     "PRD-MMH9IFIJ",
//     "PRD-TBXNBAJ9",
//     "PRD-UCFDUXVA4",
//     "PRD-DEREQGJF",
//     "PRD-4AGL9HJW",
//     "PRD-HTHQHYAO",
//     "PRD-ONW65ET0",
//     "PRD-ALYCLIHZ",
//     "PRD-ZWR6ED7P",
//     "PRD-CYIVA4BHK",
//     "PRD-91CRDEZP",
//     "PRD-85VDDYVU",
//     "PRD-A1HURGZR",
//     "PRD-4UKBIHZFK",
//     "PRD-INDP4L04N",
//     "PRD-TQIAEA34",
//     "PRD-FQ8XPI2X9",
//     "PRD-G2KMSVZR",
//     "PRD-WOXRGI5I",
//     "PRD-D0V77DDST",
//     "PRD-WP2TSF0DJ",
//     "PRD-SQ2MWVNU",
//     "PRD-4IINBGGN",
//     "PRD-YNHFVEC0",
//     "PRD-DTPQPITV",
//     "PRD-JWTRHMGMQ",
//     "PRD-HQRNKX0D",
//     "PRD-TISSTJ1D",
//     "PRD-LMOYJN4WB",
//     "PRD-I2XNGWKD6",
//     "PRD-YXWFAZQHZ",
//     "PRD-0ABIZSJ97",
//     "PRD-W2DG35RX",
//     "PRD-I7B71UQB",
//     "PRD-CC1UDSOZ",
//     "PRD-ZNE6YFWQV",
//     "PRD-AMEMHDRT",
//     "PRD-2CKZOESS",
//     "PRD-9YT4QNHPK",
//     "PRD-VIXBR33HX",
//     "PRD-0WMNNWHV4",
//     "PRD-CNEY3Q6RT",
//     "PRD-GKC0TF8JE",
//     "PRD-ZRYBBJBWI",
//     "PRD-MHR8VJQ2S",
//     "PRD-TLC4SQLTO",
//     "PRD-HOH6EH9TJ",
//     "PRD-HI94QYYCA",
//     "PRD-OUVWJKKET"
// ];

// Keeping these around for future use but currently these are included in the holiday placeholder test
// export const consumer = [
//     "PRD-IPZA2PSN",
//     "PRD-Q6ZROJNB",
//     "PRD-NFAY3FV2",
//     "PRD-V1DVLU3E",
//     "PRD-LCGNSUFS",
//     "PRD-V88PW8RG",
//     "PRD-98MSVPKI",
//     "PRD-U71ICPRJ",
//     "PRD-HW3LQO6H",
//     "PRD-6YLYJS8P",
//     "PRD-UAWUDWBY",
//     "PRD-TXGZDHP7",
//     "PRD-QAE730JI",
//     "PRD-FULMBGSK",
//     "PRD-UFFIQA9IZ",
//     "PRD-99UJYKPF",
//     "PRD-6BGZYLBV",
//     "PRD-RF7NQ0SV",
//     "PRD-TTQZNG5N",
//     "PRD-MDC3WCHJ",
//     "PRD-MTCEMKZYY",
//     "PRD-OBZGEZNW",
//     "PRD-8JTK1GHE",
//     "PRD-V7H0GE4Q",
//     "PRD-55BMJSVU",
//     "PRD-MSPZWNWB",
//     "PRD-UTCB94K8",
//     "PRD-SEW7I3UWP",
//     "PRD-QQTJP0JJ",
//     "PRD-T2ZQMC0H",
//     "PRD-VY7GMTH4",
//     "PRD-P4DIGX3P",
//     "PRD-KOQMHC08",
//     "PRD-UVTWF6JA",
//     "PRD-XAVWPSDBB",
//     "PRD-NZPDCOQK",
//     "PRD-TPMI6DKS",
//     "PRD-CFO6TINI",
//     "PRD-GSKJIQ8U",
//     "PRD-UAGOO5YW",
//     "PRD-PNCS2DF0",
//     "PRD-MJZFOYX3",
//     "PRD-MB3SIJSK",
//     "PRD-TEVEC5IY",
//     "PRD-Z4LGTRSJ",
//     "PRD-FIZPQN1R",
//     "PRD-B6JFP6KC",
//     "PRD-4MGI7XI4",
//     "PRD-ZHAGCFNF",
//     "PRD-3DLLQS2V",
//     "PRD-MOIKO8CZ",
//     "PRD-JVIIPB3O",
//     "PRD-S9T3Z4OXR",
//     "PRD-OJXKZ0YT",
//     "PRD-IOWSA798",
//     "PRD-XOVJFUEFG"
// ];

// const consumerTestIteration2 = [
//     "PRD-OPTIMWT6J",
//     "PRD-0BKUQJYW",
//     "PRD-LGSITRFS",
//     "PRD-VBEUEYPR",
//     "PRD-EFAIDYTB",
//     "PRD-RPQJFEWR",
//     "PRD-OKN59YTC",
//     "PRD-LHVWFWPM",
//     "PRD-AUSRKCU3",
//     "PRD-G0FY0APWW",
//     "PRD-4G58UKQ9",
//     "PRD-GBMPUBTP",
//     "PRD-RF7NQ0SV",
//     "PRD-ATVIPGXC",
//     "PRD-JZ2W7RS8M",
//     "PRD-RDT8YIOX",
//     "PRD-63ABNYHP",
//     "PRD-JAQVRVNU",
//     "PRD-0AYZDT3L",
//     "PRD-ATC7I72DG",
//     "PRD-BMW2IYNBH",
//     "PRD-5ZME3L4P",
//     "PRD-QCQNCLJG3",
//     "PRD-BPJZ5KHJN",
//     "PRD-YX0E7CJGK",
//     "PRD-17TY7N846",
//     "PRD-6BEDVKYYT",
//     "PRD-YDSNDGHQ",
//     "PRD-REMJYG3X",
//     "PRD-XVWPSCBC0",
//     "PRD-OJXKZ0YT",
//     "PRD-Q4TFZOAP",
//     "PRD-5SD8ZP0M",
//     "PRD-4MSHI5T3",
//     "PRD-16RF02VA",
//     "PRD-TZXVEOFA",
//     "PRD-EG1SU3GN",
//     "PRD-SE3PDADYD",
//     "PRD-DH0FJQYJ",
//     "PRD-0ITMIKYTJ",
//     "PRD-9JE9OZPOU",
//     "PRD-MDGKZTDH",
//     "PRD-W1RCSWZI",
//     "PRD-9TRAFLVG0",
//     "PRD-LFORMMCA",
//     "PRD-GNOPJHCD",
//     "PRD-GJ1OCEPC",
//     "PRD-ICD16Q68",
//     "PRD-HONALU7W1",
//     "PRD-IPUFU3HKZ",
//     "PRD-EAU10WHOZ",
//     "PRD-GKUYIILJ",
//     "PRD-6WFCNUXE",
//     "PRD-SJCAAVLT",
//     "PRD-MEDCJTERE",
//     "PRD-97VXXAS3",
//     "PRD-R7GKCJR5",
//     "PRD-ZUADUGMO",
//     "PRD-FGDHKQVD",
//     "PRD-62YQNYFW",
//     "PRD-PFXHVGHJ",
//     "PRD-6HCO0TE2X",
//     "PRD-Q7CQAIGJ",
//     "PRD-X0GSU8JN7",
//     "PRD-5TKCIRUW",
//     "PRD-E2HUSSDN",
//     "PRD-QO3YKSIQ",
//     "PRD-7AMR72RD",
//     "PRD-X8QFPKB5Z",
//     "PRD-9D1WGGZ3",
//     "PRD-3NGXVW2QU",
//     "PRD-OZ7988HJR",
//     "PRD-5XXQFMSN",
//     "PRD-ME8TZYSPR",
//     "PRD-UMXXCT7I",
//     "PRD-1CFFLN4C",
//     "PRD-6UDGOPN9",
//     "PRD-RJS0WWO6",
//     "PRD-RLDGPQB9W",
//     "PRD-FHTFWLC4U",
//     "PRD-E7TIEWKQY",
//     "PRD-6DMQSI3Y0",
//     "PRD-DHQ4BFQGN",
//     "PRD-0IDU21G8F",
//     "PRD-8LN7NQ5OH",
//     "PRD-Y2RXNFHU",
//     "PRD-V9NFSJ56I"
// ];

const holidayCombinedTestG = ["PRD-RJS0WWO6", "PRD-RLDGPQB9W"];

// const signagePRDs = [
//     "PRD-C2LMIJBZ", // Acrylic Signs - NA
//     "PRD-RXPDDRBFI", // Floor display EU
//     "PRD-AW7AG0FZ", // Green Background retractable Banner (retire)
//     "PRD-LYNCRQJFU", // Double Sided Retractable Banners - EU (do not use)
//     "PRD-IYXVEXBW", // Outdoor Retractable Banners - EU
//     "PRD-VTBJFOPH", // Retractable Banners - EU
//     "PRD-1X9N12OB", // Table Top Retractable Banners - EU
//     "PRD-JDY26PROS", // PVC Free Retractable Banners - EU
//     "PRD-YMOO63T1", // Transparent Retractable Banner (OOS)
//     "PRD-XLNWMHCXY", // Double Sided Retractable Banners - EU - NEW
//     "PRD-IAJ6NLMG", // A-Frames - NA
//     "PRD-YSORLLUJ", // Blank Tablecloths - NA
//     "PRD-7VM7JAKI", // Bulk Posters - NA
//     "PRD-BTHRKFRF", // Bumper Stickers - NA
//     "PRD-IBDSVNOY", // Banner Ropes
//     "PRD-ZDOACQLZ", // Banner Zip Tie
//     "PRD-MWIMQIDV", // Adhesive Hanger
//     "PRD-LT1L2KI8J", // Vinyl Banners
//     "PRD-HGW71SU2Z", // Mesh Banners
//     "PRD-ZSNDOGS2P", // Fabric Banners
//     "PRD-QCCQ6VLYZ", // PVC free Banner
//     "PRD-LQLZKZFAP", // Custom size Banner (TEST)
//     "PRD-SQJSRHWR", // Canopy Tents - NA
//     "PRD-LJE2OYSE", // Canvas Signs - NA
//     "PRD-XROFY2WA", // Car Door Decals - NA
//     "PRD-V8T4KB6DL", // Pole Banner
//     "PRD-EDT51BHH", // Car Door Magnets - NA
//     "PRD-4NU4FPKG", // Car Window Decals - NA
//     "PRD-PMCHJKBS", // Chalkboard Signs - NA
//     "PRD-UY3QHHCCW", // Circular tablecloth NA
//     "PRD-KPIMAABYJ", // Corrugated A-Frames
//     "PRD-US26JIBB", // Custom Floor Decals - NA
//     "PRD-N2UWZP80Z", // Custom size vinyl banner
//     // "PRD-SSOYCVF7", // Desk Name Plates - NA SINGLE COLOR PRODUCT
//     "PRD-1Z57IGIGH", // Double Sided Banners
//     "PRD-LQFILNUZ", // Fabric Banners - NA
//     "PRD-PUEHO9DMR", // Facade flags
//     "PRD-NO2UUWVN", // Flags - NA
//     "PRD-BQCWJUNM", // A-Frame
//     "PRD-VBQMEV4Y", // Liquid Chalk Pens
//     "PRD-QSSXUM99", // Chalkboard signs (cust and non cust)
//     "PRD-GZMXBVSX6", // Waterbase Pavement Signs
//     "PRD-OUIBQIUOG", // Waterbase Pavement Signs Stands
//     "PRD-M23AGMDL", // Flags Fabric Only - NA
//     "PRD-BGQ5T2COP", // Real  Estate Signs
//     "PRD-RYXDCDWY9", // Sandwich  Board
//     "PRD-RWBBHHLDC", // Tents EU
//     "PRD-7TQUKYFHO", // V-Board Signs EU
//     "PRD-MHFMTIY2", // Floor Standups - NA
//     "PRD-BFPOUIIR", // Foam Boards - NA
//     "PRD-BFBL4NNO", // Mesh Banners - NA
//     "PRD-GKMT4TIA", // Metal Signs
//     "PRD-TSMG9GSR", // Acrylic Signs - EU
//     "PRD-DPOLCOQ8Q", // Cardboard Signs
//     "PRD-BCQJXSVS", // Lawn Sign (Plastic Sign)
//     "PRD-EDELQT7E", // Foam Board - EU
//     "PRD-RSTBGWWZU", // Sticky tiles
//     "PRD-R3IIFP9W", // Indoor Rigid Sign Stands
//     "PRD-Q6YLACMP", // Wooden Signs (Plywood)
//     "PRD-NEJKI1RV", // PVC Foam Boards (Foamex)
//     "PRD-HB08HBPI", // Metal Spacers
//     "PRD-RWOHRJE2", // Metal Signs - NA
//     "PRD-GXW83BWKP", // Reboard Signs
//     "PRD-GBBQM4INR", // NA Impress Rectangle Counter Kit
//     "PRD-ULIDNHW5F", // NA Portable Exhibition Counter
//     "PRD-T5TOAHZXJ", // NA ShowGoer Case-To-Podium Kit
//     "PRD-QJK24HZOV", // Pennant Banners - NA
//     "PRD-G7UHAHFX", // Plastic Door Signs - NA
//     "PRD-64L0F4EC", // Plastic Signs - NA
//     "PRD-XGI2LOD9O", // Pole Banners - NA
//     "PRD-SDNLKFBO", // Pop Up Displays - NA
//     "PRD-6IWDAEKE", // Banner Flags
//     "PRD-QQF4U5OZ", // Façade Flags
//     "PRD-N112ILM3", // Flag Base - Auger
//     "PRD-MCMZBQSIK", // Flags - Fabric only
//     "PRD-SHFZ09N1", // Kiosk Flags
//     "PRD-HHMNQHNSS", // Flags Bases
//     "PRD-MJDKN6NZ", // Flag Base - Drive On Foot
//     "PRD-67R4CMBZ", // Flags
//     "PRD-AE6UVDE3", // Flag Base - Square Steel
//     "PRD-M3X0GPG5", // Flag Base - Ground Spike
//     "PRD-RY8X9OGO", // Pop Up Displays Fabric Only - NA
//     "PRD-HSKBMXYA", // Posters - NA
//     "PRD-9VJ1ZQGC1", // PVC Free Board Signs
//     "PRD-AHYJY00R", // Restroom Signs - NA
//     "PRD-NLRHYQSJ", // Retractable Banners - NA
//     "PRD-NFQCYGY6", // Signage Accessories - A-Frame Stand - NA
//     "PRD-YXZXBSW6Q", // Signage Accessories - Aluminum Poster Rails - NA
//     "PRD-1ZALTOFFS", // Signage Accessories - Auger Flag Base - NA
//     "PRD-ORVVHMIS", // Bulk Poster - EU (OOS)
//     "PRD-D7LMPNOY", // Outdoor Poster
//     "PRD-IG9ZMRVW", // Poster - EU (+ expansion from WMD)
//     "PRD-M0GLXONO", // LED Snap Frames - EU
//     "PRD-WNNWBBMB", // Command Strips
//     "PRD-8TRWPDRT", // Poster Holding Stand
//     "PRD-0OQ2JPFD", // Poster Frames
//     "PRD-1Y3LQCCR", // Poster Rails
//     "PRD-4HDX5AOD", // LED Light Boxes - EU
//     "PRD-JI7I9TFU", // Bulk Poster
//     "PRD-XLNTARASO", // Wooden poster hanger
//     "PRD-MUVJM2HB", // Signage Accessories - Canopy Tent Frames - NA
//     "PRD-LEUJ32J3", // Signage Accessories - Clear Adhesive Hangers - NA
//     "PRD-8EKKHBWR", // Signage Accessories - Command Strips - NA
//     "PRD-4ZCUFFUUU", // Signage Accessories - Drive Over Flag Base - NA
//     "PRD-Y8MIGCCO", // Car Window Decals - EU
//     "PRD-FVIEFEO5", // Car Door Decals - EU
//     "PRD-JKWSO2KM", // Bumper Stickers
//     "PRD-VST1EW3U", // Car Door Magnets
//     "PRD-NMMX2PV2", // Signage Accessories - Easels - NA
//     "PRD-LLK8ECQB", // Signage Accessories - Flag Bases - NA
//     "PRD-MWKBTXVX", // Signage Accessories - Flag Poles - NA
//     "PRD-LIGGXPWZ", // Wall Decals
//     "PRD-UMMALXP6", // Floor Decals
//     "PRD-QZPDJO00", // Squeegee
//     "PRD-ZEUOS3KX", // Whiteboard Film
//     "PRD-BB84I2PX", // Window Decals - EU
//     "PRD-TDTZKZPL", // Chalkboard stickers
//     "PRD-HZSXWQIOJ", // Signage Accessories - Floor Standing Easel - NA
//     "PRD-2Y0YFCPR", // Plexiglass Shield/Plastic Devider
//     "PRD-YV8MZ3C7", // Hand Sanitizer Holder
//     "PRD-IELXBWFK", // Sneeze Guard
//     "PRD-APBCI3PDS", // Signage Accessories - Ground Stake Flag Base - NA
//     "PRD-BN9QJKQY", // Signage Accessories - H Banner Stands - NA
//     "PRD-YD9BIC9GX", // Signage Accessories - Hook & Loop Fastener - NA
//     "PRD-8QOGOTS9", // Signage Accessories - L Banner Stands - NA
//     "PRD-IPOQVIH9", // Signage Accessories - LED Lights - NA
//     "PRD-ILPXWNRQ", // Signage Accessories - Magnetic Mounts - NA
//     "PRD-WPTANYFII", // Signage Accessories - Metal Spacers - 4 Pack - NA
//     "PRD-IDEFJJTXO", // Signage Accessories - Metal Spacers - 6 Pack - NA
//     "PRD-AE4MJF1X", // Signage Accessories - Metal Spacers - NA
//     "PRD-G1LRJHAW", // Signage Accessories - Nylon Rope - NA
//     "PRD-KXCGSR5TF", // Signage Accessories - Pavement Flag Base - NA
//     "PRD-QKVYQLM7F", // Signage Accessories - Plastic Poster Rails - NA
//     "PRD-97Z9QLQXX", // Signage Accessories - Plastic Yard Sign Stands - NA
//     "PRD-THXKEHKMG", // Signage Accessories - Pop Up Display Frames - NA
//     "PRD-C72DBZQ0", // Signage Accessories - Poster Rails - NA
//     "PRD-JWI6QCVV", // Easels - EU
//     "PRD-PRQRQPGN", // Signage Accessories - Poster Snap Frames - NA
//     "PRD-EVKJHFMS", // Signage Accessories - Signicade Frame - NA
//     "PRD-XPFCMEU5", // Signage Accessories - Squeegee - NA
//     "PRD-HPHRXLHP", // Signage Accessories - Suction Cups - NA
//     "PRD-8SUFEYOOE", // Signage Accessories - Table Top Easel - NA
//     "PRD-E6WADRHDX", // Signage Accessories - Table Top Yard Sign Stands - NA
//     "PRD-NVD1P2MG", // Signage Accessories - Tablecloth Clamps - NA
//     "PRD-UDRAY7GI", // Signage Accessories - Wall Clamps - NA
//     "PRD-5XCCPKZZK", // Signage Accessories - Wire Yard Sign Stands - NA
//     "PRD-SLLBZY6W6", // Shelf  Wobblers
//     "PRD-BRZB8LQX", // Silicon-edge graphics
//     "PRD-KNM3Q2KO1", // Wall Paper
//     "PRD-I45JRBZMB", // Signage Accessories - X Banner Stands - NA
//     "PRD-TD5YF4R8", // Signage Accessories - Yard Sign Stands - NA
//     "PRD-KW6BMHDT", // Signage Accessories - Zip Ties - NA
//     "PRD-M0AP7SMH", // Signicade - NA
//     "PRD-G0KGXDTP", // Table Runners - EU
//     "PRD-8RMPKQSG", // Tablecloths
//     "PRD-LQBPBBERN", // Circular Tablecloth EU
//     "PRD-KJF5VJF2K", // Stretch Tablecloth EU
//     "PRD-EARQPLINT", // Stretch Tablecloth NA
//     "PRD-SGSCYO0U", // Table Runners - NA
//     "PRD-VZFDERJK", // Table Top Signs (Combined) - NA
//     "PRD-P6MRPLBS", // Tablecloths - NA
//     "PRD-T2OU2YBXG", // Tension Fabric Displays - NA
//     "PRD-XKHDONHL", // Vinyl Banners - NA
//     "PRD-KISZLEEW", // Pop-up Displays - Frames Only
//     "PRD-OC4HIT4X", // Pop-ups - EU
//     "PRD-TU0KNT6I", // Display Lights
//     "PRD-F9V4ELYB", // Trolley Cases Pop-Ups
//     "PRD-UDXWFJOSB", // Wall Decals - NA
//     "PRD-GBAL9PPEI", // Tribecca - POS
//     "PRD-PZ9VUDXAY", // Triple sided floor display EU
//     "PRD-T4KX4TNBT", // Stowaway Banner
//     "PRD-LTUWBUX3", // Wayfinding Signs - NA
//     "PRD-JB9IGF1O4", // Tube banner stands
//     "PRD-4QRS61YPH", // Wall Tube display
//     "PRD-YGETJLGFM", // EU Portable Exhibition Counter
//     "PRD-TZZJOMLT8", // Wind Resistant Side Walk Sign - NA
//     "PRD-TOSQXNJD", // Window Decals - NA
//     "PRD-2RT8ANDBB", // Window clings - NA
//     "PRD-7AKLUSA5P", // Perforated decals - NA
//     "PRD-R1AJEBVL", // Yard Signs - NA
//     "PRD-3RBWVTLKD", // Bean bags
//     "PRD-MICTFUIWE" // Printed chairs
// ];

// const marketingMaterialsPRDs = [
//     "PRD-CTNYEUWW", // Brochures / Folded Leaflets - NA
//     "PRD-SMKHXXUQE", // Marketing Material Accessories - NA
//     "PRD-SJXWQYWJ", // Magnets - NA
//     "PRD-G6UZIHNM", // Booklets - NA
//     "PRD-YOT92MKI", // Marketing Material Accessories - NA
//     "PRD-EP0U0LNLE", // Flyers - IN
//     "PRD-OOJ0TJV9W", // Binders - IN
//     "PRD-HELYAQNXA", // Booklets - IN
//     "PRD-XW5KI8U6A", // Marketing Material Accessories - IN
//     "PRD-53ZNPKQ5P", // Placemats - IN
//     "PRD-SU9RWDJVU", // Placemats - IN
//     "PRD-L7VDT8GLY", // Placemats - IN
//     "PRD-0QQN93Q1L", // Placemats - IN
//     "PRD-1RTUEWUR", // Marketing Material Accessories - IN
//     "PRD-XH9LALB8", // Door Hangers - IN
//     "PRD-4IPP5DNW9", // Magnets - IN
//     "PRD-Z8XYUTF0D", // Placemats - IN
//     "PRD-2PTMLINSF", // Flyers - IN
//     "PRD-AYCFBSF73", // Flyers - IN
//     "PRD-0H1J1NOAA", // Flyers - IN
//     "PRD-IIFPJ9YHL", // Menus- IN
//     "PRD-5AEWL5GBE", // Magnets - IN
//     "PRD-OSEAV9B3L", // Placemats - IN
//     "PRD-DS386TY8", // Brochures / Folded Leaflets - IN
//     "PRD-AP1HPEWNX", // Booklets - IN
//     "PRD-K71QZZX9", // Rack Cards - IN
//     "PRD-3K33QSNM", // Magnets - IN
//     "PRD-QJIISQLA", // Flyers - IN
//     "PRD-UXARRJLPD", // Marketing Material Accessories - IN
//     "PRD-CXDA1TMXO", // Placemats - IN
//     "PRD-FJUAEIVG5", // Flyers - IN
//     "PRD-IOWTFSI9I", // Magnets - IN
//     "PRD-FQ789C6DY", // Booklets - IN
//     "PRD-INFZFFWLU", // Marketing Material Accessories - IN
//     "PRD-5QEQ0EBQG", // Flyers - IN
//     "PRD-8XN9JW8BL", // Magnets - IN
//     "PRD-MEEGUY6Y", // Menus- IN
//     "PRD-89KDI3JCP", // Magnets - IN
//     "PRD-S8EX5FQC3", // Desk Display - EU
//     "PRD-RE79FXDX", // Placemats - EU
//     "PRD-BJBHGGWB", // Door Hangers - EU
//     "PRD-ESGJ2KYHR", // Booklets - EU
//     "PRD-1LQVDATM", // Marketing Material Accessories - EU
//     "PRD-BHNNLMR3H", // Table Tents - EU
//     "PRD-KHXBCCQY", // Magnets - EU
//     "PRD-1EBG8KVL", // Brochures / Folded Leaflets - EU
//     "PRD-OK3TR6GPB", // Binders - EU
//     "PRD-GBFFNFAW", // Presentation Folders - EU
//     "PRD-FKK1FRUB", // Coasters - EU
//     "PRD-4OAR8RCM", // Flyers - EU
//     "PRD-O0PLCTNDH", // Tickets - EU
//     "PRD-1ARXL770", // Menus - EU
//     "PRD-UBAKEK253", // Flyers - EU
//     "PRD-MVEGDEAW", // Magnets - ANZ
//     "PRD-COFYJDVW", // Brochures / Folded Leaflets - ANZ
//     "PRD-IIHNIHNX", // Menus - ANZ
//     "PRD-NYYEACN0", // Marketing Material Accessories - ANZ
//     "PRD-AHQPKKNB", // Brochures / Folded Leaflets - ANZ
//     "PRD-TQQZYLZZI", // Placemats - ANZ
//     "PRD-N2LQNKLY", // Flyers - ANZ
//     "PRD-N9LHAR3X", // Marketing Material Accessories - ANZ
//     "PRD-IFA2Q8GJ", // Menus - ANZ
//     "PRD-IXE5AFARM", // Booklets - ANZ
//     "PRD-J03XJKRBG", // Flyers - ANZ
//     "PRD-08SZPP1W", // Placemats - ANZ
//     "PRD-GQ8BQSOQ", // Menus - ANZ
//     "PRD-B9EJV5IY", // Postcards - IN
//     "PRD-LCOISZBH", // Postcards - EU
//     "PRD-KSRZIQ3ZX", // Postcards - EU
//     "PRD-HBTR1ZFR", // Postcards - ANZ
//     "PRD-SXGXDC2AY" // Postcards - ANZ
// ];

// const StickersABTest = [
//     "PRD-YW87FCKFB",
//     "PRD-GZ4AXUAC",
//     "PRD-OF0TLADZ8",
//     "PRD-B0UCELQSR",
//     "PRD-ZJMARZ44",
//     "PRD-WMRXQ39ZC",
//     "PRD-TH24SFJ9",
//     "PRD-EFLP0L0PS",
//     "PRD-GFWSWFE9",
//     "PRD-4BVJSNLE",
//     "PRD-PYMAD6BTW",
//     "PRD-5IM7BS8KK",
//     "PRD-NKLWL3IA",
//     "PRD-HRW8GSPPY",
//     "PRD-TGXYL04SG",
//     "PRD-H7MJGOP3",
//     "PRD-YMQOHDDQQ",
//     "PRD-XE2F86IEC",
//     "PRD-WTZHU8BXT",
//     "PRD-WOAOGCWL",
//     "PRD-WO3ZI1RZR",
//     "PRD-UZE5LVT6J",
//     "PRD-TJ35CHT8",
//     "PRD-T2YGMOXZM",
//     "PRD-QDX1YADL",
//     "PRD-PMDVJEAMC",
//     "PRD-MBROW87FE",
//     "PRD-LDDVZZ2Z",
//     "PRD-IL4WCK4Z8",
//     "PRD-I8CDD4VTA",
//     "PRD-HMFICJEF",
//     "PRD-FEJPBPGWF",
//     "PRD-EFSQ5VWHE",
//     "PRD-DF5PWTHC",
//     "PRD-ADZNM4LP",
//     "PRD-AC7PN8I8X",
//     "PRD-A0IDGCTAH",
//     "PRD-8GPNVH4F",
//     "PRD-6JP2MB8WB",
//     "PRD-5H4YSQTDC",
//     "PRD-DBQKJABA",
//     "PRD-GM3AR6AZ",
//     "PRD-A1R7AUI3",
//     "PRD-LOA0NMEYC",
//     "PRD-1B7JR4VG",
//     "PRD-JKYUUXWV",
//     "PRD-618UJPXA",
//     "PRD-BJAUOWMX",
//     "PRD-C91T4Q9RX",
//     "PRD-WUI8386EU",
//     "PRD-XUJORULWH",
//     "PRD-DBQKJABA",
//     "PRD-PRS6YR6D",
//     "PRD-K5CSOWI66",
//     "PRD-UN8M4UA9",
//     "PRD-KAP3ZW3D",
//     "PRD-9XEFDKOU",
//     "PRD-064TWAC4Z",
//     "PRD-VYASTIJA",
//     "PRD-SKFTVDJWH",
//     "PRD-ZHIF8AH9",
//     "PRD-JREXPUNSX",
//     "PRD-KGKOKJRBD",
//     "PRD-ASUICNRAG",
//     "PRD-SAYNYWUAB",
//     "PRD-NFCPUSWV3",
//     "PRD-FMRJTFKO",
//     "PRD-JYVOJLIB",
//     "PRD-MOTUCCPIW",
//     "PRD-OBZME1YLS",
//     "PRD-3KXZP3BHG",
//     "PRD-LH4CU4ZDM",
//     "PRD-XTHGKQTA",
//     "PRD-NY4BPQQ6",
//     "PRD-RGYZY9GV"
// ];

// const consumerPRDs = [
//     "PRD-THAIXFSI", // NA Custom Envelopes
//     "PRD-CMKGEJ0D", // Custom Envelopes
//     "PRD-FWY9GXHO", // Invitation & Announcement (all Events)
//     "PRD-ANBICZ62O", // ANZS Custom Envelopes - New
//     "PRD-ZLXWNEJLV", // Foam Board Wedding Signs - EU
//     "PRD-SEW7I3UWP", // Kids' Labels
//     "PRD-LBZPHAVN", // EU Consumer Mugs
//     "PRD-NFMVBYBK1", // NA Photo Balloons
//     "PRD-8IF1J2QDT", // ANZS Menu Cards
//     "PRD-KV3KUVSV4", // ANZS Essential Business Mugs
//     "PRD-AEENMJHIY", // ANZS White Envelopes with RA - New
//     "PRD-FV85HQGBD", // ANZS Premium Mugs
//     "PRD-DWQ1UMNT", // ANZS Custom Envelopes
//     "PRD-R3SGSIAEP", // NA Classroom Valentines
//     "PRD-JUGZN935", // EU White Envelopes with RA
//     "PRD-ECS7YYP1R", // ANZS Kids Drink Bottles
//     "PRD-QQCAO8UD3", // ANZS Hardboard Photo Tiles
//     "PRD-TFCG8A3T", // EU Mug
//     "PRD-AGZ4V6K4F", // EU Stars Accessory Envelope
//     "PRD-EUTBA7N5X", // EU Plaid Accessory Envelope
//     "PRD-3CH4HC0M", // ANZS Wedding Reception Cards
//     "PRD-QXEAKTVFA", // ANZS Greeting Cards
//     "PRD-TIBZBCSY", // ANZS Save The Date Cards
//     "PRD-PXVRCK6WY" // ANZS Kids Bento Lunch Boxes
// ];

// taken from https://vistaprint.app.box.com/s/8bwtnkp61kk34dp6x08l3zyflhgtm6w8
// const fullColorPPAGPRDs = [
//     "PRD-LBJVP0NSZ",
//     "PRD-5A2E0BC6",
//     "PRD-M9JXECHMS",
//     "PRD-X2FCWNP7J",
//     "PRD-6B120048",
//     "PRD-LTPDSH2BG",
//     "PRD-ONG6ONIW",
//     "PRD-90Z53NUV",
//     "PRD-YJQZ5YGVD",
//     "PRD-9GTOL9U2R",
//     "PRD-CUY8VTBK",
//     "PRD-TJVM79SB",
//     "PRD-GJBAZ6SI",
//     "PRD-UNGQGV4B",
//     "PRD-EBB0218E",
//     "PRD-GZJIDKKBD",
//     "PRD-468AB68A",
//     "PRD-POULAPDMU",
//     "PRD-44F46BC4",
//     "PRD-5A322CA1",
//     "PRD-BRCBRPHI",
//     "PRD-AIBMGROJ",
//     "PRD-1K0OD84K7",
//     "PRD-RXTCQYV4E",
//     "PRD-MPOFRX7UU",
//     "PRD-FFB01A13",
//     "PRD-90E019E8",
//     "PRD-NMLDUOJX",
//     "PRD-7BE64EFE",
//     "PRD-XGTYQOMLS",
//     "PRD-TJLRSSQ2H",
//     "PRD-4D539DA8",
//     "PRD-YTHZAU7E",
//     "PRD-E8RGTTKMO",
//     "PRD-INRJEHPSJ",
//     "PRD-QJ4U8VRTJ",
//     "PRD-D316FF3C",
//     "PRD-TNNCNZOL",
//     "PRD-JJOYDYAZB",
//     "PRD-D0KONNOO",
//     "PRD-C70645EB",
//     "PRD-ZMIASAGCA",
//     "PRD-BDHGSKP1A",
//     "PRD-DPJV3BTR9",
//     "PRD-D8DJNNFO2",
//     "PRD-29C320D9",
//     "PRD-SLO8S3VTK",
//     "PRD-554231CB",
//     "PRD-1C29DC73",
//     "PRD-NSZEGFYAV",
//     "PRD-RVLDYOIJT",
//     "PRD-L3FZBLSJR",
//     "PRD-TONQ4CEBM",
//     "PRD-64139D6F",
//     "PRD-FQZQCPZZM",
//     "PRD-HHBYE3Y2J",
//     "PRD-493DE5CB",
//     "PRD-NVCVND0KQ",
//     "PRD-4CE9D10B",
//     "PRD-9QVKXZ8GU",
//     "PRD-GAOSLGCQ6",
//     "PRD-EJWSWP6ZH",
//     "PRD-OMPBXAQWM",
//     "PRD-RJDKZQXSX",
//     "PRD-HKFQGEO8I",
//     "PRD-HLHE6AFQT",
//     "PRD-DDEIUPJKG",
//     "PRD-JYKQVQLXP",
//     "PRD-VUCKHPGUK",
//     "PRD-0D0D5B05",
//     "PRD-IACD88IQ",
//     "PRD-ZPIZQUAHM",
//     "PRD-YCWZCXVU7",
//     "PRD-RFNCCYUKO",
//     "PRD-K3FPYHARQ",
//     "PRD-068348BA",
//     "PRD-D7QD3ELJ",
//     "PRD-W406LMKKA",
//     "PRD-6TNUZAR8P",
//     "PRD-QIP7X7OCK",
//     "PRD-IZ199L9JF",
//     "PRD-X5RTSEWKO",
//     "PRD-D9HBOBYJL",
//     "PRD-MOWKHGNNG",
//     "PRD-WYZKWVWQK",
//     "PRD-XSQXRUQN2",
//     "PRD-HVAWLIBLQ",
//     "PRD-AIJLZ7PP3",
//     "PRD-930644D1",
//     "PRD-G1LNP8XI8",
//     "PRD-BUJS6EJU",
//     "PRD-UUCFTU8X",
//     "PRD-MVO78KZ0I",
//     "PRD-UQCVKUQSU",
//     "PRD-NAGCOPGRL",
//     "PRD-Z5RYN3ORL",
//     "PRD-ASFUMNV6",
//     "PRD-CGHX6HXEF",
//     "PRD-4OPOUXB56",
//     "PRD-J8VC2PKRQ",
//     "PRD-NUHD7UWG",
//     "PRD-LDCWL10IG",
//     "PRD-CZOO9YPH7",
//     "PRD-MPOKVMWKU",
//     "PRD-H3HKMZ6MB",
//     "PRD-9H6INOFM",
//     "PRD-QN4GC0IK3",
//     "PRD-E4C32BD3",
//     "PRD-CC42B695",
//     "PRD-W7DHO8SST",
//     "PRD-R3EQD3FR",
//     "PRD-XMOZMNLIO",
//     "PRD-BPZQUWF29",
//     "PRD-9915D202",
//     "PRD-SBWUZQMFX",
//     "PRD-J9UD0DFU",
//     "PRD-TEUJOPT0E",
//     "PRD-8NJCF1WMT",
//     "PRD-ESFPDTBCW",
//     "PRD-ADUFHO14U",
//     "PRD-9KZFPHKMU",
//     "PRD-DF14144C",
//     "PRD-SLUOHWL3W",
//     "PRD-BLQALKTUQ",
//     "PRD-TDPWLJIYU",
//     "PRD-QMA7U0KQZ",
//     "PRD-XFB7Q0NY",
//     "PRD-CO4N7ORFO",
//     "PRD-OWTYJJUTK",
//     "PRD-FCLT91XGL",
//     "PRD-UFDRGBEPC",
//     "PRD-4GN1QE6L6",
//     "PRD-CYZBFN997",
//     "PRD-LFHP3X2GZ",
//     "PRD-HDKVUNYFD",
//     "PRD-ESN3UFJ0T",
//     "PRD-TADCHSHJZ",
//     "PRD-TTAVD5C1R",
//     "PRD-RE7IQ1PRE",
//     "PRD-TH1GVQVHZ",
//     "PRD-7X63Q2W1H",
//     "PRD-QO0ZX0G3L",
//     "PRD-5GJHSYHBN"
// ];

export function isPRDInHolidayCombinedTestG(productKey: string): boolean {
    return holidayCombinedTestG.includes(productKey);
}

export function isPRDInStudio6ABTest(productKey: string): boolean {
    return holidayCombinedTestG.includes(productKey);
}
