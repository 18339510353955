import { createSlice } from "@reduxjs/toolkit";
import { setError, showSaveLoader } from "../compoundActions";

const showErrorPageSlice = createSlice({
    name: "showErrorPage",
    initialState: false as null | boolean,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(showSaveLoader, (state, action) => null);
        builder.addCase(setError, (state, action) => action.payload.showErrorPage ?? false);
    }
});

export const showErrorPage = showErrorPageSlice.reducer;
