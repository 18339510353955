import React, { createContext, useEffect, useState, useMemo, ReactNode, useCallback } from "react";
import { useAbTestContext } from "@shared/features/ABTesting";

type ContextData = {
    isProofGenerationABEnabled: boolean;
    isTestActive: boolean;
    trackImpression: () => void;
};

export const generateProofABcontext = createContext<ContextData | undefined>(undefined);

enum Variations {
    Control = "control",
    Enabled = "variation"
}

export const GenerateProofExperimentData: ExperimentData = {
    experimentKey: "pdf_proof_generation",
    experimentName: "Enable Pdf Proof Generation",
    variations: Variations
};

type Props = {
    children: ReactNode | ReactNode[];
};

export const GenerateProofABProvider = ({ children }: Props) => {
    const { Provider } = generateProofABcontext;
    const ABTest = useAbTestContext();
    const { experimentKey } = GenerateProofExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [isTestActive, setIsTestActive] = useState(false);

    useEffect(() => {
        if (!experimentKey || !ABTest) {
            return;
        }
        const { isExperimentActive, isExperimentUsingVariation } = ABTest;
        isExperimentActive(experimentKey).then((active: boolean) => {
            setIsTestActive(active);
        });

        const checkEnabledVariation = async () => {
            const res = await isExperimentUsingVariation(experimentKey, Variations.Enabled);
            setIsEnabled(!!res);
        };
        checkEnabledVariation();
    }, [experimentKey, ABTest]);

    const trackImpression = useCallback(() => {
        if (!experimentKey || !ABTest) {
            return;
        }
        const { isExperimentUsingVariation, trackImpression } = ABTest;
        const trackImpressionIfInVariant = async (variation: Variations) => {
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res) {
                trackImpression(experimentKey, variation);
            }
        };
        trackImpressionIfInVariant(Variations.Enabled);
        trackImpressionIfInVariant(Variations.Control);
    }, [ABTest, experimentKey]);

    const contextObject = useMemo(
        () => ({
            isProofGenerationABEnabled: isEnabled,
            isTestActive,
            trackImpression
        }),
        [isEnabled, isTestActive, trackImpression]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

GenerateProofABProvider.displayName = "GenerateProofABProvider";
