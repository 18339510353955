import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompatibleOptionsEntry } from "../../utils/Product";
import { setSelectedAndCompatibleProductOptions } from "../compoundActions";

type StateType = CompatibleOptionsEntry[];

const compatibleProductOptionsSlice = createSlice({
    name: "compatibleProductOptions",
    initialState: [] as StateType,
    reducers: {
        setCompatibleProductOptions(state, action: PayloadAction<StateType>) {
            return action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(setSelectedAndCompatibleProductOptions, (state, action) => {
            return action.payload.compatibleOptions ?? state;
        });
    }
});

export const { setCompatibleProductOptions } = compatibleProductOptionsSlice.actions;
export const compatibleProductOptions = compatibleProductOptionsSlice.reducer;
