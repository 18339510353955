import { tryFetch } from "@shared/utils/Network";
import { newRelicWrapper } from "@shared/utils/Errors";
import { getQueryParams } from "@shared/utils/WebBrowser";

const host = getQueryParams().uploadsUrl || UPLOAD_POST_URL;
const entityCode = 16;
const merchantId = MERCHANT_ID;
const mcpUploadsHost = "https://uploads.documents.cimpress.io";

export type Upload = Record<string, string | number | undefined>;

// Take upload url and parse id out
export function parseIdFromUrl(url: any) {
    if (url && typeof url === "string") {
        // https://uploads.documents.cimpress.io/v1/uploads/ddb5c6c3-362d-4e9f-bc42-0bee48d38ab5~110/original?tenant=designtechprod
        const urlParts = url.split("/");
        const index = urlParts.indexOf("uploads") + 1;
        return index > 0 && urlParts[index];
    }
    return undefined;
}

const buildUploadUrl = (upload: Upload, variant: string) => {
    const uploadId = parseIdFromUrl(upload.url || upload.originalUrl) || upload.uploadId;
    let uploadUrl = `${mcpUploadsHost}/v1/uploads/${uploadId}/${variant}?tenant=${upload.tenant}`;
    if (upload.uploadToken) {
        uploadUrl = `${uploadUrl}&uploadToken=${upload.uploadToken}`;
    }
    return uploadUrl;
};

/**
 * Takes upload object from upload service (MCP model) and map it model that designer uses in localstorage to show images
 * For more details: manageNewUpload function in UploadManager.js cimpress-designer
 */
export function formatUpload(upload: Upload) {
    return {
        status: upload.status || 3, // uploadStatus.js in cimpress-designer
        percentComplete: 100,
        id: upload.uploadId,
        requestId: upload.uploadId,
        fileType: upload.originalFileContentType,
        validations: [],
        pages: [
            {
                analysis: {
                    isLogo: upload.analysisIsLogo,
                    isPhoto: upload.analysisIsPhoto,
                    isVector: upload.analysisIsVector,
                    lineartness: upload.analysisLineartness
                },
                pageNumber: upload.pageNumber || 1,
                file: {},
                status: 50,
                percentComplete: 0,
                fileType: upload.originalFileContentType,
                requestId: upload.uploadId,
                uploadTime: upload.processingTimeEnd,
                fileSize: upload.originalFileSize,
                height: upload.printPixelHeight,
                width: upload.printPixelWidth,
                originalUrl: upload.url,
                printUrl: upload.printUrl,
                previewUrl: upload.previewUrl
            }
        ],
        file: {},
        fileName: upload.uploadedFileName,
        originalUrl: upload.url,
        printUrl: upload.printUrl
    };
}

async function getUploadUrls(authToken: string) {
    // grab the owner from query param if there so care agent can upload to correct owner
    const owner = getQueryParams().owner || "";

    return tryFetch({
        url: `${host}/uploads/mcpUrls?owner=${owner}&tenant=${merchantId}&includeTenant=false`,
        options: {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "uploadClient:getUploadUrl",
        friendlyDescription: "retrieve authenticated upload urls",
        entityCode
    });
}

async function getUploads(authToken: string) {
    // grab the owner from query param if there so care agent can upload to correct owner
    const owner = getQueryParams().owner || "";
    return tryFetch<any>({
        url: `${host}/uploads?owner=${owner}`,
        options: {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "uploadClient:getUploads",
        friendlyDescription: "get user uploads",
        entityCode
    });
}

export async function deleteUpload(authToken: string, uploadId: string | undefined | false) {
    if (uploadId && typeof uploadId === "string") {
        // grab the owner from query param if there so care agent can upload to correct owner
        const owner = getQueryParams().owner || "";
        return tryFetch({
            url: `${host}/uploads/${uploadId}?owner=${owner}`,
            options: {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`
                }
            },
            moduleFunction: "uploadClient:deleteUpload",
            friendlyDescription: "delete user upload",
            entityCode
        });
    }
    return undefined;
}

/**
 * Get image upload url from url client
 */
export async function getImageUploadUrl(authToken: string) {
    try {
        const response = await getUploadUrls(authToken);
        return response;
    } catch (e) {
        // temporary logging to figure out invalid token issue
        newRelicWrapper.logPageAction("studio-uploadurls-failed", {
            ...e,
            authToken
        });
        throw e;
    }
}

export async function getFormattedUploads(authToken: string) {
    const downloadStart = Date.now();
    const result = await getUploads(authToken);
    newRelicWrapper.logPageAction("PreviousUploads:Finish", { callTime: (Date.now() - downloadStart) / 1000 });
    return result.uploads.map((upload: Upload) =>
        formatUpload({
            ...upload,
            printUrl: buildUploadUrl(upload, "print"),
            previewUrl: buildUploadUrl(upload, "preview")
        })
    );
}
