import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const workLastSavedSlice = createSlice({
    name: "workLastSaved",
    initialState: null as string | null,
    reducers: {
        setWorkLastSaved(state, action: PayloadAction<string | null>) {
            return action.payload ?? state;
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.workLastSaved ?? state;
        });
    }
});

export const { setWorkLastSaved } = workLastSavedSlice.actions;
export const workLastSaved = workLastSavedSlice.reducer;
