import qs from "qs";
import Cookies from "js-cookie";
import { tryFetch } from "@shared/utils/Network";
import { ensureUserTestId } from "@vp/ab-test-cookie";
import impression from "@vp/ab-impression";

const host = SITE_FLOW_URL;
const entityCode = 32;

export type NextStepAction = "None" | "Navigate";
export interface PredictedStep {
    action: NextStepAction;
    url: string;
    urls: string[];
}
export interface Context {
    experiment: string;
    variation: string;
}
export interface FlowResponse {
    action: NextStepAction;
    url: string;
    urls: string[];
    flowAction: string;
    type: string;
    predictedFlow: PredictedStep[];
    evaluationSteps: any; // See https://siteflow.merch.vpsvc.com/index.html if we need to fill this in.
    context: Context[];
}

export async function getNextStep(
    productKey: string,
    productVersion: number,
    optionValues: Record<string, string>,
    locale: string
): Promise<FlowResponse> {
    const query = qs.stringify({
        requestor: REQUESTOR,
        optionValues,
        siteContext: Cookies.get("sci-scx"),
        optimizelyEndUserId: ensureUserTestId()
    });
    return tryFetch({
        url: `${host}/nextstep/vistaprint/${locale}/Studio/${productKey}/${productVersion}?${query}`,
        moduleFunction: "siteFlowClient:getNextStep",
        friendlyDescription: "get next step in site flow",
        entityCode
    });
}

// GetAction is an endpoint on site flow that allows us to navigate to specific pages
// We're using it to send customers to pages get customers out of error states
export async function getAction(
    productKey: string,
    productVersion: string | number,
    optionValues: Record<string, string>,
    locale: string,
    action: string
): Promise<FlowResponse> {
    const query = qs.stringify({
        requestor: REQUESTOR,
        optionValues,
        siteContext: Cookies.get("sci-scx"),
        optimizelyEndUserId: ensureUserTestId()
    });
    return tryFetch({
        url: `${host}/action/vistaprint/${locale}/${action}/${productKey}/${productVersion}?${query}`,
        moduleFunction: "siteFlowClient:getAction",
        friendlyDescription: `get next step for ${action}`,
        entityCode
    });
}

// fireImpressionsForContexts is an utlity to fire impressions for all the experiments
// considered as viewed at the moment the user navigates to the next page
export async function fireImpressionsForContexts(contexts: Context[]): Promise<void> {
    if (contexts.length > 0) {
        await Promise.all(contexts.map(c => impression.fireImpression(c.experiment, c.variation, OPTIMIZELY_SDK_KEY)));
    }
}
