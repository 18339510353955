export const Spot = "spot"; // A string representing a spot color

export const INITIAL_ICONS_MAX_PERCENT_OF_CANVAS = 0.2;

// Based on
//  https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/cimpress-designer/-/blob/master/app/publicApi/constants/processTypes.js
export enum ProcessType {
    none = "none",
    offset = "offset",
    digital = "digital",
    offsetOrDigital = "offsetOrDigital",
    directToGarment = "directToGarment",
    heatTransfer = "heatTransfer",
    laserEngraving = "laserEngraving",
    screenPrint = "screenPrint",
    padPrint = "padPrint",
    inkJet = "inkJet",
    thermography = "thermography",
    embroideryFlat = "embroideryFlat",
    embroideryCylinder = "embroideryCylinder",
    sublimation = "sublimation",
    unknown = "unknown"
}

export const USER_CHANGE_EVENT = "identity:change";

enum DecoTechCategory {
    print = "print",
    pantone = "pantone",
    engraved = "engraved",
    embroidery = "embroidery"
}

export const decoTechCategories = {
    [ProcessType.offset]: DecoTechCategory.print,
    [ProcessType.digital]: DecoTechCategory.print,
    [ProcessType.offsetOrDigital]: DecoTechCategory.print,
    [ProcessType.directToGarment]: DecoTechCategory.print,
    [ProcessType.heatTransfer]: DecoTechCategory.print,
    [ProcessType.laserEngraving]: DecoTechCategory.engraved,
    [ProcessType.screenPrint]: DecoTechCategory.pantone,
    [ProcessType.padPrint]: DecoTechCategory.pantone,
    [ProcessType.inkJet]: DecoTechCategory.print,
    [ProcessType.thermography]: DecoTechCategory.print,
    [ProcessType.embroideryFlat]: DecoTechCategory.embroidery,
    [ProcessType.embroideryCylinder]: DecoTechCategory.embroidery,
    [ProcessType.sublimation]: DecoTechCategory.print
};
