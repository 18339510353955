import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const ownerSlice = createSlice({
    name: "owner",
    initialState: "",
    reducers: {
        setOwner(state, action: PayloadAction<string | null>) {
            return action.payload || null;
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => action.payload.owner || null);
    }
});

export const { setOwner } = ownerSlice.actions;
export const owner = ownerSlice.reducer;
