import React from "react";
import { P, Typography } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useUploadConfiguration } from "@design-stack-vista/upload-components";
import { useUploadComponents } from "./useUploadComponents";

interface Props {
    isImageUpload?: boolean;
    showTitle?: boolean;
}

const messages = defineMessages({
    imagePanelAcceptedFormats: {
        id: "studio.components.panels.studiopanels.acceptedFormats",
        defaultMessage: "Accepted formats",
        description: {
            note: "Accepted formats title"
        }
    }
});

export function AcceptedUploadFormats({ isImageUpload, showTitle }: Props) {
    const { isSmall } = useStudioLayout();
    const uploadComponents = useUploadComponents();
    const { t } = useTranslationSSR();

    const {
        supportedFileTypes: { fileExtensionsAsString }
    } = useUploadConfiguration();

    const marginForText = isSmall ? 5 : 6;
    const acceptedFormatsStr = uploadComponents
        ? fileExtensionsAsString
        : ".jpg, .jpeg, .bmp, .png, .gif, .heic, .tif, .tiff, .pdf, .psd, .ai, .ait, .ppt, .pptx.";
    return (
        <>
            {showTitle && (
                <Typography fontSize="1" mb={isSmall ? 2 : 0} fontWeight={isSmall ? "bold" : "normal"}>
                    {t(messages.imagePanelAcceptedFormats.id)}
                </Typography>
            )}
            <P mt={0} mb={!isImageUpload ? marginForText : 4} textColor="dark-grey" fontSize={isImageUpload ? -2 : -1}>
                {acceptedFormatsStr}
            </P>
        </>
    );
}
AcceptedUploadFormats.displayName = "AcceptedUploadFormats";
