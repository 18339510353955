interface ApiCall {
    /**
     * Used to describe the call.
     * Commonly <module>:<function>
     */
    module: string;
    /** Stringified body, if any */
    body?: string;
    /** May provide method (GET, POST) */
    method?: string;
    /** URL called */
    url: string;
    /** Result of the call */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result: Error | Record<string, any> | string;
    /** Status code of the call */
    statusCode: number;
}

export const apiCalls: ApiCall[] = [];

export function trackApiCall(apiCall: ApiCall) {
    apiCalls.push(apiCall);
}
