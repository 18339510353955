import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductOption } from "../../utils/Product";

const designAttributeMappingsSlice = createSlice({
    name: "designAttributeMappings",
    initialState: [] as ProductOption[],
    reducers: {
        setDesignAttributeMappings: (state, action: PayloadAction<ProductOption[]>) => action.payload
    }
});

export const { setDesignAttributeMappings } = designAttributeMappingsSlice.actions;
export const designAttributeMappings = designAttributeMappingsSlice.reducer;
