import { createSlice } from "@reduxjs/toolkit";
import { resetAlerts } from "./alerts";

const hideAlertToastSlice = createSlice({
    name: "hideAlertToast",
    initialState: false,
    reducers: {
        autoClosableAlert: () => true
    },
    extraReducers: builder => {
        builder.addCase(resetAlerts, () => false);
    }
});

export const { autoClosableAlert } = hideAlertToastSlice.actions;
export const hideAlertToast = hideAlertToastSlice.reducer;
