import { windowExists, getQueryParams } from "@shared/utils/WebBrowser";
import { isCareAgent } from "@shared/utils/Care";
import { getUserSessionId, getStudioUniqueSessionId } from "@shared/utils/Tracking";

declare global {
    interface Window {
        newrelic: typeof newrelic;
        QuantumMetricAPI?: {
            getReplay?: () => string;
        };
        QuantumMetricOnload: () => void;
    }
}

function cleanseAttributeName(input: string) {
    // allowable characters retrieved from
    // https://docs.newrelic.com/docs/insights/insights-data-sources/custom-data/insights-custom-data-requirements-limits#general
    return input.replace(/[^a-zA-Z0-9_.:]/g, "_");
}

class NewRelicWrapper {
    NewRelic: typeof newrelic;

    constructor(NewRelic: typeof newrelic) {
        this.NewRelic = NewRelic;
        if (windowExists()) {
            this.NewRelic.setCustomAttribute("metadata/href", window.location.href);
            const queryParams = getQueryParams();
            Object.keys(queryParams).forEach(param => {
                if (param === "selectedOptions") {
                    Object.keys(queryParams[param]).forEach(option => {
                        this.NewRelic.setCustomAttribute(
                            `param/selectedOptions/${cleanseAttributeName(option)}`,
                            queryParams[param][option]
                        );
                    });
                } else {
                    this.NewRelic.setCustomAttribute(`param/${cleanseAttributeName(param)}`, queryParams[param]);
                }
            });
            if (window.QuantumMetricAPI && window.QuantumMetricAPI.getReplay) {
                this.NewRelic.setCustomAttribute("QMReplayLink", window.QuantumMetricAPI.getReplay());
            } else {
                window.QuantumMetricOnload = () => {
                    if (window.QuantumMetricAPI && window.QuantumMetricAPI.getReplay) {
                        this.NewRelic.setCustomAttribute("QMReplayLink", window.QuantumMetricAPI.getReplay());
                    }
                };
            }
        }
    }

    setUpUserVars(locale: string) {
        this.NewRelic.setCustomAttribute("studioUserSession", getUserSessionId());
        this.NewRelic.setCustomAttribute("studioUniqueSession", getStudioUniqueSessionId());
        this.NewRelic.setCustomAttribute("metadata/locale", locale);
    }

    setLoadFinished() {
        this.NewRelic.setCustomAttribute("loadingFinished", "true");
    }

    updateCustomAttributes(state: Record<string, any>) {
        try {
            const selectedProductOptions = state.selectedProductOptions || state.customerSelectedProductOptions;
            if (selectedProductOptions) {
                Object.keys(selectedProductOptions).forEach(option => {
                    this.NewRelic.setCustomAttribute(
                        `param/selectedOptions/${cleanseAttributeName(option)}`,
                        selectedProductOptions[option]
                    );
                });
            }
            if (state.productKey) {
                this.NewRelic.setCustomAttribute(`param/key`, state.productKey);
            }
            if (state.productVersion) {
                this.NewRelic.setCustomAttribute(`param/productVersion`, state.productVersion);
            }
            if (state.quantity) {
                this.NewRelic.setCustomAttribute(`param/qty`, state.quantity);
            }
            if (state.workId) {
                this.NewRelic.setCustomAttribute(`param/workId`, state.workId);
            }
            if (state.mpvId) {
                this.NewRelic.setCustomAttribute(`param/mpvId`, state.mpvId);
            }
            this.NewRelic.setCustomAttribute(`isCareAgent`, `${isCareAgent()}`);
            // eslint-disable-next-line no-empty
        } catch {}
    }

    markRelease() {
        this.NewRelic.addRelease("studio", `${RELEASE_ID}`);
    }

    logPageAction(eventName: string, includedMetadata?: Record<string, any>) {
        this.NewRelic.addPageAction(eventName, includedMetadata);
    }

    newTimingEvent(eventName: string, includedMetadata?: Record<string, any>) {
        let startTime: number;
        return {
            start: () => {
                startTime = performance.now();
            },
            end: () => {
                const duration = (performance.now() - startTime) / 1000;
                this.NewRelic.addPageAction(eventName, {
                    ...includedMetadata,
                    duration
                });
            },
            status: () => {
                return performance.now() - startTime;
            }
        };
    }

    noticeError(error: Error | string, includedMetadata?: Record<string, any>) {
        this.NewRelic.noticeError(error, includedMetadata);
    }
}

const NewRelic =
    windowExists() && typeof window.newrelic !== "undefined"
        ? window.newrelic
        : {
              addPageAction() {},
              addRelease() {},
              noticeError() {},
              setCustomAttribute() {}
          };

export const newRelicWrapper = new NewRelicWrapper(NewRelic);
