import { defineMessages } from "@vp/i18n-helper";

export const RedesignedUploadsPanelMessages = defineMessages({
    hideUsedPhotos: {
        id: "easel.components.panels.uploadsPanel.hideUsedPhotosLabel",
        defaultMessage: "Hide used photos",
        description: {
            note: "Checkbox to hide photos already used in the design"
        }
    },
    uploadButton: {
        id: "easel.components.panels.uploadsPanelRedesign.uploadButton",
        defaultMessage: "Upload logo or image",
        description: {
            note: "The button users tap/click to upload an image"
        }
    },
    uploadImageButton: {
        id: "easel.components.panels.uploadsPanelRedesign.uploadImageButton",
        defaultMessage: "Upload an image",
        description: {
            note: "The button users tap/click to upload an image"
        }
    },
    deleteButton: {
        id: "easel.components.panels.uploadsThumbnail.delete",
        defaultMessage: "Delete",
        description: {
            note: "Button on bottom of image in order to delete image"
        }
    },
    previousImagesText: {
        id: "easel.components.panels.uploadsThumbnail.previousImagesText",
        defaultMessage: "Your Recent and Previous Photos",
        description: {
            note: "Text above list of images"
        }
    },
    uploading: {
        id: "easel.components.panels.uploadsThumbnail.uploading",
        defaultMessage: "Uploading....",
        description: {
            note: "Text that appears below the progress indicator to indicate the image is being uploaded"
        }
    },
    processing: {
        id: "easel.components.panels.uploadsThumbnail.processing",
        defaultMessage: "Processing....",
        description: {
            note: "Text that appears below the progress indicator to indicate the image is being processed"
        }
    },
    currentlyUploading: {
        id: "easel.components.panels.uploadUImage.currentlyUploading",
        defaultMessage: "Currently Uploading",
        description: {
            note: "Alt text that appears when an upload doesn't have a file name"
        }
    },
    recentlyUploaded: {
        id: "easel.components.panels.uploadsPanel.recentlyUploaded",
        defaultMessage: "Recently Uploaded",
        description: {
            note: "Text that appears above the list of images"
        }
    },
    previousUploads: {
        id: "easel.components.panels.uploadsPanel.previousUploads",
        defaultMessage: "to access previous uploads.",
        description: {
            note: "Text that appears above the login button"
        }
    },
    login: {
        id: "easel.components.panels.uploadsPanel.login",
        defaultMessage: "Log in",
        description: {
            note: "Text for login button"
        }
    },
    loading: {
        id: "easel.components.panels.uploadsPanel.loading",
        defaultMessage: "Loading....",
        description: {
            note: "Text that appears on loading indicator to indicate that images are loading"
        }
    },
    deleting: {
        id: "easel.components.panels.uploadsThumbnail.deleting",
        defaultMessage: "Deleting....",
        description: {
            note: "Text that appears on a thumbnail indicator to indicate that images are deleted"
        }
    },
    noImagesFound: {
        id: "easel.components.panels.uploadsThumbnail.noImagesFound",
        defaultMessage: "No images found",
        description: {
            note: "Text indicator to show that no images have been found. "
        }
    },
    inUseLabel: {
        id: "easel.components.panels.uploadsThumbnail.inUseLabel",
        defaultMessage: "Image is used in Design",
        description: {
            note: "Label to indicate that an image is used on the design. "
        }
    },
    closeButton: {
        id: "easel.components.panels.uploads.deleteImageDialog.closeButton",
        defaultMessage: "Close Delete Image Dialog"
    },
    modalDialogTitle: {
        id: "easel.components.panels.uploads.deleteImageDialog.modalDialogTitle",
        defaultMessage: "Delete Image"
    },
    modalDialogBody: {
        id: "easel.components.panels.uploads.deleteImageDialog.modalDialogBody",
        defaultMessage: "Are you sure you want to delete this image?"
    },
    cancelButton: {
        id: "easel.components.panels.uploads.deleteImageDialog.cancelButton",
        defaultMessage: "Cancel"
    },
    confirmButton: {
        id: "easel.components.panels.uploads.deleteImageDialog.confirmButton",
        defaultMessage: "Confirm"
    }
});
