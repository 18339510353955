export const photoHeavyProductKeyList = [
    "PRD-JZ2W7RS8M",
    "PRD-6YLYJS8P",
    "PRD-G0FY0APWW",
    "PRD-V7H0GE4Q",
    "PRD-BMW2IYNBH",
    "PRD-0BKUQJYW",
    "PRD-P4DIGX3P",
    "PRD-GBMPUBTP",
    "PRD-6BEDVKYYT",
    "PRD-LHVWFWPM",
    "PRD-XAVWPSDBB",
    "PRD-OKN59YTC",
    "PRD-ATC7I72DG",
    "PRD-XDLYJWSG",
    "PRD-RLDGPQB9W",
    "PRD-6BGZYLBV",
    "PRD-MTCEMKZYY",
    "PRD-TEVEC5IY",
    "PRD-FIZPQN1R",
    "PRD-V1DVLU3E"
];
