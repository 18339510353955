import { useScreenClass } from "@vp/swan";

/**
 * @note This hook is not SSR friendly. Where possible, use `Visible` and `Hidden` from
 * `@vp/swan`. See https://vistaprint.atlassian.net/wiki/spaces/SS/pages/3163818741/Layouts+Conditional+and+Responsive+Design+Patterns+for+Studio6
 * for more details and caveats.
 *
 * @note This hook isn't initialized immediately on the client, nor ever in a server-side/static generation context.
 * Thus, by default, it will match behavior of useScreeClass, resulting in: `{ isSmall: true, isMedium: false, isLarge: false }`
 * See https://vista.design/swan/components/responsive-system?tab=Develop#useIsScreenClassInitialized
 */
export function useStudioLayout() {
    const screenClass = useScreenClass();

    const [isSmall, isMedium] = [screenClass === "xs", screenClass === "sm"];

    return {
        isSmall,
        isMedium,
        isLarge: !isSmall && !isMedium
    };
}
