import { Severity } from "@design-stack-vista/smart-validations-ui";
import set from "lodash/set";
import type { DSS } from "@vp/types-ddif";
import cloneDeep from "lodash/cloneDeep";
import { get } from "../../../easel/utilities/lodash-replacement-utils";

const ValidationMetadataPath = "metadata.validations";

const VALIDATION_METADATA_VERSION = 1;

export const ValidationInfoKey = "validationInfo";

interface CanvasValidationInfo {
    count: number;
    errorCount: number;
    containsError: boolean;
    validations?: DSS.StudioValidation[] | string;
    warningCount: number;
    dismissedWarningCount: number;
}

type CanvasValidationInfoRecord = Record<string, CanvasValidationInfo>;

interface ValidationMetadata {
    version: number;
    canvases: CanvasValidationInfoRecord;
    totalWarningCount: number;
    totalErrorCount: number;
    totalDismissedWarningCount: number;
}

const setValidationInfo = (canvas: Canvas, info: CanvasValidationInfo) => {
    canvas._canvasViewModel.model.set(ValidationInfoKey, info);
};

export const getValidationInfo = (canvas: Canvas) => {
    return canvas._canvasViewModel.model.get(ValidationInfoKey);
};

export const saveCanvasInfo = (canvas: Canvas, validations: DSS.StudioValidation[] | undefined) => {
    let info: CanvasValidationInfo = {
        count: 0,
        errorCount: 0,
        containsError: false,
        validations,
        warningCount: 0,
        dismissedWarningCount: 0
    };
    if (validations) {
        const untouchedValidations = validations.filter(validation => !validation.smartFixed && !validation.dismissed);
        const errorValidations = untouchedValidations.filter(v => v.severity === Severity.ERROR);
        const dismissedWarnings = validations.filter(v => v.severity === Severity.WARNING && v.dismissed);
        info = {
            count: untouchedValidations.length,
            errorCount: errorValidations.length,
            containsError: !!errorValidations.length,
            validations,
            warningCount: untouchedValidations.length - errorValidations.length,
            dismissedWarningCount: dismissedWarnings.length
        };
    }
    setValidationInfo(canvas, info);
};
/**
 * This saves the placeholder data (positions and background images) to the cimdoc metadata so we can restore from it.
 * We do this because un-replaced placeholders are explicitly not saved to the cimdoc.
 * @param {*} cimDoc
 */
export function saveValidations(cimDoc: any) {
    const validationMetadata: ValidationMetadata = {
        version: VALIDATION_METADATA_VERSION,
        canvases: {},
        totalErrorCount: 0,
        totalWarningCount: 0,
        totalDismissedWarningCount: 0
    };

    window.designer!.api.design.canvases.forEach((canvas: Canvas) => {
        const info: CanvasValidationInfo = getValidationInfo(canvas);
        if (info && Array.isArray(info.validations)) {
            info.validations = JSON.stringify(info.validations);
        }

        validationMetadata.canvases[canvas._canvasViewModel.model.get("id")] = info;
        validationMetadata.totalErrorCount += info?.errorCount ?? 0;
        validationMetadata.totalWarningCount += info?.warningCount ?? 0;
        validationMetadata.totalDismissedWarningCount += info?.dismissedWarningCount ?? 0;
    });
    set(cimDoc, ValidationMetadataPath, validationMetadata);
}

/**
 * Studio Metadata Hydration refers to restoring any `studioMetadata` attributes that existed on items before user saved.
 * This function loops through all items on every canvas and if there's studioMetadata that exists for it in the cimDoc
 * it adds the studioMetadata back into the item's model attributes.
 * @param {*} cimDoc
 * @param {*} canvases
 */
export function hydrateValidationMetadata(cimDoc: any, canvases: Canvas[]) {
    const validationMetadata = get(cimDoc, ["metadata", "validations", "canvases"]) || {};

    if (window.designer) {
        canvases.forEach((canvas: Canvas) => {
            const { id } = canvas._canvasViewModel.model;
            const info = cloneDeep(validationMetadata[id]);
            if (info?.validations && !Array.isArray(info.validations)) {
                info.validations = JSON.parse(info.validations);
            }
            setValidationInfo(canvas, info);
        });
    }
}
