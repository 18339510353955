import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AlertSkin = "standard" | "warning" | "error" | "positive" | "legal-warning";

export interface Alert {
    key: string;
    variables?: Record<string, string>;
    includeCareLink?: boolean;
    careOnlyMessage?: string;
    skin?: AlertSkin;
    // this message is being displayed directly to UI, make sure its translated
    message?: string;
}

const alertsSlice = createSlice({
    name: "alerts",
    initialState: [] as Alert[],
    reducers: {
        setAlerts(state, action: PayloadAction<{ alerts: Alert[] }>) {
            return [...state, ...action.payload.alerts];
        },
        resetAlerts() {
            return [];
        }
    }
});

export const { setAlerts, resetAlerts } = alertsSlice.actions;
export const alerts = alertsSlice.reducer;
