import React, { createContext, ReactNode, useState, useMemo, useContext, useEffect } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useAppSelector, useAppDispatch, setQuantity } from "@shared/redux";
import { type CartItems, getItemsFromCart, type LineItem, getItemInCart } from "@shared/utils/Cart";

interface Props {
    children: ReactNode;
}
interface ContextData {
    isItemInCart: boolean;
    itemsInCart: CartItems;
    getItemInCart: (cartItems: CartItems, workId: string) => LineItem | undefined;
}

const CartContext = createContext<ContextData | undefined>(undefined);

export const useCartContext = () => {
    const result = useContext(CartContext);
    if (!result) {
        throw Error("Missing context.  This must be called within a CartContextProvider");
    }
    return result;
};

export const CartContextProvider = ({ children }: Props) => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector(state => state.locale);
    const {
        isIdentityInitialized,
        auth,
        identity: { shopperId, anonymousUserId }
    } = useIdentityContext();
    const ownerId = shopperId || anonymousUserId;
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const workId = useAppSelector(state => state.workId);
    const quantity = useAppSelector(state => state.quantity);
    const isQuantityPageEnabled = useAppSelector(state => state.isQuantityPageEnabled);
    const quantityPerSize = useAppSelector(state => state.quantityPerSize);
    const [itemsInCart, setItemsInCart] = useState<CartItems>({ lineItems: [] });
    const [isItemInCart, setIsItemInCart] = useState<boolean>(false);

    useEffect(() => {
        if (easelLoaded) {
            const authToken = isIdentityInitialized && auth.getToken();
            if (
                authToken &&
                workId &&
                ((quantity && !isQuantityPageEnabled) || (isQuantityPageEnabled && quantityPerSize !== "0"))
            ) {
                getItemsFromCart(authToken, ownerId, locale)
                    .then(cartItems => {
                        setItemsInCart(cartItems);
                        const itemInCart = getItemInCart(cartItems, workId);
                        setIsItemInCart(!!itemInCart);
                        if (itemInCart) {
                            dispatch(setQuantity({ quantity: itemInCart?.quantity }));
                        }
                    })
                    .catch(e => {
                        setItemsInCart({ lineItems: [] });
                        setIsItemInCart(false);
                    });
            } else {
                setItemsInCart({ lineItems: [] });
                setIsItemInCart(false);
            }
        }
    }, [
        ownerId,
        auth,
        dispatch,
        easelLoaded,
        isIdentityInitialized,
        locale,
        workId,
        quantity,
        isQuantityPageEnabled,
        quantityPerSize
    ]);

    const contextObject = useMemo(() => {
        return {
            isItemInCart,
            getItemInCart,
            itemsInCart
        };
    }, [isItemInCart, itemsInCart]);

    return <CartContext.Provider value={contextObject}>{children}</CartContext.Provider>;
};
CartContextProvider.displayName = "CartContextProvider";
