import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurfaceUpsell } from "@shared/utils/Calcifer";
import {
    CUSTOM_SELECTED_TEMPLATE,
    BLANK_SELECTED_TEMPLATE,
    FULLBLEED_SELECTED_TEMPLATE,
    DUPLICATE_FIRST_PANEL_TEMPLATE
} from "../../utils/Templates";

export type TemplateType =
    | typeof CUSTOM_SELECTED_TEMPLATE
    | typeof BLANK_SELECTED_TEMPLATE
    | typeof FULLBLEED_SELECTED_TEMPLATE
    | typeof DUPLICATE_FIRST_PANEL_TEMPLATE;

// TODO: This is incomplete and maybe inaccurate
export interface ExtendedSurfaceUpsell extends SurfaceUpsell {
    document?: any;
    selectedTemplate?: TemplateType | string;
    pricing?: Record<string, { differentialDiscountPrice: number; differentialListPrice: number }>;
    upsellOffered?: boolean;
}

type StateType = Record<string, ExtendedSurfaceUpsell>;

const surfaceUpsellsSlice = createSlice({
    name: "surfaceUpsells",
    initialState: {} as StateType,
    reducers: {
        loadSurfaceUpsells(state, action: PayloadAction<StateType>) {
            return action.payload;
        }
    }
});

export const { loadSurfaceUpsells } = surfaceUpsellsSlice.actions;
export const surfaceUpsells = surfaceUpsellsSlice.reducer;
