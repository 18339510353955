import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const localeSlice = createSlice({
    name: "locale",
    initialState: "",
    reducers: {
        setLocale(state, action: PayloadAction<string>) {
            return action.payload.toLowerCase();
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.locale?.toLowerCase() ?? state;
        });
    }
});

export const { setLocale } = localeSlice.actions;
export const locale = localeSlice.reducer;
