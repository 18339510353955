import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const forceWorkRecoverySlice = createSlice({
    name: "forceWorkRecovery",
    initialState: false,
    reducers: {
        setForceWorkRecovery: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setForceWorkRecovery } = forceWorkRecoverySlice.actions;
export const forceWorkRecovery = forceWorkRecoverySlice.reducer;
