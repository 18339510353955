import has from "lodash/has";
import set from "lodash/set";
import { getRotatedDesignViews } from "@vp/static-views-rotation";
import { getFontCategories } from "@shared/utils/Fonts";
import type { DSS, DTR, MCP } from "@vp/types-ddif";
import type { EaselSceneConfiguration } from "@shared/utils/Scene";
import type { StudioConfiguration } from "@shared/utils/StudioConfiguration";
import type { EaselConfig } from "@shared/features/StudioBootstrap";
import { convertToDesignerSceneConfiguration } from "@shared/utils/Scene";
import { type DefaultTFunction } from "@vp/i18n-helper";
import easelClipboard from "../designer-suite/easelClipboard";
import { CurrentOptions, Context } from "./Store";
import { waitForProcessing, checkForProcessing } from "./Utilities";
import { setUpGetAsCimDocProxy, getDesignDocumentFromDesigner } from "./getDesignDocument";
import DefaultConfig, { DefaultConfigurationFlags } from "./designerConfiguration/DefaultConfig.config";
import { ENTITY_CODE } from "./ErrorCodes";
import { hydrateStudioMetadata } from "./StudioMetadataHydration";
import {
    getProductConfiguration,
    UpdateDesignerConfigurationForSurfaces
} from "./designerConfiguration/ProductConfigs.config";

import { get } from "./lodash-replacement-utils";
import { hydrateValidationMetadata } from "../../studioFive/components/Validation/ValidationMetadata";

// The DTR.TemplateMetadata type seems to be missing originalTemplateElementId.
function generateTemporaryTemplateMetadata(templateMetadata: DTR.TemplateMetadata[]) {
    return (templateMetadata as any[])
        .filter(item => item.originalTemplateElementId)
        .map(item => ({ originalTemplateElementId: item.originalTemplateElementId, id: item.id }));
}

function getDefaultConfiguration(
    apiKey: string,
    merchantId: string,
    imageUploadUrl: string,
    isMobile = false,
    isTablet = false,
    locale: string,
    productKey: string,
    productOptions: Record<string, string>,
    studioConfiguration: StudioConfiguration,
    cimDoc: any,
    flags: DefaultConfigurationFlags,
    isMileStone1Enabled = false,
    t: DefaultTFunction
) {
    if (!apiKey || !merchantId) {
        const err = {
            errorMessage: `Not all params are properly passed in.`,
            errorCodeStack: `${ENTITY_CODE}-403`
        };
        throw Error(JSON.stringify(err));
    }
    return DefaultConfig(
        apiKey,
        merchantId,
        imageUploadUrl,
        isMobile,
        isTablet,
        getFontCategories(),
        locale,
        productKey,
        productOptions,
        studioConfiguration,
        cimDoc,
        flags,
        isMileStone1Enabled,
        t
    );
}

async function init({
    configuration,
    studioConfiguration,
    cimDoc,
    designViews,
    productKey = "",
    productOptions = {},
    editorMode = "customerDesigner",
    scenesConfiguration,
    easelScenesConfiguration,
    locale
}: EaselConfig & { configuration: any; scenesConfiguration?: any }) {
    if (!locale) throw Error("No locale present");

    CurrentOptions.configuration = configuration;

    Context.locale = locale;

    Context.productKey = productKey;
    Context.productOptions = productOptions;

    // Possible Values: developer, customerDesigner, templateEditor
    CurrentOptions.editorMode = editorMode;

    const modifiedDesignViews = getRotatedDesignViews({ designViews, projectionId: cimDoc.projectionId }, false);
    CurrentOptions.surfaceSpecifications = {
        surfaces: modifiedDesignViews.designViews
    };

    CurrentOptions.cimDoc = cimDoc;

    if (scenesConfiguration) {
        CurrentOptions.scenesConfiguration = scenesConfiguration;
    } else if (CurrentOptions.cimDoc && CurrentOptions.cimDoc.document.panels && easelScenesConfiguration) {
        CurrentOptions.scenesConfiguration = convertToDesignerSceneConfiguration(
            CurrentOptions.cimDoc.document.panels,
            easelScenesConfiguration,
            CurrentOptions.surfaceSpecifications.surfaces
        );
    }

    UpdateDesignerConfigurationForSurfaces(
        CurrentOptions.configuration,
        CurrentOptions.surfaceSpecifications.surfaces,
        studioConfiguration?.initialZoomFactor
    );

    return CurrentOptions;
}

function postDocumentLoad(cimDoc: DSS.DesignDocument, configuration?: any) {
    if (cimDoc && window.designer) {
        hydrateStudioMetadata(cimDoc, window.designer.api.design.canvases);
        hydrateValidationMetadata(cimDoc, window.designer.api.design.canvases);

        if (cimDoc.metadata?.template) {
            set(
                cimDoc,
                ["metadata", "originalTemplateTemp"],
                generateTemporaryTemplateMetadata(cimDoc.metadata.template)
            );
        }
    }
}

function start(options: any) {
    setUpGetAsCimDocProxy();

    postDocumentLoad(options.cimDoc, options.configuration);

    easelClipboard.enable(window.designer!);
}

interface SwitchContextParams {
    productKey: string;
    productOptions?: Record<string, string>;
    studioSelectedProductOptions?: Record<string, string>;
    designDocument: DSS.DesignDocument;
    views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[];
    studioConfiguration: StudioConfiguration;
    easelScenesConfiguration?: EaselSceneConfiguration;
    scenesConfiguration?: any;
    locale?: string;
    template?: string;
}

async function switchContext(params: SwitchContextParams, focusCanvasOrdinal = true, ordinal?: number) {
    const canvasOrdinal: number =
        ordinal || (focusCanvasOrdinal && window.designer!.documentRepository.getActiveCanvas()?.get("ordinal"));

    const { studioConfiguration } = params;

    const productConfiguration = { configuration: getProductConfiguration(params.productKey, studioConfiguration) };
    const { productMinDpi } = studioConfiguration;
    set(productConfiguration, ["configuration", "validations", "imageResolution", "productDpi"], productMinDpi);

    // switchProduct only accepts a subset of options, so we need to be specific about what we can update
    const switchableOptions: Record<string, (p: SwitchContextParams) => any> = {
        cimDoc: p => get(p, ["designDocument"]),
        skuVariables: p => get(p, ["designDocument", "skuVariables"]),
        surfaceSpecifications: p => {
            if (get(p, ["views"])) {
                return {
                    surfaces: getRotatedDesignViews(
                        { designViews: p.views, projectionId: params.designDocument.projectionId },
                        false
                    ).designViews
                };
            }
            return undefined;
        },
        scenesConfiguration: p => {
            if (has(p, ["scenesConfiguration"])) {
                return p.scenesConfiguration;
            }

            if (has(p, ["easelScenesConfiguration"])) {
                return convertToDesignerSceneConfiguration(
                    CurrentOptions.cimDoc.document.panels,
                    get(p, ["easelScenesConfiguration"]),
                    get(p, ["views"])
                );
            }
            return undefined;
        }
    };

    const switchableContext: Record<string, (p: SwitchContextParams) => any> = {
        productKey: p => get(p, ["productKey"]),
        productOptions: p => get(p, ["productOptions"])
    };

    const newContext = Object.keys(switchableContext).reduce((accumulator, currentValue) => {
        const mappedValue = switchableContext[currentValue](params);
        if (mappedValue != null) {
            accumulator[currentValue] = mappedValue;
        }
        return accumulator;
    }, {});

    const newOptions: any = Object.keys(switchableOptions).reduce((accumulator, currentValue) => {
        const mappedValue = switchableOptions[currentValue](params);
        if (mappedValue != null) {
            accumulator[currentValue] = mappedValue;
        }
        return accumulator;
    }, {});

    // const newContext = chain(switchableContext)
    //     .mapValues(fn => fn(params))
    //     .omitBy(isNil)
    //     .value();
    // const newOptions = chain(switchableOptions)
    //     .mapValues(fn => fn(params))
    //     .omitBy(isNil)
    //     .value();

    Object.assign(Context, newContext);
    Object.assign(newOptions, productConfiguration);

    // We're not using CurrentOptions as of now, so we won't bother updating.
    // Object.assign(CurrentOptions, newOptions);

    easelClipboard.clear();

    await window.designer!.switchProduct(newOptions);

    postDocumentLoad(newOptions.cimDoc);

    if (
        focusCanvasOrdinal &&
        canvasOrdinal &&
        canvasOrdinal <= window.designer!.documentRepository.getDocument().canvases.length
    ) {
        // If a canvas with the same ordinal that the user was previously editing can be
        // focused on, do it. Otherwise, let Designer decide which canvas to make active
        window.designer!.eventBus.trigger(window.designer!.eventBus.events.manageCanvases, {
            enabledCanvases: [canvasOrdinal],
            visibleCanvases: [canvasOrdinal],
            activeCanvas: canvasOrdinal
        });
    }
}

export {
    getDefaultConfiguration,
    getDesignDocumentFromDesigner,
    waitForProcessing,
    checkForProcessing,
    init,
    start,
    switchContext,
    get
};

export {
    useStudio5AvailablePremiumFinishesOnCanvas,
    useFinishType,
    useHasFinish,
    useActiveCanvasPremiumFinishItems,
    useDecorationTechnology,
    useProcessType,
    useLocalStorage,
    usePremiumFinishWordArtAndTextToggle
} from "./hooks";
export * from "./utils-premium-finishes";
export {
    updateItemStudioMetadataProperty,
    getItemStudioMetadataProperty,
    removeItemStudioMetadataProperty,
    setItemStudioMetadata,
    getAllStudioMetadata,
    updatedTextAndWordArtFieldNumber,
    getTextAndWordArtFieldNumber,
    getLargestTextFieldNumber
} from "./utils-studio-metadata";
export * from "./utils";
export * from "./Tables";

export { EASEL_EVENTS, DEFAULT_SHAPE_COLOR, FontStyle, SCROLLBAR_WIDTH, SHAPE_SIZE_FACTOR } from "./constants";
export { getFormattedShape } from "./Shapes/utilities";
export { getTrackingDataForSelection, getTrackingDataForImageSelection, classifyImage } from "./TrackingUtilities";
export {
    hasAdvancedTools,
    pxToMm,
    isInViewport,
    getMaskByPathType,
    convertPxToMm,
    convertMmToPx,
    convertMmToPt
} from "./Utilities";
export { isImageUnreplacedPlaceholder, isItemQRCodePlaceholder } from "./placeholderUtilities";
