/**
 * A set of utility methods that deal with premium finishes
 */
import cloneDeep from "lodash/cloneDeep";
import { itemIsImageUnreplacedPlaceholder } from "@designer-suite";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { SUPPORTED_FINISHES } from "@shared/features/PremiumFinish";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { isText, isWordArt, selectedItemsAreOfTypes, updateSelectedItems } from "./utils";
import { getTrackingDataForSelection } from "./TrackingUtilities";
import type { Designer, ItemSelection } from "../designer-suite/@types/designer";

export interface PremiumFinish {
    color: string;
    format: string;
}

function filterByAvailableFinishes(result: PremiumFinish[]) {
    return result.filter(finish => SUPPORTED_FINISHES.includes(finish.color));
}

/**
 * Checks if the given canvas has premium finishes available on it
 * @param {Canvas} canvas
 * @returns {boolean} true if available, false otherwise
 */
export function hasPremiumFinishes(canvas: Canvas | undefined) {
    return Boolean(
        canvas &&
            canvas._canvasViewModel &&
            canvas._canvasViewModel.has("availablePremiumFinishes") &&
            canvas._canvasViewModel.get("availablePremiumFinishes").length > 0 &&
            filterByAvailableFinishes(canvas._canvasViewModel.get("availablePremiumFinishes")).length > 0
    );
}

/**
 * Gets the available premium finishes from the provided canvas
 * @param {Canvas} canvas
 * @return {{color: string, format: string}[]} return an arrya of finishes that are available.
 *  The first item in the array is the active finish.
 */
export function getPremiumFinishes(canvas: Canvas | undefined): PremiumFinish[] {
    if (!canvas || !canvas._canvasViewModel || !canvas._canvasViewModel.get) {
        return [];
    }
    const result = canvas._canvasViewModel.get("availablePremiumFinishes");
    return filterByAvailableFinishes(result);
}

/**
 * Provide an update function to remove premium finish data from a word art item
 * @returns {function(MutableReferenceItem & WordArtItem)} - a function that takes an item and removed the
 *  specified field from that item
 */
export function removeWordArtPremiumFinish() {
    return (item: MutableReferenceItem & WordArtItem) => {
        const newData = { ...item.data } as WordArtData;
        if (newData.overprints && newData.overprints.length > 0) {
            if (newData.overprints.some(overprint => overprint.toLowerCase().includes("raisedfoil"))) {
                // have to provide a color since raised foil replaces the color
                newData.color = "rgb(#101820)";
            }
            delete newData.overprints;
        }
        if (newData.shadow && newData.shadow.overprints) {
            if (
                newData.shadow.overprints.some(overprint => overprint.toLowerCase().includes("raisedfoil")) &&
                (!newData.shadow.color || newData.shadow.color.length === 0)
            ) {
                // have to provide a color since raised foil replaces the color
                newData.shadow.color = "rgb(#101820)";
            }
            delete newData.shadow.overprints;
        }
        item._itemViewModel.model.set("data", newData);
    };
}

/**
 * Provide an update function to add a finish to word art
 * @param {string} finishString - string to put in the word art premium finishes fields
 * @returns {function(MutableReferenceItem & WordArtItem)} - an update function that takes a word art item and add the
 * field(s) neededd to add a premium finish
 */
export function addWordArtPremiumFinish(finishString: string) {
    return (item: MutableReferenceItem & WordArtItem) => {
        const newData = cloneDeep(item.data);
        newData.overprints = [finishString];
        // raised foil overprint should not have a color specified
        if (newData.color && finishString.toLowerCase().includes("raisedfoil")) {
            delete newData.color;
        }
        item._itemViewModel.model.set("data", newData);
    };
}

/**
 * Checks if the provided word art items have a finish turned on
 * @param {Items[]} wordartItems - Items are assumed to be word art items
 * @returns {boolean} true if all word art item has a finish set on it, false otherwise
 */
export function wordartHasSpotColor(wordartItems: WordArtItem[] | undefined) {
    if (wordartItems && !!wordartItems.length) {
        const viewModels = wordartItems.map(item => item._itemViewModel);
        return viewModels.every(viewModel => viewModel.model.get("data").overprints?.length > 0);
    }
    return false;
}

export function itemFetchingPremiumFinish(item: CanvasItem) {
    return item._itemViewModel.get("fetchingPremiumFinishOverlay");
}

export function itemHasPremiumFinish(item: CanvasItem) {
    if (isWordArt(item)) {
        return wordartHasSpotColor([item]);
    }
    const hasOverprints = item._itemViewModel.get("overprints") && !!item._itemViewModel.get("overprints").length;
    const hasRaisedFoil = isText(item) && item.color?.includes("RaisedFoil");
    const hasMaskPreviewUrl = item._itemViewModel.model.has("premiumFinishMaskPreviewUrl");
    return hasOverprints || hasMaskPreviewUrl || hasRaisedFoil;
}
// DT-462 this should be imported from designer-suite
export function itemGetPremiumFinish(item: CanvasItem) {
    return !!item._itemViewModel.model.get("premiumFinish");
}
// DT-462 this should be imported from designer-suite
export function toggleFinish(item: ImageItem) {
    if (item._itemViewModel.model.has("premiumFinishMaskPreviewUrl")) {
        window.designer!.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_PREMIUM_FINISH, {
            ...getTrackingDataForSelection([item]),
            finishApplied: false
        });
        item._itemViewModel.disablePremiumFinishMask();
    } else {
        window.designer!.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_PREMIUM_FINISH, {
            ...getTrackingDataForSelection([item]),
            finishApplied: true
        });
        item._itemViewModel.enablePremiumFinishMask();
    }
}
// DT-462 this should be imported from designer-suite
export function isPremiumFinishImageButtonCompatibleForSelection(
    selection: CanvasItem[],
    isPremiumFinish: boolean = false
) {
    return !isPremiumFinish
        ? selection.length && selectedItemsAreOfTypes(selection, [ItemTypes.IMAGE])
        : selection.length;
}

export function isPremiumFinishImageButtonCompatibleForCanvas(canvas: Canvas | undefined) {
    const finishes = getPremiumFinishes(canvas);
    return finishes.length !== 0 && finishes.every(finish => !finish.color.includes("RaisedFoil"));
}

export function isPremiumFinishImageButtonCompatible(
    designer: Designer | undefined,
    selection: ItemSelection,
    canvas: Canvas | undefined,
    isPremiumFinish = false
) {
    return Boolean(
        selection.length === 1 &&
            !!designer &&
            !!canvas &&
            getPremiumFinishes(canvas).length !== 0 &&
            isPremiumFinishImageButtonCompatibleForCanvas(canvas) &&
            isPremiumFinishImageButtonCompatibleForSelection(selection, isPremiumFinish) &&
            !itemIsImageUnreplacedPlaceholder(selection[0])
    );
}

/**
 * Add premium finish to a text item
 * @param {Designer} designer
 * @param {Canvas | undefined} canvas
 * @param {CanvasItem[]} selection
 * @param {boolean} hasPremiumFinishSpotcolor
 */
export function applyTextPremiumFinish(
    designer: Designer,
    canvas: Canvas | undefined,
    selection: CanvasItem[],
    hasPremiumFinishSpotcolor: boolean
) {
    if (canvas) {
        const viewModels = selection.map(item => item._itemViewModel);
        const finish = getPremiumFinishes(canvas)[0];
        if (hasPremiumFinishSpotcolor) {
            designer.commandDispatcher.changeFinish({ viewModels, finish: "" });
        } else if (finish) {
            designer.commandDispatcher.changeFinish({ viewModels, finish: finish.color });
        }
    }
}

/**
 * Add premium finish to a word art item
 * @param {Designer} designer
 * @param {Canvas | undefined} canvas
 * @param {CanvasItem[]} selection
 */
export function applyWordArtPremiumFinish(designer: Designer, canvas: Canvas | undefined, selection: CanvasItem[]) {
    const { color, format } = getPremiumFinishes(canvas)[0];
    const finish = `${format}(${color})`;
    updateSelectedItems(designer, selection, addWordArtPremiumFinish(finish));
}
