import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Validation {
    message: string;
    type: string;
    value: string;
}

export interface TeamsPlaceholder {
    key: string;
    itemReferenceType: string;
    name?: string;
    type: TeamsPlaceholderType;
    validations: Validation[];
}

const initialState: TeamsPlaceholder[] = [];

const teamsPlaceholdersSlice = createSlice({
    name: "teamsPlaceholders",
    initialState,
    reducers: {
        addTeamsPlaceholder: (state, action: PayloadAction<TeamsPlaceholder>) =>
            state.find(placeholder => placeholder.key === action.payload.key) ? state : [...state, action.payload],
        removeTeamsPlaceholder: (state, action: PayloadAction<string>) =>
            state.filter(placeholder => placeholder.key !== action.payload),
        setTeamsPlaceholders: (state, action: PayloadAction<TeamsPlaceholder[]>) => action.payload || state
    }
});

export const { addTeamsPlaceholder, removeTeamsPlaceholder, setTeamsPlaceholders } = teamsPlaceholdersSlice.actions;
export const teamsPlaceholders = teamsPlaceholdersSlice.reducer;
