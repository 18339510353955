import {
    convertToSelectableColor,
    type EmbroiderySelectableColor,
    type SelectableColor
} from "@shared/features/ColorPicker";
import { getItemStudioMetadataProperty, getTrackingDataForSelection, isSingleColorCanvas } from "@utilities";
import { newRelicWrapper } from "@shared/utils/Errors";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { PremiumFinishSpotColors } from "@shared/features/PremiumFinish";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { UploadTypes } from "@shared/features/UploadsAndAssets";
import { Spot } from "./constants";
import { standardPaletteColors, standardPaletteColorsForEmbroidery } from "./StandardPaletteColors";
import type { Designer, ItemSelection } from "./@types/designer";

export const getDocumentItems = (canvases: Canvas[]) => {
    // Get the items for all the canvases
    return canvases.reduce<ItemSelection>((acc, curr) => [...acc, ...curr.items], []);
};

export function isSingleColor(item: CanvasItem) {
    return item._itemViewModel.get("onSingleColorCanvas");
}

export function itemIsImagePlaceholder(item: CanvasItem) {
    return item.itemType === ItemTypes.IMAGE && item._itemViewModel.model.get("placeholder");
}

export function itemIsImageUnreplacedPlaceholder(item: CanvasItem) {
    return itemIsImagePlaceholder(item) && !item._itemViewModel.model.get("placeholderReplaced");
}

export function itemIsBackgroundRemovable(item: CanvasItem) {
    return item._itemViewModel.get("isBackgroundRemoveCandidate");
}

const maxPixelSize = 10000000; // Max 10 MB
const minPixelSize = 80; // Minimum 80 pixel

export function itemIsSharpenable(designer: Designer, item: CanvasItem) {
    if (!designer) {
        return false;
    }
    // Replicating designer logic, without the lock restriction
    //  return item._itemViewModel.get("sharpenable");
    //  https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/cimpress-designer/-/blob/master/app/core/viewModels/ImageViewModel.js#L516
    const { height, width } = item._itemViewModel.model.get("naturalDimensions");

    return (
        // Looks at the previewUrl and the premiumFinishMaskPreviewUrl under the hood
        !item._itemViewModel.get("isOnlyOverlay") &&
        // Only for supported file types
        designer.clients.ipa.canCrispify(item._itemViewModel.model.get("fileType")) &&
        // Note in the orginal function but pulling in this limitation
        //  - crispify has these limits - see documentation
        // https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/388432576/Crispify+API+Parameters
        height * width < maxPixelSize &&
        height * width > minPixelSize
    );
}

export function itemIsIcon(item: CanvasItem) {
    const studioMetadata = getItemStudioMetadataProperty(item, "thirdPartyUploadInfo");
    return studioMetadata ? studioMetadata.uploadType === UploadTypes.ICON : false;
}

export function imageIsIcon(image: any) {
    const uploadType = image?.studioMetadata?.thirdPartyUploadInfo?.uploadType;
    return uploadType === UploadTypes.ICON;
}

// This should live next to `getStringifiedSelectedItemTypes` in easel/utilities/utils.js but
// adding it there creates a circular dependency with this file. For now we'll keep this here
// until we clean up the two util files.
export function getStringifiedSelectedImageTypes(selection: ItemSelection) {
    return selection
        .reduce((acc: string[], curr) => {
            let currentType: string = "";
            if (curr.itemType === ItemTypes.IMAGE) {
                currentType = itemIsIcon(curr) ? UploadTypes.ICON : UploadTypes.IMAGE;
            }
            if (currentType === "" || acc.includes(currentType)) {
                return acc;
            }
            return [...acc, currentType];
        }, [])
        .join(",");
}

export function updateSelectedItems<T extends CanvasItem = CanvasItem>(
    designer: Designer | undefined,
    selection: T[],
    updateFunction: (item: MutableItem & T) => void
) {
    if (designer) {
        const selectedIds = selection.map(model => model.id);
        designer.api.design.update(mutableCanvases =>
            mutableCanvases.forEach(canvas =>
                canvas.items.filter(item => selectedIds.includes(item.id)).forEach(updateFunction)
            )
        );
    }
}

export function updateItems(
    designer: Designer | undefined,
    items: Item[],
    updateFunction: (item: MutableItem) => void
) {
    if (designer) {
        const selectedIds = items.map(item => item._itemViewModel.id);
        designer.api.design.update(mutableCanvases =>
            mutableCanvases.forEach(canvas =>
                canvas.items.filter(item => selectedIds.includes(item.id)).forEach(updateFunction)
            )
        );
    }
}

export function addModelsToSelection(selection: ItemSelection) {
    const items = selection.map(item => item._itemViewModel);
    // @ts-ignore I am faking a backbone collection by adding a first function. Designer breaks otherwise.
    items.first = () => items[0];
    return items;
}

export function getReferenceData(item: Item) {
    return item._itemViewModel.model.get("data");
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setReferenceData(updatedData: Record<string, any>) {
    return (item: Item) =>
        item._itemViewModel.model.set("data", {
            ...getReferenceData(item),
            ...updatedData
        });
}

export function getCurrentCanvas(designer: Designer, item?: Item) {
    if (!item) {
        return designer.api.design.canvases[0];
    }
    return (
        designer.api.design.canvases.find(canvas => canvas.items.some(canvasItem => canvasItem.id === item.id)) ||
        designer.api.design.canvases[0]
    );
}

export function getDecorationTechnologyForCanvas(designer: Designer, canvas: Canvas): DecorationTechnologiesSimple {
    return canvas.decorationTechnology === designer.api.design.constants.decorationTechnologies.embroidery
        ? DecorationTechnologiesSimple.EMBROIDERY
        : DecorationTechnologiesSimple.PRINT;
}

export function getDecorationTechnology(designer: Designer, item?: Item): DecorationTechnologiesSimple {
    if (!designer) {
        return DecorationTechnologiesSimple.PRINT;
    }
    const currentCanvas = getCurrentCanvas(designer, item);
    if (!currentCanvas) {
        return DecorationTechnologiesSimple.PRINT;
    }
    return getDecorationTechnologyForCanvas(designer, currentCanvas);
}

export function areCustomColorsAllowed(designer: Designer, item: CanvasItem) {
    return getDecorationTechnology(designer, item) !== DecorationTechnologiesSimple.EMBROIDERY && !isSingleColor(item);
}

export function getActiveCanvasIndex(designer: Designer) {
    return designer.documentRepository.getActiveCanvasViewModel().get("ordinal") - 1;
}

function mapColors(
    designer: Designer,
    paletteFn: (canvas: Canvas) => Color[],
    item?: Item,
    mapFn?: (color: Color) => SelectableColor
): SelectableColor[] {
    if (!mapFn) {
        // eslint-disable-next-line no-param-reassign
        mapFn = color => ({
            value: color.toString(),
            cssBackground: color.hex
        });
    }
    const canvas = getCurrentCanvas(designer, item);
    return canvas ? paletteFn(canvas).map(mapFn) : [];
}

export function getRecommendedColors(designer: Designer, item?: Item, mapFn?: (color: Color) => SelectableColor) {
    return mapColors(designer, canvas => canvas._canvasViewModel.getColorPalette(), item, mapFn);
}

export function getColorPalette(
    designer: Designer,
    item?: Item,
    mapFn?: (color: Color) => SelectableColor
): SelectableColor[] {
    if (isSingleColorCanvas(designer)) {
        return mapColors(designer, canvas => canvas.providedColors, item, mapFn);
    }
    if (getDecorationTechnology(designer, item) === DecorationTechnologiesSimple.EMBROIDERY) {
        const canvasColors = mapColors(designer, canvas => canvas.providedColors, item, mapFn);
        return standardPaletteColorsForEmbroidery.reduce((acc, thread) => {
            const canvasColor = canvasColors.find(c => c.value === thread);
            if (canvasColor) {
                return [...acc, canvasColor];
            }
            newRelicWrapper.noticeError("studio-missing-thread-color", { thread });
            return acc;
        }, [] as SelectableColor[]);
    }
    return standardPaletteColors.map(standardPaletteColor => convertToSelectableColor(standardPaletteColor));
}

export function getCurrentEmbroideryImageColors(
    originalColors: ColorSpecification[] | undefined,
    colorOverrides: ColorSpecification[] | undefined,
    paletteColors: SelectableColor[]
) {
    return originalColors?.map((originalColor: ColorSpecification): EmbroiderySelectableColor => {
        const override = colorOverrides?.find(o => o.ordinal === originalColor.ordinal);
        // override could be a hex color or it could be a thread color
        // we can't rely on the format existing (sometimes it does not)
        // instead we must check the actual color string to determine whether its hex or thread
        if (override?.color.startsWith("#")) {
            return {
                value: paletteColors.find(pColor => pColor.cssBackground === override.color)?.value || "",
                cssBackground: override.color,
                ordinal: originalColor.ordinal
            };
        }
        if (override?.color.startsWith("thread(")) {
            return {
                value: override.color,
                cssBackground: paletteColors.find(pColor => pColor.value === override.color)?.cssBackground || "",
                ordinal: originalColor.ordinal
            };
        }

        const threadColor = `thread(${originalColor.color})`;
        return {
            value: threadColor,
            cssBackground: paletteColors.find(pColor => pColor.value === threadColor)?.cssBackground || "",
            ordinal: originalColor.ordinal
        };
    });
}

export function getUpdatedEmbroideryOverrideColors(
    originalColors: ColorSpecification[] | undefined,
    colorOverrides: ColorSpecification[] | undefined,
    newOverride: ColorSpecification
) {
    return originalColors?.map((originalColor: ColorSpecification): ColorSpecification => {
        if (originalColor.ordinal === newOverride.ordinal) {
            return newOverride;
        }
        const override = colorOverrides?.find(o => o.ordinal === originalColor.ordinal);
        if (override) {
            return override;
        }
        return originalColor;
    });
}

export function getPremiumFinishes(canvas: Canvas) {
    return canvas._canvasViewModel.get("availablePremiumFinishes");
}

export function getCanvasPagePosition(canvas: Canvas): { top: number; left: number } {
    return canvas._canvasViewModel.get("offsetPosition");
}

export function getHandleBoundingBox(item: CanvasItem): { width: number; height: number; top: number; left: number } {
    return item._itemViewModel.get("handleBoundingBox");
}

export function getTopItemPageBoundingBox(selection: ItemSelection, canvas: Canvas, isDesktop = false) {
    const canvasPagePosition = getCanvasPagePosition(canvas);
    if (!canvasPagePosition) {
        return null;
    }
    const { top, left } = canvasPagePosition;
    const selectedItemRectangles = selection
        .map(item => ({ ...getHandleBoundingBox(item), rotation: item._itemViewModel.model.get("rotation") }))
        .filter(Boolean);
    if (!selectedItemRectangles.length) {
        return { top, left, width: 0, height: 0, rotation: 0 };
    }

    const firstRectangle = selectedItemRectangles.pop()!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const topRectangle = selectedItemRectangles.reduce((acc, curr) => {
        if (curr.top < acc.top) {
            return curr;
        }
        return acc;
    }, firstRectangle);

    if (isDesktop) return topRectangle;

    return {
        ...topRectangle,
        top: top + topRectangle.top,
        left: left + topRectangle.left
    };
}

export function itemHasPremiumFinishSpotColor(item: CanvasItem) {
    if (!item) {
        return false;
    }
    const colors = item._itemViewModel.get("colors");
    return colors && colors.some((color: Color) => color.format === Spot && PremiumFinishSpotColors[color.color]);
}

export function disableChangingZIndex(selectedItem: CanvasItem, canvas: Canvas) {
    const selectedZIndex = selectedItem._itemViewModel.model.get("zIndex");

    const zIndexes = canvas.items
        .filter(item => !item._itemViewModel.model.get("zIndexLock"))
        .map(item => item._itemViewModel.model.get("zIndex"));

    const disableBringForward = zIndexes.every(zIndex => zIndex <= selectedZIndex);
    const disableSendBackward = zIndexes.every(zIndex => zIndex >= selectedZIndex);

    return { disableBringForward, disableSendBackward };
}

/**
 * Determines if the layers tool should be available for the given selection
 */
export function isLayersToolCompatible(
    designer: Designer | undefined,
    selection: ItemSelection,
    canvas: Canvas | undefined
) {
    if (designer && selection && selection.length === 1 && canvas) {
        // Disable everything when all tolls will be disabled
        const { disableBringForward, disableSendBackward } = disableChangingZIndex(selection[0], canvas);
        return !disableBringForward || !disableSendBackward;
    }
    return false;
}

export function updateZIndex(
    action: "bringToFront" | "sendToBack" | "bringForward" | "sendBackward",
    selectedItem: CanvasItem,
    designer: Designer
) {
    return () => {
        if (!designer) {
            return;
        }

        switch (action) {
            case "bringToFront":
                designer.eventBus.trigger(
                    STUDIO_TRACKING_EVENTS.CLICK_ARRANGE_SEND_TO_FRONT,
                    getTrackingDataForSelection([selectedItem])
                );
                break;
            case "sendToBack":
                designer.eventBus.trigger(
                    STUDIO_TRACKING_EVENTS.CLICK_ARRANGE_SEND_TO_BACK,
                    getTrackingDataForSelection([selectedItem])
                );
                break;
            case "bringForward":
                designer.eventBus.trigger(
                    STUDIO_TRACKING_EVENTS.CLICK_ARRANGE_SEND_FORWARD,
                    getTrackingDataForSelection([selectedItem])
                );
                break;
            case "sendBackward":
                designer.eventBus.trigger(
                    STUDIO_TRACKING_EVENTS.CLICK_ARRANGE_SEND_BACKWARD,
                    getTrackingDataForSelection([selectedItem])
                );
                break;
            default:
                break;
        }

        const selectedItemViewModel = selectedItem._itemViewModel;
        designer.commandDispatcher.changeZIndex({ selectedItem: selectedItemViewModel, action });
    };
}

/**
 * Convert angle from degrees to Redians
 * @param degrees angle in degrees (360)
 */
export function degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
}

/**
 * Calculate the new location of a point rotated around a center point
 * Based on: https://stackoverflow.com/questions/17410809/how-to-calculate-rotation-in-2d-in-javascript
 * @param centerPoint Point that rotation is centered around
 * @param originalPoint Point that the rotated new location should be calculated for
 * @param angleInDegrees Angle of rotation in degrees
 */
export function rotate(centerPoint: Point, originalPoint: Point, angleInDegrees: number): Point {
    const radians = degreesToRadians(angleInDegrees);
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const newX = cos * (originalPoint.x - centerPoint.x) + sin * (originalPoint.y - centerPoint.y) + centerPoint.x;
    const newY = cos * (originalPoint.y - centerPoint.y) - sin * (originalPoint.x - centerPoint.x) + centerPoint.y;
    return { x: newX, y: newY };
}

/**
 * Calculate the rectangle that would enclose the shape defined by the points
 * @param points points that make up a rectangle that maybe rotated
 * @returns {Rectangle}
 */
export function getBoundingRectangle(points: Point[]): Rectangle {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    points.forEach(point => {
        minX = Math.min(minX, point.x);
        maxX = Math.max(maxX, point.x);
        minY = Math.min(minY, point.y);
        maxY = Math.max(maxY, point.y);
    });
    return {
        left: minX,
        top: minY,
        width: maxX - minX,
        height: maxY - minY
    };
}

/**
 * Calculate a box that encloses a box that has a rotation applied to it
 * @param top Y of the point representing the nw corner of the unrotated box
 * @param left X of the point representing the nw corner of the unrotated box
 * @param width Width of the box
 * @param height Height of the box
 * @param rotation angle of rotation to apply to the box
 * @returns { left: number, top: number, width: number, height: number}
 */
export function getRotatedOuterBox(
    top: number,
    left: number,
    width: number,
    height: number,
    rotation: number
): Rectangle {
    const boxPoints: Point[] = [
        { x: left, y: top },
        { x: left + width, y: top },
        { x: left, y: top + height },
        { x: left + width, y: top + height }
    ];
    const center: Point = { x: left + width / 2, y: top + height / 2 };
    const rotatedPoints = boxPoints.map(point => rotate(center, point, rotation));
    return getBoundingRectangle(rotatedPoints);
}

/**
 * Find the center of the provided rectangle
 * @param { left, top, width, height }: Rectangle
 */
export function itemCenter({ left, top, width, height }: Rectangle): Point {
    return {
        x: left + width / 2,
        y: top + height / 2
    };
}

export const getPreviewBackgroundStyle = (
    underlayScene: Scene,
    previewImageWidth: number,
    previewImageHeight: number
) => {
    const sceneWidth = underlayScene.dimensions?.width;
    const sceneHeight = underlayScene.dimensions?.height;

    const topLeft = underlayScene.positionWithinImage?.topLeft;
    const bottomRight = underlayScene.positionWithinImage?.bottomRight;

    let designAreaDetails: DesignAreaDetails = { x: 0, y: 0 };
    if (topLeft && bottomRight) {
        designAreaDetails = {
            x: topLeft.x,
            y: topLeft.y,
            height: bottomRight.y - topLeft.y,
            width: bottomRight.x - topLeft.x
        };
    }

    const width = sceneWidth || previewImageWidth;
    const height = sceneHeight || previewImageHeight;

    let widthRatio = 1;
    let heightRatio = 1;

    if (designAreaDetails.width && designAreaDetails.height) {
        widthRatio =
            Math.max(designAreaDetails.width, previewImageWidth) / Math.min(designAreaDetails.width, previewImageWidth);
        heightRatio =
            Math.max(designAreaDetails.height, previewImageHeight) /
            Math.min(designAreaDetails.height, previewImageHeight);
    }
    const ratio = Math.max(widthRatio, heightRatio);
    const sceneUrl = underlayScene?.url;
    // @ts-ignore
    const underlayUrl = `${CIMPRESS_RENDERING_URL}/preview?scene=${encodeURIComponent(
        sceneUrl
    )}&width=${width}&category=studio`;

    const backgroundSize = `${width * ratio}px ${height * ratio}px`;
    const backgroundPosition = `-${designAreaDetails.x * ratio}px -${designAreaDetails.y * ratio}px`;
    const backgroundImage = `url(${underlayUrl})`;

    return { backgroundSize, backgroundPosition, backgroundImage };
};

export const findNodeByContent = (text: string, root = document.body) => {
    const treeWalker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT);

    const nodeList = [];

    while (treeWalker.nextNode()) {
        const node = treeWalker.currentNode;

        if (node.nodeType === Node.TEXT_NODE && node.textContent?.includes(text)) {
            nodeList.push(node.parentNode);
        }
    }

    return nodeList;
};

export const flatContent = (content: CimDocTextField[], result: CimDocTextField[] = []): CimDocTextField[] => {
    content.forEach(current => {
        if (Array.isArray(current.content)) {
            flatContent(current.content, result);
        } else {
            result.push(current);
        }
    });

    return result;
};

/**
 * Checks if provided string is a pdf file type
 * @param {string} fileType
 * @returns {boolean} whether or not input was a pdf file type
 */
export const isPdfFileType = (fileType: string) =>
    typeof fileType === "string" && fileType.toLowerCase() === "application/pdf";

/**
 * Checks if provided string is an heic file type
 * @param {string} fileType
 * @returns {boolean} whether or not input was a heic file type
 */
export const isHeicFileType = (fileType: string) =>
    typeof fileType === "string" && fileType.toLowerCase() === "image/heic";
