import React from "react";
import classNames from "classnames";
import "./LoadingSpinner.scss";

export enum SpinnerVariant {
    Uncontrolled = "uncontrolled",
    Small = "small",
    Large = "large",
    Button = "button"
}
interface Props extends React.SVGProps<SVGSVGElement> {
    circleColor?: string;
    fragmentColor?: string;
    variant?: SpinnerVariant;
    centering?: boolean;
}
export function LoadingSpinner(props: Props) {
    const {
        circleColor = "#dfdfdf",
        fragmentColor = "#666666",
        variant = SpinnerVariant.Uncontrolled,
        centering = false,
        className,
        ...rest
    } = props;
    let classes = classNames(className, { "easel-loading-spinner-centering": centering });
    let spinColor = fragmentColor;
    switch (variant) {
        // current lint rules are aginst cases falling through
        case SpinnerVariant.Button:
            spinColor = "#ffffff";
            classes = classNames(classes, "easel-loading-spinner-small");
            break;
        case SpinnerVariant.Small:
            classes = classNames(classes, "easel-loading-spinner-small");
            break;
        case SpinnerVariant.Large:
            classes = classNames(classes, "easel-loading-spinner-large");
            break;
        case SpinnerVariant.Uncontrolled:
        default:
            break;
    }

    return (
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" role="status" className={classes} {...rest}>
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke={circleColor}
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                />
            </circle>
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke={spinColor}
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
                strokeDashoffset="56.97787143782138"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                />
            </circle>
        </svg>
    );
}
LoadingSpinner.displayName = "LoadingSpinner";
