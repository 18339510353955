import cloneDeep from "lodash/cloneDeep";
import defaultsDeep from "lodash/defaultsDeep";
import { type DefaultTFunction, defineMessages } from "@vp/i18n-helper";
import { windowExists } from "@shared/utils/WebBrowser";
import type { StudioConfiguration } from "@shared/utils/StudioConfiguration";
import type { DecoTechFontFamilies } from "@shared/utils/Fonts";
import { TOAST_NOTIFICATION_TIME_MS } from "@shared/features/UploadsAndAssets";
import { localesWithInches } from "@shared/utils/Locales";
import { getProductConfiguration } from "./ProductConfigs.config";
import designerDefaults from "../../i18n/scripts/designer.en-us";
import { provideTranslations } from "../../i18n/localizationUtilities";
import { DEFAULT_SHAPE_COLOR, SHAPE_SIZE_FACTOR } from "../constants";
import { get } from "../lodash-replacement-utils";
import { isEmbroideryCanvasModel, isSingleColorCanvasModel } from "../utils";

declare global {
    interface Window {
        getDesignDocumentFromDesigner?: () => any;
    }
}

export const DEFAULT_IMAGE_MIN_WIDTH = 8;
export const DEFAULT_IMAGE_MIN_HEIGHT = 8;
export const DEFAULT_MAX_ZOOM = 3.0;
export const DEFAULT_MIN_ZOOM = 0.25;

const messages = defineMessages({
    watermark: {
        id: "easel.core.defaultconfig.pdfproof.watermark",
        defaultMessage: "PROOF",
        description: {
            note: "Text to watermark PDF proofs with"
        }
    }
});

const platinum = "#f8f8f8"; // this used to come from the ui library but the colors have changed with Visage. This value needs to match the workspace.

const defaultMarginStrategy = {
    marginStrategy: () => ({
        "margin-top": 0,
        "margin-left": 0,
        "margin-right": 0,
        "margin-bottom": 0
    })
};

function shouldOverlapValidate(model: ItemModel) {
    const itemRefOverlapTypes = ["QR Code", "Table", "Word Art"];
    return model.get("module") === "ItemReference" && itemRefOverlapTypes.includes(model.get("type"));
}

function shouldBetweenBoundariesValidate(model: ItemModel, cimDoc: any) {
    let currentCimDoc = cimDoc;
    if (windowExists() && window && window.getDesignDocumentFromDesigner) {
        currentCimDoc = window.getDesignDocumentFromDesigner();
    }
    const itemId = model.get("id");
    const templateMetadata = get(currentCimDoc, ["metadata", "template"], []);
    const itemTemplateMetadata = templateMetadata.find((metadata: any) => metadata.id?.includes(itemId));
    return !itemTemplateMetadata?.originalTemplateElementId;
}

const isNumberWithinPercentOfNumber = (firstN: number, secondN: number, percent: number) => {
    const percentDiff = 1 - firstN / secondN;
    return Math.abs(percentDiff) <= percent;
};

const shouldUseCropStrategy = (
    image: any,
    placeholderItem: ImageViewModel,
    canvasViewModel: CanvasViewModel,
    tolerance: number
) => {
    const isSingleColor = isSingleColorCanvasModel(canvasViewModel);
    // Embroider always does getScaleToFit as it is checked in designer, but we're checking here too just in case.
    const isEmbroidery = isEmbroideryCanvasModel(canvasViewModel);
    if (isEmbroidery || isSingleColor || !placeholderItem?.model.get("placeholder")) {
        return false;
    }
    const itemWidth = placeholderItem.model.get("width");
    const itemHeight = placeholderItem.model.get("height");

    const canvasWidth = canvasViewModel.model.get("width");
    const canvasHeight = canvasViewModel.model.get("height");

    const widthOk = isNumberWithinPercentOfNumber(itemWidth, canvasWidth, tolerance);
    const heightOk = isNumberWithinPercentOfNumber(itemHeight, canvasHeight, tolerance);

    const { width: imageWidth, height: imageHeight } = image;
    const imageAspectRatio = imageWidth / imageHeight;
    const itemAspectRatio = itemWidth / itemHeight;
    const aspectRationOk = isNumberWithinPercentOfNumber(imageAspectRatio, itemAspectRatio, tolerance);
    return widthOk && heightOk && aspectRationOk;
};

export interface DefaultConfigurationFlags {
    enabledValidationOverlays?: boolean;
    enableDesignerDropZone?: boolean;
}

const DefaultConfig = (
    apiKey = "",
    merchantId = "",
    imageUploadUrl = {},
    mobile = false,
    tablet = false,
    fonts: DecoTechFontFamilies,
    locale: string,
    productKey = "",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _productOptions = {},
    studioConfiguration: StudioConfiguration,
    cimDoc = {},
    flags: DefaultConfigurationFlags = {},
    isMileStone1Enabled = false,
    t: DefaultTFunction
) => {
    const uploadConfig = typeof imageUploadUrl === "string" ? { imageUploadUrl } : imageUploadUrl;

    const productConfiguration = cloneDeep(getProductConfiguration(productKey, studioConfiguration));
    const units = localesWithInches.includes(locale.toLowerCase()) ? "in" : "cm";
    const { productMinDpi, shouldUseOutsideMarginsValidation } = studioConfiguration;
    const isRulerEnabled = mobile ? false : !isMileStone1Enabled;

    return defaultsDeep(productConfiguration, {
        core: {
            authentication: {
                apiKey
            },
            merchantId,
            premiumFinishes: {
                RaisedFoilGlitterSilver: {},
                RaisedFoilGold: {},
                RaisedFoilSilver: {},
                RaisedInk: { hasBaseColor: true },
                Metallic: { hasBaseColor: true }
            },
            snapping: {
                rotation: {
                    showLine: true,
                    snapToOther: true
                }
            },
            rotation: {
                showDegrees: true,
                cornerRotationEnabled: true
            },
            items: {
                text: {
                    multiline: true,
                    inlineEdit: false,
                    resizeStrategy: "scaleOnCorners"
                },
                image: {
                    backgroundRemovable: true,
                    placeholder: {
                        selectWhenReplaced: true,
                        getStrategyByImage: <T>({
                            sizingTypes,
                            image,
                            dropViewModel,
                            canvasViewModel
                        }: {
                            sizingTypes: { crop: T; scale: T; stretch: T };
                            image: ModelAttributes;
                            dropViewModel: ImageViewModel;
                            canvasViewModel: CanvasViewModel;
                        }): T => {
                            if (image.analysis?.isPhoto === "True") {
                                return sizingTypes.crop;
                            }
                            if (shouldUseCropStrategy(image, dropViewModel, canvasViewModel, 0.1)) {
                                return sizingTypes.crop;
                            }
                            return sizingTypes.scale;
                        }
                    },
                    minHeight: DEFAULT_IMAGE_MIN_HEIGHT,
                    minWidth: DEFAULT_IMAGE_MIN_WIDTH
                },
                shape: {
                    // strokeWidth of 0 withouth "" converts to false
                    initialSize: SHAPE_SIZE_FACTOR,
                    strokeWidthOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
                    defaults: {
                        Rectangle: {
                            fillColor: DEFAULT_SHAPE_COLOR,
                            strokeWidth: "0"
                        },
                        Ellipse: {
                            fillColor: DEFAULT_SHAPE_COLOR,
                            strokeWidth: "0"
                        },
                        Curve: {
                            fillColor: DEFAULT_SHAPE_COLOR,
                            strokeWidth: "0"
                        }
                    }
                },
                itemReference: {
                    // VP itemReferences default resizing strategy. Value can currently be 'all' or 'corners'.
                    resizeStrategy: "all",
                    // Set the resize stretegy for a specific item reference types.
                    // Value can currently be 'all' or 'corners'.
                    // Overrides the general default for item refs.
                    byType: {
                        // Must resize by corners and proportianally, otherwise these will render incorrectly
                        "Word Art": {
                            resizeStrategy: "corners"
                        },
                        "Teams Name": {
                            resizeStrategy: "corners"
                        },
                        // Must resize by corners and proportianally, otherwise these will render incorrectly
                        Table: {
                            resizeStrategy: "custom",
                            customHandles: "e"
                        },
                        "QR Code": {
                            resizeStrategy: "corners"
                        }
                    },
                    colorGetter: (viewModel: ViewModel) =>
                        viewModel.model.get("data").color ? [viewModel.model.get("data").color] : [],
                    colorUpdater: ({
                        itemReferenceViewModel,
                        newColor
                    }: {
                        itemReferenceViewModel: ItemViewModel;
                        newColor: string;
                    }) => {
                        itemReferenceViewModel.model.set("data", {
                            ...itemReferenceViewModel.model.get("data"),
                            color: newColor
                        });
                    },

                    previewRenderingHook: (attributes: any, itemRef: ReferenceItem) => {
                        const newAttributes = cloneDeep(attributes);

                        if (itemRef.type === "Word Art" || itemRef.type === "Teams Name") {
                            // If there is no content for word art, render the placeholder text
                            if (!itemRef.data.content && itemRef.templateMetaData) {
                                newAttributes.data.content = itemRef.templateMetaData.placeholder;
                            }

                            const canvasViewModel = itemRef._itemViewModel.parent;
                            const canvasIsRestrictedWithOneAvailableColor =
                                canvasViewModel.get("maxSpotColors") === 1 &&
                                canvasViewModel.get("availablePantoneColors").length === 1;
                            // this case is generally for engraved products.
                            // only one 'spot' color is allowed and the engraving color is available as this 'pantone color'
                            // we can't render spot(engraving) so instead we provide the color that we actually want
                            if (canvasIsRestrictedWithOneAvailableColor) {
                                const restrictedColor = canvasViewModel.get("availablePantoneColors")[0];
                                const newColorValue = `rgb(${restrictedColor.hex})`;
                                newAttributes.data.color = newColorValue;

                                if (newAttributes.data.shadow?.color?.length > 0) {
                                    newAttributes.data.shadow.color = newColorValue;
                                }
                                if (newAttributes.data.stroke?.color?.length > 0) {
                                    newAttributes.data.stroke.color = newColorValue;
                                }
                            }
                        }

                        return newAttributes;
                    }
                }
            },
            engraving: {
                // Embedded colors for engraving only impact preview:
                // https://vistaprint.slack.com/archives/CH9RKD6JE/p1630494643042300?thread_ts=1630479423.038500&cid=CH9RKD6JE
                // We don't want to embed the color because we want the scene to provide it
                // If we embed the color, then the scene tries to apply its color overtop of the color in the document resulting in a 'faded' look
                embedColor: false,
                color: "#C0C0C0"
            },
            fonts: {
                print: {
                    minLimit: 8,
                    minInterval: 8
                },
                embroidery: {
                    minLimit: 30,
                    minInterval: 30
                },
                byProcessType: {
                    embroideryFlat: {
                        minLimit: 30,
                        minInterval: 30
                    },
                    heatTransfer: {
                        minLimit: 24,
                        minInterval: 24
                    },
                    directToGarment: {
                        minLimit: 18,
                        minInterval: 18
                    },
                    screenPrint: {
                        minLimit: 18,
                        minInterval: 18
                    },
                    padPrint: {
                        minLimit: 8,
                        minInterval: 8
                    },
                    digital: {
                        minLimit: 8,
                        minInterval: 8
                    },
                    inkJet: {
                        minLimit: 8,
                        minInterval: 8
                    },
                    sublimation: {
                        minLimit: 8,
                        minInterval: 8
                    },
                    laserEngraving: {
                        minLimit: 9,
                        minInterval: 9
                    },
                    offsetOrDigital: {
                        minLimit: 8,
                        minInterval: 8
                    },
                    offset: {
                        minLimit: 8,
                        minInterval: 8
                    }
                }
            }
        },

        services: {
            clients: {
                // We use the font provider in easel instead of designer's fonts, however we still need to provide this for defaults
                font: {
                    print: {
                        defaultFont: "Arimo",
                        fonts:
                            fonts &&
                            fonts.print &&
                            fonts.print.length > 0 &&
                            [
                                ...new Set(
                                    fonts.print.map(category => category.fontFamilies).reduce((a, b) => a.concat(b), [])
                                )
                            ].sort()
                    },
                    embroidery: {
                        defaultFont: "Arial",
                        fonts:
                            fonts &&
                            fonts.embroidery &&
                            fonts.embroidery.length > 0 &&
                            [
                                ...new Set(
                                    fonts.embroidery
                                        .map(category => category.fontFamilies)
                                        .reduce((a, b) => a.concat(b), [])
                                )
                            ].sort()
                    }
                },
                uds: {
                    // There's probably a larger conversation here about how we determine
                    // tolerance between a surface and a document
                    // https://vistaprint.slack.com/archives/CHJNDS6P2/p1557762447195100
                    surfaceTolerance: 0.05
                },
                upload: {
                    ...uploadConfig,
                    dropzoneOptions: {
                        enabled: flags.enableDesignerDropZone
                    }
                },
                prepress: {
                    filePrepParametersUrl:
                        "https://uploads.documents.cimpress.io/v1/uploads/021b90c6-0cf3-4c84-a26a-168f86312f96~110?tenant=designtechprod",
                    proofGenerationParameters: {
                        WatermarkText: t(messages.watermark.id)
                    }
                }
            }
        },

        ui: {
            colors: {
                style: "rounded"
            },

            canvas: {
                enabledCanvas: 1,
                visibleCanvas: 1,
                chromes: {
                    border: {
                        enabled: false
                    },
                    margins: {
                        alwaysUseSvg: true,
                        bleed: {
                            fillAroundSVG: true,
                            fillColor: platinum,
                            fillOpacity: 1,
                            opacity: 1
                        },
                        safe: {
                            gapColor: "#FFFFFF",
                            gapOpacity: 0.5
                        }
                    },
                    label: {
                        color: "#000000",
                        enabled: !mobile
                    },
                    canvasTools: {
                        enabled: false
                    },
                    infoIndicators: {
                        enabled: !mobile
                    },
                    dimensions: {
                        color: "#222222",
                        showBottom: isRulerEnabled,
                        showLeft: isRulerEnabled
                    },
                    outsideMarginsHighlight: {
                        enabled: false
                    }
                },
                ...defaultMarginStrategy
            },

            measurement: {
                units,
                precision: 2
            },

            zoomStrategy: {
                initialWidth: 1,
                initialHeight: 1,
                maxZoom: DEFAULT_MAX_ZOOM,
                minZoom: DEFAULT_MIN_ZOOM,
                zoomIncrement: 0.25
            },

            widgets: {
                contextualToolbar: {
                    enabled: false
                },
                itemPlacementToolbar: {
                    enabled: false
                },
                documentValidationList: {
                    containerElement: ".document-validations__list"
                },
                canvasSwitcher: {
                    previewWidth: "100"
                }
            },

            toastNotifications: {
                visibleTime: TOAST_NOTIFICATION_TIME_MS
            },

            touchStrategy: {
                enableTouchEvents: true
            },
            useObjectUrlsForImages: false,

            allowDragSelectTouchEventPropagation: mobile || tablet,
            blockDragSelectMultiTouchPoints: mobile || tablet
        },
        features: {
            autoPlace: {
                enabled: false,
                strategy: "ScaleToFit",
                onUploadComplete: {
                    enabled: true,
                    placeAllPages: true,
                    placeOnlyOnEmptyPages: false,
                    placeOnlyOnVisiblePages: true
                }
            },
            // acceptable to enable this because we control whether the tool is availble in the toolbar
            recolorization: {
                enabled: true
            },
            premiumFinish: {
                enabled: true,
                useModal: true
            },
            placeholderHydration: {
                enabled: true
            },
            useDraggableImage: {
                enabled: !mobile
            },
            clipboard: {
                // we provide our own clipboard
                enabled: false
            }
        },
        localization: {
            language: "en",
            en: provideTranslations(designerDefaults, "easel.designer", t)
        },
        validations: {
            overlay: flags.enabledValidationOverlays !== undefined ? flags.enabledValidationOverlays : true,
            imageResolution: {
                productDpi: productMinDpi
            },
            outsideBounds: {
                image: {
                    enabled: false
                },
                shape: {
                    enabled: false
                },
                text: {
                    boundary: "bleed",
                    enabled: true
                },
                itemReference: {
                    boundary: "bleed",
                    enabled: true
                }
            },
            outsideMargins: {
                enabled: shouldUseOutsideMarginsValidation,
                text: {
                    bleedMarginEnabled: true,
                    safetyMarginEnabled: true
                },
                itemReference: {
                    bleedMarginEnabled: true,
                    safetyMarginEnabled: true
                }
            },
            overlap: {
                customized: (viewModels: ViewModel[]) => {
                    return viewModels.map(viewModel => viewModel.model).some(shouldOverlapValidate);
                },
                customEligibility: (_viewType: Item["itemType"], model: ItemModel) => {
                    return shouldOverlapValidate(model);
                }
            },
            betweenBoundaries: {
                customEligibility: (_viewType: Item["itemType"], model: ItemModel) => {
                    return shouldBetweenBoundariesValidate(model, cimDoc);
                }
            },
            loadingTime: {
                enabled: false
            }
        }
    });
};

export default DefaultConfig;
