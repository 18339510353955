import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const workRevisionIdSlice = createSlice({
    name: "workRevisionId",
    initialState: null as string | null,
    reducers: {
        setWorkRevisionId(state, action: PayloadAction<string | null>) {
            return action.payload ?? state;
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.workRevisionId ?? state;
        });
    }
});

export const { setWorkRevisionId } = workRevisionIdSlice.actions;
export const workRevisionId = workRevisionIdSlice.reducer;
