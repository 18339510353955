import React, { createContext, ReactNode } from "react";

export interface PageContextProps {
    includeNewRelic?: boolean;
    locale?: any;
    bookendsHeader?: {
        logo: any;
        url: any;
    };
    segmentKey?: string | false;
    careSchedule?: any;
    careUrl?: string;
    myProjectsUrlPath?: string;
    UILibraryDomain?: any;
    studioTranslations?: any;
    easelTranslations?: any;
    cartUrl?: string;
    miniCartConfig?: any;
}

interface Props {
    content: PageContextProps;
    children: ReactNode;
}

export const PageContext = createContext<PageContextProps>({ myProjectsUrlPath: "", cartUrl: "/c/" });

export const PageContextProvider = (props: Props) => {
    const { content, children } = props;
    return <PageContext.Provider value={content}>{children}</PageContext.Provider>;
};

PageContextProvider.displayName = "PageContextProvider";
