import { isFlagEnabled } from "@shared/utils/Flags";

/**
 * Rename surfaces to panels if need be.  This mutates the parameter.
 * @param {*} designDocument
 */
export function checkForProcessing() {
    if (!window.designer) {
        throw new Error("designer is undefined");
    }
    if (!window.designer.documentRepository) {
        throw new Error("document repository is undefined");
    }
    if (!window.designer.documentRepository.getAllItemViewModelsCollection()) {
        throw new Error("getAllItemViewModelsCollection is undefined");
    }
    const itemViewModels = window.designer.documentRepository.getAllItemViewModelsCollection().models;
    for (let i = 0; i < itemViewModels.length; i += 1) {
        if (itemViewModels[i].get("loading")) {
            return true;
        }
    }
    return false;
}

export function waitForProcessing(timeout: number) {
    return new Promise<void>((resolve, reject) => {
        let unsubscribeChanged: (() => void) | undefined;
        let timeoutPointer: number | undefined;
        const checkForProcessingDone = () => {
            if (!checkForProcessing()) {
                if (unsubscribeChanged) {
                    unsubscribeChanged();
                }
                window.designer!.eventBus.on(
                    window.designer!.eventBus.events.imageProcessingUpdateModel,
                    checkForProcessingDone
                );
                window.clearTimeout(timeoutPointer);
                resolve();
            } else {
                // Reset timeout if some models are still processing. This will give more time to documents with multiple images being processed before showing error
                window.clearTimeout(timeoutPointer);
                timeoutPointer = window.setTimeout(
                    () => reject(new Error("Waiting for processing images timed out.")),
                    timeout
                );
            }
        };
        unsubscribeChanged = window.designer!.api.events.subscribe(
            window.designer!.api.events.eventTypes.ITEMS_CHANGED,
            checkForProcessingDone
        );
        window.designer!.eventBus.on(
            window.designer!.eventBus.events.imageProcessingUpdateModel,
            checkForProcessingDone
        );
        timeoutPointer = window.setTimeout(
            () => reject(new Error("Waiting for processing images timed out.")),
            timeout
        );
    });
}

// Designer also assumes we print at the standard 300 dpi
export function pxToMm(pixels: number, productDpi = 300) {
    return (pixels / productDpi) * 25.4;
}

export function hasAdvancedTools() {
    return isFlagEnabled("advancedTools");
}

function isCornerVisible({ x, y }: { x: number; y: number }) {
    return (
        y >= 0 &&
        x >= 0 &&
        y <= (window.innerHeight || document.documentElement.clientHeight) &&
        x <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function isInViewport(element: Element, fullView?: boolean) {
    const { top, left, right, bottom } = element.getBoundingClientRect();
    if (fullView) {
        return isCornerVisible({ x: left, y: top }) && isCornerVisible({ x: right, y: bottom });
    }
    return isCornerVisible({ x: left, y: top }) || isCornerVisible({ x: right, y: bottom });
}

export function getMaskByPathType({ model, pathType }: { model: Canvas; pathType: string }) {
    return model
        .get("masks")
        .find((mask: any) => mask.pathType === pathType)
        ?.paths?.map((path: any) => path.pathPoints)
        .flat();
}

export function convertPxToMm(pixelValue: number, zoomFactor: number) {
    return pixelValue / zoomFactor;
}

// closure around zoomfactor
export function convertPxToMmWithZoomFactor(zoomFactor: number) {
    return (pixel: number) => convertPxToMm(pixel, zoomFactor);
}

export function convertMmToPx(mmValue: number, zoomFactor: number) {
    return mmValue * zoomFactor;
}

export function convertMmToPt(mmValue: number) {
    return mmValue * 2.83465;
}
