import { Store, setSaveStatus, setDocument } from "@shared/redux";
import type { Identity } from "@shared/utils/Identity";
import { saveWorkEntity, WorkEntity } from "@shared/utils/Work";
import { saveDocumentToUds, UdsResponse } from "@shared/utils/DocumentStorage";
import { DSS } from "@vp/types-ddif";
import { SAVE_STATUS } from "./constants";

export interface SaveDocumentParams {
    authToken: string;
    document: DSS.DesignDocument;
    onSave?: () => void;
    updateStore?: boolean;
}

/**
 * Saves the document to UDS; conditionally updates the local state based on param 'updateStore'
 * @return The output of saving the document to UDS
 */
export async function saveDocument({ authToken, document, onSave, updateStore }: SaveDocumentParams) {
    const state = Store.getState();

    const existingDocumentUrl = state.document?.documentUrl;
    const surfaceUpsells = Object.values(state.surfaceUpsells).map(upsell => ({
        upsellOffered: !!upsell.upsellOffered,
        panelName: upsell.panelName,
        optionName: upsell.optionName,
        colorOption: upsell.colorOption!,
        blankOption: upsell.blankOption!
    }));
    const documentMetadata = {
        reviewInstructions: state.reviewInstructions,
        surfaceUpsells,
        teamsPlaceholders: state.teamsPlaceholders
    };
    const udsResponse = await saveDocumentToUds({
        owner: state.owner,
        udsTenant: UDS_TENANT,
        authToken,
        documentUrl: existingDocumentUrl,
        documentMetadata,
        document,
        onSave
    });
    if (updateStore && udsResponse.url) {
        Store.dispatch(setDocument(udsResponse));
    }

    return udsResponse;
}

export interface SaveDesignParams {
    authToken: string;
    identity: Identity;
    newWorkName?: string | undefined;
    savedProjectTrackingEventData?: any | undefined;
    saveDocument: () => Promise<UdsResponse>;
}

/**
 * Saves the document to UDS, updates the state if necessary with the document url, and then saves to WES
 * IF a workname is passed in it will save it as new
 * * @param {string} newWorkName
 * @return The output of saving the document to WES after saving it to UDS
 */
export async function saveDesign({
    authToken,
    identity,
    newWorkName,
    savedProjectTrackingEventData,
    saveDocument
}: SaveDesignParams): Promise<WorkEntity> {
    try {
        Store.dispatch(setSaveStatus(SAVE_STATUS.CURRENTLY_SAVING));
        const udsResponse = await saveDocument();
        const workResponse = await saveWorkEntity({
            authToken,
            identity,
            newWorkName,
            savedProjectTrackingEventData,
            udsResponse
        });
        Store.dispatch(setSaveStatus(SAVE_STATUS.CHANGES_SAVED));
        return workResponse;
    } catch (e) {
        Store.dispatch(setSaveStatus(SAVE_STATUS.CAN_BE_SAVED));
        throw e;
    }
}
