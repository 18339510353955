// this will run the last instance of the debounced function
function debounce<T extends []>(cb: (...args: T) => void, duration: number) {
    let timer: ReturnType<typeof setTimeout>;
    return (...args: T) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            cb(...args);
        }, duration);
    };
}

// debounce functions by first argument
export function getDebouncedByType(func: (...args: any[]) => void, wait: number) {
    const memory = {};

    return (...args: any[]) => {
        // use first argument as a key
        const [searchType] = args;

        if (typeof memory[searchType] === "function") {
            return memory[searchType](...args);
        }

        memory[searchType] = debounce(func, wait);
        return memory[searchType](...args);
    };
}
