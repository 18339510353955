import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { EaselSceneConfiguration } from "@shared/utils/Scene";
import { setDefaultState } from "../compoundActions";

const initialState = {
    underlay: [],
    overlay: []
} as EaselSceneConfiguration;

const scenesConfigurationSlice = createSlice({
    name: "scenesConfiguration",
    initialState,
    reducers: {
        setScenesConfiguration: (state, action: PayloadAction<EaselSceneConfiguration>) => action.payload
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => action.payload.scenesConfiguration || initialState);
    }
});

export const { setScenesConfiguration } = scenesConfigurationSlice.actions;
export const scenesConfiguration = scenesConfigurationSlice.reducer;
