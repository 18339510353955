import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import type { DSS } from "@vp/types-ddif";
import type { ImageItem } from "@design-stack-ct/cdif-types/v2";

const CROP_LIMIT = "0.9999";

export function cropFractionCheck(docJson: DSS.DesignDocument, udsResponse: any) {
    try {
        // Filter to just the panels w/ images
        const panelsWithImages = docJson.document.panels?.filter((panel: { images?: any }) => !!panel?.images);
        if (panelsWithImages.length === 0) {
            return;
        }
        // Get an array of images for each panel
        const clusteredImages = panelsWithImages.map((panel: { images?: ImageItem[] }) => panel.images);
        // Flatten the image clusters
        const images = clusteredImages.reduce((accumulator: ImageItem[], value: any) => accumulator.concat(value), []);
        if (!images) {
            return;
        }
        // Filter to just the images w/ Crop Fractions
        const imagesWithCropFractions = images.filter((image: { cropFractions?: any }) => !!image?.cropFractions);
        if (imagesWithCropFractions.length === 0) {
            return;
        }
        const imageCropFractions = imagesWithCropFractions.map((image: { cropFractions?: any }) => image.cropFractions);
        // Check if any value is set to the cropLimit... something suspect maybe going on
        const cropFractionsAtLimit = imageCropFractions.filter(
            (cropFractions: { top: string; left: string; right: string; bottom: string }) =>
                cropFractions.top === CROP_LIMIT ||
                cropFractions.left === CROP_LIMIT ||
                cropFractions.right === CROP_LIMIT ||
                cropFractions.bottom === CROP_LIMIT
        );
        if (cropFractionsAtLimit.length > 0) {
            fireDesignToolTrackingEvent({
                eventDetail: STUDIO_TRACKING_EVENTS.SAVE_SUSPECT_VALUES,
                extraData: () => ({
                    udsResponseLinks: {
                        url: udsResponse._links.self.href,
                        documentRevisionUrl: udsResponse._links.documentRevision.href
                    }
                })
            });
        }
    } catch (e) {
        // Eat any exception
    }
}
