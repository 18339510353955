// Utility functions for adjusting data for the clipboard
import cloneDeep from "lodash/cloneDeep";
import { ItemReferenceTypes } from "@shared/utils/CimDoc";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { getTableFontSize, setTableFontSize } from "../utilities/Tables";
import { isSingleColorCanvasModel } from "../utilities/utils";
import type { Designer, ItemSelection } from "./@types/designer";
import { getDecorationTechnology, getRotatedOuterBox, itemCenter } from "./util";

const SAFETY_NUDGE = 0.2;
const OFFSET = 2;

interface SizeRatios {
    heightRatio: number;
    widthRatio: number;
}

function isTableData(data: AttributeData): data is TableItemData {
    return (data as TableItemData).columns !== undefined;
}

function hasOverprints(data: AttributeData): data is WordArtData {
    return (data as WordArtData).overprints !== undefined;
}

// Lifted from designer
export function mapTextContent(contentArray: any[], mappingFunction: (arg0: any) => any) {
    return contentArray.map(item => {
        const result = mappingFunction(item);
        if (item.type === "list" || item.type === "listItem") {
            result.content = mapTextContent(result.content, mappingFunction);
        }
        return result;
    });
}

/**
 * Remove premium finishes if the new canvas does not support it
 * @param activeCanvas
 * @param items
 */
export function premiumFinishesFixup(activeCanvas: Canvas, items: ModelAttributes[]) {
    const canvasHasPremiumFinishes =
        activeCanvas.get("availablePremiumFinishes") &&
        activeCanvas.get("availablePremiumFinishes").length > 0 &&
        activeCanvas.get("ordinal") === 1; // only front canvas (ordinal = 1) is supposed to have premium finishes

    // if the destination canvas does not have premium finishes, make sure to remove any PF from the items being copied
    if (!canvasHasPremiumFinishes) {
        items.forEach(item => {
            if (item.specialProcessing && item.specialProcessing.includes("legacyFinishMask")) {
                // eslint-disable-next-line no-param-reassign
                delete item.specialProcessing;
            }
            // go thru the potentially nested content items for rich text and remove any overprints
            if (item?.content) {
                // eslint-disable-next-line no-param-reassign
                item.content = mapTextContent(item.content, itemContent => {
                    // remove the overprints from the content
                    const { overprints: _overprints, ...content } = itemContent;
                    // If no color or it is a PF apply a regular color
                    if (!content.color || content.color.includes("spot(")) {
                        content.color = "rgb(#101820)"; // Selected by looking at applied color value when foil is toggle off
                    }
                    return {
                        ...content
                    };
                });
            }
            if (item.overprints && item.overprints.length > 0) {
                // eslint-disable-next-line no-param-reassign
                item.overprints = [];
            }
            if (item.data && hasOverprints(item.data) && item.data.overprints && item.data.overprints.length > 0) {
                // eslint-disable-next-line no-param-reassign
                delete item.data.overprints;
            }
            if (item.premiumFinish) {
                // eslint-disable-next-line no-param-reassign
                delete item.premiumFinish;
                // eslint-disable-next-line no-param-reassign
                delete item.premiumFinishMaskFullyCovers;
                // eslint-disable-next-line no-param-reassign
                delete item.premiumFinishMaskPreviewUrl;
                // eslint-disable-next-line no-param-reassign
                delete item.premiumFinishMaskPrintUrl;
            }
        });
    }
}

/**
 * Resolves issues with copying laser engraved items to a non-laser engraved product
 * @param activeCanvas
 * @param items
 */
export function singleColorFixUp(activeCanvas: Canvas, items: ModelAttributes[]) {
    const isSingleColor = isSingleColorCanvasModel(activeCanvas);

    if (!isSingleColor) {
        items.forEach(item => {
            // laser engraved images have overlays for the engraving color spot mask
            if (item.isSegmented) {
                // eslint-disable-next-line no-param-reassign
                item.isSegmented = false;
                // eslint-disable-next-line no-param-reassign
                item.colorOverrides = [];

                // for PDFs designer vectorizes only the page needed so the pageNumber is only correct for the original url
                // copying to a non-single color canvas, designer will use the print/preview urls (from the overlay, which have one page)
                // this will work in designer and fail in prepress
                // we want to keep the vectorized 1-page pdfs since that is what the customer is copying
                // so if the old design used a page other than the first, set the original url to be the vectorized print url and reset the page number
                if (item.pageNumber && item.pageNumber > 1) {
                    // eslint-disable-next-line no-param-reassign
                    item.pageNumber = 1;
                    // eslint-disable-next-line no-param-reassign
                    item.originalUrl = item.printUrl;
                }
            }
        });
    }
}

/**
 * Offset the items by 2 pixels diagonally
 * @param items
 */
export function offset(items: ModelAttributes[]) {
    // Move items 2 pixels diagonal from its last position, when item is paste on the same canvas
    items.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.top += OFFSET;
        // eslint-disable-next-line no-param-reassign
        item.left += OFFSET;
    });
}

/**
 * Calculate the height and width ratios for 2 different canvases
 * @param currentDimensions current dimensions
 * @param referenceDimensions dimensions to compare to
 */
export function dimensionRatios(currentDimensions: Dimensions, referenceDimensions: Dimensions): SizeRatios {
    return {
        heightRatio: currentDimensions.height / referenceDimensions.height,
        widthRatio: currentDimensions.width / referenceDimensions.width
    };
}

/**
 * Adjust an item to be back inside the safety if possible
 * Only applied to text and word art
 * @param designer Designer
 * @param activeDimensions Dimensions of the canvas
 * @param item The item
 */
function safetyAdjustment(designer: Designer, activeDimensions: Dimensions, item: ModelAttributes) {
    // Only adjust if Word Art or Text
    if (item.module === "TextField" || (item.module === "ItemReference" && item.type === ItemReferenceTypes.WORD_ART)) {
        // If can't find a safetyMargin assume is all 0's
        const safetyMargin: SafetyMargins = designer.documentRepository.getActiveCanvasViewModel().has("safetyMargin")
            ? designer.documentRepository.getActiveCanvasViewModel().get("safetyMargin")
            : { bottom: 0, left: 0, right: 0, top: 0 };

        // ToDo: Right now the handle box is being used, not the preview (actual size of rich text items)
        //  it would be better to use that for sizing for rich text so safety line calculations are more precise

        // Calculate the box that would enclose the rotated item (will be same box with no rotation)
        const outerBox = getRotatedOuterBox(item.top, item.left, item.width, item.height, item.rotation);

        // If the item is less wide than the safety area, apply a horizontal adjustment if needed
        if (outerBox.width < activeDimensions.width - safetyMargin.left - safetyMargin.right) {
            if (outerBox.left <= safetyMargin.left) {
                outerBox.left = safetyMargin.left + SAFETY_NUDGE;
            }
            if (outerBox.left + outerBox.width >= activeDimensions.width - safetyMargin.right) {
                outerBox.left = activeDimensions.width - safetyMargin.right - outerBox.width - SAFETY_NUDGE;
            }
        }
        // If the item is less tall then the safety area, apply a vertical adjustment if needed
        if (outerBox.height < activeDimensions.height - safetyMargin.top - safetyMargin.bottom) {
            if (outerBox.top <= safetyMargin.top) {
                outerBox.top = safetyMargin.top + SAFETY_NUDGE;
            }
            if (outerBox.top + outerBox.height >= activeDimensions.height - safetyMargin.bottom) {
                outerBox.top = activeDimensions.height - safetyMargin.bottom - outerBox.height - SAFETY_NUDGE;
            }
        }
        const originalCenter = itemCenter(item);
        const newCenter = itemCenter(outerBox);
        // eslint-disable-next-line no-param-reassign
        item.top += newCenter.y - originalCenter.y;
        // eslint-disable-next-line no-param-reassign
        item.left += newCenter.x - originalCenter.x;
    }
}

/**
 * Adjust item to new size, adjusting both height and width based on the new canvas dimensions.
 * Item will be stretched if dimensions are very different.
 * @param item Item Model
 * @param ratios width and height ratio differences between source and destination canvas
 */
function proportionalAdjustment(item: ModelAttributes, ratios: SizeRatios) {
    // eslint-disable-next-line no-param-reassign
    item.height *= ratios.heightRatio;
    // eslint-disable-next-line no-param-reassign
    item.width *= ratios.widthRatio;
}

/**
 * Unlock changing the aspect ratio, as images can get seriously stretched,
 *  and users may want to unstretch some of them
 * @param item Image Item Model
 */
function unlockAspectRatio(item: ModelAttributes) {
    if (item?.isAspectRatioLocked) {
        // Adjust font size for rich text items
        // eslint-disable-next-line no-param-reassign
        item.isAspectRatioLocked = false;
    }
}

/**
 * Get the minimum font size
 * @param designer Designer
 * @param string selectedFontFamily
 * @returns number
 */
export function getMinFontSize(designer: Designer, selectedFontFamily: string) {
    const decorationTechnology = getDecorationTechnology(designer);
    const defaultFontConfig = designer.clients.font.fontSizeConfig[decorationTechnology];
    const { minInterval, minLimit } = defaultFontConfig;
    const { canvases } = designer.api.design;
    const activeCanvas = canvases.find(can => can._canvasViewModel.get("active")) || canvases[0];

    const fontIntervalsForFontFamily = designer.FontSizeManager.getFontIntervalsForFontFamily(
        activeCanvas.mmDimensions,
        selectedFontFamily
    );

    // Anything less then 8, is almost always to small
    const min = Math.max(minInterval, minLimit, 8, fontIntervalsForFontFamily.minLimit);
    return Math.trunc(min);
}

/**
 * Adjust the text font size.
 * As Text Items self re-adjust size based on content, its very important to do this for text
 * @param designer Designer
 * @param item Text Item Model
 * @param ratios width and height ratio differences between source and destination canvas
 */
function textFontSizeAdjustment(designer: Designer, item: ModelAttributes, ratios: SizeRatios) {
    // This could take into account additional font size restrictions, but currently does not
    //  For example: font size overrides for particular fonts
    if (item?.content) {
        // const newSize = Math.max(item.fontSize * ratios.heightRatio, 40);
        // eslint-disable-next-line no-param-reassign
        item.content = mapTextContent(item.content, ({ fontSize, ...fieldProperties }) => {
            const minSize = getMinFontSize(designer, fieldProperties.fontFamily as string);
            // scale individual textfield
            const scaledSize = +Math.max(parseFloat(fontSize) * ratios.heightRatio, minSize).toFixed(1);
            return {
                ...fieldProperties,
                fontSize: scaledSize
            };
        });
    }
}

/**
 * Adjust the table font size apropriately for the new canvas size. Maintain a min font size of 8pt.
 * @param item Table Item Model
 * @param ratios width and height ratio differences between source and destination canvas
 */
function tableFontSizeAdjustment(item: ModelAttributes, ratios: SizeRatios) {
    if (item.data && isTableData(item.data)) {
        const fontSize = getTableFontSize(item?.data);
        if (fontSize) {
            const adjustedSize = Math.trunc(parseInt(fontSize, 10) * ratios.heightRatio);
            setTableFontSize(item.data, adjustedSize >= 8 ? `${Math.trunc(adjustedSize)}pt` : "8pt");
        }
    }
}

/**
 * Adjust item to new size, but maintain proportions. Uses the width adjustment for both height & width.
 * @param item Item Model
 * @param ratios width and height ratio differences between source and destination canvas
 */
function adjustWithMaintainedProportions(item: ModelAttributes, ratios: SizeRatios) {
    // Width is being used as the scale to maintain proportions against
    // For some itemref, it has to be the width (TABLES), for some others it could be either (WORDART)
    // eslint-disable-next-line no-param-reassign
    item.height *= ratios.widthRatio;
    // eslint-disable-next-line no-param-reassign
    item.width *= ratios.widthRatio;
}

/**
 * Use the module to determine type
 * See https://gitlab.com/Cimpress-Technology/DocDesign/designexperience/cimpress-designer/-/blob/master/app/publicApi/constants/modules.js
 * @param module - module of the current Item
 * @returns an Item Type
 */
export function moduleToType(module: string): ItemTypes {
    switch (module) {
        case "TextField":
            return ItemTypes.TEXT;
        case "UploadedImage":
            return ItemTypes.IMAGE;
        case "Rectangle":
        case "Curve":
        case "Ellipse":
        case "Line":
            return ItemTypes.SHAPE;
        case "ItemReference":
            return ItemTypes.ITEM_REFERENCE;
        default:
            return ItemTypes.ITEM_REFERENCE;
    }
}

/**
 * Yet another typing method - but only has the model available to it unlike elsewhere...
 * @param item Item Model
 * @returns an Item Type
 */
export function getItemType(item: ModelAttributes): ItemTypes {
    let currentType = moduleToType(item.module);
    if (currentType === ItemTypes.ITEM_REFERENCE) {
        switch (item.type) {
            case ItemReferenceTypes.WORD_ART:
                currentType = ItemTypes.WORDART;
                break;
            case ItemReferenceTypes.TABLE:
                currentType = ItemTypes.TABLE;
                break;
            case ItemReferenceTypes.QR_CODE:
                currentType = ItemTypes.QR_CODE;
                break;
            case ItemReferenceTypes.CALENDAR_GRID:
                currentType = ItemTypes.CALENDAR_GRID;
                break;
            default:
                currentType = ItemTypes.ITEM_REFERENCE;
        }
    }
    return currentType;
}

const ItemTypesText = {
    TEXT: "Text item",
    Reference: "Reference item",
    IMAGE: "Image item",
    SHAPE: "Shape",
    WORDART: "Wordart item",
    Table: "Table item",
    QRCODE: "QR code",
    CALENDARGRID: "Calender grid",
    TEAMS: "Teams"
};

export function getItemTypeText(item: ModelAttributes): string {
    const itemTypeText = ItemTypesText[getItemType(item)];
    if (itemTypeText === "Shape") {
        return `${item.module} Shape`;
    }
    return itemTypeText;
}

export function getStatusMessage(selections: ItemSelection): string {
    const itemTypes = selections.map(item => getItemTypeText(item._itemViewModel.model.attributes));
    if (itemTypes.length === 1) return itemTypes[0];
    const itemTypeMap = Array.from(new Set(itemTypes)).map(itemType => {
        const count = itemTypes.filter(x => x === itemType).length;
        const itemCountAndName = count > 1 ? `${count} ${itemType}s` : `${count} ${itemType}`;
        return itemCountAndName;
    });
    if (itemTypeMap.length === 1) return itemTypeMap[0];
    const last = itemTypeMap.pop();
    const statusMeassage = itemTypeMap.join(", ").concat(` and ${last}`);
    return statusMeassage;
}

/**
 * Adjust the size of each item as best possible to be placed on the new canvas
 *
 * Resize the item so that it is sized appropriately for the new product it's being pasted on
 * (e.g. when using Studio Clipboard, it's possible to copy and paste from a really small product (e.g. business card)
 * to a really large product (e.g. banner) or vice versa)
 * Otherwise, if you were pasting from a business card to a banner, you would end up pasting a really small item,
 * or if you were pasting from a banner to a business card, you would end up pasting an item larger than the business card itself
 * @param designer Designer
 * @param item Item to be adjusted
 * @param ratios width and height ratio differences between source and destination canvas
 */
export function typedSizeAdjustment(designer: Designer, item: ModelAttributes, ratios: SizeRatios) {
    const itemType = getItemType(item);
    switch (itemType) {
        case ItemTypes.TEXT:
            proportionalAdjustment(item, ratios); // Will self adjust it's height based on content
            textFontSizeAdjustment(designer, item, ratios);
            break;
        case ItemTypes.IMAGE:
            proportionalAdjustment(item, ratios);
            unlockAspectRatio(item);
            break;
        case ItemTypes.TABLE:
            proportionalAdjustment(item, ratios); // Will self adjust it's height based on content
            tableFontSizeAdjustment(item, ratios);
            break;
        // Most of the item references can get seriously messed up if there proportions are not maintained
        case ItemTypes.ITEM_REFERENCE: // Unknown item ref
        case ItemTypes.WORDART:
        case ItemTypes.QR_CODE:
        case ItemTypes.CALENDAR_GRID:
            // Generally cal grids are restricted in a way that they cannot be copied.
            //  If they get to a place where we are allowing access, they likely need a font size adjustment.
            //  Its not worth doing unless we unrestrict the grids to regular users.
            adjustWithMaintainedProportions(item, ratios);
            break;
        case ItemTypes.SHAPE:
        default:
            proportionalAdjustment(item, ratios);
    }
}

/**
 * Repositions items based on the relative location on the new canvas vs the old
 * Note: Does not attempt to resize items
 * @param designer Designer
 * @param newDimensions Dimensions of the canvas being copied to
 * @param originalDimensions Dimensions of the canvas being copied from
 * @param items Items to adjust
 */
export const scaledDisplace = (
    designer: Designer,
    newDimensions: Dimensions,
    originalDimensions: Dimensions,
    items: ModelAttributes[]
) => {
    if (newDimensions.height !== originalDimensions.height || newDimensions.width !== originalDimensions.width) {
        items.forEach(item => {
            const center = itemCenter(item);
            const ratios = dimensionRatios(newDimensions, originalDimensions);

            // Do a proportionate size adjustment
            //  Needs to happen before calculating the new position
            typedSizeAdjustment(designer, item, ratios);

            // Do a proportionate placement adjustment
            // eslint-disable-next-line no-param-reassign
            item.top = center.y * ratios.heightRatio - item.height / 2;
            // eslint-disable-next-line no-param-reassign
            item.left = center.x * ratios.widthRatio - item.width / 2;

            // If needed adjust text / word art inside the safety line
            safetyAdjustment(designer, newDimensions, item);
        });
    }
};

/**
 * Repositions items based on the relative location on the new canvas vs the old
 * Note: Does not attempt to resize items
 * @param designer Designer
 * @param activeCanvas The current canvas
 * @param originalDimensions Dimensions of the canvas being pasted from
 * @param items Items to adjust
 */
export function scaledDisplaceWithActiveCanvas(
    designer: Designer,
    activeCanvas: Canvas,
    originalDimensions: Dimensions,
    items: ModelAttributes[]
) {
    // For canvases are different size, adjust offset using the ratio of the
    // size difference relative to the items center point
    // For multi-select paste this will cause things to move appart / get
    // closer relative to the canvas dimension(s) that are different
    const activeDimensions = {
        height: activeCanvas.get("height"),
        width: activeCanvas.get("width")
    };
    scaledDisplace(designer, activeDimensions, originalDimensions, items);
}

function scrubAttributes(modelAttributes: ModelAttributes) {
    // Using spread to replace lodash omit
    const {
        id: _id,
        locked: _locked,
        placeholderUrl: _placeholderUrl,
        placeholderReplaced: _placeholderReplaced,
        autoPlaced: _autoPlaced,
        ...remainingAttributes
    } = modelAttributes;
    if (modelAttributes.module !== "TextField") {
        const { placeholder: _placeholder, ...remainingTextAttributes } = remainingAttributes;
        return remainingTextAttributes;
    }
    return remainingAttributes;
}

/**
 * Copy and pull out the model attributes and remove some attributes that shouldn't be copied.
 * @param itemViewModel
 * @returns { ModelAttributes }
 */
export function scrubItemViewModel(itemViewModel: ItemViewModel) {
    const item = cloneDeep(itemViewModel.model.attributes);
    return scrubAttributes(item);
}
