import { useAppSelector } from "@shared/redux";

export const useExtraErrorDetails = () => {
    const errorMessage = useAppSelector(state => state.errorMessage);
    const errorStackTrace = useAppSelector(state => state.errorStackTrace);
    const errorExtraData = useAppSelector(state => state.errorExtraData);
    const productKey = useAppSelector(state => state.productKey);
    const quantity = useAppSelector(state => state.quantity);

    return {
        productKey,
        Quantity: quantity,
        errorStackTrace,
        errorExtraData,
        errorMessage: (errorMessage as any)?.errorMessage ?? errorMessage?.toString()
    };
};
