import qs from "qs";
import { isCareAgent } from "@shared/utils/Care";
import { getQueryParams, addQueryParam, removeQueryParam } from "./queryString";

export function buildHistoryUrl(queryParams: { [key: string]: string | boolean }) {
    const urlParams = getQueryParams();

    const updatedQueryParams = queryParams || {};
    if (isCareAgent()) {
        updatedQueryParams.careAgent = true;
    }
    if (urlParams.owner) {
        updatedQueryParams.owner = urlParams.owner;
    }
    if (urlParams.debugMode) {
        updatedQueryParams.debugMode = urlParams.debugMode;
    }
    if (urlParams.studio_bundle_source) {
        updatedQueryParams.studio_bundle_source = urlParams.studio_bundle_source;
    }
    if (urlParams.metadata) {
        updatedQueryParams.metadata = urlParams.metadata;
    }
    if (urlParams.quickStudioAnalytics) {
        updatedQueryParams.quickStudioAnalytics = urlParams.quickStudioAnalytics;
    }
    if (urlParams.returnUrl) {
        updatedQueryParams.returnUrl = urlParams.returnUrl;
    }

    return `${window.location.pathname}?${qs.stringify(updatedQueryParams)}`;
}

export function adjustGalleryPreviewUrl(url: string) {
    // we don't want to accept the url if scene is specified
    // todo: figure out how we can make acceptable previewUrl for products with scenes
    if (url.includes("&scene=")) return "";
    let resultUrl = "https:".concat(url);
    resultUrl = addQueryParam(resultUrl, "width", "600");
    return resultUrl;
}

export function removeGalleryUrlData() {
    window.history.replaceState("update-url", "", removeQueryParam(window.location.href, "metadata"));
    window.history.replaceState("update-url", "", removeQueryParam(window.location.href, "quickStudioAnalytics"));
}

export function cleanStudioUrl(incomingUrl: string) {
    return incomingUrl.replace(/studio5|studio6|studioab/, "studio");
}
