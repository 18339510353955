import { tryFetch } from "@shared/utils/Network";

const entityCode = 25;

export function getMPVId(merchandisingMpvUrl: string, mpvId: string): string {
    if (merchandisingMpvUrl && !mpvId) {
        // parse merchandisingMpvUrl if no mpvId provided
        const ind = merchandisingMpvUrl.lastIndexOf("/");
        // Check if url contains query to truncate
        if (merchandisingMpvUrl.includes("?")) {
            const queryInd = merchandisingMpvUrl.indexOf("?");
            return merchandisingMpvUrl.substring(ind + 1, queryInd);
        }
        return merchandisingMpvUrl.substring(ind + 1);
    }
    return mpvId;
}

export async function getProductName(productKey: string, locale: string): Promise<string> {
    const response = await tryFetch({
        url:
            `${MSX_URL}/tenant/${MERCHANDISING_TENANT}/productKey/${productKey}/merchandisedProperty/productName` +
            `?requestor=${REQUESTOR}&cultures=${locale.toLowerCase()}&lower=true`,
        moduleFunction: "merchandisingProductClient:getProductName",
        friendlyDescription: "retrieve name for product",
        entityCode
    });

    return response && response[locale.toLowerCase()] && response[locale.toLowerCase()].productName;
}

export async function getProductKey(mpvId: string, locale: string): Promise<string> {
    const response = await tryFetch({
        url: `${MSX_URL}/tenant/${MERCHANDISING_TENANT}/mpvs/${mpvId}?requestor=${REQUESTOR}&cultures=${locale.toLowerCase()}`,
        moduleFunction: "merchandisingProductClient:getProductKey",
        friendlyDescription: "retrieve product key from MPV",
        entityCode
    });

    return response && response[locale.toLowerCase()];
}

export async function getIsPpagTail(productKey: string, locale: string): Promise<boolean> {
    try {
        const response = await tryFetch({
            url: `${MSX_URL}/tenant/${MERCHANDISING_TENANT}/productKey/${productKey}/merchandisedProperty/ppagTail?requestor=${REQUESTOR}&cultures=${locale}&lower=true`,
            moduleFunction: "merchandisingProductClient:getIsPpagTail",
            friendlyDescription: "retrieve ppagTail boolean",
            entityCode
        });

        return response && response[locale.toLowerCase()].ppagTail;
    } catch {
        // if the above failed then the data doesn't exist.  we can assume this is not a ppagtail product.
        return false;
    }
}

// These interfaces are simplified;
// the service returns more but this is
// all that Studio5 uses.
export type ProductAttributeValue = {
    key: string;
    images: string[];
    merchandisingName: string;
};

export type ProductAttributeKey = {
    key: string;
};

export type ProductAttributesKeyAndValues = {
    key: ProductAttributeKey;
    value: ProductAttributeValue[];
};
