import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SaveSuccessPayload {
    saveSuccess: boolean | null;
    hideSaveToast?: boolean;
}

const saveSuccessSlice = createSlice({
    name: "saveSuccess",
    initialState: null as boolean | null,
    reducers: {
        setSaveSuccess(state, action: PayloadAction<SaveSuccessPayload | null>) {
            return action.payload?.saveSuccess ?? null;
        }
    }
});

export const { setSaveSuccess } = saveSuccessSlice.actions;
export const saveSuccess = saveSuccessSlice.reducer;
