import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const isQuantityPageEnabledSlice = createSlice({
    name: "isQuantityPageEnabled",
    initialState: false,
    reducers: {
        setIsQuantityPageEnabled(state, action: PayloadAction<boolean>) {
            return action.payload;
        }
    }
});

export const { setIsQuantityPageEnabled } = isQuantityPageEnabledSlice.actions;
export const isQuantityPageEnabled = isQuantityPageEnabledSlice.reducer;
