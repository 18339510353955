import { createAction } from "@reduxjs/toolkit";

export interface PayloadType {
    errorCode?: any;
    errorMessage?: string;
    showErrorPage?: boolean;
    hideErrorToast?: boolean;
    errorStackTrace?: any;
    errorExtraData?: any;
}

export const setError = createAction<PayloadType>("setError");
