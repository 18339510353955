import type { DSS } from "@vp/types-ddif";
import { upgradeCimDoc } from "@design-stack-ct/cimdoc-state-manager";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import {
    addPlaceholderMetadataToCimDoc,
    isUnreplacedPlaceholderImage
} from "@design-stack-vista/studio-document-metadata-management";
import cloneDeep from "lodash/cloneDeep";
import { saveValidations } from "../../studioFive/components/Validation/ValidationMetadata";
import { getAllStudioMetadata } from "./utils-studio-metadata";

function blankPanelWithContentCheck(docJson: DSS.DesignDocument) {
    try {
        docJson.document.panels.forEach(panel => {
            if (
                panel?.colorMode === "blank" &&
                (panel?.textAreas?.length ||
                    panel?.itemReferences?.length ||
                    panel?.images?.length ||
                    panel?.shapes?.length)
            ) {
                // eslint-disable-next-line no-param-reassign
                delete panel.textAreas;
                // eslint-disable-next-line no-param-reassign
                delete panel.itemReferences;
                // eslint-disable-next-line no-param-reassign
                delete panel.images;
                // eslint-disable-next-line no-param-reassign
                delete panel.shapes;

                window?.designer?.eventBus.trigger(STUDIO_TRACKING_EVENTS.SAVE_BAD_BLANK_PANEL, {
                    id: panel?.id,
                    name: panel?.name
                });
            }
        });
    } catch (e) {
        // Eat any exception
    }
}

function reApplyTempTemplateMetadata(designDocument: DSS.DesignDocument) {
    if (designDocument.metadata?.template) {
        // eslint-disable-next-line no-param-reassign
        designDocument.metadata.template = designDocument?.metadata?.template?.map(item => {
            const tempItem = designDocument?.metadata?.originalTemplateTemp?.find(
                originalItem => originalItem.id === item.id
            );
            if (tempItem) {
                return { ...item, originalTemplateElementId: tempItem.originalTemplateElementId };
            }
            return item;
        });

        // eslint-disable-next-line no-param-reassign
        delete designDocument.metadata.originalTemplateTemp;
    }
}

/**
 * This method will allow us to maintain a single interface between Designer and Studio when it comes to pulling
 * documents out of Designer.
 *
 * As of June 06, 2019, there are a few differences between documents that Designer outputs and the Design
 * Document Interchange Format (DDIF)
 * https://vistaprint.atlassian.net/wiki/spaces/DTT/pages/22447717/Design+Document+Interchange+Format+DDIF
 *
 * - DDIF does not store any product information. Designer attaches sku and sku variables to its documents
 * - DDIF expects .document to contain .panels. Designer always outputs documents with .surfaces
 *   - this appears to be a defect and may be resolved within Designer, or Designer may switch to panels
 *     for all documents.
 *     https://vistaprint.slack.com/archives/CH9RKD6JE/p1559849159061600
 *
 * This functionality may be a good candidate for a DSS endpoint (POST a Designer cimDoc, receive a VP Design Document)
 */

export function getDesignDocumentFromDesigner(removeUnreplacedImagePlaceholders: boolean = true) {
    // in the designer api, itemFilters defaults to filtering out empty image placeholders when nothing is passed in.
    // an empty array can be passed in thus overriding the designer api default and running no filters.
    // or any array of functions that we want to be used to filter canvas items
    // e.g. [item => item.placeholder && !item.placeholderReplaced]
    let designerOutput = window.designer!.api.design.getAsCimDoc(true, []) as DSS.DesignDocument;

    reApplyTempTemplateMetadata(designerOutput);

    // Defining the sku/skuVariables on the document cause UDS validations to fail when the PRD and MCP Sku are not sync'd
    delete designerOutput.sku;
    // @ts-ignore next-line // sku variables come from designer and should not be exposed outside of designer
    delete designerOutput.skuVariables;
    delete designerOutput.owner;
    delete designerOutput.tenant;

    if (removeUnreplacedImagePlaceholders) {
        // addPlaceholderMetadataToCimdoc uses immer which freezes the object but studio5 tries to modify the object all over the place
        designerOutput = cloneDeep(addPlaceholderMetadataToCimDoc(designerOutput));
        const placeholderImageIds = (designerOutput.metadata?.template?.filter(isUnreplacedPlaceholderImage) ?? []).map(
            imageTemplate => imageTemplate.id
        );
        designerOutput.document.panels.forEach(panel => {
            // Remove any images from the document that are placeholder images
            // eslint-disable-next-line no-param-reassign
            panel.images = panel.images?.filter(image => !placeholderImageIds.includes(image.id)) ?? [];
        });
    }

    saveValidations(designerOutput);
    blankPanelWithContentCheck(designerOutput);
    if (!designerOutput.metadata) {
        designerOutput.metadata = { template: [] };
    }
    // @ts-ignore
    designerOutput.metadata.studioMetadata = getAllStudioMetadata(window.designer);
    return upgradeCimDoc(designerOutput);
}

export function setUpGetAsCimDocProxy() {
    if (typeof window === "undefined") {
        return;
    }
    // Utility method for testing purposes
    // This can be a stand-in for when you may want to call designer.api.design.getAsCimDoc()
    window.getDesignDocumentFromDesigner = getDesignDocumentFromDesigner;

    // Proxy designer.api.design.getAsCimDoc to inform Studio (i.e. Easel) users that this
    // method will not return a DDIF compatible document
    window.designer!.api.design.getAsCimDoc = new Proxy(window.designer!.api.design.getAsCimDoc, {
        apply(target, context, [silent, ...argumentList]) {
            if (!silent) {
                // eslint-disable-next-line no-console
                console.warn(
                    "designer.api.design.getAsCimDoc() returns a CDIF document from Cimpress Designer." +
                        "To retrieve A DDIF document, please use window.getDesignDocumentFromDesigner()."
                );
            }

            return target.apply(context, argumentList);
        }
    });
}
