import { usePageContext } from "@shared/features/StudioConfiguration";
import { useAppDispatch, useAppSelector, setTracking, DexName } from "@shared/redux";
import { getQueryParams } from "@shared/utils/WebBrowser";
import { newRelicWrapper, debouncedReportRawErrorToSegment } from "@shared/utils/Errors";
import { retry } from "@shared/utils/Network";
import { getRootClassNames } from "@vp/swan";
import { useEffect } from "react";

export function useAnalytics(dexName: DexName) {
    const { segmentKey } = usePageContext();
    const dispatch = useAppDispatch();
    const locale = useAppSelector(state => state.locale);
    const productDataLoadAttempted = useAppSelector(state => state.productDataLoadAttempted);

    useEffect(() => {
        let hidden: string;
        let visibilityChange;
        if (typeof document.hidden !== "undefined") {
            // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof (document as any).msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof (document as any).webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }
        if (visibilityChange) {
            const handleVisibilityChange = () => {
                if (document[hidden]) {
                    newRelicWrapper.logPageAction("document-hidden");
                }
            };

            document.addEventListener(visibilityChange, handleVisibilityChange, false);
        }
    }, []);

    useEffect(() => {
        newRelicWrapper.markRelease();
        newRelicWrapper.setUpUserVars(locale);

        window.addEventListener("trackingReady", () => {
            newRelicWrapper.setUpUserVars(locale);
        });
    }, [locale]);

    useEffect(() => {
        if (productDataLoadAttempted && segmentKey && !getQueryParams().noTrack) {
            retry(() => import("@vp/tracking"), { name: "import" }).then(TrackingJS =>
                TrackingJS.init(
                    segmentKey,
                    {
                        All: false,
                        "Adobe Analytics": true,
                        "Amazon EventBridge": true,
                        "Amazon Kinesis": true,
                        "Amazon Personalize": true,
                        "Google Analytics": true,
                        "Google Tag Manager": true,
                        Iterable: true,
                        Qualaroo: true,
                        Repeater: true,
                        "Segment.io": true,
                        "Tag Injector": true,
                        "VP Optimizely (Vistaprint)": true
                    },
                    "vistaprint",
                    locale,
                    undefined,
                    undefined,
                    true,
                    undefined,
                    getRootClassNames() // consentManagerCssClassNames
                    // The Consent Manager popup is rendered outside the body so needs the swan classes
                )
            );
        }
    }, [productDataLoadAttempted, locale, segmentKey]);

    useEffect(() => {
        window.addEventListener("error", e => {
            const { message, error } = e;
            debouncedReportRawErrorToSegment(error?.message || message, undefined, error?.stack);
        });
    }, []);

    /**
     * The legacy implementation of tracking did not support a way for base information which should come from runtime (e.g. the studio version)
     * to be sent along every request. With this state available, the trackingClient can forward runtime information from the state which helps
     * distinguish which dex is used. Moving forward we plan to use a class which better indicates an instance of tracking that exists per runtime.
     */
    useEffect(() => {
        dispatch(setTracking({ dexName }));
    }, [dispatch, dexName]);
}
