import type { DSS } from "@vp/types-ddif";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { StudioMetadataItem } from "@shared/utils/Metadata";
import type { Designer } from "../designer-suite/@types/designer";
import { isWordArt } from "./utils";

type StudioMetadataKey = keyof StudioMetadataItem;

// Adds or updates a property within the studioMetadata of an item
export function updateItemStudioMetadataProperty(
    item: CanvasItem,
    key: StudioMetadataKey,
    metadata: DSS.StudioMetadata
) {
    if (!item._itemViewModel.model.get("studioMetadata")) {
        item._itemViewModel.model.set("studioMetadata", {});
    }
    const metadataClone = { ...item._itemViewModel.model.get("studioMetadata") };
    metadataClone[key] = metadata;
    item._itemViewModel.model.set("studioMetadata", metadataClone);
}

// Returns the value of a property within an item's studioMetadata or null if none exist
export function getItemStudioMetadataProperty<T extends StudioMetadataKey>(
    item: CanvasItem,
    key: T
): StudioMetadataItem[T] {
    const studioMetadata = item._itemViewModel.model.get("studioMetadata");
    return studioMetadata && studioMetadata[key] ? studioMetadata[key] : null;
}

// Removes a metadata property from the studioMetadata of an item
export function removeItemStudioMetadataProperty(item: CanvasItem, key: StudioMetadataKey) {
    const metadataClone = { ...item._itemViewModel.model.get("studioMetadata") };
    delete metadataClone[key];
    item._itemViewModel.model.set("studioMetadata", metadataClone);
}

// Overwrites the entire `studioMetadata` property of an item with new metadata
export function setItemStudioMetadata(item: CanvasItem, metadata: StudioMetadataItem) {
    item._itemViewModel.model.set("studioMetadata", metadata);
}

function itemContainsStudioMetadata(item: any) {
    return item._itemViewModel.model.has("studioMetadata");
}

// Returns all `studioMetadata` that exist on all items across all canvases
export function getAllStudioMetadata(designer: Designer | undefined): Record<string, StudioMetadataItem> {
    const studioMetadata = {};
    if (designer) {
        designer.api.design.canvases.forEach(canvas => {
            canvas.items.filter(itemContainsStudioMetadata).forEach(item => {
                const itemId = item._itemViewModel.model.get("id");
                studioMetadata[itemId] = item._itemViewModel.model.get("studioMetadata");
            });
        });
    }
    return studioMetadata;
}

export function updatedTextAndWordArtFieldNumber(item: TextItem | WordArtItem, index: number) {
    const metadata = getItemStudioMetadataProperty(item, "display");
    if (!metadata || metadata.fieldNumber === undefined) {
        updateItemStudioMetadataProperty(item, "display", {
            fieldNumber: `${index}`
        });
        return true;
    }
    return false;
}

export function getTextAndWordArtFieldNumber(item: TextItem | WordArtItem) {
    const metadata = getItemStudioMetadataProperty(item, "display");
    const fieldNumber = metadata && metadata.fieldNumber ? metadata.fieldNumber : "";
    return fieldNumber;
}

export function getLargestTextFieldNumber(designer: Designer | undefined) {
    const indexes: number[] = [];
    if (designer) {
        designer.api.design.canvases.forEach(canvas => {
            canvas.items
                .filter(
                    item => (item.itemType === ItemTypes.TEXT || isWordArt(item)) && itemContainsStudioMetadata(item)
                )
                .forEach((item: TextItem | WordArtItem) => {
                    const textIndex = Number(getTextAndWordArtFieldNumber(item));
                    if (textIndex) indexes.push(textIndex);
                });
        });
    }
    return indexes.reduce((a, b) => Math.max(a, b), 0);
}
