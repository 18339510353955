import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showRulersSlice = createSlice({
    name: "showRulers",
    initialState: true,
    reducers: {
        setShowRulers: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowRulers } = showRulersSlice.actions;
export const showRulers = showRulersSlice.reducer;
