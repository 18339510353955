import { tryFetch } from "@shared/utils/Network";
import { getCountry } from "@shared/utils/i18n";
import { WorkEntity } from "../Work";
import { getItemInCart } from "./cartUtilities";

function getTenant(locale: string) {
    return `VP-${getCountry(locale)}-PROD`;
}

const host = VISTA_CART_URL;
const entityCode = 71;

export enum CartVersion {
    CART_V1 = 1,
    CART_V2 = 2
}

// These interfaces are incomplete, but contain everything Studio actually uses.
export interface CartItems {
    lineItems: LineItem[];
}

export interface LineItem {
    correlationId: string;
    designData: {
        editDocumentUrl: string;
    };
    customOrderData: {
        fulfillment: {
            workId: string;
        };
        analytics: {
            workId: string;
            product_id: string;
        };
        modData: {
            AccessoryRelationship?: {
                ParentWorkEntityId: string;
            };
        };
    };
    merchandisingData: {
        editOptionsUrl: string;
    };
    quantity: number;
    version: CartVersion;
}

function buildDocRefUrl(work: WorkEntity) {
    if (!work.design.docRefUrl) {
        // derived from how WATCH originally built a documentReferenceUrl
        // https://gitlab.com/vistaprint-org/design-technology/work-add-to-cart-handler/-/blob/master/src/Domain/Cart/CartLineItemBuilder.cs#L92
        const initialUrl = work.design.designUrl.replace(
            "uds.documents.cimpress.io/v0",
            "uds.documents.cimpress.io/v3"
        );
        const url = new URL(initialUrl);
        url.pathname = url.pathname.replace("/+", "/");
        url.pathname = url.pathname.endsWith("/") ? url.pathname : `${url.pathname}/docref`;
        return url.toString();
    }
    return work.design.docRefUrl;
}

export async function getItemsFromCart(authToken: string, ownerId: string, locale: string): Promise<CartItems> {
    const cartUrl = `${host}/tenants/${getTenant(locale)}/carts/${ownerId}`;

    return tryFetch({
        url: cartUrl,
        options: {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "vistaCartClient:getItemsFromCart",
        friendlyDescription: "get items from VistaCart",
        entityCode
    });
}

export async function callUpsertCart(
    authToken: string,
    locale: string,
    ownerId: string,
    work: WorkEntity,
    selectedProductOptions: Record<string, string>,
    mpvId: string | null
) {
    const existingCartItems = await getItemsFromCart(authToken, ownerId, locale);
    const existingItemInCart = getItemInCart(existingCartItems, work.workId);

    await upsertCartV2(
        authToken,
        locale,
        ownerId,
        work,
        existingItemInCart?.correlationId || work.workId,
        selectedProductOptions,
        mpvId,
        existingItemInCart
    );
}

async function upsertCartV2(
    authToken: string,
    locale: string,
    ownerId: string,
    work: WorkEntity,
    correlationId: string,
    selectedProductOptions: Record<string, string>,
    mpvId: string | null,
    existingItemInCart?: LineItem
) {
    const url = `${host}/tenants/${getTenant(
        locale
    )}/carts/${ownerId}/v2/items/correlationId/${correlationId}/?requestor=${REQUESTOR}`;

    const selectedAttributes = Object.entries(selectedProductOptions).map(([key, value]) => {
        return { key, value };
    });

    const editDocumentUrl = work.design.editUrl.replace(/\${workId}/, work.workId);

    const body = JSON.stringify({
        quantity: work.merchandising.quantity,
        product: {
            productKey: work.product.key,
            productVersion: work.product.version,
            selectedAttributes,
            editOptionsUrl: !existingItemInCart
                ? `/pdc/${locale}?workId=${work.workId}`
                : existingItemInCart?.merchandisingData.editOptionsUrl
        },
        design: {
            documentReferenceUrl: buildDocRefUrl(work),
            editDocumentUrl,
            livePreviewUrl: undefined // set to undefined to clear out existing preview url and let cart generate its own
        },
        customOrderData: {
            analytics: {
                // Required for a minimum edit in cart, which references this field rather than the designData
                workId: work.workId,
                workRevisionId: work.workRevisionId,
                product_id: mpvId, // mpvId required for x sell
                productKey: work.product.key
            },
            fullfillment: {
                workId: work.workId // required for envelopes
            }
        }
    });

    const content = await tryFetch({
        url,
        options: {
            method: "PUT",
            body,
            headers: {
                Authorization: `Bearer ${authToken}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        },
        moduleFunction: "vistaCartClient:upsertCart",
        friendlyDescription: "update cart",
        entityCode,
        retryCount: 0
    });

    return content;
}
