import { initI18n } from "@vp/i18n-helper";
// The need for these translations should go away once designer is retired
// eslint-disable-next-line import/no-restricted-paths
import { defaultENUSMessages } from "@easel";
import { isDevelopmentMode } from "@shared/utils/Debug";
import { windowExists, getQueryParams } from "@shared/utils/WebBrowser";
import defaultMessagesJSON from "./en-US.json";

function getTranslationsLocale(locale: string) {
    // if we are running locally or with studio_bundle_source, use local copy unless explicitly specified in a QP
    return getQueryParams().translationsLocale || getQueryParams().locale || (isDevelopmentMode() ? "local" : locale);
}

export function getCountry(locale: string) {
    return locale.split("-")[1].toUpperCase();
}

export function initializeI18n(locale: string, studioTranslations: any, easelTranslations: any) {
    const translationsLocale = getTranslationsLocale(locale).toLowerCase();
    // const useLocale = translationsLocale === "local" ? "en-us" : translationsLocale;
    const localeMessages =
        translationsLocale === "local"
            ? { ...defaultMessagesJSON, ...defaultENUSMessages }
            : // start with the default messages in order to provide fallbacks for en-us
              { ...defaultMessagesJSON, ...studioTranslations, ...easelTranslations };

    const setup = {
        defaultMessages: defaultMessagesJSON,
        projectName: "studio",
        translationsLocale,
        baseURL: "https://translations.design.vpsvc.com",
        setCultureOnBody: windowExists(),
        overrides: {
            debug: false
        },
        localeMessages
    };
    return initI18n(setup);
}
