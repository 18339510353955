import React, { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "@shared/redux";
import { useAbTestContext } from "../ABTesting";
import { photoHeavyProductKeyList } from "./utils";

type ContextData = {
    isQRCodeImageUploadABEnabled: boolean;
    isTestActive: boolean;
    trackImpression: () => void;
};

export const UploadQRABcontext = createContext<ContextData | undefined>(undefined);

enum Variations {
    Control = "control",
    Enabled = "test"
}

export const UploadQRABExperimentData: ExperimentData = {
    experimentKey: "qr_code_image_upload_v2",
    experimentName: "QR Code Image Upload",
    variations: Variations
};

type Props = {
    children: ReactNode | ReactNode[];
};

export const UploadQRABProvider = ({ children }: Props) => {
    const { Provider } = UploadQRABcontext;
    const ABTest = useAbTestContext();
    const { experimentKey } = UploadQRABExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [experimentalProduct, setExperimentalProduct] = useState(false);
    const [isTestActive, setIsTestActive] = useState(false);
    const { productKey } = useAppSelector(state => ({
        productKey: state.productKey
    }));

    useEffect(() => {
        if (productKey) {
            const isExperimentalProductKey = photoHeavyProductKeyList.filter(element => element === productKey).length;
            setExperimentalProduct(!!isExperimentalProductKey);
        }
    }, [productKey]);

    useEffect(() => {
        if (!experimentKey || !ABTest || !experimentalProduct) {
            return;
        }
        const { isExperimentActive, isExperimentUsingVariation } = ABTest;
        isExperimentActive(experimentKey).then((active: boolean) => {
            setIsTestActive(active);
        });

        const checkEnabledVariation = async () => {
            const res = await isExperimentUsingVariation(experimentKey, Variations.Enabled);
            setIsEnabled(!!res);
        };
        checkEnabledVariation();
    }, [experimentKey, ABTest, experimentalProduct]);

    const trackImpression = useCallback(() => {
        if (!experimentKey || !ABTest || !experimentalProduct) {
            return;
        }
        const { isExperimentUsingVariation, trackImpression: trackABImpression } = ABTest;
        const trackImpressionIfInVariant = async (variation: Variations) => {
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res) {
                trackABImpression(experimentKey, variation);
            }
        };
        trackImpressionIfInVariant(Variations.Enabled);
        trackImpressionIfInVariant(Variations.Control);
    }, [ABTest, experimentKey, experimentalProduct]);

    const contextObject = useMemo(
        () => ({
            isQRCodeImageUploadABEnabled: isEnabled,
            isTestActive,
            trackImpression
        }),
        [isEnabled, isTestActive, trackImpression]
    );
    return <Provider value={contextObject}>{children}</Provider>;
};

UploadQRABProvider.displayName = "UploadQRABProvider";
