import type { DecoTechFontFamilies } from "../FontTypes";
import { embroideryFontFamilyOverride } from "./FontFamilyOverrideConfig";

export const FONT_REPOSITORY_URL =
    "https://fonts.documents.cimpress.io/v1/repositories/0a3b1fba-8fa6-43e1-8c3e-a018a5eb8150/published";

export const DEFAULT_FONT_FAMILY = Object.freeze({
    print: "Arimo",
    embroidery: "Arial"
});

export function getFontNames() {
    return {
        print: [
            "Abel",
            "Adamina",
            "Adelle",
            "Alex Brush",
            "Amazone BT",
            "AnnabelleJF",
            "Antic Slab",
            "Apertura Web",
            "Archivo Narrow",
            "Arial",
            "Arimo",
            "Armitage",
            "Athelas Rg",
            "Balloon Bd BT",
            "BankGothic Md BT",
            "Baskerville BT",
            "BatangChe",
            "BeignetJF",
            "Benchnine",
            "BernhardMod BT",
            "Bevan",
            "BookmanJF",
            "BoxerScriptJF",
            "Bree",
            "Bree Serif",
            "BrushScript BT",
            "BuenaParkJF",
            "Buenard",
            "CabernetJF",
            "Calla Web Titling",
            "CapitalPoster",
            "Carrois Gothic",
            "Catamaran",
            "Caveat",
            "Comic Sans MS",
            "ComicPro JY",
            "CopprplGoth BT",
            "Coquette",
            "CornerStoreJF",
            "Courier New",
            "Crete",
            "Cuisine",
            "Damion",
            "DandelionJF",
            "De Soto",
            "De Soto Engraved",
            "DebonairJF",
            "Didact Gothic",
            "DomCasual BT",
            "Dotum",
            "DotumChe",
            "EB Garamond",
            "EloquentJF",
            "EmpyreanJF",
            "English157 BT",
            "Euphoria Script",
            "FangSong_GB2312",
            "FenwayParkJF",
            "Fjalla One",
            "Futura Bk BT",
            "FZShuTi",
            "FZYaoTi",
            "Georgia",
            "Gochi Hand",
            "GoldenStateJF",
            "GoudyOlSt BT",
            "Grand Hotel",
            "Grandma",
            "Gruppo",
            "Gulim",
            "GulimChe",
            "Gungsuh",
            "GungsuhChe",
            "Handlee",
            "HellenicWideJF",
            "Hind",
            "HolidayTimesJF",
            "HooliganJF",
            "HucklebuckJF",
            "Humanst521 BT",
            "Impact",
            "Integrity JY Lining 2",
            "JohnAndrewJF",
            "KaiTi_GB2312",
            // "Kandal",
            "Kandal Black",
            "Kennedy Sm Caps Book GD",
            "KonTikiEnchantedJF",
            "LFT Etica",
            "LiSu",
            "Lobster Two",
            "LuxRoyaleJF",
            "Mallanna",
            "MaryHelenJF",
            "Melanie BT",
            "MingLiU",
            "Missy BT",
            "MisterEarl BT",
            "Mostra Nuova",
            "MS Gothic",
            "MS Mincho",
            "MS PGothic",
            "MS PMincho",
            "Nixie One",
            "NSimSun",
            "Open Sans",
            "Parisienne",
            "Petit Formal Script",
            "Pill Web 300mg",
            "PMingLiU",
            "Poiret One",
            "Politica",
            "Poppins",
            "Proxima Nova Black",
            "Proxima Nova Rg",
            "Quattrocento",
            "Quicksand",
            "Raleway",
            "RandolphJF",
            "Refrigerator Deluxe",
            "Roboto Serif",
            "Roboto Serif Light",
            "Roboto Slab",
            "Roger",
            "Ronnia Cond",
            "Roundhand BT",
            "Sacramento",
            "Sail",
            "Satisfy",
            "ScriptoramaJF Hostess",
            "SimHei",
            "SimSun",
            "Slabo 27Px",
            "Sofia",
            "SouthlandJF",
            "STCaiyun",
            "Stencil BT",
            "StephanieMarieJF",
            "Stint Ultra Expanded",
            "STXihei",
            "STXingkai",
            "STXinwei",
            "STZhongsong",
            "Suranna",
            "Swis721 BT",
            "Tahoma",
            "Times New Roman",
            "Trebuchet MS",
            "VAGRounded BT",
            "ValentinaJoyJF",
            "VarsityScriptJF",
            "Verdana",
            "WesleyJF",
            "Wingdings",
            "Zag"
        ],
        embroidery: Object.keys(embroideryFontFamilyOverride)
    };
}

export function getFontCategories(): DecoTechFontFamilies {
    return {
        print: [
            {
                name: "All Fonts",
                fontFamilies: getFontNames().print
            },
            {
                name: "Traditional",
                fontFamilies: [
                    "Adamina",
                    "Adelle",
                    "Alegreya",
                    "Alegreya SC",
                    "Antic",
                    "Antic Slab",
                    "Arapey",
                    "Arimo",
                    "Athelas Rg",
                    "Autobahn",
                    "Baskerville BT",
                    "Bellefair",
                    "BernhardMod BT",
                    "Bevan",
                    "Bitter Pro",
                    "BookmanJF",
                    "Borges Titulo CE Blanca",
                    "Bree Serif",
                    "Buenard",
                    "C4 v.4",
                    "CAT Altgotisch",
                    "CabernetJF",
                    "Caladea",
                    "Calla Web Titling",
                    "Carlito",
                    "Carrois Gothic",
                    "Chomsky",
                    "ChunkFive",
                    "Cinzel",
                    "Cinzel Decorative",
                    "CopprplGoth BT",
                    "Cormorant",
                    "Cormorant Garamond",
                    "Cormorant Infant",
                    "Cormorant SC",
                    "Courier New",
                    "Crete",
                    "Crete Round",
                    "Crimson Pro",
                    "De Soto",
                    "Didactic",
                    "EB Garamond",
                    "EloquentJF",
                    "Empyrean",
                    "EmpyreanJF",
                    "Fanwood Text",
                    "Faustina",
                    "Fondamento",
                    "Forum",
                    "GFS Galatea",
                    "GFS Orpheus",
                    "Gelasio",
                    "Georgia",
                    "Gilda Display",
                    "Goldenbook",
                    "GoudyOlSt BT",
                    "Helgoland",
                    "Humanst521 BT",
                    "JohnAndrewJF",
                    "Kandal Black",
                    "Kennedy Sm Caps Book GD",
                    "Linden Hill",
                    "Mate",
                    "Mate SC",
                    "Medio",
                    "Messapia",
                    "Newsreader 16pt",
                    "Nixie One",
                    "Overlock",
                    "Pirata One",
                    "Playfair Display",
                    "Playfair Display SC",
                    "QT Basker",
                    "QT Bookmann",
                    "QT Caslan",
                    "QT CaslanOpen",
                    "QT Graveure",
                    "QT HeidelbergType",
                    "QT Korrin",
                    "QT Linoscroll",
                    "QT OldGoudy",
                    "QT SchoolCentury",
                    "Quattrocento",
                    "Quattrocento Sans",
                    "Quintessential",
                    "Roboto Serif",
                    "Roboto Serif Light",
                    "Roboto Slab",
                    "Sarabun",
                    "Slabo 27Px",
                    "Stint Ultra Expanded",
                    "Suranna",
                    "TeXGyre Cursor",
                    "TeXGyre Heros",
                    "TeXGyre Heros Cn",
                    "TeXGyre Termes",
                    "Times New Roman",
                    "Viaoda Libre",
                    "YoungSerif",
                    "Zilla Slab"
                ]
            },
            {
                name: "Modern",
                fontFamilies: [
                    "Abel",
                    "Abril Fatface",
                    "Adelle",
                    "Advent Pro",
                    "Albert Sans",
                    "Alumni Sans",
                    "Antic Slab",
                    "Apertura Web",
                    "Archivo",
                    "Archivo Narrow",
                    "Arimo",
                    "Armitage",
                    "Barlow",
                    "Barlow Semi Condensed",
                    "Basically A Mono",
                    "Basically A Sans Serif",
                    "Be Vietnam Pro",
                    "Benchnine",
                    "BioRhyme",
                    "BioRhyme Expanded",
                    "Blazma",
                    "BravoRG",
                    "BravoSC",
                    "Bree",
                    "Carrois Gothic",
                    "Catamaran",
                    "Celebes",
                    "Cooper Hewitt",
                    "Courier New",
                    "Creato Display",
                    "Crete",
                    "D-DIN-PRO",
                    "DejaVu Sans",
                    "DejaVu Sans Condensed",
                    "DejaVu Sans Mono",
                    "DejaVu Serif",
                    "DejaVu Serif Condensed",
                    "Didact Gothic",
                    "Dosis",
                    "Fira Sans",
                    "Fira Sans Condensed",
                    "Fira Sans Extra Condensed",
                    "Fjalla One",
                    "Fugaz One",
                    "Futura Bk BT",
                    "GRADUATE",
                    "Godia SemiCondensed",
                    "Gothic A1",
                    "Gruppo",
                    "Hepta Slab",
                    "Human Sans",
                    "Humanst521 BT",
                    "IBM Plex Sans",
                    "IBM Plex Sans Condensed",
                    "IBM Plex Serif",
                    "Ikra Slab",
                    "Impact",
                    "Italiana",
                    "Josefin Sans",
                    "Jost",
                    "Karantina",
                    "Kumbh Sans",
                    "LFT Etica",
                    "Lato",
                    // eslint-disable-next-line max-len
                    // "Lavoir", it wasn't added because of the kerning issues slack thread https://vistaprint.slack.com/archives/C019L6KGWVA/p1658757247246029?thread_ts=1650989655.347729&cid=C019L6KGWVA
                    "League Gothic",
                    "League Gothic Condensed",
                    "League Gothic Condensed Italic",
                    "League Gothic Italic",
                    "League Spartan",
                    "Lexend",
                    "Lexend Deca",
                    "Libre Bodoni",
                    "Lumberjack",
                    "Lumberjack Inline Rough",
                    "Lumberjack Rough",
                    "Mallanna",
                    "Montserrat",
                    "Mostra Nuova",
                    "Nacelle",
                    "Neumann",
                    "Nixie One",
                    "Nunito",
                    "Nunito Sans",
                    "Open Sans",
                    "Oranienbaum",
                    "Outfit",
                    "Picaflor 12 pt",
                    "Picaflor 16 pt",
                    "Picaflor 24 pt",
                    "Picaflor 36 pt",
                    "Picaflor 48 pt",
                    "Picaflor 72 pt",
                    "Picaflor 96 pt",
                    "Pill Web 300mg",
                    "Poiret One",
                    "Politica",
                    "Poppins",
                    "Praho Black",
                    "Prata",
                    "Prodigy Sans",
                    "Proxima Nova Rg",
                    "QT BodiniPoster",
                    "QT Frank",
                    "QT FrankHeavy",
                    "QT VagaRound",
                    "Quicksand",
                    "Raleway",
                    "Readex Pro",
                    "Red Hat Text",
                    "Refrigerator Deluxe",
                    "Roboto",
                    "Roboto Condensed",
                    "Roboto Serif",
                    "Roboto Slab",
                    "Rondal",
                    "Ronnia Cond",
                    // "Science Gothic ",  Fonts not loading, investigate DT-11611
                    "Secuela",
                    "SilverThreadJF",
                    "Slabo 27Px",
                    "Solway",
                    "Spicy Rice",
                    "Stint Ultra Expanded",
                    "Suranna",
                    "Swis721 BT",
                    "Syne",
                    "Syne Extra",
                    "Syne Mono",
                    "Tahoma",
                    "Teko",
                    "Trebuchet MS",
                    "URW Gothic",
                    "Verdana",
                    "WesleyJF",
                    "Zag",
                    "cuyabra"
                ]
            },
            {
                name: "Script",
                fontFamilies: [
                    "AcroterionJF",
                    "AdageScriptJF",
                    "Alex Brush",
                    "Allison",
                    "Allura",
                    "Amazone BT",
                    "AndantinoJF",
                    "AnnabelleJF",
                    "BeignetJF",
                    "Biographer",
                    "Birthstone Bounce",
                    "BoxerScriptJF",
                    "Bravissima Script",
                    "BrushScript BT",
                    "Calligraph421 BT",
                    "Carattere",
                    "Caveat",
                    "Chaumont_script Beta",
                    "Cookie",
                    "Corinthia",
                    "CornerStoreJF",
                    "Cuisine",
                    "Damion",
                    "DebonairJF",
                    "DulcimerJF",
                    "Dynalight",
                    "English157 BT",
                    "Ephesis",
                    "Espresso",
                    "Euphoria Script",
                    "FenwayParkJF",
                    "Grand Hotel",
                    "Great Vibes",
                    "Gwendolyn",
                    "Handlee",
                    "Havana",
                    "HucklebuckJF",
                    "Ingrid Darling",
                    "JeffrianaJF",
                    "JohannaWhimsyJF",
                    "KonTikiEnchantedJF",
                    "Lily Script One",
                    "Lobster Two",
                    "LuxRoyaleJF",
                    "MartiniJF",
                    "MaryHelenJF",
                    "Mea Culpa",
                    "MonteCarlo",
                    "Mr Dafoe",
                    "Oleo Script",
                    "Oleo Script Swash Caps",
                    "Pacifico",
                    "Parisienne",
                    "Petit Formal Script",
                    "Pinyon Script",
                    "Playball",
                    "Praise",
                    "QT BrushStroke",
                    "QT Linostroke",
                    "Roundhand BT",
                    "Sacramento",
                    "Sail",
                    "Sarina",
                    "Satisfy",
                    "ScriptoramaJF Hostess",
                    "Shalimar",
                    "Smooch",
                    "Sofia",
                    "Stalemate",
                    "StellaAnnJF",
                    "StephanieMarieJF",
                    "Style Script",
                    "TypoUpright BT",
                    "ValentinaJoyJF",
                    "VarsityScriptJF",
                    "WindSong",
                    "Yesteryear"
                ]
            },
            {
                name: "Playful",
                fontFamilies: [
                    "Adlib BT",
                    "Amaranth",
                    "Amatic SC",
                    "BaileywickJF Curly",
                    "Beatnik",
                    "Big Limbo BT",
                    "Boogaloo",
                    "Bowlby One",
                    "Bubblegum Sans",
                    "CAT Rhythmus",
                    "Caveat",
                    "Caveat Brush",
                    "Chelsea Market",
                    "Chewy",
                    "Chicle",
                    "Comic Neue",
                    "Comic Sans MS",
                    "ComicPro JY",
                    "Corben",
                    "CousinBethJF",
                    "DandelionJF",
                    "DomCasual BT",
                    "FairyTaleJF",
                    "FanfareJF",
                    "Feronia",
                    "Fruktur",
                    "FunnyBoneJF",
                    "FuzzGuitarJF",
                    "Gochi Hand",
                    "GoldenStateJF",
                    "Grandstander",
                    "Griffy",
                    "GuedelScriptJF",
                    "Handlee",
                    "Henny Penny",
                    "Hitch Hike",
                    "HolidayTimesJF",
                    "HooliganJF",
                    "HorrorFlickJF",
                    "Irish Grover",
                    "JohannaWhimsyJF",
                    "Joti One",
                    "Just Another Hand",
                    "Knewave",
                    "Kolker Brush",
                    "Lemon Tuesday",
                    "Life Savers",
                    "Lobster",
                    "Lobster Two",
                    "Londrina Solid",
                    "Love Ya Like A Sister",
                    "MagicSpellJF",
                    "Mammut CAT",
                    "Melanie BT",
                    "Missy BT",
                    "MisterEarl BT",
                    "Montez",
                    "Moon Dance",
                    "Mrs Sheppards",
                    "Mystery Quest",
                    "OpenDyslexic",
                    "OpenDyslexicAlta",
                    "OpenDyslexicMono",
                    "Pecita",
                    "QT Blimpo",
                    "QT Casual",
                    "QT Kooper",
                    "Ranchers",
                    "Rancho",
                    "Ribes Black",
                    "Risque",
                    "Roger",
                    "SaharanJF",
                    "Shadows Into Light",
                    "Shrikhand",
                    // "Sue Ellen Francisco ",  Fonts not loading, investigate DT-11611
                    "Sunshiney",
                    "Trade Winds",
                    "TroubleSide",
                    "Truculenta",
                    "Truculenta Bold",
                    "Truculenta Dirty",
                    "Truculenta Thin",
                    "Twinkle Star",
                    "VAGRounded BT"
                ]
            },
            {
                name: "Decorative",
                fontFamilies: [
                    "AlpengeistJF",
                    "BankGothic Md BT",
                    "BaroqueTextJF",
                    "Bellota",
                    "Bevan",
                    "Blackout",
                    "Blackout 2AM",
                    "Blackout Midnight",
                    "Blackout Sunrise",
                    "Borges Titulo CE Hueca",
                    "BuenaParkJF",
                    "CapitalPoster",
                    "Coquette",
                    "Coupeur Jack",
                    "Creepster",
                    "De Soto Engraved",
                    "EloquentJF",
                    "Engraving CC",
                    "Engraving Shaded CC",
                    "Engraving Unshaded CC",
                    "Germania One",
                    "Grandma",
                    "HandelGothic BT",
                    "HellenicWideJF",
                    "HooliganJF",
                    "Kandal Black",
                    "KugelhopfJF",
                    "LedenbachJF",
                    "Parisian BT",
                    "Playbill BT",
                    "QT Impromptu",
                    "QT Jupiter",
                    "QT Military",
                    "QT OKCorral",
                    "QT OKCorral-Cnd",
                    "QT OKCorral-Ext",
                    "RandolphJF",
                    "Rye",
                    "Sail",
                    "Sancreek",
                    "Serreria Extravagante",
                    "Serreria Sobria",
                    "Skandal",
                    "Smokum",
                    "SouthlandJF",
                    "Stardos Stencil",
                    "Stencil BT",
                    "Stint Ultra Expanded",
                    "TypoUpright BT",
                    "WalcottGothicHollywoodJF",
                    "WeddingText BT"
                ]
            }
        ],
        embroidery: [
            {
                name: "All Fonts",
                fontFamilies: getFontNames().embroidery,
                fontFamilyOverrides: embroideryFontFamilyOverride
            }
        ]
    };
}
