import { createSlice } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const productDataLoadAttemptedSlice = createSlice({
    name: "productDataLoadAttempted",
    initialState: false,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            if (action.payload.productDataLoadAttempted === undefined) {
                return state;
            }
            return action.payload.productDataLoadAttempted;
        });
    }
});

export const productDataLoadAttempted = productDataLoadAttemptedSlice.reducer;
