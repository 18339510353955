import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductGroupConfiguration } from "@shared/utils/StudioConfiguration";
import { setDefaultState } from "../compoundActions";

const productGroupConfigurationSlice = createSlice({
    name: "productGroupConfiguration",
    initialState: {} as ProductGroupConfiguration,
    reducers: {
        setProductGroupConfiguration: (state, action: PayloadAction<any>) => action.payload || state
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => action.payload.productGroupConfiguration || state);
    }
});

export const { setProductGroupConfiguration } = productGroupConfigurationSlice.actions;
export const productGroupConfiguration = productGroupConfigurationSlice.reducer;
