import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface StatusMessage {
    key: string;
    statusMessage: string;
}

const statusSlice = createSlice({
    name: "status",
    initialState: [] as StatusMessage[],
    reducers: {
        setStatus: (state, action: PayloadAction<{ messages: StatusMessage[] }>) => [
            ...state,
            ...action.payload.messages
        ],
        resetStatus: () => []
    }
});

export const { setStatus, resetStatus } = statusSlice.actions;
export const status = statusSlice.reducer;
