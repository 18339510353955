import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const productNameSlice = createSlice({
    name: "productName",
    initialState: "",
    reducers: {
        setProductName(state, action: PayloadAction<string>) {
            if (action.payload === undefined) {
                return state;
            }
            return action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            if (action.payload.productName === undefined) {
                return state;
            }
            return action.payload.productName || "";
        });
    }
});

export const { setProductName } = productNameSlice.actions;
export const productName = productNameSlice.reducer;
