export const standardPaletteColors = [
    // grey
    "#FFFFFF",
    "#AAAAAA",
    "#555555",
    "#000000",
    // blue
    "#B1E2F3",
    "#66C6E8",
    "#1CAADE",
    "#127194",
    // green
    "#C2F0BC",
    "#87E27B",
    "#4CD43B",
    "#328E27",
    // yellow
    "#FEF2B8",
    "#FDE674",
    "#FDDB31",
    "#A99220",
    // orange
    "#FBD5A8",
    "#F8AC54",
    "#F58400",
    "#A45800",
    // red
    "#FBBCB7",
    "#F87B72",
    "#F63A2E",
    "#A4261E",
    // purple
    "#E6A9F0",
    "#CF56E2",
    "#B804D5",
    "#7B028E"
];

// this list is the data from this url: https://embroidery.documents.cimpress.io/standard-palette
export const standardPaletteColorsForEmbroidery = [
    "thread(mcp0015)", // #FFFFFF
    "thread(mcp0108)", // #7F878B
    "thread(mcp0132)", // #4A555D
    "thread(mcp0020)", // #282E2E
    "thread(mcp0145)", // #C5D3DA
    "thread(mcp3241)", // #8C91B1
    "thread(mcp2830)", // #9377B1

    "thread(mcp2905)", // #604D99
    "thread(mcp3110)", // #383574
    "thread(mcp3962)", // #A7DBF2
    "thread(mcp3640)", // #99BDE8
    "thread(mcp4111)", // #43B5CD
    "thread(mcp3902)", // #0B72A7
    "thread(mcp3743)", // #2F506B

    "thread(mcp3544)", // #174D95
    "thread(mcp3323)", // #263663
    "thread(mcp0520)", // #F4E79F
    "thread(mcp0600)", // #FAD815
    "thread(mcp0703)", // #FDB500
    "thread(mcp1102)", // #ED9206
    "thread(mcp1300)", // #F66B29

    "thread(mcp2113)", // #6C2431
    "thread(mcp1904)", // #AF2529
    "thread(mcp2363)", // #F7DEEA
    "thread(mcp2550)", // #E49CC4
    "thread(mcp2153)", // #C68494
    "thread(mcp1950)", // #E34D79
    "thread(mcp2508)", // #BC3F88

    "thread(mcp2300)", // #B92860
    "thread(mcp5050)", // #B8E4DD
    "thread(mcp5220)", // #93D7B9
    "thread(mcp4610)", // #3EA19C
    "thread(mcp5500)", // #7CE553
    "thread(mcp5510)", // #4BA044
    "thread(mcp5324)", // #245E41

    "thread(mcp6051)", // #BFCF93
    "thread(mcp6031)", // #C9D000
    "thread(mcp1060)", // #F1DABB
    "thread(mcp1141)", // #CEA984
    "thread(mcp0721)", // #B38533
    "thread(mcp0931)", // #B06A23
    "thread(mcp0933)", // #6D4A2F

    "thread(mcp0747)", // #655330
    "thread(mcp0672)", // #C3BDA7
    "thread(mcp1172)", // #CFBFA0
    "thread(mcp0853)", // #977F5E
    "thread(mcp6133)", // #887A31
    "thread(mcp0776)" // #776F62
];
