import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CompatibleColorsWithStockInfo = {
    substrateColorsWithStockInfo?: {
        [key: string]: {
            primaryColor: string;
            secondaryColor: string;
            title: string;
            disabled: boolean;
        };
    };
    substrateColorOptionName?: string;
};

const initialState: CompatibleColorsWithStockInfo = {};

const compatibleColorsWithStockInfoSlice = createSlice({
    name: "compatibleColorsWithStockInfo",
    initialState,
    reducers: {
        setCompatibleColorsWithStockInfo: (state, action: PayloadAction<CompatibleColorsWithStockInfo>) =>
            action.payload
    }
});

export const { setCompatibleColorsWithStockInfo } = compatibleColorsWithStockInfoSlice.actions;
export const compatibleColorsWithStockInfo = compatibleColorsWithStockInfoSlice.reducer;
