import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showGridlinesSlice = createSlice({
    name: "showGridlines",
    initialState: false,
    reducers: {
        setShowGridlines: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowGridlines } = showGridlinesSlice.actions;
export const showGridlines = showGridlinesSlice.reducer;
