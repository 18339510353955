import React, { useState } from "react";
import { withPrefix } from "gatsby";
import { useAppSelector } from "@shared/redux";
import { getQueryParams } from "@shared/utils/WebBrowser";
import { Helmet } from "react-helmet";

export function Studio5HeadCollection() {
    const productDataLoadSuccessful = useAppSelector(state => state.productDataLoadSuccessful);
    const [documentComplete, setDocumentComplete] = useState(false);

    if (typeof window !== "undefined" && typeof document !== "undefined" && !documentComplete) {
        if (document.readyState === "complete") {
            setDocumentComplete(true);
        } else {
            window.addEventListener("load", () => {
                setDocumentComplete(true);
            });
        }
    }

    const shouldLoadDesigner = documentComplete || productDataLoadSuccessful;

    return (
        <Helmet>
            {productDataLoadSuccessful && (
                <link rel="preconnect" href="https://eventapi.designer.cimpress.io" crossOrigin="true" />
            )}

            <link rel="preload" as="style" href={ASSET_PREFIX + withPrefix("/designer.5.10.0.css")} />
            {shouldLoadDesigner && (
                <link key="designerCSS" rel="stylesheet" href={ASSET_PREFIX + withPrefix("/designer.5.10.0.css")} />
            )}

            {shouldLoadDesigner && (
                <script
                    defer
                    key="jquery"
                    src={ASSET_PREFIX + withPrefix("/jquery-3.6.0.min.js")}
                    crossOrigin="anonymous"
                ></script>
            )}
            {shouldLoadDesigner && (
                <script
                    defer
                    src={getQueryParams().designerUrl || ASSET_PREFIX + withPrefix("/designer.5.10.0.min.js")}
                />
            )}
        </Helmet>
    );
}

Studio5HeadCollection.displayName = "Studio5HeadContent";
