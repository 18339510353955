export enum ItemReferenceTypes {
    WORD_ART = "Word Art",
    TABLE = "Table",
    QR_CODE = "QR Code",
    CALENDAR_GRID = "Calendar Grid",
    TEAMS_NAME = "Teams Name",
    TILE_IMAGE = "panel" // used for patterns
}

export enum DecorationTechnology {
    None = "none",
    Offset = "offset",
    Digital = "digital",
    OffsetOrDigital = "offsetOrDigital",
    DirectToGarment = "directToGarment",
    HeatTransfer = "heatTransfer",
    LaserEngraving = "laserEngraving",
    ScreenPrint = "screenPrint",
    PadPrint = "padPrint",
    InkJet = "inkJet",
    Thermography = "thermography",
    EmbroideryFlat = "embroideryFlat",
    EmbroideryCylinder = "embroideryCylinder",
    Sublimation = "sublimation",
    Unknown = "unknown"
}

/**
 *   In designer these 'constants' are used both upper and lowercase, but for fonts
 *   they are used lowercase so that is the method I'm using here, as they have to match
 *   object keys when defining font categories and such
 */
export enum DecorationTechnologiesSimple {
    PRINT = "print",
    EMBROIDERY = "embroidery"
}

enum DecoTechCategory {
    print = "print",
    pantone = "pantone",
    engraved = "engraved",
    embroidery = "embroidery"
}

export const decoTechCategories = {
    [DecorationTechnology.Offset]: DecoTechCategory.print,
    [DecorationTechnology.Digital]: DecoTechCategory.print,
    [DecorationTechnology.OffsetOrDigital]: DecoTechCategory.print,
    [DecorationTechnology.DirectToGarment]: DecoTechCategory.print,
    [DecorationTechnology.HeatTransfer]: DecoTechCategory.print,
    [DecorationTechnology.LaserEngraving]: DecoTechCategory.engraved,
    [DecorationTechnology.ScreenPrint]: DecoTechCategory.pantone,
    [DecorationTechnology.PadPrint]: DecoTechCategory.pantone,
    [DecorationTechnology.InkJet]: DecoTechCategory.print,
    [DecorationTechnology.Thermography]: DecoTechCategory.print,
    [DecorationTechnology.EmbroideryFlat]: DecoTechCategory.embroidery,
    [DecorationTechnology.EmbroideryCylinder]: DecoTechCategory.embroidery,
    [DecorationTechnology.Sublimation]: DecoTechCategory.print
};
