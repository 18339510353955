import React from "react";
import { Box, Typography } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useAppSelector } from "@shared/redux";
import { LoadingSpinner, SpinnerVariant } from "../StudioChrome";
import * as styles from "./FullScreenLoader.module.scss";

const messages = defineMessages({
    loading: {
        id: "shared.features.loading.fullscreenloader.loading",
        defaultMessage: "Loading...",
        description: {
            note: "Text used for the aria-label of the full screen loading spinner when no loading message is provided"
        }
    }
});

export function FullScreenLoader() {
    const { t } = useTranslationSSR();
    const isLoading = useAppSelector(state => state.isLoading);
    const loadingMessage = useAppSelector(state => state.loadingMessage);

    if (!isLoading) {
        return null;
    }

    return (
        <div className={styles.container}>
            <Box className={styles.loader} padding="4">
                <LoadingSpinner
                    variant={SpinnerVariant.Large}
                    aria-label={!loadingMessage ? t(messages.loading.id) : undefined}
                    aria-labelledby={loadingMessage ? "loading-overlay-loading-message" : undefined}
                    centering={true}
                    data-testid="fullScreenLoader"
                />
                {loadingMessage && (
                    <Typography
                        id="loading-overlay-loading-message"
                        marginTop="4"
                        fontSize="-1"
                        textAlign="center"
                        fontWeight="normal"
                    >
                        {loadingMessage}
                    </Typography>
                )}
            </Box>
        </div>
    );
}

FullScreenLoader.displayName = "FullScreenLoader";
