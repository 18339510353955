import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const templateSlice = createSlice({
    name: "template",
    initialState: null as string | null,
    reducers: {
        setTemplate(state, action: PayloadAction<string | null>) {
            return action.payload || null;
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => action.payload.template || null);
    }
});

export const { setTemplate } = templateSlice.actions;
export const template = templateSlice.reducer;
