export const FontStyle = {
    NORMAL: "Normal",
    BOLD: "Bold",
    ITALIC: "Italic"
};

export const SCROLLBAR_WIDTH = 18;

export const SHAPE_SIZE_FACTOR = 0.21;

export const ITEM_ROTATE_HEIGHT = 36;

export const EASEL_EVENTS = {
    EMBROIDERY_UPLOAD_OPEN: "modal:embroideryUploadWarning",
    REACT_CROPPER_OPEN: "modal:reactCropper",
    DELETED_CANVAS_ITEM: "canvas:item:deleted"
};

export const DEFAULT_SHAPE_COLOR = "#000000"; // Remember to change the colors in shapespanel.scss in studio
export const DEFAULT_MIN_DISTANCE_TO_ITEMS = 12;
