import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const hasDesignsSlice = createSlice({
    name: "hasDesigns",
    initialState: false as boolean,
    reducers: {
        setHasDesigns: (state, action: PayloadAction<boolean>) => action.payload
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => action.payload.hasDesigns ?? false);
    }
});

export const { setHasDesigns } = hasDesignsSlice.actions;
export const hasDesigns = hasDesignsSlice.reducer;
