import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const isProductSwitchedSlice = createSlice({
    name: "isProductSwitched",
    initialState: false,
    reducers: {
        setIsProductSwitched: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setIsProductSwitched } = isProductSwitchedSlice.actions;
export const isProductSwitched = isProductSwitchedSlice.reducer;
