/* eslint-disable max-len */

// English (American) translations (pulled directly from designer)
// https://opengrok.cimpress.io/source/xref/DocDesign/designexperience/cimpress-designer/app/publicApi/configuration/localization/en.js?r=beb05500
module.exports = {
    translation: {
        text: {
            defaultPlaceholder: "Type text here"
        },
        views: {
            dropTargetText: "Drop here to place item."
        },
        modals: {
            imageModification: {
                processingMessage: "Please wait while we process your image",
                failureMessage:
                    "There was an error while processing your image. No changes have been made to your design.",

                embroidery: {
                    colorModificationHeader: "Available Colors",
                    modalHeader: "Embroidery Colors"
                },
                segmentation: {
                    modalHeader: "Image Modifications",
                    actionButtons: {
                        printColors: "Print Colors",
                        invertColors: "Invert Colors",
                        removeBackground: "Remove Background",
                        removeWhite: "Remove all White"
                    }
                },
                thresholding: {
                    sliderHeader: "Details"
                }
            },
            crop: {
                modalHeader: "Crop Image",
                proportionsConstrainedCheckbox: "Lock crop box to maintain proportions"
            },
            sharpen: {
                modalHeader: "Sharpen Image",
                sharpenedTitle: "Sharpened",
                originalTitle: "Original",
                revertButton: "Keep Original"
            },
            restriction: {
                attributeModalHeader: "Attributes",
                propertiesModalHeader: "Properties",
                restrictionHeader: "Restrictions to item.",
                restrictionSubHeader:
                    "Restrict users from editing this selected item in their design. Specify if there any exceptions, such as being able to replace the text, or sharpen an image.",
                templateFieldHeader: "Modify template text field properties.",
                templateImageHeader: "Modify template placeholder image properties.",
                templatePlaceholderTextHeader: "Update the placeholder text for this text field to a custom value.",
                templateLabelHeader:
                    "Change the text label for this text field that will be displayed in the context panel, when this template will be loaded.",
                templateImageLabelHeader: "Change the image label for this image field.",
                templateRestrictionHeader:
                    "Restrict the users from making any edits to this field, when the template is loaded",
                templateRequireHeader: "This requires a user to enter a value for this field.",
                textOptionsHeader: "Convert this text field into a dropdown menu, with custom options.",
                selectedCountText: "selected",
                exceptionsLink: "Allow some editing exceptions",
                applyButton: "Apply Changes",
                unlockContentHeader:
                    "This allows the user to replace the text in this text box, but does not allow them to do anything else.",
                unlockFontSizeHeader:
                    "This allows the user to change the font size of this text, but does not allow them to do anything else.",
                unlockCropHeader:
                    "This allows the user to crop this image, but does not allow them to do anything else.",
                unlockSharpenHeader:
                    "This allows the user to sharp this image, but does not allow them to do anything else.",
                dropdownOptionPlaceholder: "Enter option text here",
                labelPlaceholder: "Enter text field label here",
                imageLabelPlaceholder: "Enter image label here",
                textPlaceholder: "Enter default placeholder text",
                requireImage: "Require a user to replace the placeholder image."
            },
            premiumFinish: {
                title: "Premium Finishes",
                info: "The product you are designing supports special finishes. Select an option below to apply a finish to parts of your image.",
                useArtworkAsMaskButton: "Use artwork as the mask",
                useAsIsButton: "Use artwork as is",
                uploadMaskButton: "Upload a mask"
            }
        },
        widgets: {
            addButtonTextField: "Add New Text",
            addPlaceholderImage: "Add Placeholder Image",
            addRestrictions: "Restrict",
            uploadFileButton: "Upload Image",
            cancelButton: "Cancel",
            saveDocumentButton: "Save",
            documentSaving: "Saving",
            documentSaved: "Saved",
            saveFailed: "Not Saved",
            previewDocumentButton: "Preview",
            addArtworkButton: "Add artwork",
            autoPlaceArtworkText: "Auto-place artwork",
            dismissButton: "Dismiss",
            closeButton: "Close",
            imageUploading: "Uploading...",
            imageProcessing: "Processing...",
            addShapesHeader: "Shapes",
            zoomButtons: {
                in: "Zoom in",
                out: "Zoom out"
            },
            canvasHistoryActions: {
                undo: "Undo",
                redo: "Redo"
            },
            orientationChange: "Orientation",
            dragAndDropShapes: "Drag and drop shapes",
            upload: {
                infoText: "Upload some images, drag them onto the canvas and start designing",
                searchResults: "Search results",
                allImages: "All images"
            },
            text: {
                iconText: "Text",
                infoText: "Add some text to your design. Any text you add is shown here"
            },
            shape: {
                infoText: "If you want a rectangle or an ellipse, just use the grip handles to resize the shape"
            },
            itemPlacementToolbar: {
                scaleImage: {
                    default: "Scale Image",
                    fitToCanvas: "Fit to Canvas",
                    fitHorizontally: "Fit Horizontally",
                    fitVertically: "Fit Vertically"
                },
                rotation: "Rotation",
                imageSizing: {
                    width: "W",
                    height: "H"
                },
                positioning: {
                    x: "X",
                    y: "Y"
                }
            }
        },
        indicators: {
            safetyMargins: {
                title: "Safety Line",
                text: "Anything outside this line may be trimmed off in the printing process."
            },
            bleedMargins: {
                title: "Bleed Line",
                text: "Anything outside this line will not be printed."
            },
            trimMargins: {
                title: "Trim Line",
                text: "Anything outside of the trim line may be trimmed off in the printing process."
            }
        },
        maskLegend: {
            bleed: "Bleed Area",
            safety: "Safe Area",
            trim: "Trim Area"
        },
        dimensions: {
            bleed: {
                width: "Design bleed area width: {measurement}",
                height: "Design bleed area height: {measurement}"
            },
            safe: {
                width: "Design safe area width: {measurement}",
                height: "Design safe area height: {measurement}"
            },
            trim: {
                width: "Design trim area width: {measurement}",
                height: "Design trim area height: {measurement}"
            }
        },
        messages: {
            autoSharpen:
                "Your image was sharpened automatically because its resolution was too low. Click here to compare changes.",
            navigationWarning:
                "You will lose any unsaved changes if you leave this page. Are you sure you want to continue?"
        },
        tools: {
            buttons: {
                addRestrict: {
                    lock: "Restrict New Content",
                    unlock: "Unrestrict New Content"
                },
                apply: {
                    text: "Apply",
                    title: "Apply"
                },
                resetColors: {
                    text: "Reset Changes",
                    title: "Reset Changes"
                },
                lock: {
                    default: "Lock",
                    lock: "Lock",
                    unlock: "Unlock"
                },
                restrictContent: {
                    default: "Restrict"
                },
                restrictAttribute: {
                    default: "Restrictions"
                },
                properties: {
                    default: "Properties",
                    restrict: "Restrict User Edits",
                    unrestrict: "Unrestrict User Edits"
                },
                canvasBackground: {
                    default: "Background"
                },
                canvasColors: {
                    default: "Colors"
                },
                canvasLock: {
                    lock: "Lock",
                    unlock: "Unlock"
                },
                contentUnlock: {
                    default: "Unlock Content",
                    contentUnlock: "Unlock Content",
                    contentLock: "Lock Content"
                },
                crop: {
                    default: "Crop"
                },
                crispify: {
                    default: "Sharpen"
                },
                delete: {
                    default: "Delete",
                    approval: "Confirm Delete",
                    message: "This image will be removed from your uploads.",
                    accept: "Delete"
                },
                colorPicker: {
                    default: "choose color"
                },
                backgroundRemove: {
                    default: "Remove Background",
                    enable: "Remove Background",
                    disable: "Replace Background"
                },
                fontColor: {
                    default: "Color"
                },
                orientation: {
                    default: "Orientation"
                },
                shapeFillColor: {
                    default: "Fill Color"
                },
                shapeStrokeColor: {
                    default: "Stroke Color"
                },
                textbold: {
                    default: "Bold"
                },
                textitalic: {
                    default: "Italic"
                },
                textunderline: {
                    default: "Underline"
                },
                textstrikeout: {
                    default: "Strikeout"
                },
                textleftalign: {
                    default: "Align Left"
                },
                textcenteralign: {
                    default: "Align Center"
                },
                textrightalign: {
                    default: "Align Right"
                },
                rotateleft: {
                    default: "Rotate Left"
                },
                rotateright: {
                    default: "Rotate Right"
                },
                bringforward: {
                    default: "Bring Forward"
                },
                sendbackward: {
                    default: "Send Backward"
                },
                bringtofront: {
                    default: "Bring to Front"
                },
                sendtoback: {
                    default: "Send To Back"
                },
                duplicate: {
                    default: "Duplicate"
                },
                more: {
                    default: "More"
                },
                color: {
                    default: "Color"
                },
                segmentedColors: {
                    default: "Colors",
                    toolTipMessage:
                        "We tried to do a good job of processing your image. Not happy with how it looks? You can fix it here."
                },
                fontFamily: {
                    default: "Font Type",
                    placeholder: "Choose a style..."
                },
                fontSize: {
                    default: "Font Size"
                },
                layering: {
                    default: "Layering"
                },
                alignment: {
                    default: "Alignment"
                },
                shapeStrokeWidth: {
                    default: "Outline Size"
                },
                printColors: {
                    default: "Print Colors"
                },
                invertColors: {
                    default: "Invert Colors"
                },
                removeBackground: {
                    default: "Remove Background"
                },
                removeWhite: {
                    default: "Remove all White"
                },
                placeImage: {
                    text: "Place Image",
                    title: "Place Image"
                },
                createPdfProof: {
                    default: "Get PDF Proof"
                },
                createPrintPdf: {
                    default: "Create PDF"
                },
                alignItemsCenter: {
                    default: "Align Center"
                },
                alignItemsTop: {
                    default: "Align Top"
                },
                alignItemsBottom: {
                    default: "Align Bottom"
                },
                alignItemsLeft: {
                    default: "Align Left"
                },
                alignItemsRight: {
                    default: "Align Right"
                },
                distributeHorizontally: {
                    default: "Distribute Horizontally"
                },
                distributeVertically: {
                    default: "Distribute Vertically"
                },
                listOrdered: {
                    default: "Ordered List"
                },
                listUnordered: {
                    default: "Bulleted List"
                }
            },
            checkboxes: {
                require: "Mark Field As Required",
                restrict: "Restrict All User Edits",
                unlockContent: "Allow Replace Text",
                unlockFontSize: "Allow Change Font Size",
                unlockCrop: "Allow Crop",
                unlockSharpen: "Allow Sharpen",
                placeholderText: "Update Placeholder Text",
                textLabel: "Change Text Field Label",
                imageLabel: "Change Image Label",
                textOptions: "Add Dropdown Text Options",
                requireImage: "Mark Image as Required",
                unlockSizeAndPosition: "Allow Change Size And Position",
                unlockFontType: "Allow Change Font Type",
                unlockFontColor: "Allow Change Font Color",
                unlockFillColor: "Allow Change Fill Color",
                unlockStrokeColor: "Allow Change Stroke Color",
                unlockStrokeWidth: "Allow Change Stroke Width"
            },
            color: {
                providedColors: "Standard",
                recentColors: "Recent Colors",
                canvasColors: "Recommended",
                customColor: "Custom",
                allPantoneHeader: " All Pantone Colors",
                showAllPantoneLabel: "See All Pantone Colors",
                showStandardLabel: "See Standard Colors"
            },
            recolorize: {
                hueSliderLabel: "Color",
                saturationSliderLabel: "Saturation",
                lightnessSliderLabel: "Lightness"
            }
        },
        errors: {
            surfaceValidationSurfaces: "The number of surfaces don't match the document or template canvases",
            surfaceValidationSizes: "The surface sizes don't match the document or template canvas sizes",
            blankDocument: "Your design appears to be empty. Are you sure you want to publish it?",
            cropProcessing:
                "The crop selection could not be converted to the current decoration technology. Please try selecting a new crop area.",
            digitization:
                "Your image could not be digitized for embroidery. You can still use it on printed products but not on embroidery products.",
            general: "Your image was not uploaded because of an unknown error. Try again, or upload a different image.",
            segmentation:
                "Your image has too many colors or gradients and might not be embroidered properly. Try simplifying the image by using fewer colors and removing any gradients.",
            server: "Your image was not uploaded because it could not be processed by the server. Try uploading the image again.",
            uploadSize: "The image file is too large to upload. The image file must be smaller than {fileSize} MB.",
            uploadType:
                "Oops! It looks like you uploaded a file that we do not support. You can upload files with the following extensions: {fileExtensions}",
            password:
                "The PDF was password protected and cannot be used. Remove the password protection and try again.",
            saveFailedPendingProcessing: "You must wait for all items to finish processing before saving.",
            premiumFinishMaskAspectRatioMismatch:
                "The aspect ratio of the mask must match the aspect ratio of your image."
        },

        validations: {
            outsideBounds: {
                print: {
                    image: "The image is outside of the design canvas and will be cut during printing.",
                    shape: "The shape is outside of the design canvas and will be cut during printing.",
                    text: "The text is outside of the design canvas and will be cut during printing.",
                    itemReference: "The item is outside of the design canvas and will be cut during printing."
                },
                embroidery: {
                    image: "The image is outside of the design area. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
                    text: "The text is outside of the design area. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
                    itemReference:
                        "The item is outside of the design area. It will be rejected when it goes to production because it cannot be embroidered by the machines."
                }
            },
            betweenBoundaries: {
                print: {
                    image: "Extend your image to the outer edge to avoid unintended white borders when printed.",
                    shape: "Extend your shape to the outer edge to avoid unintended white borders when printed."
                }
            },
            outsideMargins: {
                print: {
                    image: "The image is outside the margins, and parts of it might be cut off during printing.",
                    shape: "The shape is outside the margins, and parts of it might be cut off during printing.",
                    text: "The text is outside the margins, and parts of it might be cut off during printing.",
                    itemReference: "The item is outside the margins, and parts of it might be cut off during printing."
                },
                embroidery: {
                    image: "The image is outside the margins. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
                    text: "The text is outside the margins. It will be rejected when it goes to production because it cannot be embroidered by the machines."
                },
                mailingValidation: {
                    print: {
                        image: "This element is outside the design area and will not appear when your postcard is printed.",
                        shape: "This element is outside the design area and will not appear when your postcard is printed.",
                        text: "This element is outside the design area and will not appear when your postcard is printed.",
                        itemReference:
                            "This element is outside the design area and will not appear when your postcard is printed."
                    },
                    embroidery: {
                        image: "This element is outside the design area and will not appear when your postcard is printed.",
                        text: "This element is outside the design area and will not appear when your postcard is printed."
                    }
                }
            },
            overlap: {
                print: {
                    image: "The image overlaps with another object and might not print properly.",
                    shape: "The shape overlaps with another object and might not print properly.",
                    text: "The text overlaps with another object and might not print properly.",
                    itemReference: "The item overlaps with another object and might not print properly."
                },
                embroidery: {
                    image: "The image overlaps with another object in your design. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
                    text: "The text overlaps with another object in your design. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
                    itemReference: "The item overlaps with another object and might not print properly."
                }
            },
            imageResolution: {
                warning:
                    "Your image has low resolution and might look blurry when printed. Try uploading a higher resolution image.",
                error: "Your image has very low resolution and won't print well. Try uploading a higher resolution image."
            },
            requiredText: {
                error: "This text field is required. Please modify the placeholder text:"
            },
            requiredImage: {
                error: "This image is required. Please replace the placeholder with an image."
            },
            fileValidationPlugin: {
                document: {
                    tooManySpotColors:
                        "The total amount of colors in the document is too high. [Maximum number of colors: {maxSpotColors}, Current number of colors: {actualNumberSpotColors}]",
                    notEnoughSpotColors:
                        "The total amount of colors in the document is too low. [Mininimum number of colors: {minSpotColors}, Current number of colors: {actualNumberSpotColors}]"
                },
                item: {
                    autoEmbeddedFonts: "Some pages have automatically embedded fonts. [Page(s) {pages}]",
                    colorConversion:
                        "Some pages have colors converted to CMYK, which can result a difference in color appearance. [Page(s) {pages}]",
                    lowResolution:
                        "The resolution of one or multiple files is too low. [Minimum DPI: {minimumDpi}. Page(s) {pages}]",
                    transparency: "Some pages has transparency.[Page(s) {pages}]",
                    imageScaledUp: "Some pages have files that are scaled up. [Page(s) {pages}]",
                    imageScaledDown: "Some pages have files that are scaled down. [Page(s) {pages}]",
                    minimumFontSize: "Some pages have font that is too small. [Page(s) {pages}]"
                },
                page: {
                    blankPage: "Some pages don't contain an uploaded file. [Page(s) {pages}]",
                    tooManySpotColors:
                        "The total amount of colors on some of the pages is too high. [Maximum number of colors: {maxSpotColors}. Page(s) {pages}]",
                    notEnoughSpotColors:
                        "The total amount of colors on some of the pages is too low. [Mininum number of colors: {minSpotColors}. Page(s) {pages}]",
                    restrictedColors: "There are some colors present that are not supported. [Page(s) {pages}]",
                    spotColorsPresent:
                        "Some pages have spot colors present that will be converted to CMYK. [Page(s) {pages}]",
                    whitespace:
                        "Some pages are not completely filled. Please adjust the positioning of your file to fill the empty space. [Page(s) {pages}]"
                },
                upload: {
                    corruption: "The PDF was corrupt and cannot be used. Please try another file.",
                    unembeddedFonts:
                        "The PDF was missing fonts and cannot be used. Embed all fonts in the PDF and try again.",
                    encryption:
                        "The file cannot be opened, because it is protected with a password. Upload a file without password protection."
                }
            },
            loadingTime: {
                information: "This may take a while. Keep working while we process the image in the background."
            }
        },
        premiumFinishes: {
            fieldTooltip: "This field has {finish} applied",
            buttonTooltip: "Apply {finish}",
            default: "Premium Finish",
            RaisedInk: "Embossed Gloss",
            Metallic: "Foil Accent",
            RaisedFoilGold: "Gold Embossed Foil",
            RaisedFoilSilver: "Silver Embossed Foil",
            RaisedFoilRoseGold: "Rose Gold Embossed Foil",
            RaisedFoilGlitterSilver: "Glitter Embossed Foil"
        }
    }
};
