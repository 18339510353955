/**
 * This client talks to UDS in order to save documents, get documents, and get document references
 */
import type { DSS } from "@vp/types-ddif";
import { retry } from "@shared/utils/Network";
import { formatError, newRelicWrapper, checkResponseError } from "@shared/utils/Errors";
import { fireGenericTrackingEvent } from "@shared/utils/Tracking";
import { upgradeCimDoc } from "@design-stack-ct/cimdoc-state-manager";
import { FONT_REPOSITORY_URL } from "@shared/utils/Fonts";
import { getTransientDocumentUrl } from "../transientDocuments";

const entityCode = 39;

export interface UdsLink {
    href: string;
}

export interface UdsLinks {
    self: UdsLink;
    documentRevision: UdsLink;
    documentReference: UdsLink;
    previewInstructions: UdsLink;
    drawingInstructions: UdsLink;
}

export interface UdsResult {
    _links: UdsLinks;
}

export interface DocRef {
    _links: UdsLinks;
    cimDocUrl?: string;
    prepressInstructionsUrl?: string;
    renderingInstructionsUrl?: string;
    variableDataUri?: string;
}

/**
 * Saves the given docJson to UDS, checking if the document URL already exists
 * @param docJson The document JSON
 * @param authToken The authorization token for the request to UDS
 * @param [documentUrl] For existing documents, the existing UDS document URL. May be null
 * @return The UDS response
 */
export async function postOrPutDocument(
    docJson: DSS.DesignDocument,
    authToken: string,
    documentUrl?: string,
    retryCount?: number
): Promise<UdsResult> {
    const moduleFunction = "udsClient:saveDocument";

    // @ts-ignore
    const uri = documentUrl || `${UDS_STORAGE_V3_URL}/documents`;
    const body = JSON.stringify(docJson);

    try {
        // this call is a major source of network errors, and is blocking as the customer cannot save their changes
        // Since this can be a PUT/POST, retrying is not as simple as a GET, but for this particular call I do not think it an issue
        // the concern is that a network error may occur but the request still gets through and is processed by UDS
        // If this is a PUT, then calling this again will just create another UDS document.  The previous one will just be abandoned.
        // If this is a POST, then a new revision will be created
        // either way, the worst that happens is a tiny bit of extra storage space is used.  The customer should not be effected.
        const response = await retry(
            additionalHeaders =>
                fetch(uri, {
                    method: documentUrl ? "PUT" : "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                        "If-Match": "*",
                        ...additionalHeaders
                    },
                    body
                }),
            {
                name: moduleFunction,
                retryCount,
                retryWhenNoException: (result: Response) => {
                    // In testing 500s may work when retried
                    return result.status === 500;
                }
            }
        );

        const resp = await checkResponseError(moduleFunction, response, entityCode);
        return resp;
    } catch (ex) {
        try {
            // special logging for UDS
            const transientDocUrl = await getTransientDocumentUrl(
                {
                    version: docJson.version,
                    projectionId: docJson.projectionId,
                    fontRepositoryUrl: docJson.fontRepositoryUrl,
                    document: {
                        panels: docJson.document?.panels
                    }
                },
                authToken
            );
            const status = `${ex.status}` || "000";
            // new relic blocks these  events  when the  url is too  long
            fireGenericTrackingEvent({
                event: "Studio UDS Error",
                eventDetail: moduleFunction,
                extraData: () => ({
                    message: ex.message || ex.errorMessage,
                    status,
                    transientUrl: transientDocUrl
                })
            });
            // eslint-disable-next-line no-empty
        } catch (ex) {
            newRelicWrapper.logPageAction("studio-uds-logging-error", { ...ex });
        }
        if (ex.moduleFunction) {
            throw ex;
        }
        throw formatError(moduleFunction, ex.message, entityCode, "000", ex, undefined, undefined, uri, body?.length);
    }
}

export async function saveTransientDocument(docJson: DSS.DesignDocument, authToken: string): Promise<DocRef> {
    const moduleFunction = "udsClient:saveTransientDocument";
    try {
        const uri = `${UDS_STORAGE_V3_URL}/documents`;
        // this call is a major source of network errors, and is blocking as the customer cannot save their changes
        // Since this can be a PUT/POST, retrying is not as simple as a GET, but for this particular call I do not think it an issue
        // the concern is that a network error may occur but the request still gets through and is processed by UDS
        // If this is a PUT, then calling this again will just create another UDS document.  The previous one will just be abandoned.
        // If this is a POST, then a new revision will be created
        // either way, the worst that happens is a tiny bit of extra storage space is used.  The customer should not be effected.
        const response = await retry(
            additionalHeaders =>
                fetch(uri, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                        "If-Match": "*",
                        ...additionalHeaders
                    },
                    body: JSON.stringify({
                        ...docJson,
                        deleteAfterDays: 1
                    })
                }),
            { name: moduleFunction }
        );

        return checkResponseError(moduleFunction, response, entityCode);
    } catch (ex) {
        if (ex.moduleFunction) {
            throw ex;
        }
        throw formatError(moduleFunction, ex.message, entityCode, "000", ex);
    }
}

/**
 * Gets the document reference for a given document revision URL
 * @param documentRevisionUrl The existing document revision URL
 * @return The document reference response
 */
export async function getDocumentReference(documentRevisionUrl: string): Promise<DocRef> {
    const moduleFunction = "udsClient:getDocumentReference";
    try {
        const docRefResponse = await retry(
            additionalHeaders => fetch(`${documentRevisionUrl}/docref`, { headers: additionalHeaders }),
            { name: moduleFunction }
        );

        return checkResponseError(moduleFunction, docRefResponse, entityCode);
    } catch (ex) {
        if (ex.moduleFunction) {
            throw ex;
        }
        throw formatError(moduleFunction, ex.message, entityCode, "000", ex);
    }
}

/**
 * Gets the document for a given document revision URL
 * @param documentRevisionUrl The existing document revision URL
 * @param authToken The authorization token for the request to UDS
 * @return The UDS response for that document
 */
export async function getDocument(documentRevisionUrl: string, authToken: string) {
    const moduleFunction = "udsClient:getDocument";
    let url = "";
    try {
        // designer expects units to be in mm
        const hasQuestionMark = documentRevisionUrl.includes("?");
        url = `${documentRevisionUrl}${hasQuestionMark ? "&" : "?"}normalize=true&unit=mm`;
        const rawResponse = await retry(
            additionalHeaders =>
                fetch(url, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                        ...additionalHeaders
                    }
                }),
            { name: moduleFunction }
        );
        const cimDoc = await checkResponseError(moduleFunction, rawResponse, entityCode);
        upgradeCimDoc(cimDoc);
        // we have to set the font repository to the one that we use, otherwise fonts will fail
        // cimdocs generated by cimpress services will generally use their own font repos, which are no good for us
        // we hardcode the fonts that we want, so text will be useless unless we use our own repository
        cimDoc.fontRepositoryUrl = FONT_REPOSITORY_URL;
        return cimDoc;
    } catch (ex) {
        if (ex.moduleFunction) {
            throw ex;
        }
        throw formatError(moduleFunction, ex.message, entityCode, "000", ex, undefined, undefined, url);
    }
}
