import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

interface Model {
    documentRevisionUrl: string | null;
    documentUrl: string | null;
}

const initialState: Model = {
    documentRevisionUrl: null,
    documentUrl: null
};

const documentSlice = createSlice({
    name: "document",
    initialState,
    reducers: {
        setDocumentUrl: (state, action: PayloadAction<string | null>) => ({
            documentRevisionUrl: null,
            documentUrl: action.payload
        }),
        setDocument: (state, action: PayloadAction<any>) => ({
            documentRevisionUrl: action.payload?.documentRevisionUrl,
            documentUrl: action.payload?.url
        })
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => ({
            documentRevisionUrl: action.payload.udsDocument?.documentRevisionUrl,
            documentUrl: action.payload.udsDocument?.url
        }));
    }
});

export const { setDocumentUrl, setDocument } = documentSlice.actions;
export const documentReducer = documentSlice.reducer;
