import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// @note this will need to be fixed once we migrate to Sherbert
// eslint-disable-next-line import/no-restricted-paths
import type { Page } from "src/easel/designer-suite/@types/page";

export interface DeleteImageData {
    uploadName?: string;
    uploadId?: string;
    page?: Page;
}

const initialState: DeleteImageData = {};

const deleteImageDataSlice = createSlice({
    name: "deleteImageData",
    initialState,
    reducers: {
        setDeleteImageData: (state, action: PayloadAction<DeleteImageData>) => action.payload,
        clearDeleteImageData: () => {}
    }
});

export const { setDeleteImageData, clearDeleteImageData } = deleteImageDataSlice.actions;
export const deleteImageData = deleteImageDataSlice.reducer;
