import React, { FC } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { SiteContent, MainContentStart, SiteMain } from "@vp/swan";
import { DexName } from "@shared/redux";
import { Performance } from "@shared/features/Performance";
import { useAnalytics } from "@shared/features/Tracking";
import StudioErrorBoundary from "./components/StudioErrorBoundary";

const StudioSiteContent: FC = ({ children }) => {
    useAnalytics(DexName.Studio5);
    return (
        <SiteContent>
            <SiteMain role="main">
                <MainContentStart />
                <StudioErrorBoundary>
                    <SkeletonTheme color="#d8d8d8" highlightColor="#bdbdbd">
                        {children}
                    </SkeletonTheme>
                    <Performance />
                </StudioErrorBoundary>
            </SiteMain>
        </SiteContent>
    );
};

StudioSiteContent.displayName = "StudioSiteContent";

export default StudioSiteContent;
