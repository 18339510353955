import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const mcpVersionSlice = createSlice({
    name: "mcpVersion",
    initialState: null,
    reducers: {
        setMcpVersion(state, action: PayloadAction<number>) {
            return action.payload || state;
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.mcpVersion ?? state;
        });
    }
});

export const { setMcpVersion } = mcpVersionSlice.actions;
export const mcpVersion = mcpVersionSlice.reducer;
