import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const documentRevisionUrlSlice = createSlice({
    name: "documentRevisioniUrl",
    initialState: null as string | null,
    reducers: {
        setDocumentRevisionUrl(state, action: PayloadAction<string>) {
            return action.payload || null;
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => action.payload.documentRevisionUrl || null);
    }
});

export const { setDocumentRevisionUrl } = documentRevisionUrlSlice.actions;
export const documentRevisionUrl = documentRevisionUrlSlice.reducer;
