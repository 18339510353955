import React, { createContext, useState, useMemo, useContext, ReactNode } from "react";
import type { StudioMetadataItem } from "@shared/utils/Metadata";

interface ContextProps {
    updateTempStudioMetadata: (key: string, metadata: StudioMetadataItem) => void;
    getTempStudioMetadata: (key: string) => StudioMetadataItem;
    removeTempStudioMetadata: (key: string) => void;
}

export const TempStudioMetadataContext = createContext<ContextProps | undefined>(undefined);

export const useTempStudioMetadataContext = () => {
    const result = useContext(TempStudioMetadataContext);
    if (!result) {
        throw Error("Missing context.  This must be called within a TempStudioMetadataProvider");
    }
    return result;
};

interface Props {
    /* Any components that need fonts should be wrapped here */
    children: ReactNode;
}

/**
 * The TempStudioMetadataProvider can be used to temporarily store and supply metadata. This is useful for storing metadata
 * before an item exists on canvas, e.g. in the case of uploading using Designer's `uploadFromUrl` API function.`
 * Metadata that is to be used and/or stored in a cimdoc on save should live in a model's `studioMetadata` attribute.
 */
export const TempStudioMetadataProvider = ({ children }: Props) => {
    const [studioMetadata, setStudioMetadata] = useState<Record<string, StudioMetadataItem | undefined>>({});

    const contextObject = useMemo(
        () => ({
            // Adds or updates metadata for a given key
            updateTempStudioMetadata: (key: string, metadata: StudioMetadataItem) => {
                setStudioMetadata(current => {
                    return { ...current, [key]: metadata };
                });
            },

            getTempStudioMetadata: (key: string) => {
                return studioMetadata[key] ?? {};
            },

            removeTempStudioMetadata: (key: string) => {
                setStudioMetadata(current => {
                    return { ...current, [key]: undefined };
                });
            }
        }),
        [studioMetadata]
    );

    return <TempStudioMetadataContext.Provider value={contextObject}>{children}</TempStudioMetadataContext.Provider>;
};

TempStudioMetadataProvider.displayName = "TempStudioMetadataProvider";
