import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { DTeC } from "@vp/types-ddif";
import { StudioSecondaryConfig } from "@shared/utils/Calcifer";

type StudioSecondaryConfigModified = Omit<
    StudioSecondaryConfig,
    | "templateAvailableFinishes"
    | "warnings"
    | "designVariations"
    | "isQuantityPageEnabled"
    | "compatibleOptions"
    | "designAttributes"
    | "productAttributeAndValues"
> & {
    templateAvailableFinishes: Capitalize<DTeC.FinishType>[];
};

const secondaryConfigSlice = createSlice({
    name: "secondaryConfig",
    initialState: null as StudioSecondaryConfigModified | null,
    reducers: {
        setSecondaryConfig(state, action: PayloadAction<StudioSecondaryConfigModified | null>) {
            return action.payload;
        }
    }
});

export const { setSecondaryConfig } = secondaryConfigSlice.actions;
export const secondaryConfig = secondaryConfigSlice.reducer;
