import defaultMessagesJSON from "./i18n/en-US.json";

export * from "./designer-suite";
export {
    EASEL_EVENTS,
    DEFAULT_SHAPE_COLOR,
    useDecorationTechnology,
    hasAdvancedTools,
    useStudio5AvailablePremiumFinishesOnCanvas,
    usePremiumFinishWordArtAndTextToggle,
    useFinishType,
    useHasFinish,
    switchContext,
    waitForProcessing,
    checkForProcessing,
    getDesignDocumentFromDesigner
} from "./utilities";
export { AddWordArtButton } from "./presentational";
export * from "./presentational/Tables";
export * from "./presentational/AddItemReference";
export * from "./designer-suite/designer/TempStudioMetadataProvider";
export const defaultENUSMessages = defaultMessagesJSON;
export {
    DEFAULT_IMAGE_MIN_WIDTH,
    DEFAULT_IMAGE_MIN_HEIGHT,
    DEFAULT_MAX_ZOOM,
    DEFAULT_MIN_ZOOM
} from "./utilities/designerConfiguration/DefaultConfig.config";
