import qs from "qs";
import { WorkEntity } from "../Work";
import { callCatch } from "./catchClient";
import { callLatCart } from "./latCartClient";
import { CartItems, CartVersion, LineItem, callUpsertCart } from "./vistaCartClient";

interface UpdateCartParams {
    authToken: string;
    locale: string;
    work: WorkEntity;
    shopperId: string;
    anonymousId: string;
    isQuantityPageEnabled: boolean;
    isConnectedCard: boolean;
    studioSelectedProductOptions: Record<string, string>;
    mpvId: string | null;
}

export const updateCart = async ({
    authToken,
    locale,
    work,
    shopperId,
    anonymousId,
    isQuantityPageEnabled,
    isConnectedCard,
    studioSelectedProductOptions,
    mpvId
}: UpdateCartParams) => {
    if (isQuantityPageEnabled) {
        await callLatCart(authToken, locale, work.workId);
    } else if (isConnectedCard) {
        await callCatch(authToken, locale, work.workId, shopperId, anonymousId);
    } else {
        const ownerId = shopperId || anonymousId;
        await callUpsertCart(authToken, locale, ownerId, work, studioSelectedProductOptions, mpvId);
    }
};

export const getItemInCart = (cartItems: CartItems | undefined, workId: string) => {
    const itemFromFulfillment = cartItems?.lineItems?.find(
        (item: LineItem) => item.customOrderData?.fulfillment?.workId === workId
    );
    const itemFromAnalytics = cartItems?.lineItems?.find(
        (item: LineItem) => item.customOrderData?.analytics?.workId === workId
    );
    const itemFromEditDocumentUrl = cartItems?.lineItems?.find((item: LineItem) => {
        // example editDocumentUrl: "/studio/?workId=<WORKID>&editSource=cart"
        const splitUrl = item.designData?.editDocumentUrl?.split("?");

        if (splitUrl && splitUrl.length > 1) {
            const parsed = qs.parse(splitUrl[1]);
            if (parsed.workId === workId) {
                return true;
            }
        }
        return undefined;
    });

    const hasRelatedAccessoryInCart = cartItems?.lineItems.find(
        (lineItem: LineItem) => lineItem.customOrderData.modData?.AccessoryRelationship?.ParentWorkEntityId === workId
    );

    if (hasRelatedAccessoryInCart) {
        return undefined;
    }

    // some properties from VistaCart may be empty so check for workId in any of these
    const lineItem = itemFromFulfillment || itemFromAnalytics || itemFromEditDocumentUrl;

    // We don't support line items from cartV1 or missing a correlation id since we can't upsert them
    if (lineItem?.version === CartVersion.CART_V1 || !lineItem?.correlationId) {
        return undefined;
    }

    return lineItem;
};
