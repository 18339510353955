import { createSlice } from "@reduxjs/toolkit";
import { setError, showSaveLoader } from "../compoundActions";

const errorExtraDataSlice = createSlice({
    name: "errorExtraData",
    initialState: {} as any,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(showSaveLoader, (state, action) => null);
        builder.addCase(setError, (state, action) => action.payload.errorExtraData || state);
    }
});

export const errorExtraData = errorExtraDataSlice.reducer;
