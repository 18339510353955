import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showBleedSlice = createSlice({
    name: "showBleed",
    initialState: true,
    reducers: {
        setShowBleed: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowBleed } = showBleedSlice.actions;
export const showBleed = showBleedSlice.reducer;
