import { tryFetch } from "@shared/utils/Network";
import { getCountry } from "@shared/utils/i18n";

const host = CATCH_URL;
const entityCode = 31;

function getTenant(locale: string) {
    return `VP-${getCountry(locale)}-PROD`;
}

/**
 * Attempts to add the work entity to cart under the given locale,
 * or updates the work in cart if it's already been added
 * @param {string} locale - the locale of the page
 * @return The output of adding the work to cart
 */
export async function callCatch(
    authToken: string,
    locale: string,
    workId: string,
    shopperId: string,
    anonymousId: string | undefined
) {
    const url = `${host}/api/v1/connectedProduct/${workId}`;
    const content = await tryFetch({
        url,
        options: {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify({
                shopperId,
                anonymousId,
                locale,
                tenant: getTenant(locale)
            })
        },
        moduleFunction: "catchClient:addToCart",
        friendlyDescription: "add to cart",
        entityCode,
        retryCount: 0
    });

    return content;
}
