import { POSTCARD_PRDS } from "@shared/features/MailingServices";

export function mailingPostCardValidation(productKey: string) {
    return {
        validations: {
            outsideMargins: {
                getOverrideMessageKey: (surfaceName: string, validationName: string) => {
                    if (POSTCARD_PRDS.MAILING === productKey && surfaceName === "Back") {
                        return `${validationName}.mailingValidation`;
                    }
                    return undefined;
                }
            }
        }
    };
}
