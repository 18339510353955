import { getQueryParams, buildHistoryUrl, cleanStudioUrl } from "@shared/utils/WebBrowser";
import { isCareAgent, isCareAgentEditingCareAgentDocument } from "@shared/utils/Care";
import { fireGenericTrackingEvent, fireStudioProjectSavedTrackingEvent } from "@shared/utils/Tracking";
import { tryFetch } from "@shared/utils/Network";
import { setWorkId, Store, setWorkRevisionId, setWorkName, setWorkLastSaved, RootState } from "@shared/redux";
import type { Identity } from "@shared/utils/Identity";
import { type UdsResponse } from "@shared/utils/DocumentStorage";
import { newRelicWrapper } from "@shared/utils/Errors";
import { transferUsingCRM } from "@shared/utils/Ownership";
import { getProductName } from "@shared/utils/Product";
import { type RecursivePartial, WesSortOptions, type WorkEntity, type WorkEntityToCreate } from "../types";

const host = WORK_ENTITY_SERVICE_URL;
const entityCode = 20;

// Only exporting this until we migrate the rest of the work entity client
export async function fetchWorkEntity(authToken: string, workId: string): Promise<WorkEntity> {
    // We want to allow anyone to view deleted documents in studio (DT-6258), so fetching with includeHidden=true
    const url = `${host}/v1/works/${encodeURIComponent(workId)}?includeHidden=true`;

    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:fetchWorkEntity",
        friendlyDescription: "retrieve user's work",
        entityCode
    });
}

export async function getWorkEntity(authToken: string, identity: Identity, workId: string): Promise<WorkEntity> {
    if (isCareAgent()) {
        const { careforceData, chatTranscriptId, careforceUserId } = getQueryParams();
        fireGenericTrackingEvent({
            event: "CARE_AGENT_LOADED_WORK",
            eventDetail: "Information",
            label: "info",
            extraData: () => ({
                workId,
                agentId: identity.shopperId,
                careforceData,
                chatTranscriptId,
                careforceUserId
            })
        });
    }
    const content = await fetchWorkEntity(authToken, workId);
    Store.dispatch(setWorkId(workId));
    return content;
}

export async function constructWorkEntity(
    authToken: string,
    udsResponse: UdsResponse,
    currentState: RootState,
    newWorkName?: string
): Promise<WorkEntityToCreate> {
    const workEntity: RecursivePartial<WorkEntity> =
        !newWorkName && currentState.workId ? await fetchWorkEntity(authToken, currentState.workId) : {};

    if (!workEntity.merchandising) {
        workEntity.merchandising = {};
    }

    workEntity.workName = newWorkName || workEntity.workName || currentState.productName;
    workEntity.merchandising.merchandisingSelections = currentState.customerSelectedProductOptions;
    workEntity.merchandising.quantity = currentState.quantity;

    if (currentState.mpvId) {
        workEntity.merchandising.mpvUrl = `${MERCHANDISING_PRODUCT_URL}/mpv/${MERCHANDISING_TENANT}/${currentState.locale}/${currentState.mpvId}`;
    }

    // the new way of saving product data
    workEntity.product = workEntity.product || {};
    workEntity.product.key = currentState.productKey;
    workEntity.product.version = currentState.productVersion ?? undefined;

    workEntity.resources = workEntity.resources || {};
    workEntity.resources.qty = currentState.quantityPerSize ?? undefined;

    workEntity.design = workEntity.design || {};
    workEntity.design.metadata = workEntity.design.metadata || {};

    // clean up this old field we no longer use
    if (workEntity.design.metadata.udsDocumentUrl) {
        delete workEntity.design.metadata.udsDocumentUrl;
    }

    // backwards compatibility with other consumers depending on this data
    workEntity.design.metadata.productKey = currentState.productKey;

    // Track the studio that generated this work (or in the case of advanced studio, the work revision)
    // We're using 'studioVersion' as thats the same name we use for tracking in segment
    workEntity.design.metadata.studioVersion = currentState.tracking?.dexName;

    // we only need to keep the studio selected product options if we actually have some,
    // and if the customer still has not selected a complete set of product options
    if (
        currentState.studioSelectedProductOptions &&
        JSON.stringify(Object.keys(currentState.studioSelectedProductOptions).sort()) !==
            JSON.stringify(Object.keys(currentState.customerSelectedProductOptions).sort())
    ) {
        // WES throws a validation error when trying to put json here and not stringifying it :(
        workEntity.design.metadata.studioSelectedProductOptions = JSON.stringify(
            currentState.studioSelectedProductOptions
        );
    } else if (workEntity.design.metadata.studioSelectedProductOptions) {
        // if we no longer need to keep the studio selected product options around because the customer
        // has a full set of product options that they have selected, just remove these from the work
        delete workEntity.design.metadata.studioSelectedProductOptions;
    }

    workEntity.design.designUrl = udsResponse.documentRevisionUrl;
    workEntity.design.manufactureUrl = udsResponse.instructionSourceUrl;
    workEntity.design.displayUrl = udsResponse.previewInstructionSourceUrl;
    if (workEntity.design.docRefUrl) {
        // most pages etc do not update this.
        delete workEntity.design.docRefUrl;
    }
    // on production the path could be /studio, or /fr/studio.  so have to do get the actual pathname.
    workEntity.design.editUrl = `${cleanStudioUrl(window.location.pathname)}?workId=\${workId}`;

    workEntity.design.metadata.hasTeamsPlaceholders = currentState?.teamsPlaceholders?.length ? "true" : undefined;

    return workEntity as WorkEntity;
}

async function undeleteWorkEntity(url: string, authToken: string) {
    const content = await tryFetch({
        url,
        options: {
            method: "PATCH",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify({ hidden: false })
        },
        moduleFunction: "workEntityClient:undeleteWorkEntity",
        friendlyDescription: "undelete user's work to enable save",
        entityCode,
        retryCount: 0
    });
    return content;
}

interface PostWorkEntityParams {
    workEntity: WorkEntityToCreate;
    url?: string;
    authToken: string;
}

export async function postWorkEntity({ workEntity, url, authToken }: PostWorkEntityParams) {
    const content = await tryFetch({
        url: url ?? `${host}/v1/works/`,
        options: {
            method: "POST",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify(workEntity)
        },
        moduleFunction: "workEntityClient:saveWorkEntity",
        friendlyDescription: "save user's work",
        entityCode
    });
    return content;
}

interface SaveWorkEntityParams {
    authToken: string;
    identity: Identity;
    udsResponse?: UdsResponse;
    debugWorkEntity?: WorkEntity;
    newWorkName?: string;
    savedProjectTrackingEventData?: any;
}

export async function saveWorkEntity({
    authToken,
    identity,
    udsResponse,
    debugWorkEntity,
    newWorkName,
    savedProjectTrackingEventData
}: SaveWorkEntityParams) {
    const currentState = Store.getState();
    let workEntityTemp: WorkEntityToCreate | undefined = debugWorkEntity;
    if (!workEntityTemp && udsResponse) {
        try {
            workEntityTemp = await constructWorkEntity(authToken, udsResponse, currentState, newWorkName);
        } catch (error) {
            if (
                identity.isSignedIn &&
                currentState.workId &&
                (!isCareAgent() || isCareAgentEditingCareAgentDocument())
            ) {
                // we're signed in, and we're trying to save a work entity we already loaded.
                // we're also either not a care agent, or if we are a care agent, we are editing our own document
                // Try transferring the resource and then fetching again
                let transferSuccess = false;
                const transferStart = Date.now();
                try {
                    if (await transferUsingCRM(authToken)) {
                        workEntityTemp = await constructWorkEntity(authToken, udsResponse, currentState, newWorkName);
                        transferSuccess = true;
                    }
                } finally {
                    newRelicWrapper.logPageAction("MidSessionTransfer", {
                        transferSuccess,
                        workId: currentState.workId,
                        callTime: (Date.now() - transferStart) / 1000
                    });
                }
            } else {
                throw error;
            }
        }
    }

    const workEntity = workEntityTemp!;

    // Always add vp-care keys if they exist in localStorage
    if (isCareAgent() && typeof window !== "undefined" && window.localStorage) {
        Object.keys(window.localStorage)
            .filter(key => key.startsWith("vp-care"))
            .forEach(key => {
                workEntity.resources[key] = window.localStorage.getItem(key)!;
            });
    }

    let url = `${host}/v1/works/${workEntity.workId ? `${encodeURIComponent(workEntity.workId)}/update` : ""}`;
    if (isCareAgent() && !isCareAgentEditingCareAgentDocument()) {
        const urlParams = getQueryParams();

        const { owner } = urlParams;
        if (!owner) {
            throw new Error("Please define owner query param");
        }
        url += `?ownerId=${owner}`;
    }

    let content = null;
    try {
        content = await postWorkEntity({ workEntity, url, authToken });
    } catch (err) {
        // since we want to allow saving deleted ("hidden") works, if save failed with 403 status,
        // we can try to undelete before saving
        if (err.status === 403 && workEntity.workId) {
            const setVisibleUrl = `${host}/v1/works/${workEntity.workId}/setVisible`;
            await undeleteWorkEntity(setVisibleUrl, authToken);
            content = await postWorkEntity({ workEntity, url, authToken });
        } else {
            throw err;
        }
    }

    // We chose not to update properties besides workId in the store from this operation, since we are not sure of the side effects of that change.
    // We currently mitigate this by always loading a fresh work from WES before making modifications.

    if (currentState.workId !== content.workId) {
        Store.dispatch(setWorkId(content.workId));
    }

    if (currentState.workRevisionId !== content.workRevisionId) {
        Store.dispatch(setWorkRevisionId(content.workRevisionId));
    }

    if (currentState.workName !== content.workName) {
        Store.dispatch(setWorkName(content.workName));
    }

    Store.dispatch(setWorkLastSaved(content.modified));

    if (!identity.isSignedIn) {
        sessionStorage.setItem("anonymous_canonical_id", identity.anonymousUserId);
    }

    fireStudioProjectSavedTrackingEvent({
        extraData: () => ({ savedFrom: savedProjectTrackingEventData })
    });

    window.history.pushState("update-workId", "Title", buildHistoryUrl({ workId: content.workId }));
    return content;
}

/**
 *
 * @param {string} ownerId
 * @param {string} authToken
 * @returns list of works, up to 200
 */
export async function getAllWorks(ownerId: string, authToken: string): Promise<WorkEntity[]> {
    const url = `${host}/v1/works?ownerId=${ownerId}`;
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:getAllWorks",
        friendlyDescription: "retrieve all of a user's works",
        entityCode
    });
}

export interface WorkCountInfo {
    ownedWorks: number;
    ownerId: string;
}

export async function getWorkCountInfo(ownerId: string, authToken: string): Promise<WorkCountInfo> {
    const url = `${host}/v2/works/count?tenants=${WORK_TENANT}&ownerId=${ownerId}`;
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:getAllWorks",
        friendlyDescription: "retrieve all of a user's works",
        entityCode
    });
}

export async function patchWorkName(workId: string, authToken: string, newName: string): Promise<void> {
    const url = `${host}/v2/works/${workId}/name`;
    return tryFetch({
        url,
        options: {
            method: "PATCH",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify({ workName: newName })
        },
        moduleFunction: "workEntityClient:patchWorkName",
        friendlyDescription: "update the name of a user's works",
        entityCode
    });
}

/**
 *
 * @param {string} ownerId
 * @param {string} authToken
 * @param {string} sortBy Use WesSortOptions for available values
 * @returns list of works, up to 50, optionally sorted
 */
async function getSortedWorksInternal(
    ownerId: string,
    authToken: string,
    sortBy = WesSortOptions.LAST_CREATED,
    pageSize = 50,
    offset = 0
): Promise<WorkEntity[]> {
    const url = `${host}/v2/works?tenants=${WORK_TENANT}&ownerId=${ownerId}&pageSize=${pageSize}&sortBy=${sortBy}&offset=${offset}`;
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:getAllWorksSorted",
        friendlyDescription: "retrieve sorted works",
        entityCode
    });
}

/**
 *
 * @param {string} ownerId
 * @param {string} authToken
 * @param {string} searchTerm Search term for work name
 * @param {string} sortBy Use WesSortOptions for available values
 * @returns list of works matching the search term, up to 50, optionally sorted
 */
export async function getWorksSearchResults(
    ownerId: string,
    authToken: string,
    searchTerm: string,
    sortBy = WesSortOptions.LAST_CREATED,
    pageSize = 50,
    offset = 0
): Promise<WorkEntity[]> {
    const url = `${host}/v2/works:search?tenants=${WORK_TENANT}&ownerId=${ownerId}&pageSize=${pageSize}&sortBy=${sortBy}&offset=${offset}&workName=${searchTerm}`;
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:getWorksSearchResults",
        friendlyDescription: "retrieve works with names including the search term",
        entityCode
    });
}

export async function replaceEmptyNames(works: WorkEntity[], locale: string) {
    const namedWorks = await Promise.all(
        works.map(async work => {
            const updatedWork = { ...work };
            if (!updatedWork.workName || !updatedWork.workName.length) {
                try {
                    const workLocale =
                        (updatedWork?.merchandising?.mpvUrl &&
                            updatedWork.merchandising.mpvUrl
                                .toLowerCase()
                                .split("/")
                                .find((section: string) => /^[a-z]{2}-[a-z]{2}$/.test(section))) ||
                        locale;
                    const name = await getProductName(
                        updatedWork.product?.key || updatedWork.design.metadata?.productKey,
                        workLocale
                    );
                    updatedWork.workName = name;
                    // eslint-disable-next-line no-empty
                } catch (err) {
                    // account for possible null values if the mpv call fails
                    updatedWork.workName = "";
                }
            }
            return updatedWork;
        })
    );
    return namedWorks;
}

/**
 * Retrieves sorted works with special logic for sorting legacy/new world works by date
 * This should go away after IM fixes dates for imported works (July or August 2021)
 * Copied from https://gitlab.com/vistaprint-org/design-technology/my-project-microfrontend/-/blob/master/src/components/projects/ProjectList.jsx#L174
 * At that point we should be able to rename getSortedWorksInternal and just use that
 * @param {string} ownerId
 * @param {string} authToken
 * @param {string} sortBy Use WesSortOptions for available values.  Defaults to last created
 * @returns list of works, optionally sorted
 */
export async function getSortedWorks(
    ownerId: string,
    authToken: string,
    locale: string,
    sortBy = WesSortOptions.LAST_CREATED,
    pageSize = 50,
    previouslyFetchedWorks: WorkEntity[] = []
) {
    const nameSort = sortBy === WesSortOptions.NAME_ASCENDING || sortBy === WesSortOptions.NAME_DESCENDING;

    // intentionally ignoring abelli projects for now
    const fetchedWorks = await getSortedWorksInternal(
        ownerId,
        authToken,
        sortBy,
        pageSize,
        previouslyFetchedWorks?.length
    );

    if (nameSort) {
        return fetchedWorks;
    }

    const namedWorks = await replaceEmptyNames(fetchedWorks, locale);

    return namedWorks;
}

export async function hideWork(authToken: string, workId: string): Promise<void> {
    const url = `${host}/v1/works/${workId}/setVisible`;
    return tryFetch({
        url,
        options: {
            method: "PATCH",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify({ hidden: true })
        },
        moduleFunction: "workEntityClient:hideWork",
        friendlyDescription: "hide (soft delete) a user's work by workId",
        entityCode
    });
}

/**
 *
 * @param {string} authToken
 * @param {string} workId
 * @param {boolean} includeHidden
 */
export async function getWorkRevisions(
    authToken: string,
    workId: string,
    includeHidden = false
): Promise<WorkEntity[]> {
    const url = `${host}/v1/works/${workId}/revisions?includeHidden=${includeHidden}&tenant=${WORK_TENANT}`;
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:getWorkRevisions",
        friendlyDescription: "retrieve revisions associated with a work",
        entityCode
    });
}

/**
 * This is used for retrieving a work entity even before a save, and should only be used for debugging purposes.
 */
export async function getWorkEntityDebug(authToken: string, identity: Identity, udsResponse: any, currentState: any) {
    return constructWorkEntity(authToken, udsResponse, currentState);
}
