import { createSlice } from "@reduxjs/toolkit";

const matchingTemplatesLoadedSlice = createSlice({
    name: "matchingTemplatesLoaded",
    initialState: false,
    reducers: {
        setMatchingTemplatesLoaded: () => true
    }
});

export const { setMatchingTemplatesLoaded } = matchingTemplatesLoadedSlice.actions;
export const matchingTemplatesLoaded = matchingTemplatesLoadedSlice.reducer;
