import { isCareAgent } from "@shared/utils/Care";
import { tryFetch } from "@shared/utils/Network";
import { newRelicWrapper } from "@shared/utils/Errors";
import { Identity } from "@shared/utils/Identity";

const crmHost = CUSTOMER_RESOURCE_MANAGER_URL;
const crmEntityCode = 36;

export async function transferResourcesClient(authToken: string) {
    try {
        return await tryFetch({
            url: `${crmHost}/api/v1/resources/transfer`,
            options: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`
                }
            },
            moduleFunction: "ownershipClient:transferResourcesClient",
            friendlyDescription: "transfer customer resources",
            entityCode: crmEntityCode
        });
    } catch (e) {
        if (e.status === 403) {
            return { UNAUTHORIZED: true };
        }
        return {};
    }
}

export async function transferUsingCRM(authToken: string) {
    const result = await transferResourcesClient(authToken);
    if (result && result.status && result.status.UNAUTHORIZED) {
        return false;
    }
    return true;
}

// Once anonymous user login using auth0, auth0 will redirect user back to studio.
// Studio will try to load work entity owned by anonymous and it will get 403 unless we transfer it.
export async function waitUntilTransfer(anonymousCanonicalId: string, identity: Identity, authToken: string) {
    const userProfileWas = identity.was;

    if (userProfileWas.indexOf(anonymousCanonicalId) > -1) {
        return transferUsingCRM(authToken);
    }

    newRelicWrapper.logPageAction("UsingOwnershipTransfer", {
        anonymousCanonicalId,
        userProfileWas: JSON.stringify(userProfileWas),
        canonicalId: identity.shopperId
    });
    return false;
}

/**
 * Transfer resources (work/document/uploads) from the anonmyous user to the logged in user
 */
export async function transferResources(identity: Identity, authToken: string) {
    // if current user is anonymous, mark session storage key with canonical id of anonymous user, so when
    // studio refresh, studio can wait until resources has been transferred over
    if (identity.isSignedIn) {
        const anonymousCanonicalId = sessionStorage.getItem("anonymous_canonical_id");
        if (anonymousCanonicalId && !isCareAgent()) {
            newRelicWrapper.logPageAction("ownership-transfer-start");
            const transferred = await waitUntilTransfer(anonymousCanonicalId, identity, authToken);
            if (transferred) {
                sessionStorage.removeItem("anonymous_canonical_id");
            }
            newRelicWrapper.logPageAction("ownership-transfer-end", { transferred });
        }
    }
}
