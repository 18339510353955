export const STUDIO_TRACKING_EVENTS = {
    CLICK_ADD_BUTTON: "click:addButton",
    CLICK_BACK_SHEET: "click:backSheet:",
    CLICK_ADD_SHEET: "click:addSheet:",
    CLICK_TEMPLATE: "click:template:",
    CLICK_RESET_TEMPLATE: "click:template:reset",
    CLICK_MY_PROJECTS: "click:myProjectsButton",
    CLICK_FILE_BUTTON: "click:fileButton",

    // Shape Events
    CLICK_SHAPE: "click:shape",
    DRAG_SHAPE_ADD: "drag:shape:add",

    // Icon Events
    CLICK_ICON: "click:icon",
    DRAG_ICON_ADD: "drag:icon:add",

    // Clipart Events
    CLICK_CLIPART: "click:clipart",
    SEARCH_CLIPART: "search:clipart",
    DRAG_CLIPART_ADD: "drag:clipart:add",

    // Image Events - Deposit Photos
    CLICK_IMAGE: "click:image",
    DRAG_IMAGE_ADD: "drag:image:add",

    // Elements Events
    CLICK_ELEMENTS_VIEW_ALL: "click:elements:viewAll",
    SEARCH_ELEMENTS: "search:elements",

    // Save Events
    CLICK_SAVE_BUTTON: "Save Button Clicked",
    CLICK_SAVE_STATUS_BUTTON: "Save Status Button Clicked",
    CLICK_SAVE_AS_BUTTON: "Save As Button Clicked",

    // Help Events
    CLICK_HELP_BUTTON: "click:helpButton",

    // My Projects Events
    CLICK_MY_PROJECTS_SORT_DROPDOWN: "click:myProjectsSortDropdown",
    CLICK_MY_PROJECTS_SORT_OPTION: "click:myProjectsSortOption",
    CLICK_MY_PROJECTS_SEE_ALL: "click:myProjectsSeeAll",
    CLICK_CLOSE_MY_PROJECTS: "click:closeMyProjects",
    CLICK_EDIT_MY_PROJECTS: "click:editMyProjects",
    CLICK_DUPLICATE_MY_PROJECTS: "click:duplicateMyProjects",
    CLICK_DELETE_MY_PROJECTS: "click:deleteMyProjects",
    CLICK_RENAME_MY_PROJECTS: "click:renameMyProjects",
    CLICK_CONFIRM_RENAME_MY_PROJECTS: "click:confirmRenameMyProjects",
    PROJECT_LOADED_FROM_MY_PROJECTS: "Project loaded from My Projects",
    SEARCH_PROJECTS: "Search My Projects",
    CLEAR_SEARCH_PROJECTS: "Clear My Projects search",

    // Add / Change Side Model
    ADD_SIDE: "model:addSide",
    CHANGE_SIDE: "model:changeSide",

    // Guides Popover click
    CLICK_VIEW_BUTTON: "click:viewButton",

    // Project Dropdown Events
    CLICK_OPEN_PROJECT_DROPDOWN: "click:projectNameDropdown",
    CLICK_DUPLICATE_DESIGN: "click:projectNameDropdownDuplicateDesign",

    // Font Size Input
    ENTER_INVALID_FONT_SIZE: "input:invalidFontSize",
    HOVER_INVALID_FONT_SIZE: "hover:invalidFontSize",
    LEAVE_INVALID_FONT_SIZE: "leave:invalidFontSize",

    CLOSE_SIDEBAR_PANEL: "click:closeSidebarPanel",
    CLICK_IMAGE_CHANGE_COLOR_BUTTON: "click:imageChangeColorButton",

    // Single Color Image Modal
    SINGLE_COLOR_IMAGE_MODAL_AUTO_OPEN: "modal:autoOpen:singleColorImageModal",

    // Postcards Mailing Services Events
    CLICK_MAILING_SERVICES_BUTTON: "click:mailingServicesButton",
    CLICK_NON_MAILING_SERVICES_BUTTON: "click:nonMailingServicesButton",
    CLICK_YES_MAIL_THEM_FOR_ME_BUTTON: "click:mailingServicesYesMailThemForMeButton",
    CLICK_REMOVE_MAILING_SERVICES_BUTTON: "click:removeMailingServicesButton",
    CLICK_NO_THANK_BUTTON: "click:mailingServicesNoThanksButton",
    SHOW_ADDED_MAILING_SERVICES_TO_POSTCARD: "show:addedMailingServicesToPostcard",
    SHOW_GUIDE_MODAL_OF_MAILING_SERVICES: "show:guideModalOfMailingServices",

    // Change Product Size Modal
    CLICK_CHANGE_PRODUCT_SIZE_HEADER_BUTTON: "click:changeProductSizeHeaderButton",
    CLICK_CHANGE_PRODUCT_SIZE_SELECTION_SET: "click:changeProductSizeSelectionSet",
    CLICK_CHANGE_PRODUCT_SIZE_SUBMIT_BUTTON: "click:changeProductSizeSubmitButton",
    CLICK_CHANGE_PRODUCT_SIZE_MODAL_CLOSE_BUTTON: "click:changeProductSizeModalCloseButton",
    CLICK_CHANGE_PRODUCT_SIZE_SELECTION_SET_SCROLL: "click:changeProductSizeSelectionSetScroll",
    SHOW_CHANGE_SIZE_BUTTON_ON_VISIBLE_PRD: "show:changeProductSizeButtonOnCompatibleProduct",
    SHOW_CURRENT_CHANGE_PRODUCT_SIZE: "show:currentChangeProductSize",
    SHOW_NEW_CHANGE_PRODUCT_SIZE: "show:newChangeProductSize",

    // Flexibility

    // CLICK_CHANGE_PRODUCT_SIZE_SELECTION_SET_SCROLL has no new event
    // maps to SHOW_CHANGE_SIZE_BUTTON_ON_VISIBLE_PRD
    SHOW_PRODUCT_OPTIONS_BUTTON: "show:productOptionsButton",

    // maps to CLICK_CHANGE_PRODUCT_SIZE_HEADER_BUTTON
    CLICK_DESIGN_ATTRIBUTE_ON_PRODUCT_OPTIONS_PANEL: "click:productOptionsChangeDesignAttribute",

    // maps to CLICK_CHANGE_PRODUCT_SIZE_SELECTION_SET
    CLICK_VALUE_ON_CHANGE_DESIGN_ATTRIBUTE_MODAL: "click:changeDesignAttributeModalValue",

    // maps to CLICK_CHANGE_PRODUCT_SIZE_SUBMIT_BUTTON & SHOW_CURRENT_CHANGE_PRODUCT_SIZE & SHOW_NEW_CHANGE_PRODUCT_SIZE (as they were firing 3 events)
    CLICK_CTA_BUTTON_ON_CHANGE_DESIGN_ATTRIBUTE_MODAL: "click:changeDesignAttributeModalCTA",

    // maps to CLICK_CHANGE_PRODUCT_SIZE_MODAL_CLOSE_BUTTON
    CLOSE_CHANGE_DESIGN_ATTRIBUTE_MODAL: "close:changeDesignAttributesModal",

    // This is a new event
    CLICK_BACK_TO_CHANGE_PREMIUM_FINISH_MODAL: "click:backToChangePremiumFinishModal",

    CLICK_TRIM_ON_CANVAS_BUTTON: "click:canvas:changeTrim",
    CLICK_SIZE_ON_CANVAS_BUTTON: "click:canvas:changeSize",

    // Gallery Templates
    CLICK_CHANGE_TEMPLATE_BUTTON: "click:changeTemplateButton",
    CLICK_CHANGE_TEMPLATE: "click:changeTemplate",
    CHANGE_TEMPLATE_CONFIRMATION_SHOWN: "show:changeTemplateConfirmation",
    CLICK_CHANGE_TEMPLATE_CONFIRMATION: "click:changeTemplateConfirmation",
    CLICK_APPLY_TEMPLATE_COLOR_BUTTON: "click:changeApplyTemplateColorButton",
    CLICK_GALLERY_CHANGE_PAGE: "click:galleryChangePage",
    CHANGE_TEMPLATE_SUCCESS: "changeTemplateSuccess",
    CHANGE_TEMPLATE_COLOR_SUCCESS: "changeTemplateColorSuccess",
    CHANGE_TEMPLATE_FAILURE: "changeTemplateFailure",
    CHANGE_TEMPLATE_COLOR_FAILURE: "changeTemplateColorFailure",
    CHANGE_TEMPLATE_SEARCH: "input:changeTemplateSearch",
    CLICK_GALLERY_TEMPLATE_COLOR: "click:galleryTemplateColor",
    CLICK_GALLERY_FILTER: "click:galleryFilter",
    CLICK_CLEAR_GALLERY_FILTERS: "click:clearGalleryFilters",
    NO_TEMPLATE_RESULTS: "noTemplateResults",
    SHOW_CHANGE_TEMPLATE_BUTTON: "show:changeTemplateButtonOnCompatibleProduct",

    // Change Colors
    CLICK_CHANGE_TEMPLATE_DESIGN_COLOR_TEMPLATE_ONLY: "click:changeTemplateDesignColorTemplateOnly",
    CLICK_CHANGE_TEMPLATE_DESIGN_COLOR_BOTH_TEMPLATE_AND_MATERIAL:
        "click:changeTemplateDesignColorBothTemplateAndMaterial",
    CLICK_CHANGE_MATERIAL_COLOR_MATERIAL_ONLY: "click:changeMaterialColorMaterialOnly",
    CLICK_CHANGE_MATERIAL_COLOR_BOTH_TEMPLATE_AND_MATERIAL: "click:changeMaterialColorBothTemplateAndMaterial",
    SHOW_CHANGE_TEMPLATE_COLOR_BUTTON_ONLY_TEMPLATE: "show:changeTemplateColorButtonOnCompatibleProductTemplateOnly",
    SHOW_CHANGE_TEMPLATE_COLOR_BUTTON_BOTH_TEMPLATE_AND_MATERIAL:
        "show:changeTemplateColorButtonOnCompatibleProductBothTemplateAndMaterial",

    // Color Picker Panel
    CLICK_COLOR_PICKER_PANEL: "click:colorPickerPanel",
    CLICK_CANVAS_BACKGROUND_COLOR: "click:canvasBackgroundColor",
    SHOW_CHANGE_BACKGROUND_COLOR_BUTTON: "show:changeBackgroundButtonOnCompatibleProduct",
    CLICK_SKIP_TO_MAIN: "click:skipToMain",

    // Teams Experience Panel
    CLICK_TEAMS_NAME_PLACEHOLDER_BUTTON: "click:teamsNamePlaceholder",
    CLICK_TEAMS_ROLE_PLACEHOLDER_BUTTON: "click:teamsRolePlaceholder",
    CLICK_TEAMS_NUMBER_PLACEHOLDER_BUTTON: "click:teamsNumberPlaceholder",
    CLICK_TEAMS_TEXT_PLACEHOLDER_BUTTON: "click:teamsTextPlaceholder",
    SHOW_TEAMS_EXPERIENCE_GUIDE_MODAL: "show:teamsExperienceGuideModal",

    // Specs and templates Panel
    CLICK_SPECS_AND_TEMPLATES_BUTTON: "click:openSpecsandTemplates",
    DOWNLOAD_SPECS_AND_TEMPLATES_FILE: "click:downloadSpecsandTemplates:[FILE TYPE]",

    // upload panel
    CLICK_UPLOAD_BUTTON: "click:imagepanel:upload:[CanvasSide]:autoload",
    CLICK_RECENTLY_UPLOADED_IMAGE: "click:imagepanel:RecentImage:[CanvasSide]:autoload",
    SHOW_UPLOAD_MODAL: "view:imagepanel:autoload",
    UPLOAD_COMPLETE: "upload:imagepanel:success:[CanvasSide]:autoload",
    CLICK_SHOW_UPLOAD_ACCEPTED_FORMATS: "click:showUploadAcceptedFormats",
    CLICK_QR_MOBILE_UPLOAD_BUTTON: "click:imagepanel:uploadMobile:[CanvasSide]",
    QR_MOBILE_UPLOAD_COMPLETE: "uploadMobile:imagepanel:success:[CanvasSide];[Number of Images Uploaded]",

    // Saved from
    SAVE_BUTTON: "Save Button",
    SAVE_STATUS_BUTTON: "Save Status Button",
    SAVED_FROM_CONTINUE_BUTTON: "Continue Button",
    SAVED_FROM_MY_PROJECTS: "My Projects",
    SAVED_FROM_DUPLICATE_PROJECT: "Duplicate Project",
    SAVED_FROM_RENAME_PROJECT: "Rename Project",
    SAVED_FROM_SWITCH_PRODUCTS: "Switch Products",
    SAVED_FROM_CHANGE_TEMPLATE: "Change Template",
    SAVED_FROM_IMAGE_LOGIN: "Image Login",
    SAVED_FROM_HELP_BUTTON: "Help Button",
    SAVED_FROM_KEYBOARD: "Keyboard",
    SAVED_FROM_AUTO_SAVE: "Auto Save",

    // These came from STUDIO_TRACKING_EVENTS
    CROP_OPEN: "modal:crop:open",
    CROP_APPLIED: "modal:crop:applied",
    CROP_CANCELED: "modal:crop:canceled",
    CROP_ASPECT_RATIO_LOCK_MODIFIED: "modal:crop:aspectRatio",
    CLICK_DELETE: "click:delete",
    CLICK_LEFT_PANEL_DELETE: "click:leftPanelDelete",
    CLICK_LEFT_PANEL_WORD_ART_DELETE: "click:leftPanelWordArtDelete",
    CLICK_ARRANGE: "click:arrange",
    CLICK_ARRANGE_SEND_TO_BACK: "click:arrange:sendToBack",
    CLICK_ARRANGE_SEND_BACKWARD: "click:arrange:sendBackward",
    CLICK_ARRANGE_SEND_FORWARD: "click:arrange:sendForward",
    CLICK_ARRANGE_SEND_TO_FRONT: "click:arrange:sendToFront",
    CLICK_DUPLICATE: "click:duplicate",
    CLICK_LOCK: "click:lock",
    CLICK_UNLOCK: "click:unlock",
    CLICK_IMAGE_ADD: "click:imageAdd",
    CLICK_IMAGE_REPLACE: "click:imageReplace",
    LOW_RESOLUTION_IMAGE_WARNING: "lowresolutionimage:warning",
    LOW_RESOLUTION_IMAGE_ERROR: "lowresolutionimage:error",
    SEARCH_ICON: "search:icon",
    SEARCH_IMAGES: "search:images",
    IMAGE_LIB_OPEN_FILTERS: "click:images:filters",
    IMAGE_LIB_CLOSE_FILTERS_OUTSIDE: "images:filters:close:outside",
    IMAGE_LIB_CLOSE_FILTERS_APPLY: "images:filters:close:click",
    IMAGE_LIB_CLEAR_ALL_FILTERS: "click:images:filters:clearAll",
    IMAGE_LIB_CLEAR_SEARCH_TERM: "search:images:searchTerm:clear",
    IMAGE_LIB_FILTER_SELECT_PEOPLE: "select:images:filter:people",
    IMAGE_LIB_FILTER_SELECT_ORIENTATION: "select:images:filter:orientation",
    IMAGE_LIB_FILTER_SELECT_COLOR: "select:images:filter:color",
    IMAGE_GALLERY_IMAGE_OPEN: "image:preview:open",
    IMAGE_GALLERY_IMAGE_CLOSE: "image:preview:close",
    IMAGE_GALLERY_IMAGE_ADD_TO_DESIGN: "click:image:addToDesign",
    IMAGE_LIB_SELECT_RECENTLY_USED: "select:search:recentlyUsed",
    DOUBLE_CLICK_ICON_ADD: "doubleClick:icon:add",
    ADD_ICON_BTN_ICON_ADD: "addIconBtn:icon:add",
    ENTER_KEY_ICON_ADD: "enterKey:icon:add",
    CLICK_ICON_REPLACE: "click:icon:replace",
    CLICK_ICON_RESULTS_NEXT_PAGE: "click:iconSearchResults:nextPage",
    CLICK_ICON_RESULTS_PREVIOUS_PAGE: "click:iconSearchResults:PreviousPage",
    CLICK_REPLACE_ICON_TOOL: "click:replaceIconModal:open",
    LOADED_NO_ICONS_PAGE: "IconPanel:noIconsPage",
    CLICK_ICON_BACK_TO_TOP: "click:iconSearchResults:backToTop",
    CLICK_SHARPEN: "click:sharpen",
    CLICK_FONT: "click:font",
    CLICK_FONT_CHOICE: "click:font:choice",
    CLICK_FONT_BROWSER: "click:font:browser",
    CLICK_FONT_CLOSE_BROWSER: "click:font:closeBrowser",
    CLICK_FONT_CATEGORY: "click:font:category",
    CLICK_FONT_SIZE: "click:fontsize",
    CLICK_EDIT_TEXT: "click:editText",
    CLICK_ITEM_EDIT_TEXT: "click:itemEditText",
    CLICK_ALIGN: "click:align",
    CLICK_ALIGN_LEFT: "click:align:left",
    CLICK_ALIGN_CENTER: "click:align:center",
    CLICK_ALIGN_RIGHT: "click:align:right",
    FONT_RGB_APPLIED: "font:rgb:applied",
    FONT_CMYK_APPLIED: "font:cmyk:applied",
    FONT_COLORTILE_APPLIED: "font:colortile:applied",
    FONT_EYEDROPPER_APPLIED: "font:eyedropper:applied",
    FONT_SIZE_INCREMENT_UP: "font:size:incrementUp",
    FONT_SIZE_INCREMENT_DOWN: "font:size:incrementDown",
    SIZE_DROPDOWN_SELECTION: "sizeDropdown:selection",
    SIZE_DROPDOWN_INPUT_ENTERED: "sizeDropdown:textInputEntered",
    SIZE_DROPDOWN_TEXT_INPUT: "sizeDropwdown:textInput",
    CLICK_PREMIUM_FINISH: "click:premiumFinish",
    CLICK_MOVE: "click:move",
    CLICK_BOLD: "click:bold",
    CLICK_ITALICS: "click:italics",
    CLICK_STRIKETHROUGH: "click:strikethrough",
    CLICK_UNDERLINE: "click:underline",
    CLICK_IMAGEPANEL_UPLOAD: "click:imagepanel:upload",
    CLICK_IMAGEPANEL_DELETE: "click:imagepanel:delete",
    IMAGE_COLOR_TOOL_SELECTION: "imageColorTool:selection",
    USE_HUE_SLIDER: "use:hueSlider",
    USE_SATURATION_SLIDER: "use:saturationSlider",
    USE_LIGHTNESS_SLIDER: "use:lightnessSlider",
    CLICK_HUE_RESET: "click:hueReset",
    CLICK_SATURATION_RESET: "click:saturationReset",
    CLICK_LIGHTNESS_RESET: "click:lightnessReset",
    IMAGE_RGB_APPLIED: "image:rgb:applied",
    IMAGE_CMYK_APPLIED: "image:cmyk:applied",
    IMAGE_COLORTILE_APPLIED: "image:colortile:applied",
    IMAGE_EYEDROPPER_APPLIED: "image:eyedropper:applied",
    CLICK_TABLE_OVERLAY_COLUMN: "click:tableOverlay:column",
    CLICK_TABLE_OVERLAY_ROW: "click:tableOverlay:row",
    CLICK_TABLE_OVERLAY_CELL: "click:tableOverlay:cell",
    CLICK_ADD_TABLE_COLUMN_LEFT: "click:tableOverlay:add:column:left",
    CLICK_ADD_TABLE_COLUMN_RIGHT: "click:tableOverlay:add:column:right",
    CLICK_ADD_TABLE_ROW_ABOVE: "click:tableOverlay:add:row:above",
    CLICK_ADD_TABLE_ROW_BELOW: "click:tableOverlay:add:row:below",
    CLICK_REMOVE_TABLE_ROW: "click:tableOverlay:remove:row",
    CLICK_REMOVE_TABLE_COLUMN: "click:tableOverlay:remove:column",
    USE_TABLE_OVERLAY_COLUMN_RESIZE: "use:tableOverlay:columnResize",
    CLICK_CHANGE_TABLE_DESIGN: "click:table:change:design",
    CLICK_CHANGE_TABLE_COLOR: "click:table:change:color",
    CLICK_ADD_TABLE: "click:table:add",
    DRAG_ADD_TABLE: "drag:table:add",
    SAVE_SUSPECT_VALUES: "save:image:crop:cropFractions",
    SAVE_BAD_BLANK_PANEL: "save:panel:blank:hasContent",
    CLICK_SIDEBAR_BUTTON: "click:sidebar:",
    CLICK_TEXT_FIELD_ADD: "click:textfield:add",
    CLICK_WORD_ART_FIELD_ADD: "click:wordartfield:add",
    CLICK_LIST: "click:list",
    CLICK_LIST_ORDERED: "click:list:ordered",
    CLICK_LIST_UNORDERED: "click:list:unordered",
    EDIT_SHAPE_SELECTION: "editShape:selection",
    CLICK_SHAPE_ATTRIBUTE_RESET: "click:shape:attributeReset",
    EDIT_DRAG_SHAPE_ATTRIBUTE: "edit:drag:shape:attribute",
    EDIT_INPUT_SHAPE_ATTRIBUTE: "edit:input:shape:attribute",
    CLICK_BACKGROUND_REMOVE: "click:backgroundRemove",
    CLICK_BACKGROUND_RESTORE: "click:backgroundRestore",
    CLICK_ASPECT_RATIO_LOCK: "click:image:aspectRatio:lock",
    CLICK_ASPECT_RATIO_UNLOCK: "click:image:aspectRatio:unlock",
    CLICK_RESTORE_PROPORTIONS: "click:image:restoreProportions",
    CLICK_WORD_ART_FONT_SIZE: "click:wordartfontsize",
    CLICK_ROTATE_OPEN: "click:rotateopen",
    TIMING_ENGINE_START: "timing:engine:start",
    CLICK_SINGLE_COLOR_IMAGE_APPLY_BUTTON: "click:singleColorImage:applyUpdates",
    UPDATE_SINGLE_COLOR_THRESHOLD_SLIDER: "slide:singleColorImage:updateThreshold",
    CLICK_SINGLE_COLOR_IMAGE_INVERT_COLORS_BUTTON: "click:singleColorImage:invertColors",
    CLICK_SINGLE_COLOR_IMAGE_UPDATE_COLOR: "click:singleColorImage:updateColor",
    CLICK_ICONSANDSHAPES_TAB: "click:tab:iconsandshapes",
    CLICK_GRAPHICS_TAB: "click:tab:graphics",
    CLICK_UNDO: "click:undo",
    CLICK_ADD_QRCODE: "click:add:qrcode",

    // AI Templates
    // The capitalized terms in these strings are tokens that will be replaced in the tracking event
    OPEN_DESIGN_PANEL: "Open Design Tab",
    OPEN_IDEAS_PANEL: "Open Ideas Tab",
    CLICK_AI_TEMPLATE: "User clicked template TEMPLATE_ID : TEMPLATE_INDEX",
    CLICK_PREVIOUS_AI_TEMPLATE: "User clicked previous template TEMPLATE_ID : TEMPLATE_INDEX",
    REPLACE_AI_TEMPLATE: "Replace Design",
    CLICK_REPLACE_DESIGN_MODAL_CANCEL: "Cancel Replace Design",
    REPLACE_DESIGN_MODAL_CLOSED: "Close Replace Design Modal",
    SUBMIT_AI_TEMPLATE_SEARCH_TERM: "User searched for 'SEARCH_TERM'",
    SHOW_MORE_AI_TEMPLATES: "User triggered Show More scroll event",
    AI_TEMPLATES_DESIGN_TOOL_USED: "click:sidebar:aiDesignTemplates",
    AI_TEMPLATE_SELECTED: "select:aiDesignTemplates",
    DA_TEMPLATE_SELECTED: "select:daDesignTemplates",

    // Left Template Panel
    CLICK_RECENTLY_USED_VIEW_ALL: "click:panel:template:recents:viewall",
    CLICK_TEMPLATE_FILTERS_TOGGLE: "click:panel:template:filters:toggle",
    CLICK_TEMPLATE_FILTERS_CLEAR_ALL: "click:panel:template:filters:clearAll",
    CLICK_TEMPLATE_FILTERS_APPLY: "click:panel:template:filters:apply",
    SHOW_TEMPLATE_PANEL_CONFIRMATION_DIALOG: "show:panel:template:confirmationDialog",

    // Placeholders
    AUTO_PLACEMENT_IMAGES: "Images Auto Placement"
};
