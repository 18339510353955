import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const warningsSlice = createSlice({
    name: "warnings",
    initialState: [] as string[],
    reducers: {
        setWarnings(state, action: PayloadAction<string[]>) {
            return [...state, ...action.payload];
        },
        resetWarnings() {
            return [];
        }
    }
});

export const { setWarnings, resetWarnings } = warningsSlice.actions;
export const warnings = warningsSlice.reducer;
