import { createSlice } from "@reduxjs/toolkit";
import { setSaveSuccess } from "./saveSuccess";

const hideSaveToastSlice = createSlice({
    name: "hideSaveToast",
    initialState: false as boolean,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setSaveSuccess, (state, action) => action.payload?.hideSaveToast ?? false);
    }
});

export const hideSaveToast = hideSaveToastSlice.reducer;
