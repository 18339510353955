/* eslint-disable no-param-reassign */
import merge from "lodash/merge";
import type { StudioConfiguration } from "@shared/utils/StudioConfiguration";
import { POSTCARD_PRDS } from "@shared/features/MailingServices";
import { scaleToFitCropStrategy, enableTrimPaths, mailingPostCardValidation } from "./snippets";
import { isEngravingView, getEngravingColor, isEmbroideryView } from "../utils";
import { defaultTrimPaths } from "./snippets/enableTrimPaths";
import { defaultCropStrategy } from "./snippets/scaleToFitCropStrategy";
import { enableBetweenBoundariesValidation } from "./snippets/enableBetweenBoundariesValidation";
import { defaultZoom } from "./snippets/defaultZoom";

function isMailingPostcard(productKey: string) {
    return POSTCARD_PRDS.MAILING === productKey;
}

export function getProductConfiguration(
    productKey: string,
    { shouldUseBetweenBoundariesValidation, shouldUseTrimPaths, cropStrategy, initialZoomFactor }: StudioConfiguration
) {
    let defaultConfig = merge({}, defaultTrimPaths, defaultCropStrategy);

    if (cropStrategy === "scaleToFit") {
        defaultConfig = merge(defaultConfig, scaleToFitCropStrategy);
    }

    if (isMailingPostcard(productKey)) {
        defaultConfig = merge(defaultConfig, mailingPostCardValidation(productKey));
    }

    if (shouldUseTrimPaths) {
        defaultConfig = merge(defaultConfig, enableTrimPaths);
    }

    if (shouldUseBetweenBoundariesValidation) {
        defaultConfig = merge(defaultConfig, enableBetweenBoundariesValidation);
    }

    if (initialZoomFactor) {
        defaultConfig = merge(defaultConfig, defaultZoom(initialZoomFactor));
    }

    return defaultConfig;
}

export function UpdateDesignerConfigurationForSurfaces(
    currentConfig: any,
    surfaceSpecifications: any,
    adjustedZoom = 1
) {
    if (isEngravingView(surfaceSpecifications, 0)) {
        const viewEngravingColor = getEngravingColor(surfaceSpecifications, 0);
        if (viewEngravingColor) {
            currentConfig.core.engraving.color = viewEngravingColor;
        }
    }

    if (isEmbroideryView(surfaceSpecifications, 0)) {
        currentConfig.validations.outsideBounds.image.enabled = true;
    }

    if (
        // not going to bother trying to resize multiple canvases
        surfaceSpecifications.length === 1 &&
        // only resize if the product is smalll enough, arbitrarily less than 4cm in one dimension
        (surfaceSpecifications[0].heightCm < 4 || surfaceSpecifications[0].widthCm < 4)
    ) {
        // convert the height to 'vistapixels', which are 144px per inch
        const vistaPixels = (surfaceSpecifications[0].heightCm / 2.54) * 144;
        const canvases = document?.getElementById("canvases");
        if (canvases) {
            // find the available size for the height of the canvas
            const availableHeight = canvases.offsetHeight || 0;

            // default zoom in designer is 1, so only update this if we're actually starting smaller than that
            if (vistaPixels < availableHeight) {
                canvases.style.minHeight = `${vistaPixels}px`;

                // This resizes the loading preview which only exists on-load, not after product switching
                const loadingDesignPreview = document.getElementsByClassName("loading-design-preview")[0];
                if (loadingDesignPreview) {
                    // Ignore the style warning on the next line
                    // @ts-ignore
                    loadingDesignPreview.style.height = `${vistaPixels}px`;
                }

                currentConfig.ui.zoomStrategy.initialWidth = adjustedZoom;
                currentConfig.ui.zoomStrategy.initialHeight = adjustedZoom;
            }
        }
    }
}
