export const enableTrimPaths = {
    ui: {
        canvas: {
            chromes: {
                margins: {
                    trim: {
                        enabled: true
                    }
                }
            }
        }
    }
};

export const defaultTrimPaths = {
    ui: {
        canvas: {
            chromes: {
                margins: {
                    trim: {
                        enabled: false
                    }
                }
            }
        }
    }
};
