import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const currentTemplateColorSlice = createSlice({
    name: "currentTemplateColor",
    initialState: "",
    reducers: {
        setCurrentTemplateColor: (state, action: PayloadAction<string | undefined>) => action.payload ?? state
    }
});

export const { setCurrentTemplateColor } = currentTemplateColorSlice.actions;
export const currentTemplateColor = currentTemplateColorSlice.reducer;
