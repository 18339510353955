import {
    RGB,
    hsv2rgb,
    CMYK,
    HSL,
    HSV,
    rgb2hsl,
    cmyk2rgb,
    hsl2rgb,
    rgb2hex,
    hex2rgb,
    CMYKA,
    rgb2cmyk,
    rgb2hsv
} from "@design-stack-ct/utility-core";
import { ColorSpace, type SelectableColor } from "./types";

export const DEFAULT_RGB = { r: 0, g: 0, b: 0 } as RGB;
export const DEFAULT_CMYK = { c: 0, m: 0, y: 0, k: 0 } as CMYK;
export const DEFAULT_HSL = { h: 0, s: 1, l: 0.5 } as HSL;
export const DEFAULT_HSV = { h: 0, s: 0.5, v: 0.5 } as HSV;
export const DEFAULT_HEX = "#000000";
export const NO_COLOR_VALUE = "cmyka(0,0,0,0,0)";

export function isRgb(value: RGB | CMYK | HSL | HSV | string): value is RGB {
    return (value as RGB).r !== undefined;
}

export function isCmyk(value: RGB | CMYK | HSL | HSV | string): value is CMYK {
    return (value as CMYK).c !== undefined;
}

export function isCmyka(value: RGB | CMYK | HSL | HSV | string | CMYKA): value is CMYKA {
    return (value as CMYKA).a !== undefined;
}

export function isHsl(value: RGB | CMYK | HSL | HSV | string): value is HSL {
    return (value as HSL).h !== undefined && (value as HSL).l !== undefined;
}

export function isHsv(value: RGB | CMYK | HSL | HSV | string): value is HSV {
    return (value as HSV).h !== undefined && (value as HSV).v !== undefined;
}

export function parseColor(
    color: RGB | CMYK | HSL | HSV | string
): { value: RGB | CMYK | CMYKA | HSL | HSV | string; colorSpace: ColorSpace } | undefined {
    if (isCmyk(color)) {
        return { colorSpace: ColorSpace.CMYK, value: color };
    }
    if (isRgb(color)) {
        return { colorSpace: ColorSpace.RGB, value: color };
    }
    if (isHsl(color)) {
        return { colorSpace: ColorSpace.HSL, value: color };
    }
    if (isHsv(color)) {
        return { colorSpace: ColorSpace.HSV, value: color };
    }
    if (color.match(/^rgb\(([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3})\)$/)) {
        const colors = color.match(/[0-9]{1,3}/g)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
        return { value: { r: +colors[0], g: +colors[1], b: +colors[2] }, colorSpace: ColorSpace.RGB };
    }
    if (color.match(/(^rgb\((#[0-9a-fA-F]{3}|#[0-9a-fA-F]{6})\))$/)) {
        const match = color.match(/rgb\((.*)\)/)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
        return { value: hex2rgb(match[1])!, colorSpace: ColorSpace.RGB }; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }
    if (color.match(/^(#[0-9a-fA-F]{6}|#[0-9a-fA-F]{3})$/)) {
        return { value: hex2rgb(color)!, colorSpace: ColorSpace.RGB }; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }
    if (color.match(/^cmyka\(([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3})\)$/)) {
        const colors = color.match(/[0-9]{1,3}/g)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
        return {
            value: { c: +colors[0], m: +colors[1], y: +colors[2], k: +colors[3], a: +colors[4] },
            colorSpace: ColorSpace.CMYK
        };
    }
    if (color.match(/^cmyk\(([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3})\)$/)) {
        const colors = color.match(/[0-9]{1,3}/g)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
        return { value: { c: +colors[0], m: +colors[1], y: +colors[2], k: +colors[3] }, colorSpace: ColorSpace.CMYK };
    }
    if (color.includes("pantone")) {
        return { value: color, colorSpace: ColorSpace.Pantone };
    }
    if (color.includes("thread")) {
        return { value: color, colorSpace: ColorSpace.Thread };
    }
    return undefined;
}

export function convertToHsl(color: RGB | CMYK | HSL | HSV | string | undefined): HSL {
    if (!color) {
        return DEFAULT_HSL;
    }
    const parsedColor = parseColor(color);
    if (!parsedColor) {
        return DEFAULT_HSL;
    }
    switch (parsedColor.colorSpace) {
        case ColorSpace.HSL:
            return parsedColor.value as HSL;
        case ColorSpace.HSV:
            return rgb2hsl(hsv2rgb(parsedColor.value as HSV));
        case ColorSpace.CMYK:
            return rgb2hsl(cmyk2rgb(parsedColor.value as CMYK));
        case ColorSpace.RGB:
            return rgb2hsl(parsedColor.value as RGB);
        default:
            return DEFAULT_HSL;
    }
}

export function convertToRgb(color: RGB | CMYK | HSL | HSV | string | undefined): RGB {
    if (!color) {
        return DEFAULT_RGB;
    }
    const parsedColor = parseColor(color);
    if (!parsedColor) {
        return DEFAULT_RGB;
    }
    switch (parsedColor.colorSpace) {
        case ColorSpace.RGB:
            return parsedColor.value as RGB;
        case ColorSpace.CMYK:
            return cmyk2rgb(parsedColor.value as CMYK);
        case ColorSpace.HSL:
            return hsl2rgb(parsedColor.value as HSL);
        case ColorSpace.HSV:
            return hsv2rgb(parsedColor.value as HSV);
        default:
            return DEFAULT_RGB;
    }
}

export function cmykToString(color: CMYK | CMYKA) {
    if (isCmyka(color)) {
        return `cmyka(${color.c}, ${color.m}, ${color.y}, ${color.k}, ${color.a})`;
    }
    return `cmyk(${color.c}, ${color.m}, ${color.y}, ${color.k})`;
}

export function rgbToString(color: RGB) {
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
}

export function colorToString(color: string | RGB | CMYK): string {
    if (isRgb(color)) {
        return rgbToString(color);
    }
    if (isCmyk(color)) {
        return cmykToString(color);
    }
    return color;
}

export const convertToSelectableColor = (
    color: string | RGB | CMYK,
    cssBackgroundOverride?: string
): SelectableColor => {
    if (isRgb(color) || isCmyk(color)) {
        return {
            value: colorToString(color),
            cssBackground: cssBackgroundOverride ?? rgb2hex(convertToRgb(color))
        };
    }
    return {
        value: color,
        cssBackground: cssBackgroundOverride ?? rgb2hex(convertToRgb(color))
    };
};

export function calculateColorValues(value: string | CMYK | RGB | HSV | HSL, colorSpace: ColorSpace) {
    const colorObj: StateColorObject = { ...defaultStateColorObject };
    // If no color option selected, use defaults
    // eslint-disable-next-line dot-notation
    if (colorSpace === ColorSpace.NoColor || value === NO_COLOR_VALUE) {
        return colorObj;
    }
    if (
        colorSpace !== ColorSpace.RGB &&
        colorSpace !== ColorSpace.CMYK &&
        colorSpace !== ColorSpace.HSV &&
        colorSpace !== ColorSpace.HEX
    ) {
        return { ...colorObj, other: value as string };
    }

    colorObj.rgb = colorSpace === ColorSpace.RGB ? (value as RGB) : convertToRgb(value);
    colorObj.cmyk = colorSpace === ColorSpace.CMYK ? (value as CMYK) : rgb2cmyk(colorObj.rgb);
    colorObj.hsv = colorSpace === ColorSpace.HSV ? (value as HSV) : rgb2hsv(colorObj.rgb);
    colorObj.hex = colorSpace === ColorSpace.HEX ? (value as string) : rgb2hex(colorObj.rgb);
    colorObj.noColor = false;
    colorObj.other = null;

    return colorObj;
}

export interface StateColorObject {
    rgb: RGB;
    cmyk: CMYK;
    hsv: HSV;
    hex: string;
    noColor: boolean;
    other: string | null;
}

export const defaultStateColorObject = {
    rgb: DEFAULT_RGB,
    cmyk: DEFAULT_CMYK,
    hsv: DEFAULT_HSV,
    hex: DEFAULT_HEX,
    noColor: true,
    other: null
};

export const areSelectableColorsEqual = (colorA: SelectableColor, colorB: SelectableColor): boolean => {
    return colorA.value === colorB.value || colorA.cssBackground === colorB.cssBackground;
};
