import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useUploadManager } from "@design-stack-vista/upload-components";
import { AssetStoreEvent, ImageInfo } from "@design-stack-ct/assets-sdk";
import { fireGenericTrackingEvent } from "@shared/utils/Tracking";
import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { AssetDataClient, AssetImageInfoEventDTO } from "@design-stack-ct/assets-sdk/dist/store/entities/types";
import { newRelicWrapper } from "@shared/utils/Errors";

interface FieldData {
    field: string;
    analysisField: string;
}

function getBooleanDataFields(
    fields: FieldData[],
    imageInfo?: ImageInfo,
    properties?: Record<string, any>,
    dataInfo?: ImageInfo
): Record<string, boolean> {
    const getBooleanData = ({ field, analysisField }: FieldData) => {
        if (imageInfo) {
            return !!imageInfo[field];
        }
        if (properties && (properties[analysisField] as string) !== "False") {
            return !!properties[analysisField];
        }
        if (dataInfo && dataInfo[field]) {
            return true;
        }
        return false;
    };

    const fieldValues = fields.reduce<Record<string, boolean>>((acc, currentData) => {
        acc[currentData.field] = getBooleanData(currentData);
        return acc;
    }, {});

    return fieldValues;
}

const getMimeType = (asset: VistaAsset | undefined) =>
    asset?.data?.info?.storage?.contentType || asset?.data?.info?.image?.format || "notAvailable";

function extractMetaData(asset?: VistaAsset, imageInfo?: ImageInfo) {
    try {
        if (!asset) {
            return {};
        }
        const { data } = asset;
        // I have to cast this so that the compiler won't complain.
        // Currently the AssetDataClient in vista-assets-sdk doesn't export totalUploadTimeMs, but the assets-sdk one does
        // Eventually this will come out of the vista-assets-sdk (or will just use the asset-sdk one)
        // For now this hack will allow this to compile.
        const { totalUploadTimeMs: timeToUpload } = data as AssetDataClient;
        if (data?.info?.image?.format === "pdf" || data?.info?.image?.format === "unknown") {
            // Less data available for PDFs
            return {
                id: data?.id,
                fileType: getMimeType(asset),
                size: data?.info?.storage?.fileSizeBytes,
                clientInitiator: "studio",
                timeToUpload
            };
        }

        const fields = getBooleanDataFields(
            [
                { field: "isLogo", analysisField: "analysisIsLogo" },
                { field: "isPhoto", analysisField: "analysisIsPhoto" },
                { field: "isVector", analysisField: "analysisIsVector" }
            ],
            imageInfo,
            data?.properties,
            data?.info?.image
        );

        return {
            id: data?.id,
            fileType: getMimeType(asset),
            size: data?.info?.storage?.fileSizeBytes,
            ...fields,
            lineartness:
                imageInfo?.lineartness ||
                (data?.properties?.analysisLineartness as string) ||
                `${data?.info?.image?.lineartness}`,
            clientInitiator: "studio",
            timeToUpload
        };
    } catch (e) {
        return {};
    }
}

const studioDiagnosticEventName = "Studio Diagnostic";

export const onUploadErrorEvent = (asset: VistaAsset | undefined, error: string | Error = "Unknown Error") => {
    // We're not using handleError so that we mimic how pre-UC studio5 worked and our dashboards don't break
    let assetUrl = "";
    try {
        if (asset) {
            assetUrl = asset.getUrl({ showDeleted: true });
        }
    } catch {
        /* empty */
    }
    const errorMessage = error instanceof Error ? error.message : error;
    newRelicWrapper.logPageAction("studio-onUpload-failed", {
        fileType: getMimeType(asset),
        errorMessage,
        isDamAsset: asset?.isDamAsset(),
        fileName: asset?.data?.info?.storage?.fileName,
        assetId: asset?.data?.id,
        assetName: asset?.data?.name,
        status: asset?.status?.type,
        // @ts-ignore
        rawError: asset?.status?.rawError instanceof Error ? asset?.status?.rawError.message : asset?.status?.rawError,
        // @ts-ignore
        assetError: asset?.data?.info?.image?.error,
        assetUrl
    });
    fireGenericTrackingEvent({
        event: studioDiagnosticEventName,
        eventDetail: "Upload Failed: Overall",
        label: "Image",
        extraData: () => {
            return { ...extractMetaData(asset), errorMessage };
        }
    });
};

// Sherbert is pretty stingy about providing data so we'll track stuff locally here
// There is a disconnect between UploadSuccess (which just as an asset with no useful data yet)
// and the ImageInfo events which do add additional data
const successfulUploadedAssets: VistaAsset[] = [];

export const CustomUploadEvents = observer(() => {
    const { addEventListener, removeEventListener } = useUploadManager();

    useEffect(() => {
        if (!addEventListener || !removeEventListener) {
            return () => {};
        }

        const logSuccessfulUpload = (event: CustomEvent<AssetImageInfoEventDTO>, asset: VistaAsset) => {
            const info = event.detail.imageInfo;
            fireGenericTrackingEvent({
                event: studioDiagnosticEventName,
                eventDetail: "Upload Complete",
                label: "Image",
                extraData: () => {
                    return extractMetaData(asset, info);
                }
            });
        };

        const onUploadSuccessEvent = (e: CustomEvent<VistaAsset>) => {
            // On success the asset still has very little data.  The image info is fetched after.
            successfulUploadedAssets.push(e.detail);
        };

        const onUploadErrorWrapper = (e: CustomEvent<VistaAsset>) => {
            // the types are pretty useless here so see
            // https://gitlab.com/Cimpress-Technology/FileReview/sherbert/sdk/assets-sdk/-/blob/staging/packages/assets-sdk/src/store/AssetStore.ts#L244
            // for how this is generated
            const asset = e.detail as VistaAsset | undefined;
            // @ts-expect-error this exists if the status is in error but I'm not comfortable assuming anything from the assets-sdk, the types are not reliable
            const rawError = asset?.status?.rawError;
            onUploadErrorEvent(asset, rawError);
        };

        const onImageInfoServerSuccess = (e: CustomEvent<AssetImageInfoEventDTO>) => {
            if (e.detail?.id) {
                const uploadedAssetIndex = successfulUploadedAssets.findIndex(asset => asset.data?.id === e.detail?.id);
                if (uploadedAssetIndex > -1) {
                    const uploadedAsset = successfulUploadedAssets[uploadedAssetIndex];
                    successfulUploadedAssets.splice(uploadedAssetIndex, 1);
                    if (e.detail.imageInfo?.format !== "unknown" && !e.detail.imageInfo?.isError) {
                        logSuccessfulUpload(e, uploadedAsset);
                    } else {
                        // @ts-expect-error types are screwed up but error exists if it failed
                        onUploadErrorEvent(uploadedAsset, e.detail.imageInfo.error ?? "Asset has unknown format");
                    }
                }
            }
        };

        addEventListener(AssetStoreEvent.UploadSuccess, onUploadSuccessEvent);
        addEventListener(AssetStoreEvent.UploadError, onUploadErrorWrapper);
        addEventListener(AssetStoreEvent.ImageInfoServerSuccess, onImageInfoServerSuccess);

        return () => {
            removeEventListener(AssetStoreEvent.UploadSuccess, onUploadSuccessEvent);
            removeEventListener(AssetStoreEvent.UploadError, onUploadErrorWrapper);
            removeEventListener(AssetStoreEvent.ImageInfoServerSuccess, onImageInfoServerSuccess);
        };
    }, [addEventListener, removeEventListener]);

    return <></>;
});

CustomUploadEvents.displayName = "CustomUploadEvents";
