import type { DSS } from "@vp/types-ddif";
import { setItemStudioMetadata } from "./utils-studio-metadata";

/**
 * Studio Metadata Hydration refers to restoring any `studioMetadata` attributes that existed on items before user saved.
 * This function loops through all items on every canvas and if there's studioMetadata that exists for it in the cimDoc
 * it adds the studioMetadata back into the item's model attributes.
 * @param {*} cimDoc
 * @param {*} canvases
 */
export function hydrateStudioMetadata(cimDoc: DSS.DesignDocument, canvases: Canvas[]) {
    const studioMetadata = (cimDoc.metadata as any)?.studioMetadata ?? [];
    const templateMetadata = cimDoc.metadata?.template ?? [];

    if (window.designer) {
        canvases.forEach(canvas => {
            canvas.items.forEach(item => {
                const itemId = item._itemViewModel.model.get("id");
                const itemTemplateMetadata = templateMetadata.find(metadata => metadata.id?.includes(itemId));
                const template =
                    itemTemplateMetadata &&
                    Object.keys(itemTemplateMetadata).reduce((acc, metadataKey) => {
                        if (metadataKey === "purpose") {
                            return {
                                ...(acc ?? {}),
                                [metadataKey]: itemTemplateMetadata[metadataKey]
                            };
                        }
                        return acc;
                    }, undefined);

                if (studioMetadata[itemId] || (template && Object.keys(template).length > 0)) {
                    const newMetadata = {
                        template,
                        ...studioMetadata[itemId]
                    };
                    if (newMetadata && Object.keys(newMetadata).length > 0) {
                        setItemStudioMetadata(item, newMetadata);
                    }
                }
            });
        });
    }
}
