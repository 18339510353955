export const ERROR_CODES = {
    START_STUDIO: 10,
    LOAD_REDUX: 11,
    START_DESIGNER: 12,
    ANON_TRY_LOGIN: 13,
    DOC_PERM_DENIED: 14,
    LOAD_DOCUMENT: 15,
    SAVE_DOCUMENT: 17,
    ERROR_BOUNDARY: 18,
    NO_PRODUCT_DATA: 19,
    PDF_PROOF_PROCESSING: 20,
    LOAD_PROJECT: 21,
    DUPLICATE_PROJECT: 22,
    DELETE_PROJECT: 23,
    SINGLE_COLOR_IMAGE_THRESHOLDING: 24,
    EASEL_CONFIG: 51,
    ADD_TO_CART_ERROR: 50,
    EASEL_CONFIG_ASYNC: 52,
    SURFACE_UPSELL_LOAD: 53,
    SURFACE_UPSELL_CHANGE: 54,
    SURFACE_UPSELL_PRICING: 55,
    MATCHING_TEMPLATE_LOAD: 56,
    PRODUCT_OPTIONS_CHANGE: 57,
    SUBSTRATE_COLOR_WITH_STOCK_INFO_LOAD: 58,
    QSP_CHECK: 59,
    CHANGE_TEMPLATE: 60,
    NAVIGATE_TO_TEAMS: 61,
    AI_TEMPLATES_SELECTION_NOT_FOUND: 62,
    AI_TEMPLATES_USE_TEMPLATE: 63,
    AI_TEMPLATES_RESIZE_DOC: 64,
    DOC_ON_BEHALF_OF_WRONG_CUSTOMER: 90
};
