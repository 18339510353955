import React, { useContext, useMemo, useState } from "react";
import { WesSortOptions } from "@shared/utils/Work";

interface UpdatedMyProjectsPanelContextProps {
    updatedSort: WesSortOptions;
    updatedScrolledToWorkId: string | null;
    updatedLoadedProjectsCount: number;
    updatedProjectsCount: number;
    updatedSearchTerm: string;
}

interface MyProjectsPanelContextInterface {
    previousSort: WesSortOptions;
    previousScrolledToWorkId: string | null;
    previousLoadedProjectsCount: number;
    previousProjectsCount: number;
    previousSearchTerm: string;
    updateMyProjectsState: ({
        updatedSort,
        updatedScrolledToWorkId,
        updatedLoadedProjectsCount,
        updatedProjectsCount,
        updatedSearchTerm
    }: UpdatedMyProjectsPanelContextProps) => void;
}

const MyProjectsPanelContext = React.createContext<MyProjectsPanelContextInterface>({
    previousSort: WesSortOptions.LAST_MODIFIED,
    previousScrolledToWorkId: null,
    previousLoadedProjectsCount: 0,
    previousProjectsCount: 0,
    previousSearchTerm: "",
    updateMyProjectsState: ({
        updatedSort,
        updatedScrolledToWorkId,
        updatedLoadedProjectsCount,
        updatedProjectsCount,
        updatedSearchTerm
    }: UpdatedMyProjectsPanelContextProps) => {}
});

export const useMyProjectsPanelContext = () => {
    return useContext(MyProjectsPanelContext);
};

export const MyProjectsPanelProvider = ({ children }: { children: any }) => {
    const [sort, setSort] = useState(WesSortOptions.LAST_MODIFIED);
    const [scrolledToWorkId, setScrolledToWorkId] = useState<string | null>(null);
    const [loadedProjectsCount, setLoadedProjectsCount] = useState(0);
    const [projectsCount, setProjectsCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");

    const contextObject = useMemo(
        () => ({
            previousSort: sort,
            previousScrolledToWorkId: scrolledToWorkId,
            previousLoadedProjectsCount: loadedProjectsCount,
            previousProjectsCount: projectsCount,
            previousSearchTerm: searchTerm,
            updateMyProjectsState: ({
                updatedSort,
                updatedScrolledToWorkId,
                updatedLoadedProjectsCount,
                updatedProjectsCount,
                updatedSearchTerm
            }: UpdatedMyProjectsPanelContextProps) => {
                setSort(updatedSort);
                setScrolledToWorkId(updatedScrolledToWorkId);
                setLoadedProjectsCount(updatedLoadedProjectsCount);
                setProjectsCount(updatedProjectsCount);
                setSearchTerm(updatedSearchTerm);
            }
        }),
        [loadedProjectsCount, projectsCount, scrolledToWorkId, sort, searchTerm]
    );

    return <MyProjectsPanelContext.Provider value={contextObject}>{children}</MyProjectsPanelContext.Provider>;
};

MyProjectsPanelProvider.displayName = "MyProjectsPanelProvider";
