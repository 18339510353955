import { createSlice } from "@reduxjs/toolkit";
import {
    easelFinishedLoading,
    hideLoader,
    setError,
    setLoadingMessage,
    showLoader,
    showSaveLoader
} from "../compoundActions";
import { setSwitchingProject } from "../Actions";

const loadingMessageSlice = createSlice({
    name: "loadingMessage",
    initialState: null as null | string,
    reducers: {},
    extraReducers: builder => {
        // Only blank out message if asked to be blanked out
        builder.addCase(showLoader, (state, action) => action.payload || state);
        builder.addCase(showSaveLoader, (state, action) => action.payload || state);
        builder.addCase(setSwitchingProject, (state, action) => action.payload.loadingMessage || state);
        builder.addCase(setLoadingMessage, (state, action) => action.payload || state);
        builder.addCase(hideLoader, () => null);
        builder.addCase(setError, () => null);
        builder.addCase(easelFinishedLoading, () => null);
    }
});

export const loadingMessage = loadingMessageSlice.reducer;
