import type { FontFamilyOverrides } from "../FontTypes";

export const embroideryFontFamilyOverride: FontFamilyOverrides = {
    AdageScriptJF: { minLimit: 34, maxLimit: 110, intervals: 40 },
    Arial: { minLimit: 26, maxLimit: 110, intervals: 40 },
    Arial_Rounded: { minLimit: 20, maxLimit: 100, intervals: 40 },
    Arial_Tiny: { minLimit: 20, maxLimit: 30, intervals: 10 },
    AthleticBlock: { minLimit: 36, maxLimit: 150, intervals: 40 },
    "BankGothic Md BT": { minLimit: 24, maxLimit: 110, intervals: 40 },
    "BrushScript BT": { minLimit: 34, maxLimit: 150, intervals: 40 },
    CabernetJF: { minLimit: 30, maxLimit: 110, intervals: 40 },
    Calibri: { minLimit: 20, maxLimit: 110, intervals: 40 },
    FranklinGotMdITCTT: { minLimit: 30, maxLimit: 110, intervals: 40 },
    JohnAndrewJF: { minLimit: 32, maxLimit: 110, intervals: 40 },
    "Kennedy Sm Caps Book GD": { minLimit: 32, maxLimit: 110, intervals: 40 },
    "Melanie BT": { minLimit: 36, maxLimit: 110, intervals: 40 },
    "MS Gothic": { minLimit: 30, maxLimit: 160, intervals: 40 },
    Pearl: { minLimit: 36, maxLimit: 250, intervals: 40 },
    "Times New Roman": { minLimit: 26, maxLimit: 160, intervals: 40 }
};
