import merge from "lodash/merge";
import { ItemReferenceTypes } from "@shared/utils/CimDoc";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { createQRCodeData } from "@shared/utils/QRCodes";
import { ProcessType, getColorPalette, getRecommendedColors } from "../designer-suite";

export interface ItemRefOptions extends Omit<ModelAttributes, "module" | "top" | "left" | "height" | "width"> {
    viewModel?: CanvasViewModel;
    altId?: string;
    top?: number;
    left?: number;
    height?: number;
    width?: number;
}

function getAllowedColorAttribute(
    canvasViewModel: CanvasViewModel,
    onlyDefaultColorWhenRestricted?: boolean,
    isLightSubstrateColor = true
) {
    const color = "rgb(#101820)"; // Won't render if color is in rgb(0,0,0) format
    const canvasIsRestricted = canvasViewModel.get("maxSpotColors") === 1; // this is how Designer currently checks for a restricted canvas

    if (canvasIsRestricted) {
        // find existing used color
        const restrictedColor =
            canvasViewModel.getColorIfCanvasIsRestricted() || canvasViewModel.get("availablePantoneColors")[0]; // since it's restricted, there is only one color
        if (restrictedColor) {
            return restrictedColor.toString();
        }
    }

    if (onlyDefaultColorWhenRestricted) {
        return undefined;
    }

    // Make sure a thread color is used for embroidery
    const processType = canvasViewModel.get("processType");
    if (processType === ProcessType.embroideryFlat || processType === ProcessType.embroideryCylinder) {
        // Pull a color from the recommended colors
        // This is not always the last selected color
        //  Do something better in the future...
        if (window.designer) {
            const recommendedColors = getRecommendedColors(window?.designer);
            if (recommendedColors?.length > 0) {
                return recommendedColors[recommendedColors.length - 1].value;
            }
            // Fall back to grabbing the first color in the palette
            const paletteColors = getColorPalette(window?.designer);
            return paletteColors[0].value;
        }
    }

    // For non embroidery process types, return white color for darker substrate products
    if (!isLightSubstrateColor) {
        return "rgb(#FFFFFF)";
    }

    return color;
}

// Lifted from app\core\util\Util.js in cimpress-designer
function getRandomPositionInArea(defaultOuterDimensions: Rectangle, itemSize: Dimensions) {
    // If there is no width or height given to the item we don't know if it will go
    // off the edge of one side so we keep the starting point at least 2/3 of the area
    // to the edge
    const outerDimensions = defaultOuterDimensions;
    const variableWidthArea = itemSize.width ? outerDimensions.width - itemSize.width : outerDimensions.width * (2 / 3);
    const variableHeightArea = itemSize.height
        ? outerDimensions.height - itemSize.height
        : outerDimensions.height * (2 / 3);

    if (!outerDimensions.top) {
        outerDimensions.top = 0;
    }
    if (!outerDimensions.left) {
        outerDimensions.left = 0;
    }
    return {
        top: variableHeightArea * Math.random() + outerDimensions.top,
        left: variableWidthArea * Math.random() + outerDimensions.left
    };
}

export function addItemReference(options: ItemRefOptions) {
    // Large portions of this code were lifted from app\core\commands\CreateTextField.js in cimpress-designer
    const canvasViewModel = options.viewModel || window.designer!.documentRepository.getActiveCanvasViewModel();
    const parent = canvasViewModel.model;

    // Decide on the initial size
    const safeArea = canvasViewModel.get("safeArea");
    const width = options.width || (safeArea.width * 2) / 3;
    const height = options.height || width / 6.25;
    const itemSize = { width, height };

    const position = getRandomPositionInArea(safeArea, itemSize);
    const top = options.top || position.top;
    const left = options.left || position.left;
    const module = window.designer!.constants.modules.itemReference;
    const data = options?.data
        ? {
              ...options?.data
          }
        : undefined;
    const attributes = {
        restricted: undefined,
        ...options,
        top,
        left,
        width,
        height,
        module,
        data
    };

    window.designer!.commandDispatcher.add({
        model: parent,
        items: [attributes],
        selectNewModels: true
    });
    // If item appears on the canvas, but does not render,
    //  most likely one of the data attributes isn't set to a valid value
}

export function addTable(
    predefinedStyle: TableItemPredefinedStyle,
    rows = 0,
    columns = 0,
    options?: ItemRefOptions | null
) {
    const tableDefaultOptions: ItemRefOptions = {
        url: "https://udsinterop.document.vpsvc.com/api/itemref/table",
        locked: undefined,
        rotation: 360,
        transforms: {
            rotatable: true,
            scalable: true
        },
        data: {
            predefinedStyle,
            columns: [...Array(columns)].map(() => ({
                alignment: "left",
                width: "10mm"
            })),
            rows: [...Array(rows)].map(() => ({
                cells: [...Array(columns)].map(() => ({
                    text: ""
                }))
            }))
        },
        type: "Table"
    };
    const tableOptions = merge(tableDefaultOptions, options);
    addItemReference(tableOptions);
}

export function addWordArt(placeholderText: string, options?: Partial<ItemRefOptions & { fontFamily?: string }>) {
    const wordArtDefaultOptions: ItemRefOptions = {
        type: "Word Art",
        // url is required for rendering to work (without this nothing will get displayed)
        url: "https://udsinterop.document.vpsvc.com/api/itemref/wordart",
        locked: undefined,
        rotation: 360,
        transforms: {
            rotatable: true,
            scalable: true
        },
        data: {
            // ToDo: Find most common values
            focus: "center",
            curve: {
                radius: 0,
                angle: 0,
                height: 0
            },
            fontFamily: options?.fontFamily ?? "Arimo",
            fontStyle: "Normal,Normal",
            content: "",
            color: getAllowedColorAttribute(
                options?.viewModel || window.designer!.documentRepository.getActiveCanvasViewModel()
            )
        },
        templateMetaData: {
            placeholder: placeholderText
        }
    };

    // Premium Finishes - ToDo
    // const finishes = getPremiumFinishes(); // getPremiumFinishes should be in utils
    // if (finishes.length > 0) {
    //     // ToDo: What is the right value to be putting in here?
    //     // eslint-disable-next-line prefer-destructuring
    //     wordArtDefaultOptions.attributes.data.overprints = finishes[0];
    // }

    const wordArtOptions = merge(wordArtDefaultOptions, options);
    addItemReference(wordArtOptions);
}

/**
 * Add a QR Code to the canvas
 * @param {} options - options
 * @param {string} url - Valid fully qualified url
 * @param {string} token - Valid user token
 * @param {user} user - Valid user
 */
export async function addVCQRCode(
    locale: string,
    url: string,
    identityContext: ReturnType<typeof useIdentityContext>,
    options?: ItemRefOptions
) {
    const canvasViewModel = options?.viewModel || window.designer!.documentRepository.getActiveCanvasViewModel();
    // Decide on the initial size
    const safeArea = canvasViewModel.get("safeArea");

    // Make sure size is not less than 0.8 inches / 20mm
    // QR Code should start as a square
    const width = options?.width || Math.max((safeArea.width * 2) / 6, 20);
    const height = options?.height || width;
    const color = getAllowedColorAttribute(
        options?.viewModel || window.designer!.documentRepository.getActiveCanvasViewModel(),
        true
    );

    const qrCodeData = await createQRCodeData({
        altId: options?.altId,
        color,
        height,
        width,
        locale,
        identityContext,
        url
    });

    const qrCodeDefaultOptions: ItemRefOptions = {
        width,
        height,
        constraints: { minWidth: "20mm", minHeight: "20mm" },
        type: "QR Code",
        // url is required for rendering to work (without this nothing will get displayed)
        url: `${VISTA_CONNECT_QR_CODE_URL}/api/qrcode/itemref`,
        locked: undefined,
        rotation: 360,
        transforms: {
            rotatable: true,
            scalable: true
        },
        data: qrCodeData
    };
    const qrCodeOptions = merge(qrCodeDefaultOptions, options);

    addItemReference(qrCodeOptions);
}

export function addTeamsPlaceholder(
    placeholderKey: string,
    content: string,    
    placeholderType: TeamsPlaceholderType,
    fontFamily = "Abel",
    isNumber = false,
    isLightSubstrateColor = true,
    options?: ItemRefOptions
) {
    let width;
    let height;
    if (isNumber) {
        const canvasViewModel = options?.viewModel || window.designer!.documentRepository.getActiveCanvasViewModel();
        const safeArea = canvasViewModel.get("safeArea");
        width = options?.width || (safeArea.width * 2) / 3;
        height = options?.height || width / 2;
    }
    const teamsPlaceholderDefaultOptions: ItemRefOptions = {
        ...(width && { width }),
        ...(height && { height }),
        type: ItemReferenceTypes.TEAMS_NAME,
        // url is required for rendering to work (without this nothing will get displayed)
        url: "https://udsinterop.document.vpsvc.com/api/itemref/wordart",
        rotation: 0,
        transforms: {
            rotatable: true,
            scalable: true
        },
        data: {
            focus: "center",
            curve: {
                radius: 0,
                angle: 0,
                height: 0
            },
            fontFamily,
            fontStyle: "Normal,Normal",
            content,
            placeholderKey,
            placeholderType,
            color: getAllowedColorAttribute(
                options?.viewModel || window.designer!.documentRepository.getActiveCanvasViewModel(),
                false,
                isLightSubstrateColor
            )
        }
    };

    const teamsPlaceholderOptions = merge(teamsPlaceholderDefaultOptions, options);
    addItemReference(teamsPlaceholderOptions);
}
