import { waitForProcessing, checkForProcessing, getDesignDocumentFromDesigner } from "@easel";
import { saveDesign, type SaveDesignParams } from "@shared/utils/Save";
import { saveDocument } from "@shared/utils/Save/saveDesign";

const WAIT_FOR_PROCESSING_TIMEOUT = 30000;

export async function saveStudio5Document(authToken: string, updateStore?: boolean) {
    if (await checkForProcessing()) {
        await waitForProcessing(WAIT_FOR_PROCESSING_TIMEOUT);
    }

    return saveDocument({
        authToken,
        document: getDesignDocumentFromDesigner(),
        onSave: () => window?.designer?.eventBus.trigger(window.designer.eventBus.events.documentSaved),
        updateStore
    });
}

export async function saveStudio5Design(params: Omit<SaveDesignParams, "saveDocument">) {
    return saveDesign({
        ...params,
        saveDocument: () => saveStudio5Document(params.authToken, true)
    });
}
