import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const productKeySlice = createSlice({
    name: "productKey",
    initialState: "",
    reducers: {
        setProductKey(state, action: PayloadAction<string>) {
            return action.payload || state;
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.productKey || state;
        });
    }
});

export const { setProductKey } = productKeySlice.actions;
export const productKey = productKeySlice.reducer;
