import React, { useContext, createContext, useState, useMemo } from "react";
import { useAbTestContext } from "@shared/features/ABTesting";
import { useAppSelector } from "@shared/redux";
import { useAsyncEffect } from "@design-stack-ct/utility-react";
import { isPRDInHolidayCombinedTestG } from "./includedPRDs";

type ContextData = {
    // Is the user in the studio6 variant
    studio6Enabled: boolean;
    // Is the test currently active
    isTestActive: boolean;
    // Have we checked whether the test is active and supported for the current product
    isInitialized: boolean;
    // Is this product in the holiday combined test G
    holidayGPrdInTest: boolean;
    // Is the holiday combined test G active
    isCombinedTestGActive: boolean;
};

const context = createContext<ContextData | undefined>(undefined);

export function useStudio6AB() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context.  This must be called within a Studio6ABProvider");
    }
    return result;
}
enum Variations {
    Control = "control",
    Enabled = "variation"
}

export const Studio6CombinedTestGABExperimentData: ExperimentData = {
    experimentKey: "editor_holiday_combined_test_g",
    experimentName: "Editor Holiday Combined Test G",
    variations: Variations
};

interface Props {
    shouldTrackImpression?: boolean;
}

export const Studio6ABProvider = ({ children, shouldTrackImpression }: React.PropsWithChildren<Props>) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { experimentKey: combinedTestGExperimentKey } = Studio6CombinedTestGABExperimentData;

    const [isInitialized, setIsInitialized] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [isTestActive, setIsTestActive] = useState(false);
    const [isCombinedTestGActive, setIsCombinedTestGActive] = useState(false);

    const productKey = useAppSelector(state => state.productKey);
    const holidayGPrdInTest = isPRDInHolidayCombinedTestG(productKey);

    useAsyncEffect(
        helpers => {
            // gotta wait for ab stuff to be initialized, experiment key to be available, and product key to be known
            if (!combinedTestGExperimentKey || !ABTest || !productKey) {
                return;
            }
            // don't bother initializing this stuff twice (for example, if My Projects is used)
            if (isInitialized) {
                return;
            }
            (async () => {
                const { isExperimentUsingVariation, trackImpression, isExperimentActive, getForcedVariation } = ABTest;

                const combinedTestGExperimentActive =
                    Boolean(getForcedVariation(combinedTestGExperimentKey)) ||
                    (await isExperimentActive(combinedTestGExperimentKey));

                helpers.runIfMounted(() => {
                    setIsTestActive(combinedTestGExperimentActive);
                    setIsCombinedTestGActive(combinedTestGExperimentActive);
                });

                if (holidayGPrdInTest) {
                    // For this ab test we have specific behavior if the experiment is enabled at all
                    // for testing treat forced variations as enabled
                    // trackImpression handles skipping the actual tracking if the user is forced into a variation

                    // when updating `setIsEnabled`, we don't want to set enabled to false if the second experiment to resolve is disabled.
                    // As long as the user is in the variation for either experiment we set the test to enabled
                    const trackImpressionIfInVariant = async (variation: Variations) => {
                        const combinedTestGResult = await isExperimentUsingVariation(
                            combinedTestGExperimentKey,
                            variation
                        );
                        if (combinedTestGResult) {
                            if (shouldTrackImpression) {
                                trackImpression(combinedTestGExperimentKey, variation);
                            }
                            helpers.runIfMounted(() =>
                                setIsEnabled(currentValue => currentValue || variation !== Variations.Control)
                            );
                        }
                    };

                    await Promise.all([
                        trackImpressionIfInVariant(Variations.Enabled),
                        trackImpressionIfInVariant(Variations.Control)
                    ]);
                }

                setIsInitialized(true);
            })();
        },
        [ABTest, shouldTrackImpression, productKey, isInitialized, combinedTestGExperimentKey, holidayGPrdInTest]
    );

    const contextObject = useMemo(
        () => ({
            studio6Enabled: isEnabled,
            isTestActive,
            isInitialized,
            holidayGPrdInTest,
            isCombinedTestGActive
        }),
        [isEnabled, isTestActive, isInitialized, holidayGPrdInTest, isCombinedTestGActive]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

Studio6ABProvider.displayName = "Studio6ABProvider";
