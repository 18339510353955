// Table data is changing format. In addition to the predefined styles.
// tables can now have a body, header and alternateStyle object.
// For now getting the style for a table will be as follows:
// Use the predefined if existing, then body, then header, then alternate
// This will make sure the new tables can work with the current UI
// NOTE: In the future, this might change as the UI might be adapted. For now we're going this is.

import { updateTableAttributeData, getTableStyle, type TableItemStyleData } from "@shared/utils/Tables";

interface FontFamilyVariants {
    Variants: {
        Bold: boolean;
        Italic: boolean;
        BoldItalic: boolean;
    };
}

type FontFamilyOptions = Record<string, FontFamilyVariants>;

const updateTableItemData = (table: Mutable & TableItem, newData: Partial<TableItemData>) => {
    table.setData({
        ...table.data,
        ...newData
    });
};

const updateTableStyleAttribute = (
    table: Mutable & TableItem,
    newStyleData: Partial<TableItemPredefinedStyle>,
    useFallback: boolean = true
) => {
    const { data } = table;
    const mergedData = updateTableAttributeData(data, newStyleData, useFallback);
    if (mergedData) {
        updateTableItemData(table, mergedData);
    }
};

export const getTableFontSize = (data: TableItemStyleData) => {
    return getTableStyle(data)?.fontSize;
};

export const setTableFontSize = (data: TableItemStyleData, fontSize: string) => {
    updateTableAttributeData(data, { fontSize });
};

export const getTableStyleId = (data: TableItemStyleData) => {
    return data?.predefinedStyle?.styleId;
};

export const getTableFontFamily = (data: TableItemStyleData) => {
    return getTableStyle(data)?.fontFamily;
};

export const getTableFontColor = (data: TableItemStyleData) => {
    return getTableStyle(data)?.fontColor;
};

export const setTableFontColor = (data: TableItemStyleData, fontColor: string) => {
    updateTableAttributeData(data, { fontColor });
};

export const getTableFontStyle = (data: TableItemStyleData) => {
    return getTableStyle(data)?.fontStyle;
};

export const getTableBackgroundColor = (data: TableItemStyleData) => {
    return getTableStyle(data)?.backgroundColor;
};

/**
 * Returns the Italic style for the table
 * @param {ItemRefernce} table to be modified
 * @returns {string} 'Italic' or 'Normal' for the table
 */
export const getItalicStyleForTable = (table: TableItem) => {
    const fontStyle = getTableFontStyle(table?.data);
    return fontStyle?.substr(fontStyle.indexOf(",") + 1);
};

/**
 * Returns the Bold style for the table
 * @param {Item Reference} table item to be modified
 * @returns {string} 'Bold' or 'Normal' for the table
 */
export const getBoldStyleForTable = (table: TableItem) => {
    const fontStyle = getTableFontStyle(table?.data);
    return fontStyle?.substr(0, fontStyle.indexOf(","));
};

const accumulateTableSelection = (
    selection: TableItem[],
    callback: (data: TableItemStyleData) => string | undefined
): string[] => {
    return selection.reduce((accumulator, current): string[] => {
        const attribute = callback(current.data);
        if (!attribute) {
            return accumulator;
        }
        if (attribute && accumulator.includes(attribute)) {
            return accumulator;
        }
        return [...accumulator, attribute];
    }, [] as string[]);
};

/**
 * Get the font for the selected table
 * @param {Item[]} selection list of items to select through
 * @returns {string} selected font
 */
export function getTableSelectedFont(selection: TableItem[]) {
    const selectedFontFamilies = accumulateTableSelection(selection, getTableFontFamily);
    return selectedFontFamilies.length === 1 ? selectedFontFamilies[0] : "";
}

export function getTableSelectedFontSize(selection: TableItem[]) {
    return accumulateTableSelection(selection, getTableFontSize);
}

// / TODO Consoldidate this!
const FONTBOLD = "fontBold";
const FONTITALIC = "fontItalic";

const disableableVariantMapping = {
    [FONTBOLD]: {
        variant: "Bold"
    },
    [FONTITALIC]: {
        variant: "Italic"
    }
};

/**
 * Is this table set to italic?
 * @param {ItemReference} table item we are checking
 * @returns {boolean}
 */
export function isTableItalic(table: TableItem) {
    return getItalicStyleForTable(table) === "Italic";
}

/**
 * Is this table set to bold?
 * @param {ItemReference} table item we are checking
 * @returns {boolean}
 */
export function isTableBold(table: TableItem) {
    return getBoldStyleForTable(table) === "Bold";
}

/**
 *
 * @param {Designer} designer
 * @param {Item[]} selection List of selected items
 * @param {string} internalItemAttributeKey "Bold" or "Italic"
 */
export function getIsDisabledForTable(
    fontMapping: FontFamilyOptions,
    selection: TableItem[],
    internalItemAttributeKey: string
) {
    return selection.some(item => {
        // Check whether B/I is supported for the item's fontFamily
        const fontFamily = getTableFontFamily(item.data);
        if (!fontFamily) {
            return false;
        }
        const fontOptions = fontMapping[fontFamily.toLowerCase()];
        if (!fontOptions || !fontOptions.Variants[disableableVariantMapping[internalItemAttributeKey].variant]) {
            return true;
        }

        const isOtherAttribute = internalItemAttributeKey === FONTBOLD ? isTableItalic(item) : isTableBold(item);
        return !fontOptions.Variants.BoldItalic && isOtherAttribute;
    });
}

// Consider making a function that will return all the data at once.
export const updateTableStyleId = (table: Mutable & TableItem, styleId: number) => {
    updateTableStyleAttribute(table, { styleId });
};

export const updateTableFontSize = (table: Mutable & TableItem, fontSize: string) => {
    updateTableStyleAttribute(table, { fontSize });
};

export const updateTableFontFamily = (table: Mutable & TableItem, fontFamily: string) => {
    updateTableStyleAttribute(table, { fontFamily });
};

export const updateTableBackgroundColor = (table: Mutable & TableItem, backgroundColor: string) => {
    updateTableStyleAttribute(table, { backgroundColor });
};

export const updateTableFontColor = (table: Mutable & TableItem, fontColor: string) => {
    updateTableStyleAttribute(table, { fontColor });
};

const FontStyle = {
    NORMAL: "Normal",
    BOLD: "Bold",
    ITALIC: "Italic"
};

/**
 * This function will update a table with bold
 * @param {boolean} add Are we setting the font style to bold?
 */
export function updateTableBoldStyle(add: boolean) {
    return (table: Mutable & TableItem) => {
        const italicStyle = getItalicStyleForTable(table);
        updateTableStyleAttribute(table, {
            fontStyle: `${add ? FontStyle.BOLD : FontStyle.NORMAL},${italicStyle}`
        });
    };
}

/**
 * This function will update a table with italic
 * @param {boolean} add Are we setting the font style to italic?
 * @return {function} Callback for updating the font style of a table.
 */
export function updateTableItalicStyle(add: boolean) {
    return (table: Mutable & TableItem) => {
        const boldStyle = getBoldStyleForTable(table);
        updateTableStyleAttribute(table, {
            fontStyle: `${boldStyle},${add ? FontStyle.ITALIC : FontStyle.NORMAL}`
        });
    };
}

/**
 * This function will update a table with an Alignment
 * @param {TableColumnAlignment} alignment alignment value to set the table to
 * @return {function} Callback for updating the alignment of the table.
 */
export function updateTableAlignment(alignment: TableColumnAlignment) {
    return (table: TableItem & Mutable) => {
        updateTableItemData(table, { columns: table.data.columns.map(column => ({ ...column, alignment })) });
    };
}
