import { createSlice } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";
import { resetQuantity, setQuantity } from "./quantity";

const quantityPerSizeSlice = createSlice({
    name: "quantityPerSize",
    initialState: null as string | null,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setQuantity, (state, action) => {
            return action.payload.quantityPerSize ?? state;
        });
        builder.addCase(setDefaultState, (state, action) => {
            return action.payload.quantityPerSize ?? state;
        });
        builder.addCase(resetQuantity, (state, actions) => {
            return "0";
        });
    }
});

export const quantityPerSize = quantityPerSizeSlice.reducer;
