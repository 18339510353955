import { createSlice } from "@reduxjs/toolkit";
import { setError } from "../compoundActions";

const hideErrorToastSlice = createSlice({
    name: "hideErrorToast",
    initialState: false,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setError, (state, action) => action.payload.hideErrorToast ?? false);
    }
});

export const hideErrorToast = hideErrorToastSlice.reducer;
