import {
    previewOpen,
    designReviewState,
    cropImageState,
    studioErrorDialogOpen,
    studioSelectedProductOptions,
    compatibleProductOptions,
    productAttributeAndValues,
    reviewInstructions,
    customerSelectedProductOptions,
    productKey,
    productVersion,
    quantity,
    quantityPerSize,
    isQuantityPageEnabled,
    template,
    workId,
    workRevisionId,
    workName,
    workLastSaved,
    mpvId,
    locale,
    isLoading,
    errorCode,
    errorMessage,
    errorStackTrace,
    errorExtraData,
    warnings,
    showErrorPage,
    hideErrorToast,
    easelLoaded,
    surfaceUpsellsLoadedState,
    productName,
    surfaceUpsells,
    currency,
    saveSuccess,
    saveStatus,
    switchingProject,
    loginReason,
    owner,
    documentReducer,
    documentRevisionUrl,
    productDataLoadAttempted,
    productDataLoadSuccessful,
    nextStepUrl,
    predictedSteps,
    pricingInitialized,
    scenesConfiguration,
    matchingTemplates,
    matchingTemplatesLoaded,
    designAttributeMappings,
    forceWorkRecovery,
    showSaveAs,
    isDuplicatingDesign,
    isRenamingProject,
    isFullBleed,
    showFullBleedPremiumFinishErrorModal,
    alerts,
    showRulers,
    showSafetyArea,
    showBleed,
    showGridlines,
    secondaryConfig,
    designDocumentHasMultiplePanels,
    hideSaveToast,
    showMyProjects,
    trackModalTransitionEvent,
    compatibleColorsWithStockInfo,
    changeDesignData,
    showPDFProofModal,
    showSpecsAndTemplate,
    processingPDF,
    isPremiumFinishModalOpen,
    premiumFinishModalOpenContext,
    isPremiumFinishWarningOn,
    isPremiumFinishToggleOn,
    showFontBrowser,
    isProductSwitched,
    teamsPlaceholders,
    showTeamsGuideModal,
    studioConfiguration,
    productGroupConfiguration,
    hideAlertToast,
    embroideryImageColorModalOpen,
    singleColorImageModalOpen,
    showImageSearchFiltersPanel,
    currentImageTab,
    editInCartModal,
    loadingMessage,
    shouldSaveOnChange,
    showValidations,
    currentTemplateColor,
    showDesignIssues,
    showReplaceImageModal,
    specsAndTemplateModalViewed,
    isReplaceOpenOnCanvasChange,
    showDeleteImageModal,
    showTableEditorModal,
    deleteImageData,
    confirmImageDeletion,
    status,
    clipartGalleryPreview,
    mcpSku,
    mcpVersion,
    isChangingTemplate,
    isUploadModalAutoLoaded,
    tracking,
    hasDesigns,
    selectedAITemplateReportingData,
    showBlankDocumentAlert,
    isDesignAssistantTabEnabled
} from "./slices";

export const reducers = {
    previewOpen,
    designReviewState,
    cropImageState,
    studioErrorDialogOpen,
    studioSelectedProductOptions,
    compatibleProductOptions,
    productAttributeAndValues,
    reviewInstructions,
    customerSelectedProductOptions,
    productKey,
    productVersion,
    quantity,
    quantityPerSize,
    isQuantityPageEnabled,
    template,
    workId,
    workRevisionId,
    workName,
    workLastSaved,
    mpvId,
    locale,
    isLoading,
    errorCode,
    errorMessage,
    errorStackTrace,
    errorExtraData,
    warnings,
    showErrorPage,
    hideErrorToast,
    easelLoaded,
    surfaceUpsellsLoadedState,
    productName,
    surfaceUpsells,
    currency,
    saveSuccess,
    saveStatus,
    switchingProject,
    loginReason,
    owner,
    document: documentReducer,
    documentRevisionUrl,
    productDataLoadAttempted,
    productDataLoadSuccessful,
    nextStepUrl,
    predictedSteps,
    pricingInitialized,
    scenesConfiguration,
    matchingTemplates,
    matchingTemplatesLoaded,
    designAttributeMappings,
    forceWorkRecovery,
    showSaveAs,
    isDuplicatingDesign,
    isRenamingProject,
    isFullBleed,
    showFullBleedPremiumFinishErrorModal,
    alerts,
    showRulers,
    showSafetyArea,
    showBleed,
    showGridlines,
    secondaryConfig,
    designDocumentHasMultiplePanels,
    hideSaveToast,
    showMyProjects,
    trackModalTransitionEvent,
    compatibleColorsWithStockInfo,
    changeDesignData,
    showPDFProofModal,
    showSpecsAndTemplate,
    processingPDF,
    isPremiumFinishModalOpen,
    premiumFinishModalOpenContext,
    isPremiumFinishWarningOn,
    isPremiumFinishToggleOn,
    showFontBrowser,
    isProductSwitched,
    teamsPlaceholders,
    showTeamsGuideModal,
    studioConfiguration,
    productGroupConfiguration,
    hideAlertToast,
    embroideryImageColorModalOpen,
    singleColorImageModalOpen,
    showImageSearchFiltersPanel,
    currentImageTab,
    editInCartModal,
    loadingMessage,
    shouldSaveOnChange,
    showValidations,
    currentTemplateColor,
    showDesignIssues,
    showReplaceImageModal,
    specsAndTemplateModalViewed,
    isReplaceOpenOnCanvasChange,
    showDeleteImageModal,
    showTableEditorModal,
    deleteImageData,
    confirmImageDeletion,
    status,
    clipartGalleryPreview,
    mcpSku,
    mcpVersion,
    isChangingTemplate,
    isUploadModalAutoLoaded,
    tracking,
    hasDesigns,
    selectedAITemplateReportingData,
    showBlankDocumentAlert,
    isDesignAssistantTabEnabled
};
