import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AITemplatesUseCase = "promotionalProducts";
export type AITemplatesColorType = "singleColor" | "multiColor";
export interface ImagemindReportingInput {
    input: {
        id?: string;
        feedback: {
            storageId: string;
            colorType: AITemplatesColorType;
            locale: string;
            hero: boolean;
            usecase: AITemplatesUseCase;
            productType?: string;
            searchedKeyword?: string;
            recentTemplate: boolean;
            productKey: string;
            pageId: string;
            userId: string;
            userActivity: {
                assetsClicked: string[];
                selectedAssetsForNextPage: string[];
                numberOfAssetsViewed: number;
                assetsRemoved: string[];
            };
        };
    };
}

const initialState: ImagemindReportingInput = {} as ImagemindReportingInput;

const selectedAITemplateReportingDataSlice = createSlice({
    name: "selectedAITemplateReportingData",
    initialState,
    reducers: {
        setSelectedAITemplateReportingData: (state, action: PayloadAction<ImagemindReportingInput | null>) =>
            action.payload || state
    }
});

export const { setSelectedAITemplateReportingData } = selectedAITemplateReportingDataSlice.actions;
export const selectedAITemplateReportingData = selectedAITemplateReportingDataSlice.reducer;
