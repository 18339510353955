import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const hasDesigns = createSlice({
    name: "isFullBleed",
    initialState: false as boolean,
    reducers: {
        setIsFullBleed: (state, action: PayloadAction<boolean>) => action.payload
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => action.payload.isFullBleed ?? false);
    }
});

export const { setIsFullBleed } = hasDesigns.actions;
export const isFullBleed = hasDesigns.reducer;
