import type { DSS } from "@vp/types-ddif";
import type { Panel } from "@design-stack-ct/cdif-types";
import type { EaselSceneConfiguration, SceneConfig } from "./types";

export function findScene(
    panel: Panel,
    index: number,
    documentPanels: Panel[],
    possibleScenes: SceneConfig[] | undefined,
    surface?: DSS.DesignView
) {
    // no scenes passed in, no scenes returned
    if (!possibleScenes) {
        return undefined;
    }

    // find the scene tagged for this panel
    const specificScene = possibleScenes.find(
        scene => scene.name && scene.name.toLowerCase() === panel?.name?.toLowerCase()
    );
    if (specificScene) {
        return specificScene.url;
    }

    const originalLocationName = surface?.docAdditionalData?.originalLocation;
    if (originalLocationName) {
        const sceneForLocationName = possibleScenes.find(
            scene => scene.name && scene.name.toLowerCase() === originalLocationName.toLowerCase()
        );
        if (sceneForLocationName) {
            return sceneForLocationName.url;
        }
    }

    // find a generic scene tagged for no specific panels - this can happen for products like kraft business cards
    // where the underlay is not a photographic scene but a texture
    const genericScene = possibleScenes.find(scene => !scene.name);
    if (genericScene) {
        return genericScene.url;
    }

    // sometimes scenes are not named correctly.  just fallback to choosing a scene in the correct order
    if (possibleScenes[index] && possibleScenes.length === documentPanels.length) {
        return possibleScenes[index].url;
    }

    // lastly - maybe the location name contains the scene name (like, 'centered on front')
    if (originalLocationName) {
        const sceneForLocationName = possibleScenes.find(
            scene => scene.name && originalLocationName.toLowerCase().includes(scene.name.toLowerCase())
        );
        if (sceneForLocationName) {
            return sceneForLocationName.url;
        }
    }

    return undefined;
}

export function convertToDesignerSceneConfiguration(
    documentPanels: Panel[],
    easelSceneConfiguration: EaselSceneConfiguration,
    surfaces?: DSS.DesignView[]
) {
    return documentPanels.map((panel, index) => ({
        scenes: {
            underlay: findScene(
                panel,
                index,
                documentPanels,
                easelSceneConfiguration.underlay,
                surfaces && surfaces[index]
            ),
            thumbnail: findScene(
                panel,
                index,
                documentPanels,
                easelSceneConfiguration.thumbnail,
                surfaces && surfaces[index]
            ),
            preview: findScene(
                panel,
                index,
                documentPanels,
                easelSceneConfiguration.preview,
                surfaces && surfaces[index]
            ),
            overlay: findScene(
                panel,
                index,
                documentPanels,
                easelSceneConfiguration.overlay,
                surfaces && surfaces[index]
            )
        }
    }));
}
