import { createSlice } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const productDataLoadSuccessfulSlice = createSlice({
    name: "productDataLoadSuccessful",
    initialState: false,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => {
            if (action.payload.productDataLoadSuccessful === undefined) {
                return state;
            }
            return action.payload.productDataLoadSuccessful;
        });
    }
});

export const productDataLoadSuccessful = productDataLoadSuccessfulSlice.reducer;
