/* eslint-disable import/prefer-default-export */
import React from "react";
import ReactModal from "react-modal";
import { StudioFiveTemplateWrapper } from "./src/studioFive/StudioFiveTemplateWrapper";

export const wrapPageElement = ({ element, props }) => {
    return <StudioFiveTemplateWrapper {...props}>{element}</StudioFiveTemplateWrapper>;
};
wrapPageElement.displayName = "wrapPageElement";

export const onClientEntry = () => {
    ReactModal.setAppElement(`#___gatsby`);

    if (typeof window.IntersectionObserver === "undefined") {
        // this is used by visage
        // eslint-disable-next-line no-unused-expressions
        import("intersection-observer");
    }

    // everything else taken from @wardpeet/gatsby-plugin-static-site because the plugin doesn't work well with newer versions of gatsby.
    // There is a PR there waiting to be merged
    // eslint-disable-next-line no-underscore-dangle
    const loader = window.___loader;

    // if development or no loader exists we shouldn't do anything
    if (process.env.NODE_ENV === "development" || !loader) {
        return;
    }

    const { pagePath } = window;
    const { location } = window;

    if (pagePath && pagePath !== location.pathname && pagePath !== `${location.pathname}/`) {
        const originalLoadPageSync = loader.loadPageSync;
        const originalLoadPage = loader.loadPage;

        loader.loadPageSync = path => {
            let pageResources;
            // With Gatsby V4, path has been appended with window.location.search when invoking loadPageSync
            const pathWithoutQueryParams = path.replace(location.search, "");

            // if the pathWithoutQueryParams is the same as our current page we know it's not a prefetch
            if (pathWithoutQueryParams === location.pathname) {
                pageResources = originalLoadPageSync(pagePath);
            } else {
                pageResources = originalLoadPageSync(pathWithoutQueryParams);
            }

            if (pageResources.page) {
                pageResources.page.matchPath = "*";
            }

            return pageResources;
        };

        loader.loadPage = path => {
            let pageResources;
            // With Gatsby V4, path has been appended with window.location.search when invoking loadPage
            const pathWithoutQueryParams = path.replace(location.search, "");

            // if the pathWithoutQueryParams is the same as our current page we know it's not a prefetch
            if (pathWithoutQueryParams === location.pathname) {
                pageResources = originalLoadPage(pagePath);
            } else {
                pageResources = originalLoadPage(pathWithoutQueryParams);
            }

            if (pageResources.page) {
                pageResources.page.matchPath = "*";
            }

            return pageResources;
        };
    }

    // disable hovering prefetching as we don't know if we can.
    loader.hovering = () => {};
};

// Also from @wardpeet/gatsby-plugin-static-site
export const disableCorePrefetching = () => true;
