import React, { ReactNode } from "react";
import { useAppSelector } from "@shared/redux";

export const GlobalCSSVariableOverides = ({ children }: { children: ReactNode }) => {
    const studioConfiguration = useAppSelector(state => state.studioConfiguration);
    const overrides = {};
    if (studioConfiguration.itemPreviewMixedBlendMultiply === true) {
        overrides["--item-mixed-blend-mode"] = "multiply";
    }

    /**
     * Make sure this is always sanitized data.
     */
    return (
        <>
            {Object.keys(overrides).length > 0 && (
                <style>
                    {`:root {
            ${Object.keys(overrides).map(overrideKey => `${overrideKey}: ${overrides[overrideKey]};\n`)}
                    }`}
                </style>
            )}
            {children}
        </>
    );
};

GlobalCSSVariableOverides.displayName = "GlobalCSSVariableOverides";
