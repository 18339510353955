import merge from "lodash/merge";
import { formatError } from "@shared/utils/Errors";
import { trackApiCall } from "./apiTracking";
import { retry } from "./retryClient";

export class ErrorWithStatus extends Error {
    constructor(message: string, readonly status: number) {
        super(message);
    }
}

async function checkErrorAndGetJSON<T = any>(
    moduleFunction: string,
    response: { status: number; text: () => Promise<string> } | null,
    entityCode: number,
    friendlyDescription: string
): Promise<T | ErrorWithStatus> {
    const responseNonNull = response || {
        status: 999,
        text: () => {
            return Promise.resolve("No response");
        }
    };

    // the pricing service returns a 301 for changed entities, which is interesting
    // 300s aren't really an error, more of a warning.  but response.ok() will return false
    // so lets do our own error checking for anything greater than or equal to 400
    if (responseNonNull.status >= 400) {
        let errorMessage = "";
        let errorObject = null;
        try {
            const responseText = await responseNonNull.text();
            try {
                errorObject = JSON.parse(responseText);
                errorMessage = errorObject.title
                    ? `Response: ${errorObject.title}`
                    : `Response: ${JSON.stringify(errorObject)}`;
            } catch {
                errorMessage = `Response: ${responseText}`;
            }
        } catch (e) {
            errorMessage = `Exception getting text: ${e}`;
        }
        const baseCodeStack = `${entityCode}-${responseNonNull.status}`;

        const e = {
            moduleFunction,
            friendlyDescription,
            response: responseNonNull,
            errorMessage,
            errorCodeStack:
                errorObject && errorObject.errorChain ? `${baseCodeStack}-${errorObject.errorChain}` : baseCodeStack
        };
        const error = new ErrorWithStatus(JSON.stringify(e), responseNonNull.status);
        return error;
    }

    const data = await responseNonNull.text();
    return data ? JSON.parse(data) : {};
}

// TODO: Once services start returning entityCodes & entityErrorCode we don't need the entityCode param, it should be in response
export async function tryFetch<T = any>({
    url,
    options,
    moduleFunction = "",
    entityCode = 0,
    trackCall = true,
    retryCount = 7,
    friendlyDescription = ""
}: {
    url: string;
    options?: RequestInit;
    moduleFunction?: string;
    entityCode?: number;
    trackCall?: boolean;
    retryCount?: number;
    friendlyDescription?: string;
}): Promise<T> {
    let response: Response | null = null;

    try {
        if (retryCount > 0) {
            response = await retry(additionalHeaders => fetch(url, merge(options, { headers: additionalHeaders })), {
                name: moduleFunction,
                retryCount
            });
        } else {
            response = await fetch(url, options);
        }
        if (response instanceof TypeError) {
            throw response;
        }
    } catch (err) {
        const bodyLength = (options?.body as unknown as any)?.length;
        throw formatError(
            moduleFunction,
            err.message,
            entityCode,
            "000",
            err,
            response,
            friendlyDescription,
            url,
            bodyLength
        );
    }

    const result = await checkErrorAndGetJSON(moduleFunction, response, entityCode, friendlyDescription);
    if (trackCall) {
        trackApiCall({
            module: moduleFunction,
            body: options && (options.body as any),
            method: options && options.method,
            url,
            result,
            statusCode: response?.status ?? 999
        });
    }

    if (result instanceof Error) {
        throw result;
    }
    return result;
}
