import { getQueryParams, removeQueryParam, windowExists } from "@shared/utils/WebBrowser";

const VP_STUDIO_DEBUG_MODE = "vp-studio-debug-mode";
export function isDebugMode() {
    if (!windowExists()) {
        return false;
    }
    const urlParams = getQueryParams();

    const debugModeQueryParamValue =
        urlParams.debugMode || urlParams.debug || urlParams.debugmode || urlParams.isdebugmode || urlParams.isDebugMode;

    if (debugModeQueryParamValue === "false" || debugModeQueryParamValue === "0") {
        window.localStorage.removeItem(VP_STUDIO_DEBUG_MODE);
    }

    if (debugModeQueryParamValue === "true" || debugModeQueryParamValue === "1") {
        window.localStorage.setItem(VP_STUDIO_DEBUG_MODE, "true");
    }

    return window.localStorage.getItem(VP_STUDIO_DEBUG_MODE) === "true";
}
export function disableDebugMode() {
    window.localStorage.removeItem(VP_STUDIO_DEBUG_MODE);
    const urlParams = getQueryParams();
    const debugParam = Object.keys(urlParams).find(key =>
        ["debugMode", "debug", "debugmode", "isdebugmode", "isDebugMode"].includes(key)
    );
    window.location.href = removeQueryParam(window.location.href, debugParam);
}

export const isBlankDesign = (design: string) => design.toLowerCase() === "blank";

export function isNextSite() {
    return windowExists() && /next/.test(window.location.hostname);
}

export function isExplicitDevelopmentMode() {
    return windowExists() && !!getQueryParams().developmentMode;
}

export function isLocalHost() {
    return windowExists() && /localhost|127\.0\.0\.1|::1/.test(window.location.hostname);
}

export function isStaging() {
    return windowExists() && /staging/.test(window.location.hostname);
}

export function isBranch() {
    return windowExists() && /branches/.test(window.location.hostname);
}

export function isDevelopmentMode() {
    return isExplicitDevelopmentMode() || isStaging() || isLocalHost() || isBranch();
}

export function isProd() {
    return !isLocalHost() && !isStaging() && !isBranch();
}

export function isShowWarningsMode() {
    return isDebugMode() || isNextSite();
}
