import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showDeleteImageModalSlice = createSlice({
    name: "deleteImageData",
    initialState: false,
    reducers: {
        setShowDeleteImageModal: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowDeleteImageModal } = showDeleteImageModalSlice.actions;
export const showDeleteImageModal = showDeleteImageModalSlice.reducer;
