import { type Flag, isFlagEnabled } from "@shared/utils/Flags";
import { useMemo } from "react";

/**
 * Get the value of a flag
 *  This needs to be used in places that server side rendering is in effect
 *   (isFlagEnabled always returns false on the server since localstorage does not exist there)
 * @param flagName Invokes isFlagEnable in a hook, so that a re-render will occur on the client-side
 * @returns {boolean}
 */
export function useIsFlagEnabled(flagName: Flag) {
    const flag = useMemo(() => isFlagEnabled(flagName), [flagName]);

    return flag;
}
