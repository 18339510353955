export {
    formatUpload,
    deleteUpload,
    parseIdFromUrl,
    getFormattedUploads,
    getImageUploadUrl
} from "./clients/uploadClient";

export const TOAST_NOTIFICATION_TIME_MS = 6000;

export { AcceptedUploadFormats } from "./AcceptedUploadFormats";

export { UploadsPanelMessages } from "./UploadsPanelMessages";

export { RedesignedUploadsPanelMessages } from "./RedesignedUploadsPanelMessages";

export { UploadButtonPresentational } from "./UploadButtonPresentational";

export type { Upload } from "./clients/uploadClient";

export const DEFAULT_SEARCH_TERM = "icon";

export { useUploadComponents } from "./useUploadComponents";

export enum UploadTypes {
    ICON = "Icon",
    IMAGE = "Image"
}

export enum ImageType {
    Upload = "upload",
    Noun = "noun-icon",
    DepositPhotos = "deposit-photos-image",
    Library = "library"
}

export { CustomUploadEvents, onUploadErrorEvent } from "./CustomUploadEvents";
export { useVerifySherbertFolderExistence } from "./useVerifySherbertFolderExistence";
export { validateAssetAndRetrieveDimensions, getUploadDimensions } from "./validateAsset";
