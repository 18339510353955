import { createSlice } from "@reduxjs/toolkit";
import { setError, showSaveLoader } from "../compoundActions";

const errorCodeSlice = createSlice({
    name: "errorCode",
    initialState: null as null | string,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(showSaveLoader, (state, action) => null);
        builder.addCase(setError, (state, action) => action.payload.errorCode || state);
    }
});

export const errorCode = errorCodeSlice.reducer;
