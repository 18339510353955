import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum DexName {
    Studio5 = "studio 5",
    Studio6 = "studio 6"
}

interface TrackingState {
    dexName: `${DexName}`;
}

/**
 * Used to store data relevant to event tracking, such as the current design experience
 * so that events can be distinguished across the different versions of Studio.
 */
const trackingSlice = createSlice({
    name: "tracking",
    initialState: null as TrackingState | null,
    reducers: {
        setTracking(state, action: PayloadAction<TrackingState>) {
            return action.payload ?? state;
        }
    }
});

export const { setTracking } = trackingSlice.actions;
export const tracking = trackingSlice.reducer;
