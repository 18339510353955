/**
 * This client wraps the udsClient `saveDocument` and adds additional logic around setting an owner and tenant,
 * getting the document JSON, additional actions on save, etc.
 */
import { type TeamsPlaceholder } from "@shared/redux";
import type { DSS } from "@vp/types-ddif";
import cloneDeep from "lodash/cloneDeep";
import { cropFractionCheck } from "../cropFractionCheck";
import { postOrPutDocument, getDocumentReference, getDocument } from "./udsClient";

export interface UdsResponse {
    url: string;
    documentRevisionUrl: string;
    instructionSourceUrl: string;
    previewInstructionSourceUrl: string;
}

interface SaveDocumentParams {
    owner?: string | null;
    udsTenant?: string;
    authToken: string;
    documentUrl?: string;
    documentMetadata?: {
        reviewInstructions?: DSS.ReviewInstructions[];
        surfaceUpsells?: DSS.SurfaceUpsell[];
        teamsPlaceholders?: TeamsPlaceholder[];
    };
    onSave?: () => void;
    document: DSS.DesignDocument;
}

/**
 * Saves the document to UDS under the given owner and tenant, triggers easel save event
 * @param owner The document owner string
 * @param udsTenant The tenant for UDS
 * @param authToken The authorization token for the request to UDS
 * @param [documentUrl] For existing documents, the existing UDS document URL. May be null
 * @param documentMetadata Optional metadata to add to the cimdoc
 * @return An object with the UDS response properties and document URLs
 */
export async function saveDocumentToUds(params: SaveDocumentParams): Promise<UdsResponse> {
    const { udsTenant, authToken, onSave, document, documentMetadata, owner, documentUrl } = params;

    const documentToBeSaved = cloneDeep<DSS.DesignDocument>(document);

    documentToBeSaved.metadata = {
        ...documentToBeSaved.metadata,
        ...documentMetadata
    };

    if (owner) {
        documentToBeSaved.owner = owner;
    }
    if (udsTenant) {
        documentToBeSaved.tenant = udsTenant;
    }

    let udsResponse;
    try {
        udsResponse = await postOrPutDocument(documentToBeSaved, authToken, documentUrl);
    } catch (originalError) {
        try {
            // if the intial save fails, try again without the document url.  Maybe it was invalid or just screwy
            // if we didn't have a document url, try again anyway.
            // Maybe it'll succeed, and the worst that happens is the user waits a bit longer to see an error
            udsResponse = await postOrPutDocument(documentToBeSaved, authToken, undefined, 10);
        } catch {
            throw originalError;
        }
    }

    // Doing a check for suspect crops & logging to see if this is a frequent problem
    cropFractionCheck(documentToBeSaved, udsResponse);

    const udsDocRefResponse = await getDocumentReference(udsResponse._links.documentRevision.href);

    onSave?.();

    // eslint-disable-next-line consistent-return
    return {
        url: udsResponse._links.self.href,
        documentRevisionUrl: udsResponse._links.documentRevision.href,
        // we want drawing and preview to have revision info so WES doesn't get URLs out of date
        instructionSourceUrl: udsDocRefResponse._links.drawingInstructions.href,
        previewInstructionSourceUrl: udsDocRefResponse._links.previewInstructions.href
    };
}

export async function cloneDocument(
    documentRevisionUrl: string,
    udsTenant: string,
    authToken: string
): Promise<UdsResponse> {
    const document = await getDocument(documentRevisionUrl, authToken);

    document.tenant = udsTenant;

    const udsResponse = await postOrPutDocument(document, authToken);

    return {
        url: udsResponse._links.self.href,
        documentRevisionUrl: udsResponse._links.documentRevision.href,
        instructionSourceUrl: udsResponse._links.drawingInstructions.href,
        previewInstructionSourceUrl: udsResponse._links.previewInstructions.href
    };
}
