import React, { useContext, createContext, ReactNode } from "react";
import { TrackingClient } from "@shared/utils/Tracking";

interface Props {
    children: ReactNode | ReactNode[];
    trackingClient: TrackingClient;
}

const TrackingClientContext = createContext<TrackingClient | undefined>(undefined);

export function useTrackingClient() {
    const client = useContext(TrackingClientContext);

    if (!client) {
        throw new Error(
            "No tracking client was provided to the TrackingClientProvider, or the TrackingClientProvider is missing."
        );
    }

    return client;
}

export function TrackingClientProvider({ children, trackingClient }: Props) {
    const { Provider } = TrackingClientContext;

    return <Provider value={trackingClient}>{children}</Provider>;
}

TrackingClientProvider.displayName = "TrackingClientProvider";
