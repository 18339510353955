import React, { useCallback } from "react";
import { UdsResponse } from "@shared/utils/DocumentStorage";
import {
    BusinessCardCompetitiveBaseliningExperimentData,
    PostcardCompetitiveBaseliningExperimentData
} from "@shared/features/CompetitiveBaselining";
import {
    Milestone1ExperimentData,
    Milestone2ExperimentData,
    Milestone3ExperimentData
} from "@shared/features/Flexibility";
import { SmartValidationsExperimentData } from "@shared/features/SmartValidations";
import loadable from "@loadable/component";
import { loadableRetry } from "@shared/utils/Network";
import type { DebugToolbarProps } from "@shared/features/DebugToolbar";
import { GenerateProofExperimentData } from "@shared/features/GlobalHeader";
import { Studio6CombinedTestGABExperimentData } from "@shared/features/Studio6ABTest";
import { UploadQRABExperimentData } from "@shared/features/QRUrlUploads";
import { switchContext, getDesignDocumentFromDesigner } from "../../../easel/utilities";
import { saveStudio5Document } from "../../clients/saveClient";
import * as styles from "./StudioFiveDebugTools.module.scss";

const DebugToolbar = loadable(() => loadableRetry(() => import("@shared/features/DebugToolbar")), {
    resolveComponent: components => components.DebugToolbar
}) as React.FC<DebugToolbarProps>;

export interface StudioFiveDebugToolbarProps {
    saveDocument: (authToken: string) => Promise<UdsResponse>;
    experiments: ExperimentData[];
}

const STUDIO_FIVE_DEBUG_EXPERIMENTS = [
    Milestone1ExperimentData,
    Milestone2ExperimentData,
    Milestone3ExperimentData,
    SmartValidationsExperimentData,
    BusinessCardCompetitiveBaseliningExperimentData,
    PostcardCompetitiveBaseliningExperimentData,
    GenerateProofExperimentData,
    Studio6CombinedTestGABExperimentData,
    UploadQRABExperimentData
];

export default function StudioFiveDebugTools() {
    const getDocument = useCallback(async () => getDesignDocumentFromDesigner(), []);

    return (
        <DebugToolbar
            getDocument={getDocument}
            saveDocument={saveStudio5Document}
            loadDocument={switchContext}
            experiments={STUDIO_FIVE_DEBUG_EXPERIMENTS}
            className={styles.debugContainer}
        />
    );
}

StudioFiveDebugTools.displayName = "StudioFiveDebugTools";
