import { newRelicWrapper } from "@shared/utils/Errors";
import { getDebouncedByType } from "@shared/utils/StandardLib";
import { fireGenericTrackingEvent } from "@shared/utils/Tracking";
import { Store, setError } from "@shared/redux";

// parse json object from errObj.message
// or if errObj is string, parse json object from it
// or return errObj, hoping it is a string.
function parseMessage(errObj: any) {
    try {
        return JSON.parse(errObj.message);
    } catch (e) {
        try {
            return JSON.parse(errObj);
        } catch (f) {
            return errObj;
        }
    }
}

function getErrorCode(err: any, studioErrorCode: string | number): string {
    let errorCode = `${ENTITY_CODE}-${studioErrorCode}`;

    if (err.errorCodeStack) {
        errorCode += `-${err.errorCodeStack}`;
    }

    return errorCode;
}

function reportErrorToNewRelic(
    originalError: any,
    convertedError: any,
    errorCode: string,
    fullErrorMessage: string,
    cleanErrorMessage: string
) {
    const errorData = {
        errorCode,
        moduleFunction: convertedError.moduleFunction,
        errorMessage: cleanErrorMessage,
        fullErrorMessage,
        fetchUrl: originalError?.url,
        bodyLength: originalError?.bodyLength
    };

    const newRelicError = new Error(cleanErrorMessage);
    newRelicError.stack = originalError.stack;

    newRelicWrapper.noticeError(newRelicError, errorData);
}

function reportRawErrorToSegment(message: string, code: string, stack: any, errorBoundary = false) {
    let updatedMessage = message;
    if (typeof updatedMessage === "object" && updatedMessage !== null) {
        updatedMessage = JSON.stringify(updatedMessage);
    }
    // filter out some unimportant errors
    if (updatedMessage?.includes("ResizeObserver") || updatedMessage === "Script error.") {
        return;
    }
    fireGenericTrackingEvent({
        event: "Studio Errors",
        eventDetail: errorBoundary ? "Error Page" : "Error",
        label: "Error",
        extraData: (state: any) => ({
            errorMessage: updatedMessage,
            stack,
            errorCode: code,
            easelLoaded: state.easelLoaded
        })
    });
}

export const debouncedReportRawErrorToSegment = getDebouncedByType(reportRawErrorToSegment, 1000);

function reportErrorToSegment(
    originalError: any,
    convertedError: any,
    errorCode: string,
    fullErrorMessage: string,
    cleanErrorMessage: string,
    showErrorPage: boolean
) {
    fireGenericTrackingEvent({
        event: "Studio Errors",
        eventDetail: showErrorPage ? "Error Page" : "Error Toast",
        label: "Error",
        extraData: (state: any) => ({
            errorCode,
            moduleFunction: convertedError.moduleFunction,
            errorMessage: cleanErrorMessage,
            fullErrorMessage,
            easelLoaded: state.easelLoaded,
            fetchUrl: originalError?.url,
            bodyLength: originalError?.bodyLength
        })
    });
}

function getCleanErrorMessage(convertedError: any, errorCode: string) {
    const probableMessage = convertedError.errorMessage || convertedError.message || convertedError;

    let possibleResponseMessage = "";
    try {
        const parsedResponse = JSON.parse(convertedError.replace("Response:", "").replaceAll(" ", ""));
        if (parsedResponse.message || parsedResponse.Message) {
            possibleResponseMessage = `: ${parsedResponse.message || parsedResponse.Message}`;
        }
        // eslint-disable-next-line no-empty
    } catch {}

    if (
        probableMessage.includes("DSS: Cannot read property 'widthCm' of undefined") ||
        probableMessage.includes("DSS: Product does not have any surfaces defined") ||
        probableMessage.includes("DSS: This product has no design views")
    ) {
        return "No surfaces are defined for this product";
    }
    if (errorCode.includes("20-400") && probableMessage.includes("is not a valid guid")) {
        return "Invalid Work ID";
    }
    if (errorCode.includes("-000")) {
        return `Unable to ${convertedError.friendlyDescription || convertedError.moduleFunction} due to network issues`;
    }
    if (probableMessage.includes("You do not have permission to access WorkId")) {
        return "User does not have permission to edit work";
    }
    if (errorCode.includes("20-401")) {
        return "User is not authenticated when attempting to access work";
    }
    if (
        (errorCode.includes("-54-404") || errorCode.includes("-54-400")) &&
        probableMessage.includes("No completion data")
    ) {
        return "Failed to retrieve complete set of options from RANCH: No completion data found";
    }
    if (errorCode.includes("-54-500")) {
        return "Failed to retrieve complete set of options from RANCH: Internal server error";
    }
    if (errorCode.includes("-54-422") && probableMessage.includes("configuration is invalid")) {
        return "Failed to retrieve complete set of options from RANCH: Provided configuration is invalid";
    }
    if (probableMessage.includes("DSS: There was a problem retrieving merchandising quantities for this product")) {
        return "Error retrieving merchandising quantities for this product while fetching surfaces from DSS";
    }
    if (probableMessage.includes("filterBySameDPS") && probableMessage.includes("Unknown error")) {
        return "Unknown error fetching template ensemble from DSS";
    }
    if (probableMessage.includes("The surface sizes don't match the document or template canvas sizes")) {
        return "Surface dimensions do not match the document dimensions";
    }
    if (probableMessage.includes("does not match `ownerId` in request body")) {
        return "User does not have permission to save another user’s work";
    }
    if (probableMessage.includes("DSS: Product has incomplete list of selectable options")) {
        return "Failed to retrieve surfaces due to incomplete or invalid combination of option selections";
    }
    if (probableMessage.includes("DSS: timeout of")) {
        return "DSS timed out waiting on dependent service";
    }
    if (errorCode.includes("20-500")) {
        return `Work Entity Service encountered internal error${possibleResponseMessage}`;
    }
    if (
        probableMessage.includes("Error occurred while getting quantities from product catalog") &&
        /-5\d{2}/.test(errorCode)
    ) {
        return "Product Catalog Quantity endpoint encountered internal error";
    }
    if (
        /.*surfaces\.products.*5\d{2}.*/.test(probableMessage) ||
        /.*5\d{2}.*surfaces\.products.*/.test(probableMessage)
    ) {
        return "Product Surface Specification Service encountered internal error";
    }
    if (errorCode.includes("-39-")) {
        return `UDS Error${possibleResponseMessage}`;
    }
    if (
        probableMessage.includes("The specified key does not exist") ||
        probableMessage.includes("No data found for mpvId")
    ) {
        return "MPV not found";
    }
    if (errorCode.includes("22-422")) {
        return "Unable to retrieve quantities";
    }
    if (probableMessage.includes("ImportFailureException")) {
        return "Failed to import altDocId";
    }
    if (errorCode === "10-55-24-404") {
        return "Pricing not found";
    }

    return probableMessage;
}

const getStackTrace = (errObj: { stack?: string; message: string }) => {
    return errObj.stack ? errObj.stack.replace("Error: ", "").replace(errObj.message, " ") : "";
};

class ErrorWithStack extends Error {
    constructor(message: string, readonly errorCodeStack: string, readonly stack: any) {
        super(message);
    }
}

export function handleError(
    originalError: any,
    studioErrorCode: string | number,
    showErrorPage = false,
    logError = true,
    hideErrorToast = false
) {
    const convertedError = parseMessage(originalError);
    const errorCode = getErrorCode(convertedError, studioErrorCode);

    const cleanErrorMessage = getCleanErrorMessage(convertedError, errorCode);

    if (logError) {
        Store.dispatch(
            setError({
                errorCode,
                errorStackTrace: getStackTrace(originalError),
                errorExtraData: convertedError,
                errorMessage: cleanErrorMessage,
                showErrorPage,
                hideErrorToast
            })
        );
    }

    let errorMessage = `Error Code: ${errorCode} - `;
    if (convertedError.moduleFunction) {
        errorMessage += convertedError.moduleFunction;
    }
    if (convertedError.response) {
        if (convertedError.response.url) {
            errorMessage += `[${convertedError.response.url}]`;
        }
        errorMessage += `[`;
        if (convertedError.response.status) {
            errorMessage += `(${convertedError.response.status})`;
        }
        if (convertedError.response.statusText) {
            errorMessage += `${convertedError.response.statusText}`;
        }
        errorMessage += `] `;
    }
    if (convertedError.errorMessage) {
        errorMessage += ` ${convertedError.errorMessage}`;
    }
    if (typeof originalError === "string") {
        errorMessage += ` ${convertedError}`;
    }

    if (logError) {
        reportErrorToNewRelic(originalError, convertedError, errorCode, errorMessage, cleanErrorMessage);
        reportErrorToSegment(originalError, convertedError, errorCode, errorMessage, cleanErrorMessage, showErrorPage);
    }

    const error = new ErrorWithStack(cleanErrorMessage, errorCode, originalError.stack);
    return error;
}
