import { tryFetch } from "@shared/utils/Network";

const host = LAT_CART_URL;
const entityCode = 31;

/**
 * Attempts to add the work entity to cart under the given locale,
 * or updates the work in cart if it's already been added
 * @param {string} locale - the locale of the page
 * @return The output of adding the work to cart
 */
export async function callLatCart(authToken: string, locale: string, workId: string) {
    let url = `${host}/v1/upsert/${workId}?tenant=${LAT_TENANT}`;
    if (locale) {
        url += `&locale=${locale}`;
    }

    const content = await tryFetch({
        url,
        options: {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        },
        moduleFunction: "latCartClient:addToCart",
        friendlyDescription: "add to cart",
        entityCode,
        retryCount: 0
    });

    return content;
}
