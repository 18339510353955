import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDefaultState } from "../compoundActions";

const currencySlice = createSlice({
    name: "currency",
    initialState: "",
    reducers: {
        setCurrency: (state, action: PayloadAction<string | null>) => action.payload || state
    },
    extraReducers: builder => {
        builder.addCase(setDefaultState, (state, action) => action.payload.currency || state);
    }
});

export const { setCurrency } = currencySlice.actions;
export const currency = currencySlice.reducer;
