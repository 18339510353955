import { createSlice } from "@reduxjs/toolkit";
import { setError, showSaveLoader } from "../compoundActions";

const errorStackTraceSlice = createSlice({
    name: "errorStackTrace",
    initialState: "" as null | string,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(showSaveLoader, (state, action) => null);
        builder.addCase(setError, (state, action) => action.payload.errorStackTrace || state);
    }
});

export const errorStackTrace = errorStackTraceSlice.reducer;
