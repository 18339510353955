import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EDIT_FROM_CART_MODAL_TYPE } from "@shared/utils/Cart";

interface State {
    showModal: boolean;
    modalType?: EDIT_FROM_CART_MODAL_TYPE;
    callback?: () => void;
    changesUpdatedModalSeen?: boolean;
}

const initialState: State = { showModal: false };

const showEditInCartModalSlice = createSlice({
    name: "showEditInCartModal",
    initialState,
    reducers: {
        showEditInCartModal(state, action: PayloadAction<State>) {
            return {
                ...state,
                showModal: action.payload.showModal,
                modalType: action.payload.modalType || undefined,
                changesUpdatedModalSeen: action.payload.changesUpdatedModalSeen || state.changesUpdatedModalSeen,
                callback: action.payload.callback || undefined
            };
        }
    }
});

export const { showEditInCartModal } = showEditInCartModalSlice.actions;
export const editInCartModal = showEditInCartModalSlice.reducer;
