export const scaleToFitCropStrategy = {
    core: {
        items: {
            image: {
                placeholder: {
                    strategy: "scale"
                }
            }
        }
    }
};

export const defaultCropStrategy = {
    core: {
        items: {
            image: {
                placeholder: {
                    strategy: undefined
                }
            }
        }
    }
};
