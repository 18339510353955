import React, { useContext, createContext, useEffect, useState, useMemo, ReactNode, useCallback } from "react";
import { useAppSelector } from "@shared/redux";
import { useAbTestContext } from "@shared/features/ABTesting";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";

const LOCALE_LIST = ["en-us"];

const BusinessCardCompetitiveBaseliningPRDList = {
    "PRD-IYXT1T3V": true, // NA Business Cards - Standard
    "PRD-GURNTEDA": true, // NA Business Cards - Rounded Corner
    "PRD-XRO5HANH": true, // NA Business Cards - Premium
    "PRD-4OVSFBSP": true, // Na Business Cards - Soft Touch
    "PRD-40GK1WRI": true // NA Business Cards - Square
};

const PostcardCompetitiveBaseliningPRDList = {
    "PRD-ENRS4XZIS": true // US Postcards (non-Mailable)
};

type ContextData = {
    isCompetitiveBaseliningEnabled: boolean;
    isCompetitiveBaseliningEnabledOnTop: boolean;
    isCompetitiveBaseliningEnabledOnBottom: boolean;
    isTestActive: boolean;
};

const context = createContext<ContextData | undefined>(undefined);

export function useCompetitiveBaselining() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context.  This must be called within a CompetitiveBaseliningABProvider");
    }
    return result;
}
enum Variations {
    Control = "control",
    XeroxEnabledOnTop = "variation_1",
    XeroxEnabledOnBottom = "variation_2"
}

export const BusinessCardCompetitiveBaseliningExperimentData: ExperimentData = {
    experimentKey: "competitive_baseline_business_cards__iteration_2",
    experimentName: "Business Card Competitive Baselining",
    variations: Variations
};

export const PostcardCompetitiveBaseliningExperimentData: ExperimentData = {
    experimentKey: "competitive_baseline_postcards__iteration_2",
    experimentName: "Postcard Competitive Baselining",
    variations: Variations
};

type Props = {
    children: ReactNode | ReactNode[];
};

export const CompetitiveBaseliningABProvider = ({ children }: Props) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { experimentKey: businessCardKey } = BusinessCardCompetitiveBaseliningExperimentData;
    const { experimentKey: postcardKey } = PostcardCompetitiveBaseliningExperimentData;
    const [variation, setVariation] = useState<Variations>(Variations.Control);
    const [isTestActive, setIsTestActive] = useState(false);
    const productKey = useAppSelector(state => state.productKey);
    const locale = useAppSelector(state => state.locale);
    const { isLarge } = useStudioLayout();

    const updateExperiment = useCallback(
        (experimentKey: string, productList) => {
            if (!experimentKey || !ABTest) {
                return;
            }
            const { isExperimentUsingVariation, trackImpression, isExperimentActive } = ABTest;
            isExperimentActive(experimentKey).then((active: boolean) => setIsTestActive(active));
            const trackImpressionIfInVariant = async (variation: Variations) => {
                const res = await isExperimentUsingVariation(experimentKey, variation);

                const shouldEnableTest =
                    productList[productKey] && isLarge && LOCALE_LIST.includes(locale.toLowerCase());

                if (res && shouldEnableTest) {
                    trackImpression(experimentKey, variation);
                    setVariation(variation);
                }
            };

            trackImpressionIfInVariant(Variations.XeroxEnabledOnTop);
            trackImpressionIfInVariant(Variations.XeroxEnabledOnBottom);
            trackImpressionIfInVariant(Variations.Control);
        },
        [ABTest, isLarge, locale, productKey]
    );

    useEffect(
        () => updateExperiment(businessCardKey, BusinessCardCompetitiveBaseliningPRDList),
        [businessCardKey, updateExperiment, ABTest]
    );
    useEffect(
        () => updateExperiment(postcardKey, PostcardCompetitiveBaseliningPRDList),
        [postcardKey, updateExperiment, ABTest]
    );

    const contextObject = useMemo(
        () => ({
            isCompetitiveBaseliningEnabled: variation !== Variations.Control,
            isCompetitiveBaseliningEnabledOnTop: variation === Variations.XeroxEnabledOnTop,
            isCompetitiveBaseliningEnabledOnBottom: variation === Variations.XeroxEnabledOnBottom,
            isTestActive
        }),
        [isTestActive, variation]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

CompetitiveBaseliningABProvider.displayName = "CompetitiveBaseliningABProvider";
